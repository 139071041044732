import '@/styles/index.styl'

import i18n from '@/core/i18n'
import { createApp } from 'vue'
import App from './App.vue'
import GlobalComponents from '@/components/_index'

const app = createApp(App)

GlobalComponents.register(app);

app.use(i18n)

app.mount('#app')
