import { ActivityId } from './activities/Activity';
import UrlParams from './core/UrlParams';
import './Features'
import GLView from './GLView';
import Renderer from './Renderer';
import XRView from './xr/XRView';

export default class GLApp {

  private _running = false;

  readonly glview: GLView
  readonly xrview: XRView
  readonly renderer: Renderer

  constructor() {
    const canvas = document.createElement( 'canvas' )
    this.glview = new GLView(canvas);
    this.xrview = new XRView(this.glview.gl);
    this.renderer  = new Renderer( this.glview, this.xrview );
  }

  loadActivity( id: ActivityId ): Promise<void> {
/////////////////
//////////////////////////////////////
//////////////
    return this.renderer.loadActivity(id);
  }

  get activity(){
    return this.renderer.activity;
  }

}


/////////////

/////////////////
//////////////////////
 

//////////