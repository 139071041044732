import GLApp from "@webgl/GLApp";
import NetworkServices from "./network/NetworkServices";
import { CreateAppStateInterpreter, AppStateInterpreter } from "./states/AppStateMachine";


const NoMulti = process.env.VUE_APP_NO_MULTIPLAYER === 'true'

export class AppServiceClass {

  private static _instance: AppServiceClass;

  readonly network: NetworkServices;
  readonly state : AppStateInterpreter
  readonly glapp: GLApp;

  static getInstance(){
    if(!AppServiceClass._instance){
      AppServiceClass._instance = new AppServiceClass();
    }
    return AppServiceClass._instance;
  }
  
  constructor(){
    this.network = new NetworkServices(this)
    this.state = CreateAppStateInterpreter()
    this.glapp = new GLApp()
  }
  
  start(){
    this.state.start()
    this.state.send( {type: 'INIT', noMulti: NoMulti } )
  }
  

}

const AppService = AppServiceClass.getInstance()

export default AppService