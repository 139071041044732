import { GameState } from "./GameStateMachine"

export const MAX_COUNTDOWN = 5 * 60
export const COUNTDOWN_ALMOST_OVER = 30

/**
 * Encode the transformed reference space set on XR session
 */
export type GamePlayerPosition = {

  /**
   * X position of the xr player in the game world
   */
  x: number,

  /**
   * Y position of the xr player in the game world
   */
  y: number

  /**
   * heading angle in radian of the player
   */
  heading: number
}

export function DefaultGamePlayerPosition() : GamePlayerPosition{
  return { x: -4, y: -3.2, heading: 1.61}
}
export function DebugGamePlayerPosition() : GamePlayerPosition{
  return { x: 3.56, y: -1.2, heading:1.8}
}

export function WinGamePlayerPosition() : GamePlayerPosition{
  return { x: 2.80, y: -2.0, heading: 1.21 }
}

export function FailGamePlayerPosition() : GamePlayerPosition{
  return { x: 2.80, y: -2.0, heading: 1.21 }
}

export function Step1GamePlayerPosition() : GamePlayerPosition{
  return { x: -4.02, y: 0.42, heading: 1.54 }
}

export function Step2GamePlayerPosition() : GamePlayerPosition{
  return { x: -1.49, y: 0.01, heading: -0.08 }
}

export function Step3GamePlayerPosition() : GamePlayerPosition{
  return { x: 1.26, y: 1.76, heading: 1.55 }
}

export function Step4GamePlayerPosition() : GamePlayerPosition{
  return { x: 3.48, y: 1.93, heading: 0.03 }
}

export function Step5GamePlayerPosition() : GamePlayerPosition{
  return { x: 5.25, y: -1.40, heading: 0.1 }
}

export function Step1DoorGamePlayerPosition() : GamePlayerPosition{
  return { x: -4.1, y: -2.13, heading: 1.61 }
}

export function Step2DoorGamePlayerPosition() : GamePlayerPosition{
  return { x: -3.55, y: 0.05, heading: -0.1 }
}

export function Step3DoorGamePlayerPosition() : GamePlayerPosition{
  return { x: 1.12, y: -0.36, heading: 1.5 }
}

export function Step4DoorGamePlayerPosition() : GamePlayerPosition{
  return { x: 1.67, y: 1.75, heading: -0.03 }
}

export function Step5DoorGamePlayerPosition() : GamePlayerPosition{
  return { x: 3.38, y: -1.54, heading: 0.03 }
}


export function playerPositionEquals(a: GamePlayerPosition, b: GamePlayerPosition) : boolean {
  return a.x === b.x && a.y === b.y && a.heading === b.heading
}

export function playerScreenPosition(pos: GamePlayerPosition): number[] {
  const x = pos.y
  const y = pos.x

  const width = 4
  const height = width * 1.3

  const normalizedX = x / width
  const normalizedY = y / height

  const offsetX = normalizedX * 0.5 + 0.5
  const offsetY = 1 - (normalizedY * 0.5 + 0.5)

  return [offsetX, offsetY]
}

export function getNextStepPosition(state: GameState): GamePlayerPosition {
  if (state.matches('tuto')) {
    return Step1GamePlayerPosition()
  }
  if (state.matches('game.bedroom')) {
    return Step2GamePlayerPosition()
  }
  if (state.matches('game.corridor')) {
    return Step3GamePlayerPosition()
  }
  if (state.matches('game.studyroom')) {
    return Step4GamePlayerPosition()
  }
  if (state.matches('game.living')) {
    return Step5GamePlayerPosition()
  }

  return DefaultGamePlayerPosition()
}

export function getDoorStepPosition(state: GameState): GamePlayerPosition {
  if (state.matches('tuto')) {
    return Step1DoorGamePlayerPosition()
  }
  if (state.matches('game.bedroom')) {
    return Step2DoorGamePlayerPosition()
  }
  if (state.matches('game.corridor')) {
    return Step3DoorGamePlayerPosition()
  }
  if (state.matches('game.studyroom')) {
    return Step4DoorGamePlayerPosition()
  }

  return Step5DoorGamePlayerPosition()
}

export enum HotspotId {
  Step0_Door,
  Step0_Continue,

  Step1_CloseDoor,
  Step1_Window,
  Step1_Door,
  Step1_Continue,

  Step2_CloseDoor,
  Step2_OpenDoor,
  // Step2_Crouch,
  Step2_Door,
  Step2_Continue,

  Step3_CloseDoor,
  Step3_Window,
  Step3_Bag,
  Step3_Door,
  Step3_Continue,

  Step4_CloseDoor,
  Step4_Call911,
  Step4_WetTowel,
  Step4_Door,
  Step4_Continue,

  Step5_Lift,
  Step5_Stairs,
}

export enum HotspotAudioId {
  Fire,
  Alarm1,
  Alarm2,
  Cat,
  Peace
}

export enum DoorId {
  Guestroom_Bedroom,
  Bedroom_Corridor,
  Corridor_Living,
  Corridor_Studyroom,
  Studyroom_Living,
  Living_Landing,
  Elevator,
  Stairs,
}