import Node from "nanogl-node";
import UnlitPass from "nanogl-pbr/UnlitPass";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import { quat } from "gl-matrix";
import { GLContext } from "nanogl/types";
import { GltfBaseMaterial } from "nanogl-gltf/lib/elements/Material";

import RenderMask from "@webgl/core/RenderMask";
import GltfResource from "@webgl/resources/GltfResource";
import CameraController from "./controllers/CameraController";
import { RenderContext } from "@webgl/core/Renderer";

export default class CameraTargetRenderer {
  cfg: LocalConfig
  res: GltfResource

  aimTarget: MeshRenderer
  targetNode: Node

  _loadPromise: Promise<void>

  constructor(
    public readonly gl: GLContext,
    public readonly context: RenderContext,
    public readonly root: Node,
    public readonly camCtrl: CameraController
  ) {
    this.cfg = GLState.get(this.gl).config()
    this.cfg
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(this.gl.ONE, this.gl.ONE);

    this.res = new GltfResource(
      "xr/aim_target/aim_target.gltf",
      this.context
    );
  }

  async load(): Promise<void> {
    if (!this._loadPromise) {
      this._loadPromise = this._doLoad();
    }
    return this._loadPromise
  }

  private async _doLoad() {
    await this.res.load().then(this.init);
  }

  init = () => {
    const gl = this.gl
    const gltf = this.res.gltf

    this.aimTarget = gltf.renderables[0] as MeshRenderer;
    this.targetNode = this.aimTarget.node
    this.targetNode.scale.set([0.5, 0.5, 0.5])
    this.root.add(this.targetNode)

    const glow = gltf.getMaterial("Glow") as GltfBaseMaterial<UnlitPass>;
    const uwhite = gltf.getMaterial("UnlitWhite") as GltfBaseMaterial<UnlitPass>;
    glow.materialPass.baseColorFactor.attachConstant(0.0, "r");
    uwhite.materialPass.baseColorFactor.attachConstant(0.65, "r");

    glow.materialPass.mask = RenderMask.BLENDED
    uwhite.materialPass.mask = RenderMask.BLENDED

    glow.materialPass.glconfig
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(gl.ONE, gl.ONE);

    uwhite.materialPass.glconfig
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(gl.ONE, gl.ONE);
  }

  preRender() {
    this.targetNode.position.set([this.camCtrl.target[0], this.camCtrl.target[1] + 0.01, this.camCtrl.target[2]])
    this.targetNode.rotation.set(this.camCtrl.camera.rotation);
    this.targetNode.rotation[0] = 0;
    this.targetNode.rotation[2] = 0;
    quat.normalize(this.targetNode.rotation, this.targetNode.rotation);
    this.targetNode.invalidate()
    this.targetNode.updateWorldMatrix()
  }

  render(ctx: RenderContext) {
    if (ctx.mask & RenderMask.BLENDED && this.camCtrl.enabled && !this.camCtrl.isMoving && !this.camCtrl.isCrouching) {
      this.cfg.apply();

      if (this.camCtrl.hasTarget) {
        this.aimTarget.render(
          ctx.gl,
          ctx.camera,
          ctx.mask,
          ctx.pass
        )
      }
    }
  }
}