import Node from "nanogl-node"
import { GLContext } from "nanogl/types"

import Fire from "@webgl/entities/Fire"
import Room from "@webgl/entities/Room"
import Audios from "../common/Audios"
import Embers from "@webgl/embers/Embers"
import Renderer from "@webgl/Renderer"
import FogProps from "@webgl/fog/FogProps"
import Lighting from "@webgl/engine/Lighting"
import Vignette from "@webgl/entities/Vignette"
import BlackFade from "@webgl/entities/BlackFade"
import DebugDraw from "@webgl/dev/debugDraw/DebugDraw"
import AppService from "@/services/AppService"
import RenderMask from "@webgl/core/RenderMask"
import AudioManager from "@/core/audio/AudioManager"
import GameStateWatcher from "@/services/states/GameStateWatcher"
import DesktopControllers from "./controllers/DesktopControllers"
import CameraTargetRenderer from "./CameraTargetRenderer"
import RoomLoadingController from "@webgl/activities/common/controllers/RoomLoadingController"
import { FogVFX } from "@webgl/fog/FogVFX"
import type { Activity } from "../Activity"
import { GameState } from "@/services/states/GameStateMachine"
import { RenderContext } from "@webgl/core/Renderer"
import { HotspotsDesktop } from "@webgl/entities/Hotspots"
import Zones from "@webgl/entities/Zones"

/**
 * Main class rendering and managing webgl rendering part of Desktop experience
 */
export default class DesktopActivity implements Activity {
  readonly gl: GLContext

  fire: Fire
  root: Node
  room: Room
  fade: BlackFade
  audio: Audios
  embers: Embers
  fogVFX: FogVFX
  lighting: Lighting
  fogProps: FogProps
  hotspots: HotspotsDesktop
  vignette: Vignette
  camTarget: CameraTargetRenderer
  roomLoader: RoomLoadingController
  controllers: DesktopControllers
  gameStateWatcher: GameStateWatcher
  zones: Zones

  constructor(public renderer:Renderer){
    this.gl = renderer.gl

    this.root = new Node()

    this.lighting = new Lighting(this.gl)
    this.root.add(this.lighting.root)

    this.fade = new BlackFade(this.gl)
    this.vignette = new Vignette(this.gl, true)
    this.fogProps = new FogProps()
    this.fogProps.density = 0.1
    this.controllers = new DesktopControllers(this)

    this.room = new Room(this.gl, this.fogProps)
    this.audio = new Audios(this)
    this.hotspots = new HotspotsDesktop(this)
    this.camTarget = new CameraTargetRenderer(this.gl, this.renderer.context, this.root, this.controllers.cameraCtrl)
    this.zones = new Zones()
    this.roomLoader = new RoomLoadingController(this.room, this.fade.createControl(), 0)
    this.gameStateWatcher = new GameStateWatcher()

    this.fire = new Fire(this.fogProps, this.gl, this.root)
    this.embers = new Embers(this.fogProps, this.gl)
    this.fogVFX = new FogVFX(this);

    this.fogVFX.addFire(this.fire);
    this.fogVFX.addOccluder(this.room);


    this.roomLoader.onLevelChanged.on(this.onRoomLevelReady)
    this.roomLoader.onFadeIn.on(this.onRoomLevelFadeIn)

    this.gameStateWatcher.onStateChange.on(this.stateChange)

/////////////////
////////////////////////
///////////////////////////////////////////////////
//////////////
  }

///////////////
//////////////////////////
/////////////////
////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//////
///
////////////

  load(): Promise<void> {
    const addLoad = () => { AppService.state.send('LOAD_GL_ELEMENT') }

    const toLoad = [
      this.lighting.load().then(addLoad),
      this.fire.load().then(addLoad),
      this.embers.load().then(addLoad),
      this.camTarget.load().then(addLoad),
      this.hotspots.load().then(addLoad),
      this.zones.load().then(addLoad),
      this.room.loadCommons().then(addLoad),
      ...this.fogVFX.load().map(p => p.then(addLoad))
    ]

    AppService.state.send({ type: 'LOAD_GL_TOTAL', total: toLoad.length })

    return Promise.all(toLoad).then( ()=>this.onLoaded())
  }

  onLoaded(){
    this.controllers.start()
    this.controllers.cameraCtrl.enable()
    this.controllers.cameraCtrl.teleportPlayer()
    this.audio.start()
  }

  unload(): void {
    this.controllers.cameraCtrl.disable()
    this.lighting.dispose()
  }

  preRender(): void {
    this.fogProps.update(null, this.room );
    this.hotspots.update()

    this.room.preRender()
    this.fire.preRender()
    this.embers.preRender()
    this.fogVFX.preRender()
    this.controllers.cameraCtrl.preRender()
    this.controllers.pickingManager.preRender()
    this.camTarget.preRender()

    this.root.updateWorldMatrix()

    AudioManager.updateListener(this.renderer.camera._wmatrix)
  }

  rttPass(): void {
    this.lighting.lightSetup.prepare(this.gl);
    // draw lighmaps here if needed
  }

  clearColor(){
    const c = this.fogProps.fogColorA
    this.gl.clearColor(c[0], c[1], c[2], 1)
  }

  render(): void {
    const gl = this.gl

    this.preRender()
    this.rttPass()

    const context = this.renderer.context
    const ctxObj = context.toObject();

    this.fogVFX.render(ctxObj);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    this.clearColor()
    gl.clear( this.gl.COLOR_BUFFER_BIT );

    this.renderScene( ctxObj )

    DebugDraw.render( context )
    DebugDraw.clear()
  }

  renderXR(): void {
    // noop on desktop activity
  }

  private renderScene(context: RenderContext): void {
    context.viewport.setupGl(this.gl)

    this.drawScene(context)
    this.embers.render(context);
    this.renderFullScreen(context)
  }

  private drawScene(context: RenderContext): void {
    this.renderSceneWithMask( context, RenderMask.OPAQUE )
    // this.skybox.render( context )
    this.renderSceneWithMask( context, RenderMask.BLENDED )
  }

  private renderSceneWithMask(context: RenderContext, mask: RenderMask): void {
    context.mask = mask
    this.room.render(context)
    this.camTarget.render(context)
  }

  private renderFullScreen(context: RenderContext):void {
    context.mask = RenderMask.BLENDED
    this.vignette.render()
    this.fade.render()
  }

  /**
   * called when room has loaded a new level
   */
   onRoomLevelReady = () => {
    this.controllers.cameraCtrl.navmesh = this.room.currentLevel.navmesh
  }

  /**
   * when changing room, immediately move the player
   */
  onRoomLevelFadeIn = () => {
    this.controllers.cameraCtrl.teleportPlayer()
  }

  stateChange = (state: GameState) => {
    if (state.event.type !== 'GAME_RESET' &&
      state.event.type !== 'GAME_BACK_HOME' && state.event.type !== 'GAME_RESTART') return
    this.reset()
  }

  reset() {
    this.controllers.reset()
    this.fire.reset()
    this.embers.reset()
  }

}
