import { GLContext } from "nanogl/types";
import { FogScattering } from "@webgl/fog/FogScattering";
import VolLightDepth from "@webgl/fog/VolLightDepth";
import SpotShadows from "@webgl/fog/SpotShadows";
import FogProps from "@webgl/fog/FogProps";
import LightCone from "@webgl/entities/LightCone";
import Texture2D from "nanogl/texture-2d";
import Node from "nanogl-node";
import { RenderContext } from "@webgl/core/Renderer";
import WebglAssets from "@webgl/resources/WebglAssets";
import Time from "@webgl/Time";
import XRView from "@webgl/xr/XRView";
import { mat4 } from "gl-matrix";
import XRActivity from "@webgl/activities/xr/XRActivity";
import DesktopActivity from "@webgl/activities/desktop/DesktopActivity";

export type Renderable = {
    worldMatrix: mat4;
    render(context: RenderContext): void;
}

type EyePass = {
    render(ctx: RenderContext, eye?: string): void;
}

export class FogVFX
{
    fogScattering: FogScattering;
    spotShadows: SpotShadows;
    volLightDepth: VolLightDepth;
    lightBeam: LightCone;
    private blackTex: Texture2D;
    gl: GLContext;
    fogProps: FogProps;
    root: Node;

    constructor(private activity: XRActivity | DesktopActivity, private hasSpotlight?: boolean)
    {
        this.gl = activity.gl;
        this.fogProps = activity.fogProps
        this.root = activity.root

        this.fogScattering = new FogScattering(this.gl, activity.room);
        this.spotShadows = new SpotShadows(this.fogProps, this.gl);

        this.blackTex = new Texture2D(this.gl);
        this.blackTex.fromData(1, 1, new Uint8Array([ 0, 0, 0, 0 ]));

        if (this.hasSpotlight) {
            this.volLightDepth = new VolLightDepth(this.gl);
            this.lightBeam = new LightCone(this.fogProps, this.gl, this.root, this.volLightDepth);

            this.volLightDepth.addSource(this.lightBeam);
        }

        this.fogProps.waveTex = WebglAssets.getTexture("fog/wave-map.jpg", this.gl);
        this.fogProps.spotTex = WebglAssets.getTexture("fog/flashlight.jpg", this.gl);
        this.fogProps.spotTex.clamp();

        this.fogProps.spotShadowMap = this.spotShadows.texture;
    }

    addFire(obj: Renderable)
    {
        // this.fogScattering.addRenderable(obj);
    }

    addOccluder(obj: Renderable)
    {
        if (this.hasSpotlight) {
            this.volLightDepth.addOccluder(obj);
            this.spotShadows.addCaster(obj);
        }
        this.fogScattering.addRenderable(obj);
    }

    preRender(): void
    {
        if (this.hasSpotlight) {
            this.spotShadows.preRender();
            this.lightBeam.preRender();
            this.volLightDepth.preRender();
        }
        this.fogScattering.preRender();

        const fogProps = this.fogProps;

        fogProps.waveTimeFactor -= this.fogProps.waveSpeed * Time.dt;
        if( fogProps.waveTimeFactor < -100 ) fogProps.waveTimeFactor = 100;
    }

    private renderScattering(xrview_context: XRView)
    {
        const fogDensity = this.fogProps.density;
        this.fogProps.density = 0.0;
        this.setScatterTex(null);
        this.fogScattering.bind();

        this.renderEyes(this.fogScattering, xrview_context);

        this.fogScattering.finalize();
        this.setScatterTex(this.fogScattering.texture);
        this.fogProps.density = fogDensity;
    }

    render(xrview_context: XRView | RenderContext): void
    {
        if (this.hasSpotlight) {
            this.spotShadows.render();
        }

        this.renderScattering(xrview_context as XRView);

        if (this.hasSpotlight) {
            this.volLightDepth.bind();
            this.renderEyes(this.volLightDepth, xrview_context);
        }

        this.fogProps.screenTexScale[0] = 1.0;
        this.fogProps.screenTexOffset[0] = 0.0;
    }

    setRenderEye(eye: string)
    {
        if (eye === "left") {
            this.fogProps.screenTexScale[0] = 0.5;
            this.fogProps.screenTexOffset[0] = 0;
        }
        else if (eye === "right") {
            this.fogProps.screenTexScale[0] = 0.5;
            this.fogProps.screenTexOffset[0] = 0.5;
        }
        else {
            this.fogProps.screenTexScale[0] = 1.0;
            this.fogProps.screenTexOffset[0] = 0.0;
        }
    }

    private setScatterTex(tex: Texture2D)
    {
        this.fogProps.scatterTex = tex ?? this.blackTex;
    }

    private renderEyes(pass: EyePass, xrview_context: XRView | RenderContext)
    {
        if (xrview_context instanceof XRView) {
            const ctxLeft: RenderContext = xrview_context.headset.getEyeContext("left");
            if (ctxLeft) pass.render(ctxLeft, "left");
            const ctxRight: RenderContext = xrview_context.headset.getEyeContext("right");
            if (ctxRight) pass.render(ctxRight, "right");
        }
        else {
            pass.render(xrview_context);
        }
    }

    load(): Promise<any>[]
    {
        if (this.hasSpotlight) {
            return  [this.fogProps.waveTex.load(), this.fogProps.spotTex.load(), this.lightBeam.load()]
        }

        return [this.fogProps.waveTex.load(), this.fogProps.spotTex.load()]
    }
}