/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActorRef, assign, createMachine, forwardTo, Interpreter, send, sendParent, spawn, State, StateMachine } from "xstate";
import AppService from "../AppService";

import { NetworkEvent } from "./NetworkEvents";

const NoMulti = process.env.VUE_APP_NO_MULTIPLAYER === 'true'

function CreateContext(){
  return {
    actorCount: NoMulti?2:0,
    roomid: null as string,
    isMain: false,
    error: null as string,
    connectionService: null as ActorRef<any, any>
  }
}

export type NetworkStateContext = ReturnType<typeof CreateContext>;




export type NetworkStateType = {
  value: string
  context: NetworkStateContext
}


export type NetworkState = State<NetworkStateContext, NetworkEvent, any, any>

export type NetworkStateMachine = StateMachine<NetworkStateContext, any, NetworkEvent, NetworkStateType>


export function CreateNetworkStateMachine(){
  return createMachine<NetworkStateContext, NetworkEvent, NetworkStateType>({

    id: "network",

    initial: 'Uninitialized',

    context: CreateContext(),

    entry: ['spawnConnectionService'],

    on: {
      
      NETWORK_ACTOR_COUNT : {actions:assign({actorCount: (context, event) => event.count})},

      NETWORK_CLIENT_ERROR                   : ".Error"                    ,
      NETWORK_CLIENT_UNINITIALIZED           : ".Uninitialized"            ,

      NETWORK_CLIENT_CONNECTINGTONAMESERVER  : ".connecting.ConnectingToNameServer"   ,
      NETWORK_CLIENT_CONNECTEDTONAMESERVER   : ".connecting.ConnectedToNameServer"    ,
      NETWORK_CLIENT_CONNECTINGTOMASTERSERVER: ".connecting.ConnectingToMasterserver" ,
      NETWORK_CLIENT_CONNECTEDTOMASTER       : ".connecting.ConnectedToMaster"        ,
      NETWORK_CLIENT_CONNECTINGTOGAMESERVER  : ".connecting.ConnectingToGameserver"   ,
      NETWORK_CLIENT_CONNECTEDTOGAMESERVER   : ".connecting.ConnectedToGameserver"    ,

      NETWORK_CLIENT_JOINEDLOBBY             : ".JoinedLobby"              ,
      NETWORK_CLIENT_JOINED                  : ".Joined"                   ,
      NETWORK_CLIENT_DISCONNECTED            : ".Disconnected"             ,

    },

    states: {


      // Critical error occurred.
      Error: {
      },
        // Client is created but not used yet.
      Uninitialized: {
        entry: 'resetRoomId',
        on:{
          NETWORK_CLIENT_UNINITIALIZED: undefined,
          NETWORK_CONNECT: {
            actions: [
              'initConnect',
              forwardTo('connectionService')
            ]
          }
        }
      },

      connecting: {

        states : {
          ConnectingToNameServer  : {},// Connecting to NameServer.
          ConnectedToNameServer   : {},// Connected to NameServer.
          ConnectingToMasterserver: {},// Connecting to Master (includes connect, authenticate and joining the lobby).
          ConnectedToMaster       : {},// Connected to Master server.
          ConnectingToGameserver  : {},// Connecting to Game server(client will authenticate and join/create game).
          ConnectedToGameserver   : {},// Connected to Game server (going to auth and join game).
        }

      },

      // Connected to Master and joined lobby. Display room list and join/create rooms at will.
      JoinedLobby: {

        initial:'joinroom',
        on:{
          NETWORK_ALREADY_JOINED_ERROR: '#network.Error',
          NETWORK_FULLROOM_ERROR: '.roomfull',
          NETWORK_UNKNOWN_ROOM_ERROR: '.unknown',
          NETWORK_RETRY: '.joinroom',
          NETWORK_CLIENT_JOIN_ROOM: {
            target: '.joinroom',
            actions: 'assignRoomId'
          }
        },

        states: {
          joinroom: {
            entry: 'networkJoinRoom',
          },
          roomfull: {
            entry: sendParent((ctx) => ({ type: 'NETWORK_FULLROOM', payload: ctx.isMain }))
          },
          unknown: {
            entry: sendParent('NETWORK_ROOM_UNKNOWN')
          }
        }
      },
      // The client joined room.
      Joined: {
        entry: sendParent((ctx) => ({ type: 'NETWORK_ROOM_JOINED', payload: ctx.isMain })),
        on: {
          NETWORK_DISCONNECT: {
            actions: [
              forwardTo('connectionService'),
              'resetRoomId'
            ]
          }
        }
      },

      // The client is no longer connected (to any server). Connect to Master to go on.
      Disconnected: {
        on:{
          NETWORK_CONNECT: {
            actions: [
              'initConnect',
              forwardTo('connectionService')
            ]
          }
        }
      },

    }

  }, {

    actions: {

      spawnConnectionService: assign<NetworkStateContext, NetworkEvent>({
        connectionService: ()=>spawn( AppService.network.getConnectionService(),{name:'connectionService'})
      }),

      resetRoomId: assign< NetworkStateContext, NetworkEvent >({
        roomid: null
      }),

      assignRoomId: assign< NetworkStateContext, NetworkEvent >({
        roomid: (ctx, evt)=>evt.type === 'NETWORK_CLIENT_JOIN_ROOM' ? evt.roomid : ctx.roomid
      }),

      initConnect: assign< NetworkStateContext, NetworkEvent >({
        roomid: (ctx, evt)=> evt.type === 'NETWORK_CONNECT' ? evt.roomid : ctx.roomid,
        isMain: (ctx, evt)=> evt.type === 'NETWORK_CONNECT' ? evt.isMain : ctx.isMain,
      }),

      networkJoinRoom: send((ctx: NetworkStateContext) => ({
        type: 'NETWORK_JOIN_ROOM', roomid: ctx.roomid, isMain: ctx.isMain
      }), { to: 'connectionService' })

    },

    services: {

    }

  })
}


export type NetworkStateInterpreter = Interpreter<NetworkStateContext, any, NetworkEvent, NetworkStateType>
