import { State } from "xstate";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stateMatch( state: State<any, any, any, any>, states: string | string[] ){
  if( states instanceof Array ){
    return states.some( s => state.matches( s ) )
  }
  return state.matches( states )
}

/**
 * convert time in second into mm:ss format
 */
export function formatCountdown(time:number){
  time = Math.round(time) 
  const mm = Math.floor(time / 60)
  const ss = Math.round(time - mm * 60)
  return `${mm < 10 ? '0' : ''}${mm}:${ss < 10 ? '0' : ''}${ss}`
}