var fn = function( obj ){
var __t,__p='';
__p+='precision lowp float;\n\nuniform sampler2D tTex;\nuniform float uReverse;\nuniform float uOpacity;\nuniform float uTime;\n\nvarying vec2 vTexCoord;\n\nvoid main(void){\n  float time = sin(uTime);\n  vec3 white = vec3(1.0, 1.0, 1.0);\n\n  vec2 coords = mix(vTexCoord, vec2(1. - vTexCoord.x, vTexCoord.y), uReverse);\n\n  float size = 0.5;\n  vec2 texCoords = coords * (1. + size) - vec2(size * 0.5);\n  vec4 tex = texture2D(tTex, texCoords);\n\n  float timeVarying = time * 0.25 + 0.25;\n  float ghostSizeX = timeVarying;\n  float ghostSizeY = 0.2 + timeVarying * 0.3;\n  vec2 ghostCoords = vec2(coords.x * (1. + ghostSizeX), coords.y * (1. + ghostSizeY)) - vec2(ghostSizeX * 0.5, ghostSizeY * 0.5);\n  float ghostOpacity = (1. - (time * 0.5 + 0.5)) * 0.3;\n  float ghostAlpha = texture2D(tTex, ghostCoords).a * ghostOpacity;\n  float finalGhostAlpha = (1. - tex.a) * ghostAlpha;\n\n  vec3 color = tex.rgb + white * (1. - tex.a);\n  gl_FragColor = vec4(color.rgb, (tex.a + finalGhostAlpha) * uOpacity);\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
