// COLORS
export const HEX_DARK_RED = '#8B2745'
export const HEX_RED = '#BB4758'
export const HEX_BRIGHT_RED = '#FF2C2C'
export const HEX_ORANGE = '#F39997'
export const HEX_YELLOW = '#FFD098'
export const HEX_GREEN = '#0C886A'
export const HEX_BEIGE = '#FFFCED'
export const HEX_WHITE = '#FFFFFF'
export const HEX_BLACK = '#1A1A1A'

// GRADIENT COLORS

export const HEX_LIGHT_RED = '#FD5757'
export const HEX_LIGHT_GREEN = '#7AE2A4'
export const HEX_PEACH = '#D87E7C'
export const HEX_LIGHT_PEACH = '#F1C28A'

// RGB COLORS

export const RGB_DARK_RED = [139, 39, 69]
export const RGB_RED = [187, 71, 88]
export const RGB_BRIGHT_RED = [255, 44, 44]
export const RGB_ORANGE = [243, 153, 151]
export const RGB_YELLOW = [255, 208, 152]
export const RGB_BEIGE = [255, 252, 237]
export const RGB_WHITE = [255, 255, 255]
export const RGB_BLACK = [26, 26, 26]