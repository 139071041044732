<template>
  <div class="panel">
    <div class="panel-heading">
      <slot name="heading" />
    </div>

    <div class="panel-body">
      <slot name="body" />
    </div>

    <div class="panel-actions">
      <slot name="actions"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="stylus" scoped>
.panel
  display flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap 2rem
  width 100%
  height 100%
  padding: 5.25rem 1rem 6rem

  position relative

  color $beige

  pointer-events: none

  &::before
    content ''
    position absolute
    inset 0
    background-color $black
    clip-path polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%, 0 100%, 0 100%, 0 0)

  .panel-heading,
  .panel-body,
  .panel-actions
    position relative

  .panel-heading
    flex none
    ::v-deep(.title)
      font-style: normal;
      font-weight: 700;
      font-size: 4.5rem;
      line-height: 90%;

      text-align: center;
      letter-spacing: 0.02em;

  .panel-body
    flex-grow 1
    max-width 375px
    width 75%
    margin-right auto
    margin-left auto
    text-align center
  .panel-actions
    flex none
</style>