import { useActor, useSelector } from "@xstate/vue";
import AppService from "./AppService";

export function useAppState(){
  return useActor(AppService.state)
}

export function useXrState(){
  return useSelector(AppService.state, (state) => state.context.xrActor.getSnapshot())
}

export function useNetworkState(){
  return useSelector(AppService.state, (state) => state.context.nwActor.getSnapshot())
}

export function useGameState(){
  return useSelector(AppService.state, (state) => state.context.gameActor.getSnapshot())
}
