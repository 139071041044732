import gsap from 'gsap'

import i18n from '@/core/i18n'
import Delay from '@/core/Delay';
import Controller from "@webgl/activities/common/controllers/Controller";
import XRActivity from "../XRActivity";
import { GameState } from "@/services/states/GameStateMachine";
import { formatCountdown } from "@/services/states/Utils";
import { Hotspot, useHotspots } from '@/services/stores/hotspots';
import { Temperature, useDoors } from '@/services/stores/doors';
import { HotspotId, COUNTDOWN_ALMOST_OVER, MAX_COUNTDOWN } from '@/services/states/GameContextDatas';
import { HEX_BEIGE, HEX_BLACK, HEX_DARK_RED, HEX_GREEN, HEX_LIGHT_GREEN, HEX_LIGHT_PEACH, HEX_LIGHT_RED, HEX_ORANGE, HEX_PEACH, HEX_RED, HEX_WHITE } from "@webgl/entities/Colors";

// CONSTANTS

const TEX_SIZE = 256

const TEXT_SMALL = .0625
const TEXT_SMALL_BIGGER = .07
const TEXT_MEDIUM_SMALL = .09375
const TEXT_MEDIUM = .11
const TEXT_NORMAL_SMALL = .125
const TEXT_NORMAL = .15
const TEXT_NORMAL_BIG = .17
const TEXT_NORMAL_BIGGER = .1875
const TEXT_BIG = .25

const BUTTON_SMALL = .17
const BUTTON_BIG = .23

const TOP_ICON = .14
const ICON_BORDER_WIDTH = 16

const EMOJI_MIN = .107
const EMOJI_MAX = .157
const EMOJI_MARGIN = .1
const EMOJI_ICON = .1875

const MARGIN_BOTTOM_SMALL = .02
const MARGIN_BOTTOM_MEDIUM = .03
const MARGIN_BOTTOM_NORMAL = .07
const MARGIN_TOP_TITLE = .1
const MARGIN_TOP_COUNTDOWN = .05
const MARGIN_IMAGE = .03
const MARGIN_IMAGE_BIG = .07
const MARGIN_CIRCLE_STROKE = .1
const MARGIN_CIRCLE_FILL = .25

const TIMER_CIRCLE_OFFSET = -Math.PI / 2
const TIMER_CIRCLE_MARGIN = 0.07
const TIMER_CIRCLE_THICKNESS = .04
const TIMER_CIRCLE_DASH_THICKNESS = 1
const TIMER_CIRCLE_SECOND_PER_DASH = 3
const TIMER_CIRCLE_CIRCUMFERENCE = TEX_SIZE  * (1 - TIMER_CIRCLE_MARGIN * 2) * Math.PI
const TIMER_CIRCLE_NUMBER_DASHES = MAX_COUNTDOWN / TIMER_CIRCLE_SECOND_PER_DASH

const GUIDE_CIRCLE_RAY = .43
const GUIDE_CIRCLE_MARGIN = .3
const GUIDE_BUBBLE = .16
const GUIDE_REC_BUBBLE_MARGIN_TUTO = .125
const GUIDE_REC_BUBBLE_MARGIN_GAME = .1875
const GUIDE_REC_BUBBLE_PADDING = .05

const HOTSPOT_ICON = .27
const HOTSPOT_BORDER_WIDTH = 4
const HOTSPOT_DOUBLE_CIRCLE_MARGIN = 32
const HOTSPOT_CIRCLE_RAY = .71875
const HOTSPOT_CIRCLE_MARGIN_TOP_TUTO = .55
const HOTSPOT_CIRCLE_MARGIN_TOP_GAME = .57
const HOTSPOT_CIRCLE_MARGIN_TOP_HOVER_TUTO = .44
const HOTSPOT_CIRCLE_MARGIN_TOP_HOVER_GAME = .52
const HOTSPOT_CIRCLE_WAVE = .69

const TRIANGLE_SIZE = .04

const COUNTDOWN = 4000

// TYPES

type StateValueTuto = {
  tuto: {
    xr: string
  }
}

export enum ScreenState {
  LOGO,
  TUTO,
  WAIT,
  TITLE,
  EMOJI,
  PAUSE,
  CROUCH,
  SUCCESS,
  TUTO_END,
  TIME_OUT,
  CONTINUE,
  TEMPERATURE,
  CROUCH_TUTO,
  FEEDBACK_ACTION,
  HOTSPOT_ADVISED,
  HOTSPOT_HOVERED,
  HOTSPOT_FEEDBACK,
  GUIDE_TRANSMISSION
}

type Variant = {
  color: string,
  fillColor?: string,
  fillCircle?: boolean,
  borderColor?: string,
  borderWidth?: number,
  borderCircle?: boolean,
  borderMargin?: number,
}

enum ImageNames {
  LOGO = 'logo_illuminator',
  INFO = 'icon_info',
  CHECK = 'icon_check',
  EMOJI = 'emoji',
  ARROW = 'icon_arrow_bottom_left',
  BUBBLE = 'icon_bubble',
  BUTTON_A = 'icon_a',
  BUTTON_B = 'icon_b',
  BUTTON_Y = 'icon_y',
  BUTTON_X = 'icon_x',
  LOW_WAVE_RED = 'low_wave_red',
  LOW_WAVE_WHITE = 'low_wave_white',
  MEDIUM_WAVE_WHITE = 'medium_wave_white',
  HIGH_WAVE_WHITE = 'high_wave_white',
  MEDIUM_WAVE_WHITE_RED = 'medium_wave_white_red',
  MEDIUM_WAVE_RED_ORANGE = 'medium_wave_red_orange',
}

enum GradientNames {
  GREEN,
  ORANGE,
  RED,
  DARK_RED,
  RED_ORANGE
}

// DATA

const IMAGES = Object.values(ImageNames)

const IMAGES_VARIANTS = new Map([
  [ImageNames.CHECK, [
    { color: HEX_DARK_RED },
    { color: HEX_WHITE },
  ]],
  [ImageNames.BUBBLE, [
    { color: HEX_DARK_RED, fillCircle: true, fillColor: HEX_WHITE },
    { color: HEX_BEIGE, fillCircle: true, fillColor: HEX_DARK_RED, borderCircle: true, borderColor: HEX_BEIGE },
  ]],
  [ImageNames.INFO, [
    { color: HEX_DARK_RED, fillCircle: true, fillColor: HEX_BEIGE, borderCircle: true, borderColor: HEX_BLACK },
    { color: HEX_BEIGE, fillCircle: true, fillColor: HEX_DARK_RED, borderCircle: true, borderColor: HEX_BEIGE },
  ]],
])

const HOTSPOTS_VARIANTS = [
  { color: HEX_BLACK },
  { color: HEX_BLACK, fillCircle: true, fillColor: HEX_BEIGE, borderCircle: true,
    borderColor: HEX_BEIGE, borderWidth: HOTSPOT_BORDER_WIDTH, borderMargin: HOTSPOT_DOUBLE_CIRCLE_MARGIN },
]

const GRADIENTS = [
  { name: GradientNames.GREEN, colorBottom: HEX_GREEN, colorTop: HEX_LIGHT_GREEN },
  { name: GradientNames.ORANGE, colorBottom: HEX_PEACH, colorTop: HEX_LIGHT_PEACH },
  { name: GradientNames.RED, colorBottom: HEX_RED, colorTop: HEX_LIGHT_RED },
  { name: GradientNames.DARK_RED, colorBottom: HEX_DARK_RED, colorTop: HEX_RED },
  { name: GradientNames.RED_ORANGE, colorBottom: HEX_RED, colorTop: HEX_ORANGE },
]

const { t } = i18n.global
const { findDoor } = useDoors()
const { findHotspot } = useHotspots()

const isDoorOpen = (state: GameState) => {
  return state.matches('tuto.xr.exit_room') || state.matches('game.bedroom.exit_room') || state.matches('game.corridor.exit_room') || state.matches('game.studyroom.exit_room') || state.matches('game.living.exit_room')
}

const isNotEnding = (state: GameState) => {
  return !state.matches('end')
}

class Emoji {
  x: number
  y: number
  size: number
  baseX: number
  opacity = 1
  progress = 0
  isActive: boolean

  constructor(private img: HTMLImageElement, private onUpdate: () => void) {
    this.size = (EMOJI_MIN + Math.random() * (EMOJI_MAX - EMOJI_MIN)) * TEX_SIZE

    this.x = this.baseX = EMOJI_MARGIN * TEX_SIZE + Math.random() * (TEX_SIZE * (1 - EMOJI_MARGIN * 2) - this.size)
    this.y = TEX_SIZE

    this.start()
  }

  start() {
    this.isActive = true

    gsap.to(this, {
      opacity: 0,
      duration: 1.75,
      delay: 0.25,
      ease: 'linear'
    })
    gsap.to(this, {
      progress: 1,
      y: - this.size,
      duration: 2,
      ease: 'power1.out',
      onUpdate: () => {
        this.x = this.baseX + Math.cos(this.progress * 10) * this.size * 0.1
        this.onUpdate()
      },
      onComplete: () => {
        this.stop()
      }
    })
  }

  stop() {
    this.isActive = false
    this.onUpdate()
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.globalAlpha = this.opacity
    ctx.drawImage(this.img, this.x, this.y, this.size, this.size)
    ctx.globalAlpha = 1
  }
}

export default class IlluminatorScreen extends Controller {
  cvs: HTMLCanvasElement[]
  ctx: CanvasRenderingContext2D[] = []
  state: ScreenState[] = [null, null]
  images = new Map<ImageNames, HTMLImageElement>()
  loaded = false
  emojis: Emoji[] = []
  started = false
  hideTitle = true
  activity: XRActivity
  gradients = new Map<GradientNames, CanvasGradient[]>()
  currentTime = 0
  hasCrouched = false
  prevHotspot: HotspotId
  prevAdvised: HotspotId
  controllerId = 0
  imagesVariants = new Map<string, HTMLCanvasElement[]>()
  notificationId: string[] = [null, null]
  currentHotspot: HotspotId = HotspotId.Step1_CloseDoor
  advisedHotspot: HotspotId = null
  hideNotification = [true, true]
  currentStateKeys = ['' , '']

  get hotspotHovered() {
    return this.activity.gameStateWatcher.currentState.context.hotspotHovered
  }

  get doorHovered() {
    return this.activity.gameStateWatcher.currentState.context.doorHovered
  }

  get visibleEmojis() {
    return this.emojis.filter(emoji => emoji.isActive)
  }

  constructor(activity: XRActivity) {
    super(activity)
  }

  start(): void {
    this.cvs = [document.createElement("canvas"), document.createElement("canvas")]

    for (let i = 0; i < this.cvs.length; i++) {
      this.cvs[i].width = TEX_SIZE
      this.cvs[i].height = TEX_SIZE
      this.ctx[i] = this.cvs[i].getContext("2d")

///////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////
////////////////////////////////////////////
///////////////////////////////////////
////////////////
    }

    this.createGradients()


    this.started = true

    super.start()
  }

  // IMAGES PREPARATION

  createGradients() {
    for (let i = 0; i < GRADIENTS.length; i++) {
      const data = GRADIENTS[i]
      const gradients = [] as CanvasGradient[]

      for (let i = 0; i < this.ctx.length; i++) {
        const gradient = this.ctx[i].createLinearGradient(0, 0, 0, TEX_SIZE)
        gradient.addColorStop(0, data.colorTop)
        gradient.addColorStop(1, data.colorBottom)

        gradients.push(gradient)
      }
      this.gradients.set(data.name, gradients)
    }
  }

  loadImg(name: ImageNames) {
    return new Promise<void>((resolve, reject) => {
      const folder = name.includes('icon') ? 'raw-icons' : 'images'
      const extension = name.includes('icon') ? 'svg' : 'png'
      const url = require(`@/assets/${folder}/${name}.${extension}`)
      const variants = IMAGES_VARIANTS.get(name) || []

      const img = new Image()
      img.src = url
      this.images.set(name, img)
      if (variants.length !== 0) {
        this.imagesVariants.set(name, [])
      }

      img.onload = () => {
        for (let i = 0; i < variants.length; i++) {
          this.createVariant(name, img, variants[i])
        }
        resolve()
      }

      img.onerror = reject
    })
  }

  // create images variants (colors, circles)
  createVariant(name: string, img: HTMLImageElement, variant: Variant) {
    const { color, fillCircle, borderCircle, fillColor, borderColor, borderWidth, borderMargin } = variant

    const hasCircle = fillCircle || borderCircle
    const lineWidth = borderWidth || ICON_BORDER_WIDTH

    const cvs = document.createElement("canvas")
    cvs.width = TEX_SIZE
    cvs.height = TEX_SIZE
    const ctx =  cvs.getContext("2d")

    ctx.clearRect(0,0, TEX_SIZE, TEX_SIZE)
    ctx.globalCompositeOperation = 'copy'

    const ratio = img.width / img.height
    const margin = hasCircle
      ? fillCircle
        ? TEX_SIZE * MARGIN_CIRCLE_FILL
        : TEX_SIZE * MARGIN_CIRCLE_STROKE + lineWidth * 2
      : 0
    const size = TEX_SIZE - margin * 2
    if(ratio >= 1){
      const h = size / ratio
      ctx.drawImage(img, margin, margin + (TEX_SIZE - margin * 2 - h) / 2, size, h);
    }else{
      const w = size * ratio
      ctx.drawImage(img, margin + (TEX_SIZE - margin * 2 - w) / 2, margin, w, size);
    }

    ctx.globalCompositeOperation = 'source-in'
    ctx.fillStyle = color
    ctx.fillRect(0,0, TEX_SIZE, TEX_SIZE)

    if (hasCircle) {
      ctx.globalCompositeOperation = "destination-over"

      ctx.lineWidth = lineWidth
      ctx.fillStyle = fillColor || color
      ctx.strokeStyle = borderColor || color

      if (fillCircle) {
        const margin = borderMargin || (borderCircle ? ctx.lineWidth : 0)
        ctx.beginPath()
        ctx.arc(TEX_SIZE / 2, TEX_SIZE / 2, TEX_SIZE / 2 - margin, 0, 2 * Math.PI)
        ctx.fill()
      }

      if (borderCircle) {
        const margin = ctx.lineWidth * 0.5
        ctx.beginPath()
        ctx.arc(TEX_SIZE / 2, TEX_SIZE / 2, TEX_SIZE / 2 - margin, 0, 2 * Math.PI)
        ctx.stroke()
      }
    }

    this.imagesVariants.get(name).push(cvs)
  }

  createHotspotIcons() {
    const icons = this.activity.hotspots.iconsImg
    for (let i = 0; i < icons.length; i++) {
      this.imagesVariants.set(icons[i].name, [])

      for (let j = 0; j < HOTSPOTS_VARIANTS.length; j++) {
        this.createVariant(icons[i].name, icons[i].img, HOTSPOTS_VARIANTS[j])
      }
    }
  }

  loadImages() {
    return IMAGES.map(
      (name) => this.loadImg(name)
    )
  }

  load() {
    return Promise.all([
      ...this.loadImages(),
    ]).then(() => this.onLoaded())
  }

  onLoaded() {
    this.loaded = true
  }

  // GET DATA

  getGradient(name: GradientNames): CanvasGradient {
    return this.gradients.get(name)[this.controllerId]
  }

  getBackgroundTemp(isHot: boolean, isWarm: boolean): CanvasGradient {
    if (isHot) {
      return this.getGradient(GradientNames.RED)
    }

    if (isWarm) {
      return this.getGradient(GradientNames.ORANGE)
    }

    return this.getGradient(GradientNames.GREEN)
  }

  // GENERIC FUNCTIONS FOR SCREENS

  drawBackground(color: string | CanvasGradient) {
    this.ctx[this.controllerId].fillStyle = color
    this.ctx[this.controllerId].fillRect(0, 0, TEX_SIZE, TEX_SIZE)

/////////////////
/////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
//////////////
  }

  getFont(fontSize: number): [string, number] {
    const size = fontSize * TEX_SIZE
    return [`bold ${size}px 'Formula Condensed'`, size]
  }

  drawText(text: string, color: string[], fontSize: number, textAlign: CanvasTextAlign = 'center', y?: number, yTop = false, x?: number): number {
    const [font, size] = this.getFont(fontSize)
    this.ctx[this.controllerId].font = font
    this.ctx[this.controllerId].textAlign = textAlign

    const lines = text.split('\\n')
    const posX = x || TEX_SIZE / 2
    const posY = y || TEX_SIZE / 2

    const totalHeight = size * lines.length

    if (yTop) {
      for (let i = 0; i < lines.length; i++) {
        const strong = lines[i].includes('<strong>')
        const line = lines[i].replace('<strong>', '').replace('</strong>', '')
        this.ctx[this.controllerId].fillStyle = strong && color[1] ? color[1] : color[0]
        this.ctx[this.controllerId].fillText(line.toUpperCase(), posX, posY + size * (i + 0.85))
      }
      return totalHeight
    }

    for (let i = 0; i < lines.length; i++) {
      const strong = lines[i].includes('<strong>')
      const line = lines[i].replace('<strong>', '').replace('</strong>', '')
      this.ctx[this.controllerId].fillStyle = strong && color[1] ? color[1] : color[0]
      this.ctx[this.controllerId].fillText(line.toUpperCase(), posX, posY + size * (i + 0.85) - totalHeight / 2)
    }

    return totalHeight
  }

  drawTriangle(color: string, x: number, y: number, size: number) {
    const halfWidth = size * 0.5
    const halfHeight = size * 0.385

    this.ctx[this.controllerId].fillStyle = color
    this.ctx[this.controllerId].beginPath()

    this.ctx[this.controllerId].moveTo(x - halfWidth, y - halfHeight)
    this.ctx[this.controllerId].lineTo(x + halfWidth, y - halfHeight)
    this.ctx[this.controllerId].lineTo(x, y + halfHeight)

    this.ctx[this.controllerId].fill()
  }

  setTexture() {
    if (this.controllerId === 0) {
      this.activity.gamepads.screenTextureR.fromImage(this.cvs[this.controllerId])
      return
    }

    this.activity.gamepads.screenTextureL.fromImage(this.cvs[1])
  }

  // SPECIFIC RECURRING FUNCTIONS FOR SCREENS

  drawTitleAndDesc(title: string, description: string) {
    const totalHeight =  (TEXT_SMALL + TEXT_NORMAL + MARGIN_BOTTOM_SMALL) * TEX_SIZE
    this.drawText(title, [HEX_BLACK], TEXT_SMALL, 'center', TEX_SIZE / 2 - totalHeight / 2, true)
    this.drawText(description, [HEX_DARK_RED], TEXT_NORMAL, 'center', TEX_SIZE * (0.5 + MARGIN_BOTTOM_SMALL + TEXT_SMALL) - totalHeight / 2, true)
  }

  drawCheck(variant: number, hasWave?: boolean) {
    const check = this.imagesVariants.get(ImageNames.CHECK)[variant]
    const check_w = TEX_SIZE * 0.1
    const check_h = check_w * (check.height / check.width)
    const check_m = hasWave ? TEX_SIZE * 0.15 : TEX_SIZE * 0.28 - check_h / 2
    this.ctx[this.controllerId].drawImage(check, TEX_SIZE / 2 - check_w / 2, TEX_SIZE / 2 + check_m, check_w, check_h)
  }

  drawWave(name: ImageNames, width?: number, isSmall?: boolean): number[] {
    const wave = this.images.get(name)

    const wave_w = width || TEX_SIZE
    const wave_h = wave_w * (wave.height / wave.width)
    const waveVisible = isSmall ? 0.6 : 0.95

    const x = (TEX_SIZE - wave_w) * 0.5
    const y = TEX_SIZE - wave_h * waveVisible

    this.ctx[this.controllerId].drawImage(wave, x, y, wave_w, wave_h)

    return [x, y]
  }

  drawTextImageCentered(text: string, color: string, fontSize: number, image: HTMLImageElement | HTMLCanvasElement,
    imageSize: number, y: number, customMargin?: number) {
    const [font] = this.getFont(fontSize)
    this.ctx[this.controllerId].font = font
    const textWidth = this.ctx[this.controllerId].measureText(text).width

    const margin = (customMargin || MARGIN_IMAGE) * TEX_SIZE

    const fullSize = textWidth + margin + imageSize
    const x = (TEX_SIZE - fullSize) * 0.5

    this.drawText(text, [color], fontSize, 'left', y, false, x)

    this.ctx[this.controllerId].drawImage(
      image,
      x + textWidth + margin,
      y - imageSize * 0.55,
      imageSize,
      imageSize
    )
  }

  drawTimerCircle(color: string) {
    const modulo = this.currentTime % TIMER_CIRCLE_SECOND_PER_DASH
    const progress = (this.currentTime - modulo) / MAX_COUNTDOWN

    this.ctx[this.controllerId].lineWidth = TEX_SIZE * TIMER_CIRCLE_THICKNESS
    this.ctx[this.controllerId].strokeStyle = color

    this.ctx[this.controllerId].setLineDash([
      TIMER_CIRCLE_DASH_THICKNESS,
      (TIMER_CIRCLE_CIRCUMFERENCE - TIMER_CIRCLE_NUMBER_DASHES * TIMER_CIRCLE_DASH_THICKNESS) / TIMER_CIRCLE_NUMBER_DASHES
    ])

    this.ctx[this.controllerId].beginPath()
    this.ctx[this.controllerId].arc(TEX_SIZE / 2, TEX_SIZE / 2,
      TEX_SIZE * (0.5 - TIMER_CIRCLE_MARGIN), TIMER_CIRCLE_OFFSET, TIMER_CIRCLE_OFFSET + progress * 2 * Math.PI)
    this.ctx[this.controllerId].stroke()
  }

  drawGuideTransmissionTitle() {
    const bubbleSize = GUIDE_BUBBLE * TEX_SIZE

    const margin = MARGIN_IMAGE * TEX_SIZE
    const text = t('illuminator.transmission')

    const longestText = text.split('\\n').reduce((acc, chars) => chars.length > acc.length ? chars : acc)
    const [font] = this.getFont(TEXT_SMALL_BIGGER)
    this.ctx[this.controllerId].font = font
    const textWidth = this.ctx[this.controllerId].measureText(longestText).width

    const totalWidth = bubbleSize + textWidth + margin
    const x = (TEX_SIZE - totalWidth) / 2
    const y = MARGIN_TOP_TITLE * TEX_SIZE

    this.drawText(text, [HEX_WHITE], TEXT_SMALL_BIGGER, 'left', y + bubbleSize * 0.5, false, x + bubbleSize + margin)
    this.ctx[this.controllerId].drawImage(this.imagesVariants.get(ImageNames.BUBBLE)[0], x, y, bubbleSize, bubbleSize)
  }

  drawGuideMessage(message: string, color: string, fontSize: number) {
    const ray = TEX_SIZE * GUIDE_CIRCLE_RAY
    const circleY = TEX_SIZE * GUIDE_CIRCLE_MARGIN
    const center = ray + circleY

    this.ctx[this.controllerId].fillStyle = HEX_BEIGE
    this.ctx[this.controllerId].beginPath()
    this.ctx[this.controllerId].arc(TEX_SIZE / 2, center, ray, 0, 2 * Math.PI)
    this.ctx[this.controllerId].fill()

    this.drawWave(ImageNames.MEDIUM_WAVE_RED_ORANGE, ray * 2.2, false)

    const textY = center - MARGIN_BOTTOM_NORMAL * TEX_SIZE
    this.drawText(message, [color], fontSize, 'center', textY)

    const margin = MARGIN_IMAGE * TEX_SIZE * 2

    const triangleSize = TEX_SIZE * TRIANGLE_SIZE
    const triangleY = circleY + margin
    this.drawTriangle(HEX_DARK_RED, TEX_SIZE / 2, triangleY + triangleSize * 0.5, triangleSize)
  }

  drawGuideRecommendation(isRecommended: boolean, isTuto: boolean) {
    if( !isRecommended ) return
    const message = t(`illuminator.${isRecommended ? '' : 'not_'}recommended`)

    const [font, fontSize] = this.getFont(TEXT_SMALL)
    this.ctx[this.controllerId].font = font
    const textWidth = this.ctx[this.controllerId].measureText(message).width

    const bubbleColor = isRecommended ? HEX_BEIGE : HEX_BLACK
    const bubbleIcon = isRecommended
      ? this.imagesVariants.get(ImageNames.BUBBLE)[1]
      : this.imagesVariants.get(ImageNames.INFO)[0]

    const bubblePadding = GUIDE_REC_BUBBLE_PADDING * TEX_SIZE
    const bubbleHeight = bubblePadding* 2 + fontSize
    const bubbleWidth = textWidth + bubblePadding + bubbleHeight
    const bubbleY = (isTuto ? GUIDE_REC_BUBBLE_MARGIN_TUTO : GUIDE_REC_BUBBLE_MARGIN_GAME) * TEX_SIZE
    const bubbleX = (TEX_SIZE - bubbleWidth) / 2

    // bubble rectangle
    this.ctx[this.controllerId].fillStyle = bubbleColor
    this.ctx[this.controllerId].beginPath()
    this.ctx[this.controllerId].rect(bubbleX + bubbleHeight * 0.5, bubbleY, bubbleWidth - bubbleHeight, bubbleHeight)
    this.ctx[this.controllerId].arc(bubbleX + bubbleWidth - bubbleHeight * 0.5, bubbleY + bubbleHeight * 0.5, bubbleHeight * 0.5, 0, 2 * Math.PI)
    this.ctx[this.controllerId].fill()

    // bubble icon
    this.ctx[this.controllerId].drawImage(
      bubbleIcon,
      bubbleX,
      bubbleY,
      bubbleHeight,
      bubbleHeight
    )

    // bubble triangle
    const triangleSize = TEX_SIZE * TRIANGLE_SIZE
    this.drawTriangle(bubbleColor, TEX_SIZE / 2, bubbleY + bubbleHeight + triangleSize * 0.2, triangleSize)

    // bubble text
    const color = isRecommended ? HEX_DARK_RED : HEX_BEIGE
    this.drawText(message, [color], TEXT_SMALL, 'right', bubbleY + bubbleHeight * 0.55, false, bubbleX + bubbleWidth - bubblePadding)
  }

  drawHotspotInstruction(hotspot: Hotspot, isHovering: boolean, isTuto: boolean): number {
    const ray = TEX_SIZE * HOTSPOT_CIRCLE_RAY
    const margin = (isHovering
      ? isTuto ? HOTSPOT_CIRCLE_MARGIN_TOP_HOVER_TUTO : HOTSPOT_CIRCLE_MARGIN_TOP_HOVER_GAME
      : isTuto ? HOTSPOT_CIRCLE_MARGIN_TOP_TUTO : HOTSPOT_CIRCLE_MARGIN_TOP_GAME) * TEX_SIZE

    this.ctx[this.controllerId].fillStyle = HEX_RED
    this.ctx[this.controllerId].strokeStyle = HEX_BEIGE
    this.ctx[this.controllerId].lineWidth = 2
    this.ctx[this.controllerId].beginPath()
    this.ctx[this.controllerId].arc(TEX_SIZE / 2, ray + margin, ray, 0, 2 * Math.PI)
    this.ctx[this.controllerId].fill()
    this.ctx[this.controllerId].stroke()

    const wavePosition = this.drawWave(ImageNames.MEDIUM_WAVE_WHITE_RED, TEX_SIZE * HOTSPOT_CIRCLE_WAVE, false)

    const size = (isHovering ? BUTTON_BIG : HOTSPOT_ICON) * TEX_SIZE
    const marginFactor = isHovering ? .6 : .7
    const marginText = (wavePosition[1] - margin - size) * marginFactor
    const y = margin + marginText + size * .55

    const text = isHovering ? t('illuminator.press') : t('illuminator.aim')
    const image = isHovering ? this.images.get(ImageNames.BUTTON_A) : this.imagesVariants.get(hotspot.icon)[1]

    this.drawTextImageCentered(
      text, HEX_BEIGE, TEXT_MEDIUM, image, size, y
    )

    return margin
  }

  drawTopCountdown(gamestate: GameState) {
    if (this.state[this.controllerId] === null ||
        this.state[this.controllerId] === ScreenState.LOGO ||
        (!gamestate.matches('game') && !gamestate.matches('end.failed'))) return false

    const text = formatCountdown(this.currentTime)
    const textY = TEX_SIZE * MARGIN_TOP_COUNTDOWN
    this.drawText(text, [HEX_BEIGE], TEXT_MEDIUM_SMALL, 'center', textY, true)

    const [font, fontSize] = this.getFont(TEXT_MEDIUM_SMALL)
    this.ctx[this.controllerId].font = font
    const textWidth = this.ctx[this.controllerId].measureText(text).width

    const image = this.images.get(ImageNames.ARROW)
    const height = textY + fontSize
    const width = height * (image.width / image.height)
    this.ctx[this.controllerId].drawImage(
      image,
      TEX_SIZE * (0.5 + MARGIN_IMAGE) + textWidth * 0.5,
      textY + fontSize * 0.5 - height * 0.9,
      width,
      height
    )
  }

  drawEmojis() {
    for (let i = 0; i < this.visibleEmojis.length; i++) {
      const emoji = this.visibleEmojis[i]
      emoji.draw(this.ctx[this.controllerId])
    }
  }

  // ALL SCREENS

  logoScreen() {
    this.drawBackground(HEX_BEIGE)

    const image = this.images.get(ImageNames.LOGO)
    const width = TEX_SIZE * 0.6
    const height = width * (image.height / image.width)
    this.ctx[this.controllerId].drawImage(image, TEX_SIZE / 2 - width / 2, TEX_SIZE / 2 - height / 2, width, height)
  }

  waitScreen() {
    this.drawBackground(HEX_BEIGE)
    this.drawText(t(`illuminator.wait.${this.currentStateKeys[1]}`), [HEX_BLACK, HEX_DARK_RED], TEXT_NORMAL_BIG)
  }

  tutoScreen() {
    this.drawBackground(HEX_DARK_RED)

    const fontSize = TEXT_NORMAL_BIG * TEX_SIZE
    const y = TEX_SIZE * 0.5// + fontSize * 0.3
    this.drawText(t(`illuminator.${this.currentStateKeys[1]}`), [HEX_WHITE, HEX_BEIGE], TEXT_NORMAL_BIG, 'center', y)
  }

  tutoCrouchScreen() {
    this.drawBackground(HEX_DARK_RED)

    const text = t(`illuminator.tutorial.crouch`)
    const lines = text.split('\\n')

    const sizeButton = BUTTON_BIG * TEX_SIZE
    const sizeText = TEXT_NORMAL_BIG * lines.length * TEX_SIZE
    const margin = MARGIN_BOTTOM_NORMAL * TEX_SIZE

    const totalHeight = sizeButton + sizeText + margin
    const yTop = TEX_SIZE * 0.5 - totalHeight * 0.5
    const yBottom = yTop + sizeText + sizeButton * 0.5 + margin
    this.drawText(text, [HEX_WHITE, HEX_BEIGE], TEXT_NORMAL_BIG, 'center', yTop, true)
    this.drawTextImageCentered(
      t('illuminator.hold'), HEX_BEIGE, TEXT_MEDIUM, this.images.get(ImageNames.BUTTON_X), sizeButton, yBottom, MARGIN_IMAGE_BIG
    )
  }

  tutoEndScreen() {
    this.drawBackground(HEX_DARK_RED)

    const key = this.controllerId === 0 ? 'right' : 'left'
    const fontSize = TEXT_NORMAL_BIG * TEX_SIZE
    const y = TEX_SIZE * 0.5// + fontSize * 0.3
    this.drawText(t(`illuminator.tutorial.end.${key}`), [HEX_WHITE, HEX_BEIGE], TEXT_NORMAL_BIG, 'center', y)
  }

  successScreen(gamestate: GameState) {
    this.drawBackground(HEX_WHITE)

    if (gamestate.matches('end')) {
      this.drawText(
        `${t(`illuminator.${this.currentStateKeys[0]}.title`)}\\n${t(`illuminator.${this.currentStateKeys[1]}.description`)}`
        , [HEX_DARK_RED], TEXT_NORMAL
      )
    } else {
      this.drawTitleAndDesc(t(`illuminator.${this.currentStateKeys[0]}.title`), t(`illuminator.${this.currentStateKeys[1]}.description`))
    }

    this.drawCheck(0, false)
  }

  feedbackScreen(gamestate: GameState) {
    const isTuto = gamestate.matches('tuto')
    this.drawBackground(this.getGradient(GradientNames.GREEN))

    const text = isTuto ? t('illuminator.game.feedback.tuto') : t('illuminator.game.feedback.good')
    this.drawText(text, [HEX_WHITE], TEXT_NORMAL)
    this.drawCheck(1)
  }

  hotspotFeedbackScreen() {
    const hotspot = findHotspot(this.currentHotspot)
    const isGood = hotspot.points >= 0

    this.drawBackground(this.getGradient(isGood ? GradientNames.GREEN : GradientNames.RED))

    this.drawText(`${isGood ? '+' : ''}${hotspot.points} ${t('illuminator.seconds')}`, [HEX_BEIGE], TEXT_NORMAL_BIGGER)
    this.drawText(t(`illuminator.game.action_feedback.${isGood ? 'good' : 'bad'}`), [HEX_BEIGE], TEXT_SMALL, 'center', TEX_SIZE / 2 + (TEXT_BIG / 2) * TEX_SIZE, true)

    this.drawWave(isGood ? ImageNames.LOW_WAVE_WHITE : ImageNames.HIGH_WAVE_WHITE)
  }

  sendEmojiScreen() {
    this.drawBackground(HEX_DARK_RED)

    const sizeButton = BUTTON_BIG * TEX_SIZE
    const sizeEmoji = EMOJI_ICON * TEX_SIZE

    const totalHeight = sizeButton + sizeEmoji
    const yTop = TEX_SIZE * 0.5 - totalHeight * 0.5 + sizeButton * 0.5
    const yBottom = yTop + sizeButton

    this.drawTextImageCentered(
      t('illuminator.press'), HEX_BEIGE, TEXT_MEDIUM, this.images.get(ImageNames.BUTTON_B), sizeButton, yTop
    )
    this.drawTextImageCentered(
      `${t('illuminator.action_link')} ${t('illuminator.send')}`,
      HEX_BEIGE, TEXT_MEDIUM, this.images.get(ImageNames.EMOJI), sizeEmoji, yBottom
    )
  }

  guideTransmissionScreen() {
    this.drawBackground(HEX_DARK_RED)
    this.drawGuideMessage(t('illuminator.hello'), HEX_DARK_RED, TEXT_NORMAL_BIGGER)
    this.drawGuideTransmissionTitle()
  }

  advisedHotspotScreen(gamestate:GameState) {
    const isTuto = gamestate.matches('tuto')

    this.drawBackground(HEX_DARK_RED)

    if (this.advisedHotspot === null) return

    const hotspot = findHotspot(this.advisedHotspot)

    const text = t(`hotspots.${hotspot.name}.label`)

    const [font] = this.getFont(TEXT_NORMAL)
    this.ctx[this.controllerId].font = font
    const textWidth = this.ctx[this.controllerId].measureText(text).width

    const fontSize = textWidth > TEX_SIZE ? TEXT_MEDIUM : TEXT_NORMAL

    const textY = isTuto
      ? TEX_SIZE * (0.5 - fontSize * 0.9)
      : TEX_SIZE * (0.5 - fontSize * 0.7)
    this.drawText(text, [HEX_BEIGE], fontSize, 'center', textY, true)
    this.drawHotspotInstruction(hotspot, false, isTuto)
    this.drawGuideRecommendation(true, isTuto)
  }

  hoveredHotspotScreen(gamestate:GameState) {
    const isTuto = gamestate.matches('tuto')

    const isAdvised = this.advisedHotspot === this.hotspotHovered

    this.drawBackground(isAdvised ? HEX_DARK_RED : this.getGradient(GradientNames.DARK_RED))

    const hotspot = findHotspot(this.hotspotHovered)

    if (!isAdvised) {
      // guide recommendation bubble
      this.drawGuideRecommendation(false, false)

      // hotspot title size
      const textTitle = t(`hotspots.${hotspot.name}.label`)

      const [font] = this.getFont(TEXT_NORMAL)
      this.ctx[this.controllerId].font = font
      const titleWidth = this.ctx[this.controllerId].measureText(textTitle).width

      const titleSize = titleWidth > TEX_SIZE ? TEXT_MEDIUM : TEXT_NORMAL_SMALL

      // hotspot instruction size

      const pressSize = BUTTON_SMALL * TEX_SIZE

      // positions

      const fullHeight = (titleSize + MARGIN_BOTTOM_SMALL) * TEX_SIZE + pressSize
      const titleY = TEX_SIZE * 0.5 - fullHeight * 0.5
      const pressY = titleY + (titleSize + MARGIN_BOTTOM_SMALL) * TEX_SIZE + pressSize * 0.5

      // hotspot title
      this.drawText(textTitle, [HEX_BEIGE], titleSize, 'center', titleY, true)

      // hotspot instruction
      this.drawTextImageCentered(
        t('illuminator.press'), HEX_BEIGE, TEXT_MEDIUM_SMALL, this.images.get(ImageNames.BUTTON_A), pressSize, pressY
      )

      // wave
      this.drawWave(ImageNames.HIGH_WAVE_WHITE)

      return
    }

    // hotspot instruction
    const y = this.drawHotspotInstruction(hotspot, true, isTuto)

    // hotspot title
    const titleText = t(`hotspots.${hotspot.name}.label`)

    const [font] = this.getFont(TEXT_NORMAL)
    this.ctx[this.controllerId].font = font
    const textWidth = this.ctx[this.controllerId].measureText(titleText).width

    const fontSize = textWidth > TEX_SIZE ? TEXT_MEDIUM : TEXT_NORMAL_SMALL

    const textY = y - TEX_SIZE * (fontSize + MARGIN_BOTTOM_MEDIUM)
    this.drawText(t(`hotspots.${hotspot.name}.label`), [HEX_BEIGE], fontSize, 'center', textY, true)

    // bubble icon
    const bubbleHeight = TEX_SIZE * TOP_ICON
    const top = isTuto ? 0 : (MARGIN_TOP_COUNTDOWN + TEXT_MEDIUM_SMALL * 0.9) * TEX_SIZE
    const bubbleY = top + (textY - top) * 0.5 - bubbleHeight * 0.5
    this.ctx[this.controllerId].drawImage(
      this.imagesVariants.get(ImageNames.BUBBLE)[1],
      TEX_SIZE * 0.5 - bubbleHeight * 0.5,
      bubbleY,
      bubbleHeight,
      bubbleHeight
    )
  }

  temperatureScreen(gamestate:GameState) {
    const door = findDoor(gamestate.context.doorHovered)
    const isHot = door.temperature === Temperature.HOT
    const isWarm = door.temperature === Temperature.WARM

    this.drawBackground(this.getBackgroundTemp(isHot, isWarm))
    this.drawText(t(`illuminator.game.temperature.${door.temperature}`), [HEX_BEIGE], TEXT_BIG)
    this.drawWave(
      isHot ? ImageNames.HIGH_WAVE_WHITE : isWarm ? ImageNames.MEDIUM_WAVE_WHITE : ImageNames.LOW_WAVE_WHITE
    )
  }

  timeScreen() {
    const isAlmostOver = this.currentTime <= COUNTDOWN_ALMOST_OVER

    this.drawBackground(isAlmostOver ? this.getGradient(GradientNames.DARK_RED) : HEX_BEIGE)
    this.drawTimerCircle(isAlmostOver ? HEX_BEIGE : HEX_DARK_RED)
    this.drawText(formatCountdown(this.currentTime), isAlmostOver ? [HEX_BEIGE] : [HEX_DARK_RED], TEXT_BIG)
    this.drawWave(isAlmostOver ? ImageNames.HIGH_WAVE_WHITE : ImageNames.LOW_WAVE_RED)
  }

  timeOutScreen() {
    this.drawBackground(this.getGradient(GradientNames.RED))
    this.drawText(t('illuminator.time_out'), [HEX_BEIGE], TEXT_BIG)
  }

  crouchScreen() {
    this.drawBackground(this.getGradient(GradientNames.DARK_RED))
    this.drawWave(ImageNames.LOW_WAVE_WHITE)
    const textHeight = this.drawText(t('illuminator.crouch'), [HEX_WHITE, HEX_BEIGE], TEXT_NORMAL_BIG, 'center', TEX_SIZE*.55)

    const iconHeight = TEX_SIZE * TOP_ICON
    const top = (MARGIN_TOP_COUNTDOWN + TEXT_MEDIUM_SMALL * 0.9) * TEX_SIZE
    const topText = TEX_SIZE * 0.5 - textHeight * 0.5
    const iconY = top + (topText - top) * 0.5 - iconHeight * 0.5

    this.ctx[this.controllerId].drawImage(
      this.imagesVariants.get(ImageNames.INFO)[1],
      TEX_SIZE * 0.5 - iconHeight * 0.5,
      iconY,
      iconHeight,
      iconHeight
    )
  }

  continueScreen() {
    this.drawBackground(this.getGradient(GradientNames.DARK_RED))

    const fontSize = TEXT_NORMAL_BIG * TEX_SIZE
    const y = TEX_SIZE * 0.5 + fontSize * 0.3
    this.drawText(t('illuminator.continue'), [HEX_BEIGE], TEXT_NORMAL_BIG, 'center', y)
  }

  pauseScreen() {
    this.drawBackground(HEX_BEIGE)
    this.drawWave(ImageNames.LOW_WAVE_RED)

    const sizeButton = BUTTON_BIG * TEX_SIZE
    const sizeText = TEXT_NORMAL * TEX_SIZE

    const totalHeight = sizeButton + sizeText
    const yTop = TEX_SIZE * 0.5 - totalHeight * 0.5 + sizeButton * 0.5
    const yBottom = yTop + sizeButton

    this.drawTextImageCentered(
      t('illuminator.press'), HEX_DARK_RED, TEXT_MEDIUM, this.images.get(ImageNames.BUTTON_Y), sizeButton, yTop
    )
    this.drawText(`${t('illuminator.action_link')} ${t('illuminator.pause')}`, [HEX_DARK_RED], TEXT_NORMAL, 'center', yBottom)
  }

  // SHOW/HIDE NOTIFICATIONS

  async showNotification(controllerId: number, state: ScreenState, condition: boolean, id: string) {
    if (this.notificationId[controllerId] !== id && condition) {
      this.hideNotification[controllerId] = false
      this.notificationId[controllerId] = id
      this.state[controllerId] = state

      await Delay(COUNTDOWN)

      if (this.notificationId[controllerId] !== id) return
      this.hideNotification[controllerId] = true

      this.stateChange(this.activity.gameStateWatcher.currentState)
    }
  }

  // STATE UPDATE HANDLERS

  willUpdateStateKey(): boolean {
    for (let i = 0; i < this.cvs.length; i++) {
      if (this.state[i] !== ScreenState.TUTO && this.state[i] !== ScreenState.SUCCESS
        && this.state[i] !== ScreenState.WAIT) continue
      return true
    }

    return false
  }

  // get i18n keys with state
  updateStateKey (state: GameState) {
    if (!this.willUpdateStateKey()) return

    if (state.matches('end.initial') || state.matches('end.success')) {
      this.currentStateKeys = ['success', 'success']
      return
    }

    if (state.matches('intro')) {
      this.currentStateKeys = ['intro', 'intro']
      return
    }

    if (state.matches('tuto')) {
      const stateVal = (state.value as unknown as StateValueTuto).tuto.xr
      const key = state.matches('tuto.xr.contact') ? 'contact' : stateVal
      this.currentStateKeys = ['tutorial', `tutorial.${key}`]
      return
    }

    this.currentStateKeys = ['', '']
  }

  // set screen state
  setStateRight(gamestate:GameState) {
    // --- priority screens ---

    if (gamestate.matches('end')) {
      this.state[0] = ScreenState.LOGO
      return
    }

    if (gamestate.context.isPaused) {
      this.state[0] = ScreenState.LOGO
      return
    }

    // --- notifications ---

    if (gamestate.event.type === 'GAME_ACTION_DONE' && isDoorOpen(gamestate)) {
      const id = gamestate.matches('tuto') ? 'tuto.xr.actions' : JSON.stringify(gamestate.value)

      this.showNotification(0, ScreenState.FEEDBACK_ACTION, true, id)

      this.advisedHotspot = null
      this.prevAdvised = null
    }

    if (gamestate.event.type === 'GAME_HOTSPOT_ACTION_DONE' && isNotEnding(gamestate)) {
      const id = gamestate.event.payload
      const hotspot = findHotspot(id)

      if (hotspot.points === 0) {
        this.showNotification(0, ScreenState.FEEDBACK_ACTION, true, `hotspot.${id}`)
        this.currentHotspot = id

        if (this.currentHotspot === this.advisedHotspot) {
          this.advisedHotspot = null
          this.prevAdvised = null
        }
      }
    }

    // hide other screens if notification is visible
    if (!this.hideNotification[0]) {
      return
    }

    // --- regular screens ---

    if (this.hotspotHovered !== -1) {
      this.state[0] = ScreenState.HOTSPOT_HOVERED
      return
    }

    if (gamestate.matches('game.corridor') && !this.hasCrouched) {
      this.state[0] = ScreenState.CROUCH_TUTO
      return
    }

    if (this.advisedHotspot !== null) {
      this.state[0] = ScreenState.HOTSPOT_ADVISED
      return
    }

    if (gamestate.matches('intro.xr.wait_companion')) {
      this.state[0] = ScreenState.WAIT
      return
    }

    if (gamestate.matches('intro')) {
      this.state[0] = ScreenState.LOGO
      return
    }

    if (gamestate.matches('tuto.xr.contact.wait')) {
      this.state[0] = ScreenState.WAIT
      return
    }

    if (gamestate.matches('tuto.xr.contact.hello')) {
      this.state[0] = ScreenState.GUIDE_TRANSMISSION
      return
    }

    if (gamestate.matches('tuto.xr.contact.emoji') || gamestate.matches('tuto.xr.contact.sent')) {
      this.state[0] = ScreenState.EMOJI
      return
    }

    if (gamestate.matches('tuto.xr.actions')) {
      this.state[0] = ScreenState.WAIT
      return
    }

    if (gamestate.matches('tuto.xr.exit_room')) {
      this.state[0] = ScreenState.TUTO_END
      return
    }

    if (gamestate.matches('tuto.xr.crouch')) {
      this.state[0] = ScreenState.CROUCH_TUTO
      return
    }

    if (gamestate.matches('tuto')) {
      this.state[0] = ScreenState.TUTO
      return
    }

    this.state[0] = ScreenState.CONTINUE
  }

  setStateLeft(gamestate: GameState) {
    // --- priority screens ---

    if (gamestate.matches('end.failed.initial')) {
      this.state[1] = ScreenState.TIME_OUT
      return
    }

    if (gamestate.matches('end')) {
      this.state[1] = ScreenState.LOGO
      return
    }

    if (gamestate.context.isPaused) {
      this.state[1] = ScreenState.LOGO
      return
    }

    // --- notifications ---

    if (gamestate.event.type === 'GAME_ACTION_DONE' && isDoorOpen(gamestate)) {
      const id = gamestate.matches('tuto') ? 'tuto.xr.actions' : JSON.stringify(gamestate.value)

      this.showNotification(1, ScreenState.FEEDBACK_ACTION, true, id)

      this.advisedHotspot = null
      this.prevAdvised = null
    }

    if (gamestate.event.type === 'GAME_HOTSPOT_ACTION_DONE' && isNotEnding(gamestate)) {
      const id = gamestate.event.payload
      const hotspot = findHotspot(id)

      const screen = hotspot.points === 0 ? ScreenState.FEEDBACK_ACTION : ScreenState.HOTSPOT_FEEDBACK
      this.showNotification(1, screen, true, `hotspot.${id}`)
      this.currentHotspot = id

      if (this.currentHotspot === this.advisedHotspot) {
        this.advisedHotspot = null
        this.prevAdvised = null
      }
    }

    // hide other screens if notification is visible
    if (!this.hideNotification[1]) {
      return
    }

    // --- regular screens ---

    if (this.doorHovered !== -1) {
      this.state[1] = ScreenState.TEMPERATURE
      return
    }

    if (gamestate.matches('intro')) {
      this.state[1] = ScreenState.LOGO
      return
    }

    if (gamestate.matches('tuto.xr.contact.wait')) {
      this.state[1] = ScreenState.WAIT
      return
    }

    if (gamestate.matches('tuto.xr.contact.hello')) {
      this.state[1] = ScreenState.GUIDE_TRANSMISSION
      return
    }

    if (gamestate.matches('tuto.xr.contact.emoji') || gamestate.matches('tuto.xr.contact.sent')) {
      this.state[1] = ScreenState.EMOJI
      return
    }

    if (gamestate.matches('tuto.xr.exit_room')) {
      this.state[1] = ScreenState.TUTO_END
      return
    }

    if (gamestate.matches('tuto.xr.crouch')) {
      this.state[1] = ScreenState.CROUCH_TUTO
      return
    }

    if (gamestate.matches('tuto.xr.move_around')) {
      this.state[1] = ScreenState.TUTO
      return
    }

    if (gamestate.matches('tuto')) {
      this.state[1] = ScreenState.PAUSE
      return
    }

    this.state[1] = null
  }

  setState(gamestate: GameState) {
    this.setStateRight(gamestate)
    this.setStateLeft(gamestate)
  }

  // draw screen condition
  shouldDrawScreen(state: ScreenState, prevState: ScreenState, hasUpdate: boolean, condition?: boolean) {
    return this.state[this.controllerId] === state && (prevState !== state || hasUpdate || condition)
  }

  // display screen depending on screen state
  displayScreen(
    gamestate:GameState, prevState: ScreenState, globalUpdate: boolean, prevStateKeys: string[]
  ) {
    if (this.shouldDrawScreen(ScreenState.FEEDBACK_ACTION, prevState, globalUpdate)) {
      this.feedbackScreen(gamestate)
      return true
    }

    if (this.shouldDrawScreen(ScreenState.HOTSPOT_FEEDBACK, prevState, globalUpdate, this.prevHotspot !== this.currentHotspot)) {
      this.prevHotspot = this.currentHotspot
      this.hotspotFeedbackScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.LOGO, prevState, globalUpdate)) {
      this.logoScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.WAIT, prevState, globalUpdate)) {
      this.waitScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.CROUCH_TUTO, prevState, globalUpdate)) {
      this.tutoCrouchScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.TUTO, prevState, globalUpdate,
      this.currentStateKeys[1] !== prevStateKeys[1])) {
      this.tutoScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.TUTO_END, prevState, globalUpdate)) {
      this.tutoEndScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.SUCCESS, prevState, globalUpdate,
      this.currentStateKeys[1] !== prevStateKeys[1])) {
      this.successScreen(gamestate)
      return true
    }

    if (this.shouldDrawScreen(ScreenState.EMOJI, prevState, globalUpdate)) {
      this.sendEmojiScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.GUIDE_TRANSMISSION, prevState, globalUpdate)) {
      this.guideTransmissionScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.HOTSPOT_ADVISED, prevState, globalUpdate, gamestate.event.type === 'GAME_HOTSPOT_HOVER')) {
      this.advisedHotspotScreen(gamestate)
      return true
    }

    if (this.shouldDrawScreen(ScreenState.HOTSPOT_HOVERED, prevState, globalUpdate, gamestate.event.type === 'GAME_HOTSPOT_HOVER')) {
      this.hoveredHotspotScreen(gamestate)
      this.activity.gamepads.buttons.updateHighlight('A', gamestate.matches('tuto'))
      return true
    }

    if (this.shouldDrawScreen(ScreenState.TEMPERATURE, prevState, globalUpdate, gamestate.event.type === 'GAME_DOOR_HOVER')) {
      this.temperatureScreen(gamestate)
      return true
    }

    if (this.shouldDrawScreen(ScreenState.TIME_OUT, prevState, globalUpdate)) {
      this.timeOutScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.CROUCH, prevState, globalUpdate)) {
      this.crouchScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.CONTINUE, prevState, globalUpdate)) {
      this.continueScreen()
      return true
    }

    if (this.shouldDrawScreen(ScreenState.PAUSE, prevState, globalUpdate)) {
      this.pauseScreen()
      return true
    }

    const time = Math.round(gamestate.context.countdown)
    if (this.shouldDrawScreen(null, prevState, globalUpdate, time !== this.currentTime)) {
      this.currentTime = time
      this.timeScreen()
      return true
    }

    return false
  }

  // update current time & check if countdown need update

  updateCountdown(gamestate: GameState) {
    if ((this.state[0] === null && this.state[1] === null)
      || (!gamestate.matches('game') && !gamestate.matches('end.failed'))) return false

    const time = Math.round(gamestate.context.countdown)
    if (time === this.currentTime) return false

    this.currentTime = time
    return true
  }

  // remove button highlight if exit screen with button highlight
  removeButtonHighlight(gamestate: GameState, prevState: ScreenState) {
    if (gamestate.matches('tuto') && prevState === ScreenState.HOTSPOT_HOVERED
      && this.state[this.controllerId] !== ScreenState.HOTSPOT_HOVERED) {
      this.activity.gamepads.buttons.updateHighlight('A', false)
      return
    }
  }

  // check if user is crouched
  checkCrouch(gamestate: GameState) {
    if (!gamestate.matches('game.corridor') || this.hasCrouched) return

    if (gamestate.context.isCrouched) {
      this.hasCrouched = true
    }
  }

  // handle gamestate change
  stateChange(gamestate:GameState, updatedEmojisAnim?: boolean){
    if (!this.loaded || !this.started) return

    if (gamestate.event.type === 'GAME_HOTSPOT_ADVISE') {
      this.advisedHotspot = gamestate.event.payload
    }

    this.checkCrouch(gamestate)

    const prevState = [...this.state]
    this.setState(gamestate)

    const prevStateKeys = [...this.currentStateKeys]
    this.updateStateKey(gamestate)

    const updatedEmojis = this.emojiUpdate(gamestate) || updatedEmojisAnim
    const updatedCountdown = this.updateCountdown(gamestate)

    for (let i = 0; i < this.ctx.length; i++) {
      this.controllerId = i

      const updatedScreen = this.displayScreen(gamestate, prevState[i], updatedCountdown || updatedEmojis, prevStateKeys)
      this.removeButtonHighlight(gamestate, prevState[i])

      if (!updatedScreen && !updatedCountdown && !updatedEmojis) continue
      this.drawTopCountdown(gamestate)
      this.drawEmojis()
      this.setTexture()
    }
  }

  // handle emojis update
  emojiUpdate(gamestate: GameState): boolean {
    if (gamestate.context.emojis.length === this.emojis.length) return false

    for (let i = 0; i < gamestate.context.emojis.length - this.emojis.length; i++) {
      this.emojis.push(new Emoji(this.images.get(ImageNames.EMOJI), this.emojiAnimUpdate))
    }
    return true
  }

  emojiAnimUpdate = () => {
    this.stateChange(this.activity.gameStateWatcher.currentState, true)
  }

  reset() {
    this.state = [null, null]
    this.emojis = []
    this.hideTitle = true
    this.currentTime = 0
    this.prevHotspot = null
    this.prevAdvised = null
    this.notificationId = [null, null]
    this.currentHotspot = HotspotId.Step1_CloseDoor
    this.advisedHotspot = null
    this.hideNotification = [true, true]
    this.currentStateKeys = ['' , '']
  }
}