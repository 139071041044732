import { quat, vec3 } from "gl-matrix"

import XRView from "@webgl/xr/XRView"
import XRUtils from "@webgl/xr/XRUtils"
import AppService from "@/services/AppService"
import Controller from "@webgl/activities/common/controllers/Controller"
import XRActivity from "../XRActivity"
import { GameState } from "@/services/states/GameStateMachine"
import { DefaultGamePlayerPosition, GamePlayerPosition, playerPositionEquals } from "@/services/states/GameContextDatas"

const Q = quat.create()
const DIRECTION = vec3.create()
const FORWARD = vec3.fromValues(0, 0, -1)

const CROUCH_HEIGHT = 1.2

export default class PlayerPosition extends Controller {
  activity: XRActivity

  _rotation: number
  _currentPosition: GamePlayerPosition = DefaultGamePlayerPosition()
  _isCrouched = false
  readonly playerTranslation = vec3.create()

  get xrview(): XRView {
    return this.activity.renderer.xrview
  }

  stateChange(state: GameState) {
    const newPos = state.context.position

    if (state.event.type === 'GAME_MOVE_HEADING_PLAYER') {
      if (newPos.heading === this._currentPosition.heading) return

      this._currentPosition.heading = newPos.heading
      this.movePlayerHeading()
      return
    }

    if( !playerPositionEquals(newPos, this._currentPosition) ){
      this._currentPosition = newPos
      this.movePlayer()
    }

    if (this._isCrouched !== state.context.isCrouched) {
      this._isCrouched = state.context.isCrouched
      this.crouchPlayer(state.context.isCrouched)
    }

  }

  movePlayer() : void {
    const pos = this._currentPosition
    this.playerTranslation.set([pos.x, this._isCrouched ? -0.5 : 0.1,pos.y])
    // this.playerTranslation.set([pos.x, 0.1, pos.y])
    this.activity.navigateTo( {position:this.playerTranslation, heading:pos.heading} )
    this.activity.zones.setPlayerPosition( this.playerTranslation )

  }

  movePlayerHeading(): void {
    this.activity.navigateToNoFade( {
      position:this.playerTranslation,
      heading:this._currentPosition.heading
    })
  }

  crouchPlayer(isCrouched: boolean): void {
    this._isCrouched = isCrouched

    this.playerTranslation[1] = this._isCrouched ? -0.5 : 0.1
    this.activity.crouch(this._isCrouched, this.playerTranslation, this._currentPosition.heading)
  }

  updateRotation() {
    if (!this.xrview.pose) return

    XRUtils.domPointToQuat(this.xrview.pose.transform.orientation, Q)
    vec3.transformQuat(DIRECTION, FORWARD, Q)
    vec3.normalize(DIRECTION, DIRECTION)
    const rotation = Math.atan2(DIRECTION[2], DIRECTION[0])

    if (rotation === this._rotation) return
    this._rotation = rotation

    AppService.state.send({ type: 'GAME_ROTATE_PLAYER', payload: rotation })
  }

  // updateCrouch() {
  //   if (!this.xrview.pose) return

  //   const isCrouched = this.xrview.pose.transform.position.y <= CROUCH_HEIGHT

  //   if (isCrouched === this._isCrouched) return
  //   this._isCrouched = isCrouched

  //   AppService.state.send({ type: 'GAME_CROUCH_PLAYER', payload: this._isCrouched })
  // }

  preRender() {
    this.updateRotation()
    // this.updateCrouch()
  }

  reset() {
    return
  }
}