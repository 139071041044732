import Signal from "@/core/Signal";
import { FadeControl } from "@webgl/entities/BlackFade";
import { AbortController } from "@azure/abort-controller";
import Room, { RoomLevelId } from "@webgl/entities/Room";
import Delay from "@/core/Delay";

/**
 * control room loading and fade in/out sequance
 * may also manage pre loading of next levels
 */
export default class RoomLoadingController {
  private _currentLevelId: RoomLevelId = null
  _abortCtrl:AbortController

  constructor(private room: Room, private fadeCtrl: FadeControl, private delayInMS = 0){
  }

  onFadeIn = new Signal<void>()
  onLevelChanged = new Signal<void>()

  async setLevel( l:RoomLevelId ){
    if (this._currentLevelId === l) return

    this._currentLevelId = l

    this._abortCtrl?.abort()
    this._abortCtrl = new AbortController()

    const level = this.room.getLevel(l)
    
    await Promise.all([
      this.fadeCtrl.fadeIn(),
      Delay(this.delayInMS),
      ...(level.loaded ? [] : [level.load()]),
    ])
    
    this.onFadeIn.dispatch()
    this.fadeCtrl.fadeOut()

    if(this.room.currentLevelId !== l){
      this.room.currentLevel.unload()
    }

    this.room.setCurrentLevel( l )
    this.onLevelChanged.dispatch()
  }

  reset() {
    return
  }
}