import ResourceGroup from "@/webgl/resources/ResourceGroup";
import { CATCH_ASYNC } from "@/core/Async";
import { ImageResource, JsonResource, loadImage } from "@/webgl/resources/Net";
import Viewport from "@/store/modules/Viewport";
import { Resource } from "@/webgl/resources/Resource";
import AppService from "@/services/AppService"

export default class FrontResources {
  static Group: ResourceGroup = new ResourceGroup();

  static setupLotties() {// 5
    const G = this.Group;

    G.add(
      <Resource>(
        new JsonResource(require('@/assets/lottie/tutorial-move_around.json').default)
      ),
      "tutorial-move_around"
    );
    G.add(
      <Resource>(
        new JsonResource(require('@/assets/lottie/tutorial-crouch.json').default)
      ),
      "tutorial-crouch"
    );
    G.add(
      <Resource>(
        new JsonResource(require('@/assets/lottie/tutorial-door.json').default)
      ),
      "tutorial-door"
    );
    G.add(
      <Resource>(
        new JsonResource(require('@/assets/lottie/tutorial-actions.json').default)
      ),
      "tutorial-actions"
    );
    G.add(
      <Resource>(
        new JsonResource(require('@/assets/lottie/sound.json').default)
      ),
      "sound"
    );
  }

  static setupImages() {// 105 + 7 = 112
    const G = this.Group;

    const sprites: { path: string; frames: number }[] = [
      {
        path: "escape-plan",
        frames: 105,
      },
      // {
      //   path: "brand-logo",
      //   frames: 105,
      // },
    ];
    const ext = "png"// Viewport.webpSupport ? "webp" : "png"
    sprites.forEach(({ path, frames }) => {
      for (let i = 0; i < frames; i++) {
        const frameName = `frame-${i.toString().padStart(5, "0000")}`
        const f = require(`@/assets/images/sprites/${path}/${frameName}.${ext}`)
        const imageResource = new ImageResource(f)
        G.add(<Resource>imageResource, `${path}-${frameName}`)
      }
    });

    // G.add(
    //   new ImageResource(require("@/assets/images/emoji.png")),
    //   "emoji"
    // );

    G.add(
      new ImageResource(require("@/assets/images/landing-desktop.jpg")),
      'landing-desktop'
    );

    G.add(
      new ImageResource(require("@/assets/images/landing-desktop@2x.jpg")),
      'landing-desktop@2x'
    );

    G.add(
      new ImageResource(require("@/assets/images/landing-mobile.jpg")),
      'landing-mobile'
    );

    G.add(
      new ImageResource(require("@/assets/images/landing-mobile@2x.jpg")),
      'landing-mobile@2x'
    );

    G.add(
      new ImageResource(require("@/assets/images/ending-desktop.jpg")),
      'ending-desktop'
    );

    G.add(
      new ImageResource(require("@/assets/images/ending-desktop@2x.jpg")),
      'ending-desktop@2x'
    );

    G.add(
      new ImageResource(require("@/assets/images/map.png")),
      'map'
    );

    G.add(
      new ImageResource(require("@/assets/images/map-stroke.png")),
      'map-stroke'
    );
  }

  static setupFonts() {
    const G = this.Group;
  }

  static async load() { // 117
    AppService.state.send({ type: 'LOAD_FRONT_TOTAL', total: 118 })
    // FrontResources.setupImages()
    const sprites: { path: string; frames: number }[] = [
      {
        path: "escape-plan",
        frames: 105,
      },
      // {
      //   path: "brand-logo",
      //   frames: 105,
      // },
    ];
    const ext = "png"
    sprites.forEach(async ({ path, frames }) => {
      for (let i = 0; i < frames; i++) {
        const frameName = `frame-${i.toString().padStart(5, "0000")}`
        const f = require(`@/assets/images/sprites/${path}/${frameName}.${ext}`)
        await loadImage(f).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
      }
    })

    await loadImage(require("@/assets/images/landing-desktop.jpg")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/landing-desktop@2x.jpg")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/landing-mobile.jpg")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/landing-mobile@2x.jpg")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/landing-cloud.png")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/landing-cloud@2x.png")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/ending-desktop.jpg")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/ending-desktop@2x.jpg")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/ending-mobile.jpg")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/ending-mobile@2x.jpg")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/map.png")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    await loadImage(require("@/assets/images/map-stroke.png")).then(() => AppService.state.send('LOAD_FRONT_ELEMENT'))

    FrontResources.setupLotties()
    return await this.Group.load().finally(() => AppService.state.send('LOAD_FRONT_ELEMENT'))
    // const toLoad: Promise<any>[] = []
    // this.resourceNames.forEach(name => {
    //   if (this.Group.hasResource(name)) {
    //     toLoad.push(this.Group.getResource(name).load().then(() => { AppService.state.send('LOAD_FRONT_ELEMENT') }))
    //   }
    // })
    // AppService.state.send({ type: 'LOAD_FRONT_TOTAL', total: toLoad.length })
    
    // return Promise.all(toLoad)
  }
}
