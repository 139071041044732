import Fbo from "nanogl/fbo";
import { GLContext } from "nanogl/types";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import { RenderContext } from "@webgl/core/Renderer";
import FogProps from "@webgl/fog/FogProps";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import RenderPass from "@webgl/core/RenderPass";
import Viewport from "@webgl/core/Viewport";
import { quat, vec3 } from "gl-matrix";
import { DEG2RAD } from "@webgl/math";

const FBO_SIZE = 512;

type Renderable = {
    render(context: RenderContext): void;
}

export default class SpotShadows
{
    private fbo: Fbo;
    private casters: Renderable[] = [];
    private ctx: RenderContext;

    constructor(private fogProps: FogProps, private gl: GLContext)
    {
        this.fbo = new Fbo(gl);
        this.fbo.bind();
        this.fbo.attachColor();
        this.fbo.attachDepth();
        this.fbo.resize(FBO_SIZE, FBO_SIZE);
        const tex = this.fbo.getColorTexture();
        tex.wrap(gl.CLAMP_TO_EDGE);
        tex.setFilter(false, false, false);

        const glConfig = GLState.get(gl).config()
            .enableCullface(true)
            .cullFace(gl.FRONT)
            .depthFunc(gl.LESS)
            .enableDepthTest(true)
            .colorMask(true, true, true, true)
            .depthMask(true)
            .enablePolygonOffset(true)
            .polygonOffset(-1, -1);

        this.ctx = {
            camera: Camera.makePerspectiveCamera(),
            gl,
            glConfig,
            viewport: new Viewport(0, 0, FBO_SIZE, FBO_SIZE),
            mask: 0x1FFFFFFF,
            pass: RenderPass.DEPTH
        };
    }

    get texture()
    {
        return this.fbo.getColorTexture();
    }

    addCaster(obj: Renderable)
    {
        this.casters.push(obj);
    }

    preRender()
    {
        const camera = this.ctx.camera;
        vec3.copy(camera.position, this.fogProps.spotPos);
        quat.copy(camera.rotation, this.fogProps.spotQuat);
        camera.invalidate();
        camera.updateWorldMatrix();

        const lens = <PerspectiveLens>camera.lens;
        lens.setAutoFov(this.fogProps.spotAngle * DEG2RAD);
        lens.near = 0.01;
        lens.far = this.fogProps.spotRadius;

        camera.updateViewProjectionMatrix(FBO_SIZE, FBO_SIZE);
    }

    render()
    {
        const gl = this.gl;

        this.fbo.bind();
        GLState.get(gl).apply();
        this.fbo.defaultViewport();

        gl.clearColor(1, 1, 1, 1);
        // gl.clearDepth(1);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        this.casters.forEach(obj => obj.render(this.ctx));
    }
}