

import { Options, Vue } from 'vue-class-component';
import AppService from '@/services/AppService';

@Options({})
export default class GLView extends Vue {
  mounted(): void {
    this.$el.appendChild( AppService.glapp.glview.canvas )
  }
}

