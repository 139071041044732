
import AppService from "@/services/AppService"
import Controller from "@webgl/activities/common/controllers/Controller"
import XRActivity from "../../xr/XRActivity"
import DesktopActivity from "../../desktop/DesktopActivity"
import { ZoneId } from "@webgl/entities/Zones"

/**
 * trigger GAME_ZONE_CHANGE when user change room
 */
export default class ZoneEvents extends Controller {
  
  private previousZone: ZoneId = null
  
  activity: XRActivity | DesktopActivity
  
  stateChange(): void {
    0
  }

  start() {
    this.activity.zones.onZoneChange.on(this.onZoneChange)
  }
  
  
  stop(): void {
    this.activity.zones.onZoneChange.off(this.onZoneChange)
  }
  
  onZoneChange = () => {
    const current = this.activity.zones.currentZone
    AppService.state.send({
      type: 'GAME_ZONE_CHANGE', payload: {
        to: current,
        from: this.previousZone
      }
    })
    this.previousZone = current
  }

  reset(): void {
    this.previousZone = null
    this.activity.zones.reset()
    return
  }
}
