import Gltf from "nanogl-gltf/lib/Gltf";
import GltfNode from "nanogl-gltf/lib/elements/Node";
import { GLContext } from "nanogl/types";
import { StandardMetalness } from "nanogl-pbr/StandardPass";

import Gamepads from "./Gamepads";
import RenderMask from "@webgl/core/RenderMask";
import RenderPass from "@webgl/core/RenderPass";
import { GltfScene } from "@webgl/engine/GltfScene";
import { RenderContext } from "@webgl/core/Renderer";
import { XRGamepadButton } from "@webgl/xr/XRGamepad";

type Button = {
  node: GltfNode,
  highlight: boolean
}

export default class GamepadButtons {
  _list: Map<XRGamepadButton, Button>
  gltfRes: GltfScene
  highlightPass: StandardMetalness

  get gl(): GLContext {
    return this.gamepads.xrview.gl
  }

  constructor(
    public gamepads: Gamepads,
    public readonly context: RenderContext
  ) {
    this.highlightPass = new StandardMetalness()

    this.gltfRes = new GltfScene("illuminators_out/controllers_btns.gltf", this.gl, undefined)
    this.gltfRes.overrides.overridePass("shell_quest2BtnMAT.001", this.highlightPass)

    this._list = new Map<XRGamepadButton, Button>()
  }

  load() {
    return this.gltfRes.load()
  }

  onLoaded() {
    const gltf = this.gltfRes.gltf as Gltf

    const leftbtns = gltf.getNode("left_quest2_buttons")
    const rightBtns = gltf.getNode("right_quest2_buttons")

    this.gamepads.list.get("left").root.add(leftbtns);
    this.gamepads.list.get("right").root.add(rightBtns);

    for (const btn of [...leftbtns._children, ...rightBtns._children] as GltfNode[]) {
      btn.setScale(1.1)
      this._list.set(
        btn.name as XRGamepadButton,
        {
          node: btn,
          highlight: false
        }
      )
    }

    this.highlightPass.surface.baseColor.attachConstant([0, 0, 0])
    this.highlightPass.emissive.attachConstant([1, 1, 1])
    this.highlightPass.glconfig
      .enableCullface()
      .depthMask(false)
      .enableDepthTest(false)
      .enableBlend()
      .blendFunc( this.gl.DST_COLOR, this.gl.SRC_COLOR )
  }

  updateHighlight(btn: XRGamepadButton, highlight = true) {
    this._list.get(btn).highlight = highlight
  }

  reset() {
    this._list.forEach((btn) => {
      btn.highlight = false
    })
  }

  render(context: RenderContext) {
    this._list.forEach((btn) => {
      if (btn.highlight) {
        btn.node.renderable.render(
          context.gl,
          context.camera,
          RenderMask.BLENDED,
          RenderPass.DEFAULT
        )
      }
    })
  }
}