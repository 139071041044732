import { FogPass } from "@webgl/fog/FogPass";
import FogProps from "@webgl/fog/FogProps";
import { TextureResource } from "@webgl/resources/TextureResource";
import WebglAssets from "@webgl/resources/WebglAssets";
import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import { Sampler, Uniform } from "nanogl-pbr/Input";
import TexCoord, { DynamicTexCoord } from "nanogl-pbr/TexCoord";
import UnlitPass from "nanogl-pbr/UnlitPass";
import { GLContext } from "nanogl/types";

export type FireMaterialId = 
  "LargeFire" |
  "SidewallFire"


class SetRedToAlpha extends Chunk {
  constructor() {
    super(true, false);
  }

  _genCode(slots: ChunksSlots): void {
    let code = '';
    code += '_alpha = _baseColor.r;'
    // code += '_baseColor.r = 1.0;'
    // code += 'FragColor.gb /= _alpha;'
    slots.add( 'postf_linear', code)
    slots.add( 'post_fsetup', code)
  }
}

function getTiling( id: FireMaterialId ) : [number, number] {
  switch(id){
    case "LargeFire": 
    case "SidewallFire":
      return [4, 4]
  }
}
export default class FireMaterial {

  readonly tex: TextureResource;
  readonly pass: UnlitPass;
  readonly uBrightness: Uniform;
  readonly bloomPass: UnlitPass;

  private tiles: [number, number];
  private time = 0;
  private fps = 14
  private tcoord: DynamicTexCoord;

  constructor( private gl:GLContext, readonly id: FireMaterialId, private fogProps: FogProps){

    this.tiles = getTiling(id)
    
    this.tex = WebglAssets.getTexture(`${id}_sheet.jpg`, this.gl)
    this.tex.load()
    this.tcoord = TexCoord.createTransformedDynamic('aTexCoord0')
    this.tcoord.scale(1/this.tiles[0], 1/this.tiles[1])


    const setRedToAlpha = new SetRedToAlpha()

    this.pass = this.fogProps ? new FogPass(this.fogProps, 'fire', true) : new UnlitPass();
    this.pass.inputs.add( setRedToAlpha )

    const sampler = new Sampler("fire", this.tcoord )
    sampler.set(this.tex.texture)
    this.pass.baseColor.attach(sampler, 'rgb')
    this.pass.alpha.attach(sampler, 'r')
    this.pass.alphaMode.set('BLEND')

    this.pass.glconfig
      .enableDepthTest()
      .depthMask(false)
      .enableCullface()
      .enableBlend()
      .blendFunc(this.gl.ONE, this.gl.ONE_MINUS_SRC_ALPHA)



    this.bloomPass = new UnlitPass();
    this.bloomPass.baseColor.attach(sampler, 'rgb')
    // this.bloomPass.alpha.attach(sampler, 'r')
    // this.bloomPass.inputs.add( setRedToAlpha )
    const amp = 3.0
    this.bloomPass.baseColorFactor.attachConstant([amp, amp, amp])

    this.bloomPass.glconfig
      .enableDepthTest()
      .depthMask(false)
      .enableCullface()
      .enableBlend()
      .blendFunc(this.gl.ONE, this.gl.ONE)
  }

  load(){
    // return this.tex.load()
  }

  update(){
    const time = performance.now()
    const numFrames = this.tiles[0] * this.tiles[1]
    const frame = Math.floor(time / 1000 * this.fps) % numFrames
    const [x, y] = [frame % this.tiles[0], Math.floor(frame / this.tiles[0])]
    this.tcoord.translate(x / this.tiles[0], y / this.tiles[1])
  }
  

}