import { mat3, mat4, quat, vec3 } from "gl-matrix";
import type { XRHandedness } from "webxr";
import Ray from "@/webgl/math/Ray";
import { XRInput } from "@webgl/xr/XRInputs";
import Node from "nanogl-gltf/lib/elements/Node";
import DebugDraw from "@webgl/dev/debugDraw/DebugDraw";

const M4 = mat4.create();
const M3 = mat3.create();
const Q_ID = quat.create();
const QUATA = quat.create();
const VRNZ = vec3.fromValues(0.0, 0.0, -1.0);

const PoseCorrection = mat4.create();
mat4.translate(PoseCorrection, PoseCorrection, vec3.fromValues(0.0, 0, .03));
// mat4.rotateY(PoseCorrection, PoseCorrection, Math.PI);
mat4.scale(PoseCorrection, PoseCorrection, vec3.fromValues(.01, .01, .01));

export default class GamepadEntity {

  ray: Ray;

  thumbstick: Node;
  ftrigger  : Node;
  grip      : Node;

  thumbstickInitiallRotation: quat;
  ftriggerInitiallRotation  : quat;
  gripInitiallRotation      : quat;

  /**
   * when true, gamepad is no longer controller by the xr gamepad position
   */
  override = false



  constructor(
    public readonly hand: XRHandedness,
    public readonly root: Node,
    public readonly mesh: Node
  ) {

    // this.skin.add(this.mesh);
    this.ray = new Ray();

    this.thumbstick = root.findChild('b_thumbstick')
    this.ftrigger = root.findChild('b_trigger_front')
    this.grip = root.findChild('b_trigger_grip')

    this.thumbstickInitiallRotation = quat.create()
    this.ftriggerInitiallRotation = quat.create()
    this.gripInitiallRotation = quat.create()

    this.thumbstickInitiallRotation.set( this.thumbstick.rotation )
    this.ftriggerInitiallRotation.set( this.ftrigger.rotation )
    this.gripInitiallRotation.set( this.grip.rotation )

    this.root.setMatrix(PoseCorrection);

  }

  update(input: XRInput) {

    if (!input.pose || !input.gamepad)
      return;

    if( this.override ) return


    // thumbstick rotation
    // ======================
    const rbase = .4
    const axes = input.gamepad.getAxes()
    if( this.hand === "right"){
      quat.rotateZ( QUATA, Q_ID, axes[1] * rbase )
      quat.rotateY( QUATA, QUATA, axes[0] * rbase )
    } else {
      quat.rotateZ( QUATA, Q_ID, axes[1] * rbase )
      quat.rotateY( QUATA, QUATA, -axes[0] * rbase )
    }

    quat.multiply( this.thumbstick.rotation, this.thumbstickInitiallRotation, QUATA )
    this.thumbstick.invalidate()


    // front trigger
    // ======================
    const trig = .25 * input.source.gamepad.buttons[0].value
    quat.rotateX( QUATA, Q_ID, -trig )

    quat.multiply( this.ftrigger.rotation, this.ftriggerInitiallRotation, QUATA )
    this.ftrigger.invalidate()


    // grip trigger
    // ======================
    const gripr = .20 * input.source.gamepad.buttons[1].value
    quat.rotateY( QUATA, Q_ID, -gripr )

    quat.multiply( this.grip.rotation, this.gripInitiallRotation, QUATA )
    this.grip.invalidate()


    this.setMatrix(input.pose.transform.matrix as mat4);

    mat3.fromMat4(M3, this.root._wmatrix);
    quat.fromMat3(QUATA, M3);
    this.ray.origin.set(this.root._wposition);
    vec3.transformQuat(this.ray.direction, VRNZ, QUATA);

    DebugDraw.drawGuizmo(this.root._wmatrix)

  }

  setMatrix( m:mat4 ){
    mat4.multiply(M4, m, PoseCorrection);
    this.root.setMatrix(M4);
  }


}