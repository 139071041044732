import { GlslModule } from "@webgl/glsl/glslModule";
import Program from "nanogl/program";
import { GLContext } from "nanogl/types";

/////////////

///
///////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////
////////////
///
////////////////////////////////////////////////////////////////////////
/////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////
///
/////////////////
/////////////////////////////////////////
///////////
 

///
//////////////////////////////////////////////////////////////////////////////////////////
/////////////
///////////////
///////////////
///////////////
////////////
///
//////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////
/////////////////////////////
/////////////////////////////
///////////////////////////////////////////////////
///////////
///////////////////
///////////////////
////////////
 


/////////
          export default function LiveShader(glslModule:GlslModule ): GlslModule { return glslModule }
          export function LiveProgram( gl:GLContext, vert:GlslModule, frag:GlslModule ): Program { return new Program(gl, vert(), frag()); }
//////////