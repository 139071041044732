/* eslint-disable @typescript-eslint/no-explicit-any */
import { createMachine, Interpreter, State, StateMachine } from "xstate";
import AppService from "../AppService";
import { XRSessionEvent } from "./XRSessionEvents";


function CreateContext(){
  return {}
}

export type XRSessionStateContext = ReturnType<typeof CreateContext>;




export type XRSessionStateType = {
  value: string
  context: XRSessionStateContext
}


export type XRSessionState = State<XRSessionStateContext, XRSessionEvent, any, any>

export type XRSessionStateMachine = StateMachine<XRSessionStateContext, any, XRSessionEvent, XRSessionStateType>


export function CreateXRSessionStateMachine(){
  
  return createMachine<XRSessionStateContext, XRSessionEvent, XRSessionStateType>({

    id:"xrsession",

    initial: 'inactive',

    context: CreateContext(),

    states: {
      
      inactive: {
        entry: ['startStdLoop'],
        on: {
          REQUEST_SESSION: 'requesting'
        }
      },

      requesting: {
        invoke: {
          src:'requestSession',
          onDone: 'active',
          onError: 'errored'
        }

      },

      active: {
        entry: [
          'stopStdLoop'
        ],
        on: {
          EXIT_SESSION: {
            actions: ['exitSession']
          },
          END_SESSION: 'inactive'
        }
      },

      errored: {
        on:{
          RETRY: 'requesting'
        }
      }

    }

  }, {
    actions: {
      exitSession() {
        AppService.glapp.xrview.exitSession();
      },

      startStdLoop(){
        AppService.glapp.glview.start()
      },
      
      stopStdLoop(){
        AppService.glapp.glview.stop()
      },
    },
    services: {
      requestSession() {
        return AppService.glapp.xrview.startSession();
      },

    }
  })
}


export type XRSessionStateInterpreter = Interpreter<XRSessionStateContext, any, XRSessionEvent, XRSessionStateType>

