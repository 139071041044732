import { LiveProgram } from "@webgl/core/LiveShader";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import GLArrayBuffer from "nanogl/arraybuffer";
import Program from "nanogl/program";

import fadeVert from "./skybox.vert"
import fadeFrag from "./skybox.frag"
import { mat4 } from "gl-matrix";
import { TextureResource } from "@webgl/resources/TextureResource";
import { RenderContext } from "@webgl/core/Renderer";
import Chunk from "nanogl-pbr/Chunk";
import Camera from "nanogl-camera";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Texture2D from "nanogl/texture-2d";

const M4 = mat4.create();
const INV_PROJ = mat4.create()

const QUAD_VERTICES = new Float32Array([
  -1, -1,
  -1, 1,
  1, -1,
  1, 1,
]);




export class SkyboxChunk extends Chunk {


  camera: Camera
  envmap: Texture2D

  constructor() {
    super(true, true);
  }

  _genCode(slots: ChunksSlots): void {
    let code = '';
    code += 'vec3 wDir = normalize(vWorldPos - uCamPosSB);\n'
    code += 'vec2 tc = vec2(  - atan(wDir.x, wDir.z)/6.283184 + .5, - asin(wDir.y)/3.1415926 + .5 );\n'
    code += 'FragColor.xyz = texture2D( tLatLon, tc ).rgb;\n'

    // code += '_baseColor.r = 1.0;'
    // code += 'FragColor.gb /= _alpha;'
    slots.add( 'pv', 'OUT vec3 vWorldPos;')
    slots.add( 'postv', 'vWorldPos = vertex.worldPos;')

    slots.add( 'pf', 'IN vec3 vWorldPos;')
    slots.add( 'pf', 'uniform vec3 uCamPosSB;')
    slots.add( 'pf', 'uniform sampler2D tLatLon;')

    slots.add( 'postf_linear', code)
    slots.add( 'post_fsetup', code)
  }

  setup(prg: Program): void {
    prg.uCamPosSB( this.camera._wposition )
    prg.tLatLon( this.envmap )
  }

}

export default class Skybox {


  private prg: Program;
  private quad: GLArrayBuffer
  private cfg: LocalConfig;
  private alphacfg: LocalConfig;

  private opacity = 1
  public envmap: TextureResource


  constructor( readonly gl: WebGLRenderingContext) {

    this.prg = LiveProgram(gl, fadeVert, fadeFrag)

    this.quad   = new GLArrayBuffer(gl, QUAD_VERTICES)
      .attrib( 'aPosition', 2, gl.FLOAT )

    this.cfg = GLState.get(gl).config()
      .depthMask(false)
      .enableDepthTest(true)
      .enableCullface(false)

    this.alphacfg = GLState.get(gl).config()

    .depthMask(false)
    .enableDepthTest(true)
    .enableCullface(false)
      .enableBlend(true)
      .blendFunc(gl.CONSTANT_ALPHA, gl.ONE_MINUS_CONSTANT_ALPHA);

  }


  render(context: RenderContext) {


    if( !this.envmap.isLoaded ) return;

    M4.set( context.camera._wmatrix )
    M4[12] = 0
    M4[13] = 0
    M4[14] = 0

    
    mat4.invert(M4, M4);
    mat4.multiply(M4, context.camera.lens.getProjection(), M4);


    mat4.invert( INV_PROJ, M4 )

    // bind program
    this.prg.use()

    // vertexAttribPointer
    this.quad.attribPointer( this.prg )

    // setup program's uniforms
    this.prg.tLatLon( this.envmap.texture )
    this.prg.uUnproject( INV_PROJ )
    // this.prg.uCamPos( camera._wposition )
    
    // drawArray()
    this.cfg.apply()

    if( this.opacity < 1 ){
      this.alphacfg.blendColor(1, 1, 1, this.opacity)
      this.alphacfg.apply()
    }

    
    this.quad.drawTriangleStrip()

  }
}