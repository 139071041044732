import XRActivity from "../../xr/XRActivity";
import DesktopActivity from "../../desktop/DesktopActivity";
import { GameState } from "@/services/states/GameStateMachine";

export default abstract class Controller {
  constructor(protected activity: XRActivity | DesktopActivity) {
  }

  start(){
    this.activity.gameStateWatcher.onStateChange.on( this._stateChange )
    this._stateChange( this.activity.gameStateWatcher.currentState )
  }

  stop(){
    this.activity.gameStateWatcher.onStateChange.off( this._stateChange )
  }

  private _stateChange = (state: GameState) => {
    this.stateChange(state)
  }

  abstract stateChange(state: GameState):void

  abstract reset():void

}
