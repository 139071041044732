import gsap from "gsap";
import Rect from "nanogl-primitives-2d/rect";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import Program from "nanogl/program";
import { GLContext } from "nanogl/types";

import fadeVert from "./fade.vert"
import fadeFrag from "./fade.frag"
import AppService from "@/services/AppService";
import { LiveProgram } from "@webgl/core/LiveShader";


export class FadeControl {

  /**
   * 0 = no black overlay, 1 = full black
   */
  value = 0;
  tween: gsap.core.Tween;

  constructor(private fade:BlackFade, private duration = .2){

  }

  fadeIn(duration = this.duration): Promise<void>{
    this.tween?.kill()

    AppService.state.send('GAME_FADE', { payload: true })

    this.value = this.fade.value
    const md = duration * (1.0-this.value)
    console.log(`duration: ${md}`)
    if( md < 0.05 ) return Promise.resolve()

    this.tween = gsap.to(this, {value:1, duration:md, ease: "linear"})
    return this.tween.then().then()
  }

  fadeOut(duration = this.duration): Promise<void>{
    this.tween?.kill()

    AppService.state.send('GAME_FADE', { payload: false })

    this.value = this.fade.value
    this.tween = gsap.to(this, {value:0, duration, ease: "linear"})
    return this.tween.then().then()
  }

}

/**
 * fade the entire screen when navigate
 */
export default class BlackFade {

  private _controls: FadeControl[] = []

  get value():number{
    let max = 0.0
    for( const c of this._controls ){
      max = Math.max( max, c.value )
    }
    return max
  }

  private prg: Program;
  private rect: Rect
  private cfg: LocalConfig;

  constructor(private gl: GLContext) {
    this.rect = new Rect(gl);
    this.prg = LiveProgram(gl, fadeVert, fadeFrag)
    this.cfg = GLState.get(gl).config()
      .enableBlend()
      .blendFunc( gl.ZERO, gl.ONE_MINUS_SRC_ALPHA )
  }


  createControl(duration = .2):FadeControl{
    const c = new FadeControl(this, duration)
    this._controls.push(c)
    return c
  }

  render(){
    if( this.value === 0.0 ) return

    this.prg.use()
    this.prg.uOpacity( this.value )

    this.cfg.apply()
    this.rect.attribPointer(this.prg)
    this.rect.render()

  }
}