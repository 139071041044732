import Controller from "@webgl/activities/common/controllers/Controller";
import XRActivity from "@webgl/activities/xr/XRActivity";
import DesktopActivity from "@webgl/activities/desktop/DesktopActivity";
import { GameState } from "@/services/states/GameStateMachine";
import { RoomLevelId } from "@webgl/entities/Room";
import { DefaultGamePlayerPosition, playerPositionEquals } from "@/services/states/GameContextDatas";

/**
 * Handle player position updates to change fog props in XR
 */
export default class FogController extends Controller {
    private _currentPosition = DefaultGamePlayerPosition()
    private _currentLevel: RoomLevelId;
    private _emitters: number[] = [];
    private _currentPresetHash = ''

    activity: XRActivity | DesktopActivity

    stateChange(state: GameState) {
        const newPos = state.context.position

        const ph = JSON.stringify(state.context.fogPreset)
        if( ph !== this._currentPresetHash ) {
            this._currentPresetHash = ph
            this.activity.fogProps.applyPreset(state.context.fogPreset)
        }

        if(!playerPositionEquals(newPos, this._currentPosition) || this._currentLevel !== state.context.roomlevel ){
            this._currentPosition = newPos
            this._currentLevel = state.context.roomlevel;
            this.activity.fire.setLevel(state.context.roomlevel);
            // this.updateFog();
            this.updateEmitters();
        }
    }

    // TODO: Could also do this time-based?
    private updateFog(): void
    {
        const distance = this.activity.fire.getDistance(this._currentPosition);
        const props = this.activity.fogProps;
        props.density = Math.min(50.0 / (distance * distance), 50.0);
        props.vignette = 1.0 - Math.min(distance / 5.0, 1.0);
    }

    private updateEmitters(): void
    {
        this._emitters.forEach(emitter => this.activity.embers.removeEmitter(emitter));
        this._emitters = [];
        const verts = this.activity.fire.vertices;
        // no vertices in this level
        if (!verts) return;

        const vertCount = verts.length;
        const cellSize = 2;
        const cells = new Map<string, number[]>();

        // we're dividing the world in cells (buckets) to store the vertices
        for (let i = 0; i < vertCount; i += 2) {
            const x = verts[i];
            const y = verts[i + 1];
            const cx = Math.floor(x / cellSize);
            const cy = Math.floor(y / cellSize);
            const key = `${cx},${cy}`;

            if (!cells.has(key))
                cells.set(key, []);

            cells.get(key).push(i);
        }

        // calculate average position for cell and spawn an emitter
        for (const indices of cells.values()) {
            const len = indices.length;
            let minX = Number.POSITIVE_INFINITY, minY = Number.POSITIVE_INFINITY;
            let maxX = Number.NEGATIVE_INFINITY, maxY = Number.NEGATIVE_INFINITY;
            let avgX = 0, avgY = 0;
            for (let i = 0; i < len; ++i) {
                const x = verts[indices[i]];
                const y = verts[indices[i] + 1];
                avgX += x;
                avgY += y;
                minX = Math.min(x, minX);
                minY = Math.min(y, minY);
                maxX = Math.max(x, maxX);
                maxY = Math.max(y, maxY);
            }
            const w = maxX - minX;
            const h = maxY - minY;
            const rad = Math.sqrt(w * w + h * h);
            if (rad < 0.1) continue;
            avgX /= len;
            avgY /= len;

            this._emitters.push(this.activity.embers.addEmitter(avgX, 0, avgY, 50 * rad, Math.max(rad, 0.5) + 1))
        }
    }

    reset() {
        return
    }
}