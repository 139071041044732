import gsap from 'gsap'
import Node from "nanogl-node";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import { mat4, quat, vec2, vec3, vec4 } from "gl-matrix";

import Ray from "@webgl/math/Ray";
import Time from "@webgl/Time";
import Signal from "@/core/Signal";
import Picking from "@webgl/engine/Picking";
import Controller from "@webgl/activities/common/controllers/Controller";
import mat4LookAt from "@/webgl/math/mat4-lookat";
import AppService from "@/services/AppService";
import DesktopActivity from "@webgl/activities/desktop/DesktopActivity";
import { DEG2RAD } from "@webgl/math";
import { GameState } from "@/services/states/GameStateMachine";
import { mat4Yaxis, mat4Zaxis } from "@/webgl/math/mat4-axis";
import { DefaultGamePlayerPosition, GamePlayerPosition, playerPositionEquals } from "@/services/states/GameContextDatas"

const pRay = new Ray()

const DIRECTION = vec3.create()
const FORWARD = vec3.fromValues(0, 0, -1)

export const STAND_HEIGHT = 1.5
const CROUCH_HEIGHT = 0.7

const DELTA = vec2.create()
const Q_ID = quat.create()
const Q_1 = quat.create()
const Q_2 = quat.create()
const M0 = mat4.create()
const M1 = mat4.create()

/**
 *  Split matrix in leveled one and remainder
 */
const _elV0 = vec3.create()
const _elDIR = vec3.create()
const _elUP = vec3.create()
const _elINV = mat4.create()
function extractLeveledMatrix(m: mat4, leveled: mat4, remainder: mat4) {
  mat4Zaxis(_elDIR, m)
  mat4Yaxis(_elUP, m)
  _elDIR[1] = 0
  _elDIR[0] *= -1
  _elDIR[2] *= -1
  mat4LookAt(leveled, _elV0, _elDIR, _elUP)
  leveled[12] = m[12]
  leveled[13] = m[13]
  leveled[14] = m[14]
  mat4.invert(_elINV, leveled)
  mat4.multiply(remainder, _elINV, m)
}

class LookAtController {
  coords = vec2.create()
  startCoords = vec2.create()
  isActive = false

  rotations = vec2.create()
  lastFrameRotation = vec2.create()
  rotationsVelocity = vec2.create()
  startRotations = vec2.create()

  private _rotationMatrix = mat4.create()

  public onStartLook: Signal<void> = new Signal();
  public onEndLook: Signal<void> = new Signal();

  constructor(private camctrl: CameraController) {}

  get primaryPointer() {
    return this.camctrl.activity.renderer.pointers.primary
  }

  start() {
    this.primaryPointer.onDown.on(this.onMouseDown)
    this.primaryPointer.onUp.on(this.onMouseUp)
  }

  stop() {
    this.isActive = false
    this.primaryPointer.onDown.off(this.onMouseDown)
    this.primaryPointer.onUp.off(this.onMouseUp)
  }

  onMouseDown = () => {
    // if (this.camctrl.isMoving || this.camctrl.isCrouching) return

    this.isActive = true

    vec2.copy(this.startCoords, this.primaryPointer.coord.viewport)
    vec2.copy(this.coords, this.primaryPointer.coord.viewport)

    this.onStartLook.dispatch()
    this.startRotations.set(this.rotations)
  }

  onMouseUp = () => {
    this.isActive = false

    this.onEndLook.dispatch()
  }

  rotateHeading(heading: number, startRotation: number) {
    this.onStartLook.dispatch()
    gsap.to(this.rotations, {
      0: startRotation - heading,
      duration: 2.0,
      ease: "power2.inOut",
    })
  }
  
  rotateImmediate(heading: number, startRotation: number) {
    gsap.killTweensOf(this.rotations)
    this.rotations[0] = startRotation - heading
    this.rotations[1] = 0
  }

  update(dt: number) {


    this.lastFrameRotation.set(this.rotations)
    
    if( this.isActive && !this.primaryPointer.isDown() ){
      this.onMouseUp()
      this.rotationsVelocity[0] = 0
      this.rotationsVelocity[1] = 0
    }

    if (this.isActive) {

      vec2.copy(this.coords, this.primaryPointer.coord.viewport)

      vec2.subtract(DELTA, this.coords, this.startCoords)

      const dx = DELTA[0] * this.camctrl.camera.lens._hfov * .5
      const dy = DELTA[1] * this.camctrl.camera.lens._vfov * .5

      this.rotations[0] = this.startRotations[0] + dx
      this.rotations[1] = this.startRotations[1] + dy
      this._storeVelocity(dt)


    } else {
      vec2.scale(this.rotationsVelocity, this.rotationsVelocity, .9)
      vec2.scaleAndAdd(this.rotations, this.rotations, this.rotationsVelocity, dt)
    }
  }

  _storeVelocity(dt: number) {
    vec2.sub(DELTA, this.rotations, this.lastFrameRotation)
    if (vec2.length(DELTA) > 0)
      vec2.scale(this.rotationsVelocity, DELTA, 1 / dt)
  }

  attenuateRotations(scale: number) {
    this.rotations[0] *= scale
    this.rotations[1] *= scale
  }

  getRotationMatrix(): mat4 {
    quat.rotateY(Q_1, Q_ID, this.rotations[0])
    quat.rotateX(Q_2, Q_ID, -this.rotations[1])
    quat.multiply(Q_1, Q_1, Q_2)
    mat4.fromQuat(this._rotationMatrix, Q_1)
    return this._rotationMatrix
  }
}

export default class CameraController extends Controller {
  activity: DesktopActivity

  _isCrouched = false
  _rotation = 0
  _currentPosition: GamePlayerPosition = DefaultGamePlayerPosition()
  readonly playerTranslation = {
    x: 0,
    y: 0
  }

  readonly playerHeight = {
    h: STAND_HEIGHT
  }

  lookat: LookAtController

  navmesh: Picking
  camTransform: Node
  target: vec4 = vec4.create();
  normal: vec3 = vec3.create();

  startCoords: vec2 = vec2.create()

  enabled = false
  hasTarget = false
  mouseDown = false
  isMoving = false
  isCrouching = false
  noNav = false;

  get camera(): Camera<PerspectiveLens> {
    return this.activity.renderer.camera
  }

  get primaryPointer() {
    return this.activity.renderer.pointers.primary
  }

  constructor(activity: DesktopActivity) {
    super(activity)

    this.camTransform = new Node()
    this.activity.root.add(this.camTransform)
    this.initCamera()

    this.camera.lens.setAutoFov(60 * DEG2RAD)

    this.lookat = new LookAtController(this)
    this.lookat.onStartLook.on(this.updateIdleMatrix);
  }

  initCamera() {
    this.camTransform.rotation.set([0, 0, 0, 1])
    this.camTransform.rotateY(180 * DEG2RAD)
    this.camTransform.rotateX(-0.23)
    this.camTransform.invalidate();
    this.camTransform.updateWorldMatrix()

    this.camera.setMatrix(this.camTransform._matrix)
  }

  enable() {
    this.enabled = true

    this.primaryPointer.onDown.on(this.onMouseDown)
    this.primaryPointer.onUp.on(this.onMouseUp)

    document.addEventListener("keyup", this.onKeyUp)
    document.addEventListener("keydown", this.onKeyDown)

    this.updateIdleMatrix();
    this.lookat.start()
  }

  disable() {
    this.enabled = false

    this.primaryPointer.onDown.off(this.onMouseDown)
    this.primaryPointer.onUp.off(this.onMouseUp)

    document.removeEventListener("keyup", this.onKeyUp)
    document.removeEventListener("keydown", this.onKeyDown)

    this.lookat.stop()
  }

  updateIdleMatrix = () => {
    this.lookat.attenuateRotations(0);
    this.camTransform.setMatrix(this.camera._matrix);
    this.camTransform.invalidate();
    this.camTransform.updateWorldMatrix();
  }

  updateRotation() {
    vec3.transformQuat(DIRECTION, FORWARD, this.camera.rotation)
    vec3.normalize(DIRECTION, DIRECTION)
    const rotation = Math.atan2(DIRECTION[2], DIRECTION[0])

    if (rotation === this._rotation) return

    this._rotation = rotation
    AppService.state.send({ type: 'GAME_ROTATE_PLAYER', payload: rotation })
  }

  preRender() {
    if (!this.enabled) return

    if( this.noNav ) this.hasTarget = false;
    else {
      this.hasTarget = this.raycastNavMesh()
    }

    const m = this.camera._matrix;
    
    this.camTransform.position[0] = this.playerTranslation.x;
    this.camTransform.position[2] = this.playerTranslation.y;
    this.camTransform.position[1] = this.playerHeight.h;
    this.camTransform.invalidate();
    this.camTransform.updateWorldMatrix();

    m.set(this.camTransform._wmatrix);

    extractLeveledMatrix(m, M0, M1);

    this.lookat.update(Time.dt);

    mat4.multiply(m, M0, this.lookat.getRotationMatrix())
    mat4.multiply(m, m, M1)

    this.camera.setMatrix(m)

    this.updateRotation()
  }

  // DETECT CROUCH

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ' && !this._isCrouched) {
      AppService.state.send({ type: 'GAME_CROUCH_PLAYER', payload: true })
    }
  }

  onKeyUp = (e: KeyboardEvent) => {
    // if (this.isMoving) return

    if (e.key === ' ') {
      AppService.state.send({ type: 'GAME_CROUCH_PLAYER', payload: !this._isCrouched })
    }
  }

  // DETECT MOVE

  onMouseDown= () => {
    this.mouseDown = true
    vec2.copy(this.startCoords, this.primaryPointer.coord.viewport)
  }

  onMouseUp = () => {
    this.mouseDown = false

    const hasChanged = this.startCoords[0] !== this.primaryPointer.coord.viewport[0]
      && this.startCoords[1] !== this.primaryPointer.coord.viewport[1]

    if (!hasChanged ){
      if(this.hasTarget) {
        this.moveToTarget()
      } else {
        this.activity.hotspots.handleClick()
      }
    }
  }

  raycastNavMesh(): boolean {
    if (!this.navmesh) return false

    pRay.unproject(this.primaryPointer.coord.viewport, this.camera)
    return this.navmesh.raycast(pRay, this.target, this.normal) !== 0;
  }

  moveToTarget() {
    this.activity.zones.setPlayerPosition( this.target as any as vec3 )

    AppService.state.send({ type: 'GAME_MOVE_PLAYER', payload: {
      x: this.target[0],
      y: this.target[2],
      heading: null
    }})
  }

  // MOVE ON STATE CHANGE

  stateChange(state: GameState) {
    const newPos = state.context.position

    this.noNav = state.matches('intro')

    if (this.enabled && (state.context.isPaused || state.matches('end'))) {
      this.disable()
    }
    if (!this.enabled && !state.context.isPaused && !state.matches('end')) {
      this.enable()
    }

    if( !playerPositionEquals(newPos, this._currentPosition) ){
      this._currentPosition = newPos
      this.movePlayer()

      if (newPos.heading !== null) {
        this.lookat.rotateHeading(newPos.heading, this._rotation)
      }
    }

    if (this._isCrouched !== state.context.isCrouched) {
      this._isCrouched = state.context.isCrouched
      this.crouchPlayer()
    }


  }

  teleportPlayer(): void {
    gsap.killTweensOf(this.playerTranslation);
    gsap.killTweensOf(this.playerHeight);
    this.isMoving = false;

    const pos = this._currentPosition
    this.playerTranslation.x = pos.x
    this.playerTranslation.y = pos.y
    this.playerHeight.h = this._isCrouched ? CROUCH_HEIGHT : STAND_HEIGHT
    
    // this.lookat.rotateImmediate(pos.heading, this._rotation)

    this.camTransform.invalidate();
    this.camTransform.updateWorldMatrix();

  }

  movePlayer() : void {
    const pos = this._currentPosition

    this.isMoving = true;
    gsap.killTweensOf(this.playerTranslation);

    gsap.to(this.playerTranslation, {
      x: pos.x,
      y: pos.y,
      duration: 2.0,
      ease: "power2.inOut",
      onComplete: () => {
        this.isMoving = false;
      }
    });
  }

  // CROUCH ON STATE CHANGE

  crouchPlayer(): void {
    const h = this._isCrouched ? CROUCH_HEIGHT : STAND_HEIGHT
    gsap.killTweensOf(this.playerHeight);

    // this.isCrouching = true;
    gsap.to(this.playerHeight, {
      h: h,
      duration: 1.0,
      ease: "power2.inOut",
      onComplete: () => {
        // this.isCrouching = false;
      }
    });
  }

  reset() {
    this._isCrouched = false
    this._rotation = 0
    this._currentPosition = DefaultGamePlayerPosition()
    this.teleportPlayer()
    this.initCamera()
    this.updateIdleMatrix()
  }
}