import Node from "nanogl-gltf/lib/elements/Node";
import GltfLoader from "nanogl-gltf/lib/io/GltfLoader";
import { vec3, vec4 } from "gl-matrix";

import Ray from "@webgl/math/Ray";
import Picking from "@webgl/engine/Picking";
import { GltfModuleIO } from "@webgl/resources/GltfResource";
import { RoomLevelId } from "@webgl/entities/Room";



interface ITarget {
  raycast(ray: Ray): number
}

export class Target implements ITarget {
  name: string
  picking: Picking

  hitNrm = vec3.create()
  hitData = vec4.create()
  hitPos = new Float32Array(this.hitData.buffer, 0, 3) as vec3

  constructor(readonly node: Node) {
    this.name = this.node.name

    this.picking = Picking.createFromGltfNode(this.node)
    this.picking.doubleSided = true
  }

  raycast(ray: Ray): number {
    if(!this.picking) return Infinity
    this.hitData[3] = Infinity
    const res = this.picking.raycast(ray, this.hitData, this.hitNrm)
    if(res === 0) return Infinity
    return this.hitData[3]
  }
}


class Dummy implements ITarget {
  raycast(): number {
    return Infinity
  }
}

const _Dummy = new Dummy()

export default class PickingSources {
  targets: Target[] = []


  constructor( private level: RoomLevelId ){}

  

  async load() : Promise<void>{
    const loader = new GltfLoader(new GltfModuleIO(), `picking/picking-${this.level}.glb`);
    const gltf = await loader.load();
    const nodes = gltf.nodes.filter(n=>n.mesh)

    this.targets = nodes.map(node => new Target(node))
  }



  getTarget(name: string): ITarget {
    const target = this.targets.find(t => t.name === name)

    if (!target) {
      return _Dummy;
      // throw new Error(`Picking target ${name} not found`)
    }

    return target
  }
}