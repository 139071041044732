var fn = function( obj ){
var __t,__p='';
__p+='attribute vec3 aPosition;\nattribute vec3 aNextPosition;\nattribute vec3 aPreviousPosition;\nattribute vec2 aTexCoord;\n\nuniform mat4 uModelViewMatrix;\nuniform mat4 uProjectionMatrix;\nuniform vec2 uResolution;\nuniform float uLineWidth;\n\nvarying vec2 vTexCoord;\n\nvec2 fix( vec4 i, float aspect ) {\n  vec2 res = i.xy / i.w;\n  res.x *= aspect;\n  return res;\n}\n\nvoid main(void){\n\n  float aspect = uResolution.x / uResolution.y;\n  \n  mat4 m = uProjectionMatrix * uModelViewMatrix;\n  vec4 finalPosition = m * vec4( aPosition, 1.0 );\n  vec4 prevPos = m * vec4( aPreviousPosition, 1.0 );\n  vec4 nextPos = m * vec4( aNextPosition, 1.0 );\n\n  vec2 currentP = fix( finalPosition, aspect );\n  vec2 prevP = fix( prevPos, aspect );\n  vec2 nextP = fix( nextPos, aspect );\n\n  float w = uLineWidth;\n\n  vec2 dir;\n  if( nextP == currentP ) dir = normalize( currentP - prevP );\n  else if( prevP == currentP ) dir = normalize( nextP - currentP );\n  else {\n    vec2 dir1 = normalize( currentP - prevP );\n    vec2 dir2 = normalize( nextP - currentP );\n    dir = normalize( dir1 + dir2 );\n\n    vec2 perp = vec2( -dir1.y, dir1.x );\n    vec2 miter = vec2( -dir.y, dir.x );\n  \n  }\n\n  vec4 normal = vec4( -dir.y, dir.x, 0., 1. );\n  normal.xy *= .5 * w;\n  normal *= uProjectionMatrix;\n\n  float side = step(aTexCoord.x, 0.5) * 1.0 + step(0.5, aTexCoord.x) * -1.0;\n  finalPosition.xy += normal.xy * side;\n\n  gl_Position = finalPosition;\n\n  vTexCoord = aTexCoord;\n  \n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
