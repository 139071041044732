var fn = function( obj ){
var __t,__p='';
__p+='precision highp float;\n\nattribute vec2 aPosition;\n\n\nvarying highp vec2 vTexCoord;\n\nvoid main( void ){\n  vec4 pos = vec4( aPosition, 0.999, 1.0 );\n  vTexCoord = aPosition;//normalize( (uUnproject * pos).xyz );\n  gl_Position = pos;\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
