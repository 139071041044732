var fn = function( obj ){
var __t,__p='';
__p+='#pragma SLOT version\n\n#pragma SLOT definitions\n\n\n#ifndef hasNormals\n#define hasNormals 0\n#endif\n#ifndef hasTangents\n#define hasTangents 0\n#endif\n\n#if hasTangents && !hasNormals\n#pragma error tan but no nrm\nerror\n#endif\n\n#pragma SLOT precision\n\n'+
( require( "nanogl-pbr/glsl/includes/glsl-compat.vert" )() )+
'\n\n#pragma SLOT pv\n\n\nIN vec3 aPosition;\n\n\n// uniform mat4 uMVP;\nuniform mat4 uWorldMatrix;\nuniform mat4 uVP;\n\nstruct VertexData {\n    highp vec3 position;\n    highp vec3 worldPos;\n    #if hasNormals\n    vec3 normal;\n    #endif\n    #if hasTangents\n    vec3 tangent;\n    #endif\n    mat4 worldMatrix;\n};\n\n\nvoid InitVertexData( out VertexData vertex ){\n\n    vertex.position = aPosition;\n    #if hasNormals\n    vertex.normal = vec3(0.0);\n    #endif\n    #if hasTangents\n    vertex.tangent = vec3(0.0);\n    #endif\n\n    vertex.worldMatrix = uWorldMatrix;\n\n}\n\n\nOUT vec3 vWorldPosition;\nOUT vec4 vNDC;\n\nvoid main( void ){\n\n    #pragma SLOT v\n\n    VertexData vertex;\n    InitVertexData( vertex );\n\n    #pragma SLOT vertex_warp\n\n    vec4 worldPos = vertex.worldMatrix * vec4( vertex.position, 1.0 );\n    vertex.worldPos.xyz = worldPos.xyz / worldPos.w;\n\n    #pragma SLOT vertex_warp_world\n\n    vNDC = gl_Position = uVP * vec4( vertex.worldPos, 1.0 );\n\n    vWorldPosition = vertex.worldPos;\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
