import gsap from 'gsap'
import Rect from "nanogl-primitives-2d/rect";
import Program from "nanogl/program";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import { GLContext } from "nanogl/types";

import vert from "./vignette.vert"
import frag from "./vignette.frag"
import Delay from "@/core/Delay";
import { LiveProgram } from "@webgl/core/LiveShader";
import { RGB_BRIGHT_RED } from "./Colors";

const color = [...RGB_BRIGHT_RED.map(val => val / 255), .4]

export default class Vignette {
  private prg: Program;
  private rect: Rect
  private cfg: LocalConfig;
  private opacity = 0
  private tween: gsap.core.Tween

  constructor(private gl: GLContext, private isDesktop?: boolean) {
    this.rect = new Rect(gl);
    this.prg = LiveProgram(gl, vert, frag)
    this.cfg = GLState.get(gl).config()
      .enableBlend()
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
  }

  show(): Promise<gsap.core.Tween> {
    this.tween?.kill()

    this.tween = gsap.to(this, {
      opacity: 1,
      duration: 0.5,
      ease: "power2.out"
    })
    return this.tween.then().then()
  }

  hide(): Promise<gsap.core.Tween> {
    this.tween?.kill()

    this.tween = gsap.to(this, {
      opacity: 0,
      duration: 1,
      ease: "power2.in"
    })
    return this.tween.then().then()
  }

  async showTemp() {
    await this.show()
    await Delay(2000)
    await this.hide()
  }

  render(){
    this.prg.use()

    this.prg.uColor(color)
    this.prg.uOpacity(this.opacity)
    this.prg.uBig(this.isDesktop ? 0. : 1.)

    this.cfg.apply()
    this.rect.attribPointer(this.prg)
    this.rect.render()
  }

  reset() {
    this.tween?.kill()
    this.opacity = 0
  }
}