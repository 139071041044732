import Delay from "@/core/Delay";
import XRGamepad from "@webgl/xr/XRGamepad";
import XRActivity from "../XRActivity";
import Controller from "../../common/controllers/Controller"
import { GameState } from "@/services/states/GameStateMachine";
import { COUNTDOWN_ALMOST_OVER } from "@/services/states/GameContextDatas";

export default class VibrationsController extends Controller {
  activity: XRActivity
  almostOver = false

  get gamepad(): XRGamepad {
    if (!this.activity.renderer.xrview.inputs.getInput('right')) return null
    return this.activity.renderer.xrview.inputs.getInput('right').gamepad
  }

  constructor(activity: XRActivity) {
    super(activity)
  }

  async stateChange(state: GameState) {
    if (this.gamepad === null) return

    if (!this.almostOver && state.event.type === 'GAME_SET_COUNTDOWN' && state.context.countdown < COUNTDOWN_ALMOST_OVER) {
      this.almostOver = true

      this.gamepad.pulse(0.25, 100)
      await Delay(600)
      this.gamepad.pulse(0.25, 100)

      return
    }

    if (state.event.type === 'GAME_HOTSPOT_ADVISE' || (state.matches('tuto.companion.contact.sent') && state.event.type === 'GAME_ACTION_DONE')) {
      this.gamepad.pulse(0.5, 500)
      return
    }

    if (state.event.type === 'GAME_HOTSPOT_ACTION_DONE') {
      this.gamepad.pulse(0.25, 100)
      return
    }

    // TODO : vibrate when hover door
    // this.gamepad.pulse(0.75, 100)
  }

  reset() {
    return
  }
}
