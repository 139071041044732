var fn = function( obj ){
var __t,__p='';
__p+='precision lowp float;\n\nuniform vec4 uColor;\nuniform float uOpacity;\nuniform float uBig;\n\nvarying vec2 vTexCoord;\n\nvoid main(void){\n  float dist = distance(vec2(0.5, 0.5), vTexCoord);\n\n  float startCircle = mix(0.2, 0.15, uBig);\n  float endCircle = mix(0.65, 0.25, uBig);\n  float circleStep = smoothstep(startCircle, endCircle, dist);\n\n  gl_FragColor = vec4(uColor.rgb, circleStep * uColor.a * uOpacity);\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
