import AppService from '@/services/AppService';
import Controller from '@webgl/activities/common/controllers/Controller'
import XRActivity from '../XRActivity';
import { GameState } from '@/services/states/GameStateMachine';
import { ButtonEvent, XRGamepadButton } from '@webgl/xr/XRGamepad';

const NOOP = () => { return }


export default class InputController extends Controller {
  init = false
  activity: XRActivity
  exitFunctions: (() => void)[] = []
  enterFunctions: (() => void)[] = []
  updateFunctions: (() => void)[] = []
  private _inputCallback: (btnName: XRGamepadButton) => void = NOOP
  private _btnUpCallback: (btnName: XRGamepadButton) => void = NOOP

  get state() {
    return this.activity.gameStateWatcher.currentState
  }

  constructor(activity: XRActivity) {
    super(activity)
  }

  stateChange(state: GameState) {
    this.enterFunctions = []
    this.exitFunctions = []
    this.updateFunctions = []

    this.handleState(state, 'intro', this.enterIntro, this.exitIntro)
    this.handleState(state, 'tuto', this.enterTuto, this.exitTuto)
    this.handleState(state, 'game', this.enterGame, this.exitGame)
    this.handleState(state, 'end.failed.final', this.enterFail, this.exitFail)
    this.handleState(state, 'end.initial', this.enterSuccess, this.exitSuccess)

    this.handleState(state, 'tuto.xr.contact.emoji', this.enterTutoEmoji, this.exitTutoEmoji)
    // this.handleState(state, 'tuto.xr.move_around', this.enterTutoMove, this.exitTutoMove)
    this.handleState(state, 'tuto.xr.exit_room', this.enterTutoEnd, this.exitTutoEnd)
    this.handleState(state, 'tuto.xr.door', this.enterTutoDoor, () => { return }, this.updateTutoDoor)
    this.handleState(state, 'tuto.xr.crouch', this.enterTutoCrouch, this.exitTutoCrouch)
    this.handleState(state, 'game.corridor', this.enterTutoCrouch, this.exitTutoCrouch)

    this.exitFunctions.forEach(func => func ? func() : null)
    this.enterFunctions.forEach(func => func ? func() : null)
    this.updateFunctions.forEach(func => func ? func() : null)

    this.init = true
  }

  start() {
    super.start()
    this.activity.renderer.xrview.inputs.onBtnDown.on(this.btnDown)
    this.activity.renderer.xrview.inputs.onBtnUp.on(this.btnUp)
/////////////////
//////////////////////////////////////////////////////////////////////
//////////////
  }

  stop() {
    super.stop()
    this._inputCallback = NOOP;
    this.activity.renderer.xrview.inputs.onBtnDown.off(this.btnDown)
    this.activity.renderer.xrview.inputs.onBtnUp.off(this.btnUp)
/////////////////
/////////////////////////////////////////////////////////////////////////
//////////////
  }


///////////////
/////////////////////////////////////////////////
/////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
////////
//////
////////////


  btnDown = (evt: ButtonEvent) => {

    
    this._inputCallback(evt.name)
  }

  btnUp = (evt: ButtonEvent) => {
    this._btnUpCallback?.(evt.name)
  }

  handleState(state: GameState, name: string, enter?: () => void, exit?: () => void, update?: () => void) {
    if (state.matches(name) && (!state.history?.matches(name) || !this.init)) {
      this.enterFunctions.push(enter)
    } else if (!state.matches(name) && state.history?.matches(name)) {
      this.exitFunctions.push(exit)
    } else if (state.matches(name) && state.history?.matches(name)) {
      this.updateFunctions.push(update)
    }
  }

  // INTRO

  enterIntro = () => {
    this._inputCallback = this.btnDownIntro;
    this.activity.gamepads.buttons.updateHighlight('B', true)
  }

  exitIntro = () => {
    this._inputCallback = NOOP;
    this.activity.gamepads.buttons.updateHighlight('B', false)
  }

  btnDownIntro = (evt: XRGamepadButton) => {
    if (evt === 'B' || evt === 'Right/Trigger' || evt === 'Left/Trigger') {
      AppService.state.send('GAME_INTRO_NEXT_STEP')
    }
  }

  // TUTO

  enterTuto = () => {
    this._inputCallback = this.btnDownTuto;
    this._btnUpCallback = this.btnUpTuto;

  }

  exitTuto = () => {
    this._inputCallback = NOOP;
    this._btnUpCallback = NOOP;
    this.activity.gamepads.buttons.updateHighlight('Y', false)
  }

  btnUpTuto = (evt: XRGamepadButton) => {
    if( evt === 'X'){
      this.unCrouch()
    }
  }

  btnDownTuto = (evt: XRGamepadButton) => {
    
    const network = AppService.state.children.get('nw').getSnapshot()
    if( network.context.actorCount !== 2 ){
      if( evt === 'A'){
        AppService.state.send('GAME_BACK_HOME')
      }
      return
    }

    if (this.activity.gameStateWatcher.currentState.context.isPaused) {
      switch (evt) {
        case 'A':
        case 'Right/Trigger':
          this.handleBackHome()
          break
        case 'B':
        case 'Y':
          this.handlePause()
          break
        default:
          break;
      }
      return
    }

    switch (evt) {
      case 'X':
        this.handleCrouch()
        break;
      case 'A':
      case 'Right/Trigger':
        this.handleHotspotOrDoors()
        break
      case 'B':
        this.handleEmoji()
        break
      case 'Y':
        this.handlePause()
        break
      default:
        break;
    }
  }

  // TUTO EMOJI

  enterTutoEmoji = () => {
    this.activity.gamepads.buttons.updateHighlight('B', true)
  }

  exitTutoEmoji = () => {
    this.activity.gamepads.buttons.updateHighlight('B', false)
  }

  // TUTO MOVE

  // enterTutoMove = () => {
  //   this.activity.gamepads.buttons.updateHighlight('Left/Thumbstick', true)
  //   this.activity.gamepads.buttons.updateHighlight('Right/Thumbstick', true)
  // }

  // exitTutoMove = () => {
  //   this.activity.gamepads.buttons.updateHighlight('Left/Thumbstick', false)
  //   this.activity.gamepads.buttons.updateHighlight('Right/Thumbstick', false)
  // }

  // TUTO DOOR

  enterTutoDoor = () => {
    this.activity.gamepads.buttons.updateHighlight('Y', true)
  }

  updateTutoDoor = () => {
    if (this.activity.gameStateWatcher.currentState.context.isPaused) {
      this.activity.gamepads.buttons.updateHighlight('Y', false)
      return
    }

    this.activity.gamepads.buttons.updateHighlight('Y', true)
  }

  // TUTO CROUCH

  enterTutoCrouch = () => {
    this.activity.gamepads.buttons.updateHighlight('X', true)
  }

  exitTutoCrouch = () => {
    this.activity.gamepads.buttons.updateHighlight('X', false)
  }

  // TUTO END

  enterTutoEnd = () => {
    this.activity.gamepads.buttons.updateHighlight('Left/Thumbstick', true)
    this.activity.gamepads.buttons.updateHighlight('Right/Thumbstick', true)
  }

  exitTutoEnd = () => {
    this.activity.gamepads.buttons.updateHighlight('Left/Thumbstick', false)
    this.activity.gamepads.buttons.updateHighlight('Right/Thumbstick', false)
  }

  // GAME

  enterGame = () => {
    this._inputCallback = this.btnDownGame;
    this._btnUpCallback = this.btnUpGame;
  }

  exitGame = () => {
    this._inputCallback = NOOP;
    this._btnUpCallback = NOOP;
  }


  btnUpGame = (evt: XRGamepadButton) => {
    if( evt === 'X'){
      this.unCrouch()
    }
  }


  btnDownGame = (evt: XRGamepadButton) => {

        
    const network = AppService.state.children.get('nw').getSnapshot()
    if( network.context.actorCount !== 2 ){
      if( evt === 'A'){
        AppService.state.send('GAME_BACK_HOME')
      }
      return
    }

    
    if (this.activity.gameStateWatcher.currentState.context.isPaused) {
      switch (evt) {
        case 'A':
        case 'Right/Trigger':
          this.handleBackHome()
          break
        case 'B':
        case 'Y':
          this.handlePause()
          break
        default:
          break;
      }
      return
    }

    switch (evt) {
      case 'X':
        this.handleCrouch()
        break;
      case 'A':
      case 'Right/Trigger':
        this.handleHotspotOrDoors()
        break
      case 'B':
        this.handleEmoji()
        break
      case 'Y':
        this.handlePause()
        break
      default:
        break;
    }
  }

  // FAIL

  enterFail = () => {
    this._inputCallback = this.btnDownFail;
  }

  exitFail = () => {
    this._inputCallback = NOOP;
  }

  btnDownFail = (evt: XRGamepadButton) => {
    switch (evt) {
      case 'A':
      case 'Right/Trigger':
        this.handleBackHome()
        break
      case 'B':
        this.handleRestart()
        break
      default:
        break;
    }
  }

  // SUCCESS

  enterSuccess = () => {
    this._inputCallback = this.btnDownSuccess;
  }

  exitSuccess = () => {
    this._inputCallback = NOOP;
  }

  btnDownSuccess = (evt: XRGamepadButton) => {
    switch (evt) {
      case 'A':
      case 'Right/Trigger':
        this.handleSuccess()
        break
      default:
        break;
    }
  }

  // HANDLERS

  handleEmoji() {
    AppService.state.send('GAME_SEND_EMOJI')
  }

  handleCrouch() {
    AppService.state.send({ type: 'GAME_CROUCH_PLAYER', payload: true })
  }
  unCrouch() {
    AppService.state.send({ type: 'GAME_CROUCH_PLAYER', payload: false })
  }

  handleHotspotOrDoors() {
    this.activity.hotspots.handleClick()
  }

  handlePause() {
    AppService.state.send('GAME_TOGGLE_PAUSE')
  }

  handleBackHome() {
    AppService.state.send('GAME_BACK_HOME')
  }

  handleRestart() {
    AppService.state.send('GAME_RESTART')
  }

  handleSuccess() {
    AppService.state.send('GAME_ACTION_DONE')
    AppService.state.send('EXIT_SESSION')
  }

  reset() {
    return
  }
}
