/*
 * register components globally
 */

import { App } from "vue";
import UIIcon from "@/components/UIIcon/UIIcon.vue";
import UIBtn from "@/components/UIBtn/UIBtn.vue";
import UIBtnIcon from "@/components/UIBtnIcon/UIBtnIcon.vue";
import AnimateSprite from "@/components/AnimateSprite/AnimateSprite.vue";

const register = (app: App<Element>): void => {
  app.component("ui-icon", UIIcon);
  app.component("ui-btn", UIBtn);
  app.component("ui-btn-icon", UIBtnIcon);
  app.component("animate-sprite", AnimateSprite);
};

export default { register };
