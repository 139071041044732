import Photon from "@/services/photon/Photon-Javascript_SDK";
import { DefaultPhotonConfig } from "./PhotonConfig";
import AppService, { AppServiceClass } from "../AppService";
import { AnyEventObject, InvokeCallback, Receiver, Sender, SCXML } from "xstate";
import { NetworkClientStateEvent, NetworkEvent } from "../states/NetworkEvents";
import { EventCachePolicy, GameEvent, GameEventCode, getGameEventCachePolicy, getGameEventCode, getGameEventType } from "../states/GameEvents";
import UrlParams from "@webgl/core/UrlParams";

const GameLobbyName = "MAIN_LOBBY"
/**
 * AppWide Room creation options, based on .env
 */
const DefaultRoomCreationOptions: Photon.LoadBalancing.CreateIfNotExistsRoomOptions = {
  maxPlayers: parseInt(process.env.VUE_APP_PHOTON_MAX_PLAYER),
  emptyRoomLiveTime: parseInt(process.env.VUE_APP_PHOTON_EMPTY_ROOM_LIVETIME),
  suspendedPlayerLiveTime: parseInt(process.env.VUE_APP_PHOTON_PLAYER_LIVETIME),
  // propsListedInLobby: ['C0'],
  // customGameProperties: {C0:1},
  // lobbyType: Photon.LoadBalancing.Constants.LobbyType.SqlLobby,
  lobbyName: GameLobbyName,
  isVisible: true,
  isOpen: true,

}


/**
 * validate env config
 */
console.assert(!isNaN(DefaultRoomCreationOptions.maxPlayers), " VUE_APP_PHOTON_MAX_PLAYER must be a number");
console.assert(!isNaN(DefaultRoomCreationOptions.emptyRoomLiveTime), " VUE_APP_PHOTON_EMPTY_ROOM_LIVETIME must be a number");
console.assert(!isNaN(DefaultRoomCreationOptions.suspendedPlayerLiveTime), " VUE_APP_PHOTON_PLAYER_LIVETIME must be a number");
console.assert(process.env.VUE_APP_PHOTON_SERVER_REGION, " VUE_APP_PHOTON_SERVER_REGION must be set");


export type PhotonClientState = keyof typeof Photon.LoadBalancing.LoadBalancingClient.State


function getNetworkStateEventFromClientState(state: PhotonClientState): NetworkClientStateEvent {
  switch (state) {
    case "Error": return { type: "NETWORK_CLIENT_ERROR" }
    case "Uninitialized": return { type: "NETWORK_CLIENT_UNINITIALIZED" }
    case "ConnectingToNameServer": return { type: "NETWORK_CLIENT_CONNECTINGTONAMESERVER" }
    case "ConnectedToNameServer": return { type: "NETWORK_CLIENT_CONNECTEDTONAMESERVER" }
    case "ConnectingToMasterserver": return { type: "NETWORK_CLIENT_CONNECTINGTOMASTERSERVER" }
    case "ConnectedToMaster": return { type: "NETWORK_CLIENT_CONNECTEDTOMASTER" }
    case "JoinedLobby": return { type: "NETWORK_CLIENT_JOINEDLOBBY" }
    case "ConnectingToGameserver": return { type: "NETWORK_CLIENT_CONNECTINGTOGAMESERVER" }
    case "ConnectedToGameserver": return { type: "NETWORK_CLIENT_CONNECTEDTOGAMESERVER" }
    case "Joined": return { type: "NETWORK_CLIENT_JOINED" }
    case "Disconnected": return { type: "NETWORK_CLIENT_DISCONNECTED" }
  }
}


function eventIsSCXML (event: GameEvent | SCXML.Event<GameEvent>): event is SCXML.Event<GameEvent> {
  const t = event.type
  return t === "external" || t === "internal" || t === "platform"
}



export default class NetworkServices extends Photon.LoadBalancing.LoadBalancingClient {



  // ========================================
  // XState callback service API
  // ========================================

  private sendFunction: Sender<NetworkEvent>;

  getConnectionService(): InvokeCallback<AnyEventObject, AnyEventObject> {
    const _InvokeCallbackActor: InvokeCallback<NetworkEvent, NetworkEvent> = (send: Sender<NetworkEvent>, onReceiveEvent: Receiver<NetworkEvent>) => {
      this.sendFunction = send
      onReceiveEvent(this.onReceiveNetworkEvent);
      return () => {
        this.sendFunction = null
        this.disconnect()
      }
    }
    return _InvokeCallbackActor as InvokeCallback<AnyEventObject, AnyEventObject>
  }




  /**
   * handle network state machine incoming events(forwarded from the state machine)
   * @param event
   */
  private onReceiveNetworkEvent = (event: NetworkEvent) => {
    this.logger.debug('NetworkingService onReceiveEvent', event);

    if (event.type === 'NETWORK_CONNECT') {
      this.connectToRegionMaster(process.env.VUE_APP_PHOTON_SERVER_REGION);
    }

    if (event.type === 'NETWORK_DISCONNECT') {
      this.disconnect()
    }

    if (event.type === 'NETWORK_JOIN_ROOM') {
      const room = (UrlParams.get('room') || event.roomid).toUpperCase()
      
      this.joinRoom(room, {
        rejoin: false,
        createIfNotExists: event.isMain,
      }, {
        ...DefaultRoomCreationOptions,
      })
    }

  }


  onActorLeave(actor: Photon.LoadBalancing.Actor, cleanup: boolean): void {
/////////////////
//////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/////////
//////////////
    this.sendFunction({type:"NETWORK_ACTOR_COUNT", count:this.myRoomActorCount()} )
  }
  
  onActorJoin(actor: Photon.LoadBalancing.Actor): void {
/////////////////
//////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/////////
//////////////
    this.sendFunction({type:"NETWORK_ACTOR_COUNT", count:this.myRoomActorCount()} )
  }


  constructor(readonly service: AppServiceClass, config = DefaultPhotonConfig) {
    super(config.protocol, config.appId, config.appVersion)
    this.setLogLevel(config.logLevel);
  }







  // ---------------------------------------------------
  // Overriden methods from LoadBalancingClient class
  // ---------------------------------------------------

  onStateChange(state: number) {
    this.logger.debug("GameNetworking onStateChange", Photon.LoadBalancing.LoadBalancingClient.StateToName(state));

    const stateName = Photon.LoadBalancing.LoadBalancingClient.StateToName(state) as PhotonClientState
    const event = getNetworkStateEventFromClientState(stateName)
    this.sendFunction(event)
  }



  onError(errorCode: number, errorMsg: string) {
    // switch (errorCode) {
    //   case Photon.LoadBalancing.LoadBalancingClient.PeerErrorCode.GameConnectClosed:
    //     this.onErrorSignal.dispatch(new PhotonClientDisconnectedError());
    //     break;
    //   default:
    //     this.onErrorSignal.dispatch(new PhotonClientError(errorMsg));
    // }

    console.error("Photon error:", errorCode, ":", errorMsg);
  }

  onOperationResponse(errorCode: number, errorMsg: string, code: number) {

    this.logger.debug("GameNetworking operation response:", errorCode, ":", errorMsg, ":", code);

    if (errorCode === Photon.LoadBalancing.Constants.ErrorCode.JoinFailedPeerAlreadyJoined) {
      this.logger.debug("GameNetworking try to rejoin instead");
      this.sendFunction("NETWORK_ALREADY_JOINED_ERROR")
      // this.joinOrCreateRoom(this._currentJoinRoomName, true)
      return;
    }

    if (errorCode === Photon.LoadBalancing.Constants.ErrorCode.GameFull) {
      this.sendFunction('NETWORK_FULLROOM_ERROR');
      return
    }

    if (errorCode === Photon.LoadBalancing.Constants.ErrorCode.GameDoesNotExist) {
      this.sendFunction('NETWORK_UNKNOWN_ROOM_ERROR');
      return
    }


    
    /*

        if (code === Photon.LoadBalancing.Constants.OperationCode.JoinRandomGame) {
          if (errorCode !== Photon.LoadBalancing.Constants.ErrorCode.Ok)
            this._findRandomDefered?.reject(errorMsg)
          else
            this._findRandomDefered?.resolve()
          return
        }






        if (errorCode !== Photon.LoadBalancing.Constants.ErrorCode.Ok) {
          this.service.state.send({ type: 'NETWORK_ERROR', msg: errorMsg });
          this.disconnect()
        }

    */
  }









  private sendGameFunction: Sender<GameEvent>;

  getGameSyncService(): InvokeCallback<AnyEventObject, AnyEventObject> {
    const _InvokeCallbackActor: InvokeCallback<GameEvent, GameEvent> = (send: Sender<GameEvent>, onReceiveEvent: Receiver<GameEvent>) => {
      this.sendGameFunction = send
      onReceiveEvent(this.onReceiveGameEvent);
      return () => this.sendGameFunction = null
    }
    return _InvokeCallbackActor as InvokeCallback<AnyEventObject, AnyEventObject>
  }




  /**
   * handle network state machine incoming events(forwarded from the state machine)
   * @param event
   */
  onReceiveGameEvent = (event: GameEvent | SCXML.Event<GameEvent>) => {
    if( eventIsSCXML(event) ){
      event = event.data
    }

    // If this event is issued from sync network layer, don't send it back to avoid infinit sync loop
    if( event._network === true ){
      return
    }

    this.sendEvent(event)
  }



  /**
   * Send response to a quest invite
   * called by GameStateMachine when in a "busy" state, or by ui dialog
   * @param invite
   * @param status
   */
  sendEvent(event: GameEvent) {
    const code = getGameEventCode(event.type)
    if( code === undefined ) return

    const cachePolicy = getGameEventCachePolicy(code)
    const cache = ( cachePolicy !== EventCachePolicy.NO_CACHE ) ?
      Photon.LoadBalancing.Constants.EventCaching.AddToRoomCache :
      Photon.LoadBalancing.Constants.EventCaching.DoNotCache;

    if (event.type !== 'GAME_SET_COUNTDOWN') {
      console.log("raiseEvent", event.type, cache, code);
    }

    if( cachePolicy === EventCachePolicy.ONCE ){
      this.raiseEvent(code, undefined, {
        cache: Photon.LoadBalancing.Constants.EventCaching.RemoveFromRoomCache
      })
    }

    this.raiseEvent(code, event.payload, {
      cache,
    })

    // if( code === GameEventCode.GAME_RESET){
    //   this.clearEventCache()
    // }

  }




  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEvent(eventCode: GameEventCode, data: any, actorNr: number) {

    const fromSelf = actorNr === this.myActor().actorNr

    if( !fromSelf ){
      const evt: GameEvent = {
        type: getGameEventType(eventCode),
        payload: data,
        _network : true,
      } as GameEvent
      if (getGameEventType(eventCode) !== 'GAME_SET_COUNTDOWN' && getGameEventType(eventCode) !== 'GAME_ROTATE_PLAYER') {
        console.debug( `GameNetworking send event back to app`, evt.type, evt.payload );
      }
      // this.sendGameFunction(evt)
      AppService.state.send(evt)
    }

  }

  clearEventCache(){
    for (let code = 1; code < GameEventCode.COUNT; code++) {
      this.raiseEvent(code, undefined, {
        cache: Photon.LoadBalancing.Constants.EventCaching.RemoveFromRoomCache,
      })
    }
  }


}