import Fbo from "nanogl/fbo";
import { GLContext } from "nanogl/types";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import Rect from "nanogl-primitives-2d/rect";
import Program from "nanogl/program";
import scatterVert from "../glsl/programs/scatter/scatter.vert";
import scatterFrag from "../glsl/programs/scatter/scatter.frag";
import PixelFormats from "nanogl-pf";
import { RenderContext } from "@webgl/core/Renderer";
import { Renderable } from "@webgl/fog/FogVFX";
import DebugDraw from "@webgl/dev/debugDraw/DebugDraw";
import RenderPass from "@webgl/core/RenderPass";
import Room from "@webgl/entities/Room";

const MAIN_SIZE = 1024
const BLUR_SIZE = 256
const KERNEL_SIZE = 32

function generateKernel()
{
    const eps = 0.01;
    const kernel = new Float32Array(KERNEL_SIZE);

    const standardDeviation = KERNEL_SIZE / Math.sqrt(-2.0 * Math.log(eps));
    const variance = standardDeviation * standardDeviation;
    // no need for amplitude since we're renormalizing in the shader
    const expScale = -1.0 / (2.0 * variance);

    for (let i = 0; i < KERNEL_SIZE; ++i) {
        const f = 2*i-KERNEL_SIZE
        kernel[i] = Math.exp( f*f * expScale);
    }

    return kernel;
}

export class FogScattering
{
    private fbo: Fbo;
    private fbo1: Fbo;
    private fbo2: Fbo;
    private rect: Rect;
    private scatterPrg: Program;
    private cfg: LocalConfig;
    private width: number;
    private height: number;
    private renderables: Renderable[] = [];

    constructor(private gl: GLContext, private room:Room )
    {
        this.fbo = new Fbo(gl)

        const pf = PixelFormats.getInstance(gl);
        const configs = [
            pf.RGB16F,
            pf.RGBA16F,
            pf.RGB32F,
            pf.RGBA32F,
            pf.RGB8
        ];
        const cfg = pf.getRenderableFormat(configs);

        this.fbo.bind();
        this.fbo.attachColor(cfg.format, cfg.type, cfg.internal)
        this.fbo.attachDepth()
        this.fbo.resize(MAIN_SIZE, MAIN_SIZE)
        this.fbo.getColorTexture().wrap(gl.CLAMP_TO_EDGE);

        // ping-pong buffer
        this.fbo1 = new Fbo(gl);
        this.fbo1.attachColor(cfg.format, cfg.type, cfg.internal)
        this.fbo1.resize(BLUR_SIZE, BLUR_SIZE);
        this.fbo1.getColorTexture().wrap(gl.CLAMP_TO_EDGE);


        this.fbo2 = new Fbo(gl);
        this.fbo2.attachColor(cfg.format, cfg.type, cfg.internal)
        this.fbo2.resize(BLUR_SIZE, BLUR_SIZE);
        this.fbo2.getColorTexture().wrap(gl.CLAMP_TO_EDGE);

        this.rect = new Rect(gl);

        let defs = '\n';
        defs += 'precision highp float;\n';
        defs += `#define KERNEL_SIZE ${KERNEL_SIZE} \n`;

        this.scatterPrg = new Program(gl, scatterVert(), scatterFrag(), defs);
        this.scatterPrg.bind();
        this.scatterPrg.uKernel(generateKernel())

        this.cfg = GLState.get(gl).config().depthMask(false)
    }
    
    addRenderable(renderable: Renderable)
    {
        this.renderables.push(renderable);
    }
    
    get texture()
    {
        return this.fbo2.getColorTexture();
    }
    
    preRender()
    {
        // empty
        this.width = BLUR_SIZE;
        this.height = BLUR_SIZE/2;//Math.floor(FBO_SIZE / window.innerWidth * window.innerHeight);
        this.fbo.resize(MAIN_SIZE, MAIN_SIZE/2);
        this.fbo1.resize(this.width, this.height);
        this.fbo2.resize(this.width, this.height);
    }

    bind()
    {
        const gl = this.gl
        this.fbo.bind()
        GLState.get(gl).apply()// ensure depthMask true
        this.fbo.defaultViewport()
        gl.clearColor(0, 0, 0, 1);
        gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT)
    }
    
    render(ctx: RenderContext, eye: string)
    {
        DebugDraw.drawTexture('bloom1', this.fbo1.getColorTexture())
        DebugDraw.drawTexture('bloom2', this.fbo.getColorTexture())

        const gl = this.gl
        const fbo = this.fbo
        const w = fbo.width >> 1;
        if (eye === "left") {
            gl.viewport(0, 0, w, fbo.height);
        }
        else if (eye === "right")
        {
            gl.viewport(w, 0, w, fbo.height);
        }
        const nctx = {...ctx}
        nctx.pass = RenderPass.BLOOM
        // this.renderables.forEach(obj => obj.render(ctx));
        this.room.render(nctx);
    }

    finalize()
    {

        this.fbo.getColorTexture().bind()
        this.fbo.getColorTexture().setFilter(true, true, false)
        this.gl.generateMipmap(this.gl.TEXTURE_2D)

        this.scatterPrg.bind()
        this.rect.attribPointer(this.scatterPrg)
        this.cfg.apply()

        // if we render multiple blurs, only the very first pass needs to be thresholded
        this.scatterPrg.uThreshold(.7);
        this.renderBlur(this.fbo, this.fbo1, 1, 0)
        this.scatterPrg.uThreshold(0.0);
        this.renderBlur(this.fbo1, this.fbo2, 0, 1)
    }

    renderBlur(src: Fbo, target: Fbo, dirX: number, dirY: number)
    {
        const gl = this.gl

        target.bind()
        target.defaultViewport()
        this.scatterPrg.uStepSize(dirX / this.width, dirY / this.height);
        // this.scatterPrg.uStepOffset(.250*dirX / this.width, .250*dirY / this.height);
        // const x = AppService.glapp.renderer.pointers.primary.coord.viewport[0]
        // console.log( x )
        // this.scatterPrg.uStepOffset(x*dirX / this.width, x*dirY / this.height);
        gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT)

        this.scatterPrg.tInput(src.getColorTexture());
        this.rect.render();
    }
}
