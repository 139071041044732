import gsap from 'gsap'
import Node from 'nanogl-node';
import Rect from "nanogl-primitives-2d/rect";
import Program from "nanogl/program";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import { mat4 } from 'gl-matrix';
import { GLContext } from "nanogl/types";

import vert from "./interface.vert"
import frag from "./interface.frag"
import Intro from './Intro';
import XRActivity from '@webgl/activities/xr/XRActivity';
import WebglAssets from '@webgl/resources/WebglAssets';
import { LiveProgram } from "@webgl/core/LiveShader";
import { RenderContext } from '@webgl/core/Renderer';
import { TextureResource } from '@webgl/resources/TextureResource';

export class Interface {
  prg: Program
  node: Node
  quad = new Rect(this.gl)
  matrix = mat4.create()
  loaded = false
  texRes: TextureResource
  opacity = 0
  showing = false
  glconfig: LocalConfig

  constructor(private activity: XRActivity, private gl: GLContext, texturePath: string) {
    this.prg = LiveProgram(gl, vert, frag)
    this.glconfig = GLState.get(gl).config()
      .depthMask(false)
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    this.texRes = WebglAssets.getTexture(texturePath, this.gl, {
      wrap: "clamp",
      smooth: true,
      alpha: true
    })
  }

  async load() {
    await this.texRes.load()
    this.onLoaded()
  }

  onLoaded() {
    this.setupNode()
    this.loaded = true
  }

  setupNode() {
    this.node = new Node()

    this.node.position.set([0, 0, -1.5])

    const width = 0.8
    const ratio = this.texRes.texture.height / this.texRes.texture.width * width
    this.node.scale.set([width, ratio, 1])
  }

  init() {
    this.activity.renderer.xrview.headset.camera.add(this.node)
  }

  show() {
    this.showing = true

    this.node.updateWorldMatrix()
    mat4.copy(this.matrix, this.node._wmatrix)
    gsap.killTweensOf(this)
    return gsap.to(this, {
      opacity: 1,
      duration: 0.5,
      ease: "power2.out"
    })
  }
  
  hide( delay=0 ) {
    gsap.killTweensOf(this)
    gsap.to(this, {
      opacity: 0,
      duration: 1,
      delay,
      ease: "power2.out",
      onComplete: () => {
        this.showing = false
      }
    })
  }

  hideNow() {
    gsap.killTweensOf(this)
    this.opacity = 0
    this.showing = false
  }

  render(context: RenderContext){
    if (!this.loaded || !this.showing) return

    this.prg.use()
    this.glconfig.apply()
    this.quad.attribPointer(this.prg)

    this.prg.uMVP(context.camera.getMVP(this.matrix))
    this.prg.uOpacity(this.opacity)
    this.prg.tTex(this.texRes.texture)

    this.quad.render()
  }
}

class Bonus extends Interface {

  show() {
    const tween = super.show()
    tween.then( ()=>{
      this.hide(1)
    })
    return tween
  }

}

export default class Interfaces {
  fail: Interface
  intro: Intro
  pause: Interface
  success: Interface
  disconnected: Interface
  bonus: Bonus
  malus: Bonus

  constructor(private activity: XRActivity, private gl: GLContext) {
    this.intro = new Intro(gl)
    this.pause = new Interface(activity, gl, 'xr/interfaces/pause.png')
    this.fail = new Interface(activity, gl, 'xr/interfaces/fail.png')
    this.success = new Interface(activity, gl, 'xr/interfaces/success.png')
    this.disconnected = new Interface(activity, gl, 'xr/interfaces/disconnected.png')
    this.bonus = new Bonus(activity, gl, 'xr/interfaces/bonus.png')
    this.malus = new Bonus(activity, gl, 'xr/interfaces/malus.png')
  }

  load() {
    return Promise.all([
      this.intro.load(),
      this.pause.load(),
      this.fail.load(),
      this.success.load(),
      this.disconnected.load(),
      this.bonus.load(),
      this.malus.load(),
    ])
  }

  init() {
    this.pause.init()
    this.fail.init()
    this.success.init()
    this.disconnected.init()
    this.bonus.init()
    this.malus.init()
  }

  render(context: RenderContext) {
    this.intro.render(context)
    this.pause.render(context)
    this.fail.render(context)
    this.success.render(context)
    this.disconnected.render(context)
    this.bonus.render(context)
    this.malus.render(context)
  }

  reset() {
    this.intro.hideNow()
    this.pause.hideNow()
    this.fail.hideNow()
    this.success.hideNow()
    this.disconnected.hideNow()
    this.bonus.hideNow()
    this.malus.hideNow()
  }
}