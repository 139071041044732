import Rect from "nanogl-primitives-2d/rect";
import { GLContext } from "nanogl/types";
import Fbo from "nanogl/fbo";
import PixelFormats from "nanogl-pf";
import Time from "@webgl/Time";
import { InstancingImpl } from "@webgl/core/Instancing";
import Program from "nanogl/program";
import ArrayBuffer from "nanogl/arraybuffer";
import IndexBuffer from "nanogl/indexbuffer";
import { createWith, removeElementOutOfOrder } from "@derschmale/array-utils";
import Capabilities from "@webgl/core/Capabilities";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import { RenderContext } from "@webgl/core/Renderer";
import emitVert from "./emit.vert";
import emitFrag from "./emit.frag";
import renderVert from "./render.vert";
import renderFrag from "./render.frag";
import advectVert from "./advect.vert";
import advectFrag from "./advect.frag";
import WebglAssets from "@webgl/resources/WebglAssets";
import { TextureResource } from "@webgl/resources/TextureResource";
import { mat4 } from "gl-matrix";
import FogProps from "@webgl/fog/FogProps";

const MAX_PARTICLES = 512 * 16;
const MAX_EMIT = 32;
const mvp = mat4.create();

function createFbo(gl: GLContext, w: number, h: number)
{
    const pf = PixelFormats.getInstance(gl);
    const configs = [
        pf.RGBA16F,
        pf.RGBA32F,
        pf.RGBA8
    ];
    const cfg = pf.getRenderableFormat(configs);
    const fbo = new Fbo(gl)
    fbo.attachColor(cfg.format, cfg.type, cfg.internal)
    fbo.getColorTexture().setFilter(false, false, false);
    fbo.resize(w, h);
    fbo.bind();
    gl.clearColor(0, 0, 0, 1);
    gl.clear(gl.COLOR_BUFFER_BIT);
    return fbo;
}

type Emitter = {
    id: number,
    pos: number[],
    rate: number
    radius: number,
    count: number
}

export default class Embers
{
    color = [ 1.0, 0.8, 0.5 ]
    glowColor = [ 1.0, 0.0, 0.0 ]
    brightness = 2.0
    age = 20.0

    // used to draw a particle
    private fboPrev: Fbo
    private fboCurr: Fbo
    private count = 0
    private globalEmitIndex = 0
    private currentEmitCount = 0
    private emitters: Emitter[] = [];
    private instancing: InstancingImpl;

    private emitBuffer: ArrayBuffer;
    private emitIndexBuffer: IndexBuffer;
    private emitData: Float32Array = new Float32Array(MAX_EMIT * 5);
    private emitPrg: Program;
    private emitCfg: LocalConfig;

    private renderCfg: LocalConfig;
    private renderPrg: Program;
    private renderUvBuffer: ArrayBuffer;
    private renderPosBuffer: ArrayBuffer;
    private size = 0.005;
    // private size = 0.1;

    private advectRect: Rect;
    private advectCfg: LocalConfig;
    private advectPrg: Program;
    private perlinTex: TextureResource;
    private time = 0;
    private prevViewMatrix: { [id: string]: mat4 } = {};
    private idCounter = 0;

    constructor(private fogProps: FogProps, private gl: GLContext)
    {
        const w = Math.floor(Math.min(MAX_PARTICLES, 512));
        const h = Math.ceil(MAX_PARTICLES / w);
        this.fboPrev = createFbo(gl, w, h);
        this.fboCurr = createFbo(gl, w, h);
        this.instancing = Capabilities(gl).instancing
        this.initEmit();
        this.initAdvect();
        this.initRender();
    }

    load()
    {
        return this.perlinTex.load();
    }

    addEmitter(x: number, y: number, z: number, rate = 0.25, radius = 3): number
    {
        this.emitters.push({
            id: ++this.idCounter,
            pos: [x, y, z],
            count: 0,
            rate, radius,
        });

        return this.idCounter;
    }

    removeEmitter(id: number)
    {
        const emitter = this.emitters.find(obj => obj.id === id);
        if (emitter)
            removeElementOutOfOrder(this.emitters, emitter);
    }

    preRender()
    {
        const tmp = this.fboPrev;
        this.fboPrev = this.fboCurr;
        this.fboCurr = tmp;

        this.queueEmittors();

        if (this.currentEmitCount > 0)
            this.renderEmit();

        this.updatePositions();
    }

    // eyeID is to keep track of unprojection matrices
    render(context: RenderContext, eyeID = 'none')
    {
        const camera = context.camera;
        const proj = camera.lens.getProjection();

        if (!this.prevViewMatrix[eyeID]) {
            this.prevViewMatrix[eyeID] = mat4.create();
            mat4.copy(this.prevViewMatrix[eyeID], camera._view);
        }

        this.renderCfg.apply();
        this.renderPrg.use();
        this.renderPrg.uSize(this.size);

        if (this.renderPrg.tPrev)
            this.renderPrg.tPrev(this.fboPrev.getColorTexture());
        this.renderPrg.tCurr(this.fboCurr.getColorTexture());
        this.renderPrg.uColor(this.color[0] * this.brightness, this.color[1] * this.brightness, this.color[2] * this.brightness);
        this.renderPrg.uGlowColor(this.glowColor[0] * this.brightness, this.glowColor[1] * this.brightness, this.glowColor[2] * this.brightness);
        this.renderPrg.uModelViewMatrix(camera._view);
        this.renderPrg.uProjectionMatrix(proj);
        if (this.renderPrg.uPrevViewMatrix)
            this.renderPrg.uPrevViewMatrix(this.prevViewMatrix[eyeID]);
        this.renderPosBuffer.attribPointer(this.renderPrg);
        this.renderUvBuffer.attribPointer(this.renderPrg);
        this.instancing.vertexAttribDivisor(this.renderPrg.aUV(), 1)
        this.instancing.drawArraysInstanced(this.gl.TRIANGLE_STRIP, 0, this.renderPosBuffer.length, this.count);
        this.instancing.vertexAttribDivisor(this.renderPrg.aUV(), 0);
        this.fogProps.applyToProgram(this.renderPrg, camera);

        mat4.copy(this.prevViewMatrix[eyeID], camera._view);
    }

    private queueEmit(x: number, y: number, z: number)
    {
        if (this.currentEmitCount === MAX_EMIT) return;

        this.count = Math.min(this.count + 1, MAX_PARTICLES);

        const ci = this.currentEmitCount * 5;
        this.emitData[ci] = x;
        this.emitData[ci + 1] = y;
        this.emitData[ci + 2] = z;

        const v = Math.floor(this.globalEmitIndex / this.fboCurr.width);
        const u = this.globalEmitIndex - v * this.fboCurr.width;
        this.emitData[ci + 3] = (u + 0.5) / this.fboCurr.width * 2 - 1;
        this.emitData[ci + 4] = (v + 0.5) / this.fboCurr.height * 2 - 1;

        ++this.currentEmitCount;
        if (++this.globalEmitIndex === MAX_PARTICLES)
            this.globalEmitIndex = 0;
    }

    private renderEmit()
    {
        const gl = this.gl;
        this.emitBuffer.subData(this.emitData, 0);

        this.fboPrev.bind();
        this.fboPrev.defaultViewport();

        this.emitCfg.apply();
        this.emitPrg.use();

        this.emitBuffer.attribPointer(this.emitPrg);

        // this.instancing.drawElementsInstanced(gl.POINTS, 1, gl.UNSIGNED_SHORT, 0, this.currentEmitCount)
        this.emitIndexBuffer.bind();
        this.emitIndexBuffer.draw(gl.POINTS, this.currentEmitCount, 0);
    }

    private initEmit()
    {
        const gl = this.gl;
        const defs = `#define MAX_EMIT ${MAX_EMIT} \n`;

        this.emitCfg = GLState.get(gl).config().depthMask(false)

        this.emitPrg = new Program(gl, emitVert(), emitFrag(), defs);
        this.emitBuffer = new ArrayBuffer(gl, this.emitData, gl.DYNAMIC_DRAW);
        this.emitBuffer.attrib("aPosition", 3, gl.FLOAT);
        this.emitBuffer.attrib("aOffset", 2, gl.FLOAT);

        const indices = new Uint16Array(createWith(MAX_EMIT, i => i));
        this.emitIndexBuffer = new IndexBuffer(gl, gl.UNSIGNED_SHORT, indices);
    }

    private initAdvect()
    {
        const gl = this.gl;
        this.advectRect = new Rect(gl);
        this.advectCfg = GLState.get(gl).config().depthMask(false)
        this.advectPrg = new Program(gl, advectVert(), advectFrag());
        this.perlinTex = WebglAssets.getTexture("embers/perlin.jpg", this.gl)
        this.perlinTex.repeat()
        this.perlinTex.setFilter(true, false, false)
    }

    private initRender()
    {
        const gl = this.gl;

        this.renderCfg = GLState.get(gl).config()
            .enableDepthTest(true)
            .depthMask(false)
            .enableBlend()
            .blendFunc(gl.SRC_ALPHA, gl.ONE)
            .enableCullface(false);
        this.renderPrg = new Program(gl, renderVert(), renderFrag());
        this.renderPrg.use()

        const w = this.fboCurr.width;
        const h = this.fboCurr.height;
        const uvData = new Float32Array(w * h * 3);
        let i = 0;
        for (let y = 0; y < h; ++y) {
            const v = y / h;
            for (let x = 0; x < w; ++x) {
                const u = x / w;
                uvData[i++] = u;
                uvData[i++] = v;
                // random sizing
                uvData[i++] = Math.random() * .25 + .75;
            }
        }

        this.renderUvBuffer = new ArrayBuffer(gl, uvData, gl.STATIC_DRAW);
        this.renderUvBuffer.attrib("aUV", 3, gl.FLOAT);

        this.renderPosBuffer = new ArrayBuffer(gl, new Float32Array([
            -0.5, -0.5,
            -0.5, 0.5,
            0.5, -0.5,
            0.5, 0.5,
        ]));
        this.renderPosBuffer.attrib("aPosition", 2, gl.FLOAT);
    }

    private queueEmittors()
    {
        this.currentEmitCount = 0;
        this.emitters.forEach(emitter => {
            const count = Math.floor(emitter.count);
            emitter.count += emitter.rate * Time.dt;
            const tgtCount = Math.floor(emitter.count);
            for (let i = count; i < tgtCount; ++i) {
                // gaussian distribution
                const r = randn_bm() / Math.PI * emitter.radius;
                const a = Math.random() * 2 * Math.PI;
                const x = Math.cos(a) * r;
                const z = Math.sin(a) * r;
                const y = Math.random();
                this.queueEmit(emitter.pos[0] + x, emitter.pos[1] + y, emitter.pos[2] + z);
            }
        });
    }

    private updatePositions()
    {
        const gl = this.gl;

        this.fboCurr.bind();
        this.fboCurr.defaultViewport();
        gl.clearColor(0, 0, 0, 1);
        gl.clear(gl.COLOR_BUFFER_BIT);

        this.advectCfg.apply();
        this.advectPrg.use();
        this.advectPrg.tSource(this.fboPrev.getColorTexture());
        this.advectPrg.tPerlin(this.perlinTex.texture);
        this.advectPrg.uBuoyancy(0.05, 0.2);
        this.advectPrg.uVelocity(0.1);
        if (Time.time > 0)
            this.time = Time.time;
        else
            this.time += Time.dt;
        this.advectPrg.uNoiseOffset(this.time * .001, this.time * .0013);
        this.advectPrg.uDt(Time.dt);
        this.advectPrg.uAging(Time.dt / this.age);
        this.advectRect.attribPointer(this.advectPrg);
        this.advectRect.render();
    }

    reset() {
        this.fboCurr.bind()
        this.fboCurr.defaultViewport()
        this.gl.clearColor(0, 0, 0, 1)
        this.gl.clear(this.gl.COLOR_BUFFER_BIT)

        this.fboPrev.bind()
        this.fboPrev.defaultViewport()
        this.gl.clearColor(0, 0, 0, 1)
        this.gl.clear(this.gl.COLOR_BUFFER_BIT)
    }
}


// Standard Normal variate using Box-Muller transform.
// https://stackoverflow.com/questions/25582882/javascript-math-random-normal-distribution-gaussian-bell-curve
function randn_bm() {
    let u = 0, v = 0;
    while(u === 0) u = Math.random(); //Converting [0,1) to (0,1)
    while(v === 0) v = Math.random();
    return Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
}
