/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActorRefFrom, assign, createMachine, interpret, Interpreter, spawn, State, TransitionConfigOrTarget } from "xstate";
import { forwardTo, send } from "xstate/lib/actions";
import { AppEvent, AppInitEvent } from "./AppEvents";
import { CreateXRSessionStateMachine, XRSessionStateMachine } from "./XRSessionStateMachine";
import { CreateNetworkStateMachine, NetworkStateMachine } from "./NetworkStateMachine";
import AppService from "../AppService";
import UrlParams from "@webgl/core/UrlParams";
import browserDetect from "browser-detect";
import { CreateGameStateMachine, GameStateMachine } from "./GameStateMachine";
import Delay from "@/core/Delay";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import Tracking from "@/core/Tracking";
import FrontResources from "@/core/FrontResources";
import { pagePanelId } from "@/services/stores/page-panels";
import { loadJson } from "@webgl/resources/Net";

// const ROOMID_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('')
const ROOMID_CHARS = 'ABCDEFGHIJKLPRSTUVYZ123456789'.split('')

export enum Role {
  None,
  XR,
  Desktop,
  Companion
}

function CreateContext() {
  return {
    role: Role.None,
    xrActor: null as ActorRefFrom<XRSessionStateMachine>,
    nwActor: null as ActorRefFrom<NetworkStateMachine>,
    gameActor: null as ActorRefFrom<GameStateMachine>,
    noMulti: false,
    glTotalLoad: 0,
    glCurrentLoad: 0,
    frontTotalLoad: 0,
    frontCurrentLoad: 0,
    pagePanelId: null as pagePanelId,
  }
}

export type AppStateContext = ReturnType<typeof CreateContext>;



export type AppStateType = {
  value: string
  context: AppStateContext
}


export type AppState = State<AppStateContext, AppEvent, any, any>

function forward(target: string): TransitionConfigOrTarget<AppStateContext, any> {
  return { actions: forwardTo(target) }
}

function CreateAppStateMachine() {

  return createMachine<AppStateContext, AppEvent, AppStateType>({

    id: "xrapp",

    initial: 'initial',

    context: CreateContext(),

    on: {
      NETWORK_CONNECT: forward('nw'),
      NETWORK_RETRY: forward('nw'),
      NETWORK_DISCONNECT: forward('nw'),
      NETWORK_CLIENT_JOIN_ROOM: forward('nw'),
      END_SESSION: forward('xr'),
      BACK_HOME: {
        target: 'landing',
        actions: [
          'disconnect',
        ]
      },
      SET_PAGE_PANEL: {
        actions: [ assign({ pagePanelId: (_, e) => e.id }) ]
      }
    },

    states: {


      initial: {
        on: {
          INIT: {
            actions: ['assignNoMulti', 'spawnActors'],
            target: 'initializing',
          }
        }
      },


      initializing: {

        initial: 'loadFrontResources',

        onDone: 'landing',

        on: {
          LOAD_GL_TOTAL: {
            actions: assign({ glTotalLoad: (_, e) => e.total })
          },
          LOAD_GL_ELEMENT: {
            actions: assign({ glCurrentLoad: (ctx) => ctx.glCurrentLoad + 1 })
          },
          LOAD_FRONT_TOTAL: {
            actions: assign({ frontTotalLoad: (_, e) => e.total })
          },
          LOAD_FRONT_ELEMENT: {
            actions: assign({ frontCurrentLoad: (ctx) => ctx.frontCurrentLoad + 1 })
          }
        },

        states: {

          loadFrontResources: {
            invoke: {
              src: 'loadFrontResources',
              onDone: {
                target: 'defineRole',
              },
              // onError: {
              //   target: 'defineRole',
              // }
            }
          },

          defineRole: {
            invoke: {
              src: 'defineRole',
              onDone: {
                target: 'loadActivity',
                actions: [
                  'assignRole',
                  'spawnGameService',
                ]
              },
            }
          },

          loadActivity: {
            invoke: {
              src: 'loadActivity',
              // onDone: 'ready'
            },
            on: {
              LOADED: 'ready'
            }
          },

          ready: {
            type: 'final'
          },

        }
      },

      landing: {
        initial: 'initial',

        onDone: {
          target: 'sync'
        },

        entry: Tracking.pageViewHomepage,

        states: {
          initial: {
            on: {
              START: {
                target:'begin', actions: [ () => Tracking.startTheExperience() ]
              },
              ROLE: {
                target: 'reloadActivity',
                actions: [
                  'assignRole',
                  'spawnGameService'
                ]
              }
            }
          },
          
          // Reload activity based on new Role
          reloadActivity: {
            invoke: {
              src: 'loadActivity',
              onDone:  {
                target: 'begin',
                actions: [ () => Tracking.startTheExperience() ]
              }
            },
          },

          begin: {
            type: 'final',
          }
        }
      },

      sync: {
        entry: [
          Tracking.pageViewSync,
        ],
        initial: 'initial',

        onDone: [
          { target: 'vr', cond: 'isXR' },
          { target: 'desktop', cond: 'isDesktop' },
          { target: 'companion', cond: 'isCompanion' }
        ],

        states: {
          initial: {
            always: [
              {
                target: 'completed',
                cond: 'noMulti'
              },
              {
                target: 'connect',
                cond: 'multi'
              }
            ]
          },

          connect: {
            type: 'parallel',
            onDone: {
              target: 'synced',
            },
            on: {
              NETWORK_ROOM_JOINED: [
                {
                  target: '.xr.completed',
                  cond: (_, evt) => evt.payload,
                },
                {
                  target: '.companion.completed',
                  cond: (_, evt) => !evt.payload,
                }
              ],
              NETWORK_FULLROOM: [
                {
                  target: '.xr.fullroom',
                  cond: (_, evt) => evt.payload
                },
                {
                  target: '.companion.fullroom',
                  cond: (_, evt) => !evt.payload
                }
              ]
            },
            states: {
              xr: {
                initial: 'initial',
                on: {
                  RELOAD_SYNC_CODE: {
                    target: '.reload',
                    actions: [ ()=>Tracking.refresh() ]
                  }
                },
                states: {
                  initial: {
                    type: 'final',
                    always: {
                      target: 'connect',
                      cond: 'isXR'
                    },
                  },
                  connect: {
                    type: 'final',
                    entry: 'networkConnect'
                  },
                  reload: {
                    type: 'final',
                    entry: 'networkReloadRoom'
                  },
                  fullroom: {
                    type: 'final',
                    entry: 'networkJoinRoom'
                  },
                  completed: {
                    type: 'final'
                  }
                }
              },
              companion: {
                initial: 'initial',
                on: {
                  NETWORK_ROOM_UNKNOWN: '.unknown'
                },
                states: {
                  initial: {
                    on: {
                      COMPANION_JOIN_ROOM: {
                        target: 'connect',
                        actions: 'networkConnectCompanion'
                      }
                    }
                  },
                  connect: {},
                  unknown: {
                    entry: [
                      () => sound(AUDIO_ID.UI_SYNC_Ko)()
                    ],
                    on: {
                      COMPANION_JOIN_ROOM: {
                        target: 'connect',
                        actions: 'networkJoinRoomCompanion'
                      }
                    }
                  },
                  fullroom: {
                    entry: [
                      () => sound(AUDIO_ID.UI_SYNC_Ko)()
                    ],
                    on: {
                      COMPANION_JOIN_ROOM: {
                        target: 'connect',
                        actions: 'networkJoinRoomCompanion'
                      }
                    }
                  },
                  completed: {
                    type: 'final',
                  }
                }
              }
            }
          },

          synced: {
            // entry: 'actionDelay',
            entry: [
              () => sound(AUDIO_ID.UI_SYNC_Ok)(),
              () => Tracking.syncOk()
            ],
            on: {
              GAME_ACTION_DONE: 'completed',
            }
          },

          completed: {
            type: 'final'
          }

        }
      },



      vr: {
        entry: 'startXR',

        exit: [send('EXIT_SESSION', { to: 'xr' })],

        on: {
          REQUEST_SESSION: forward('xr'),
          EXIT_SESSION   : forward('xr'),
          RETRY          : forward('xr'),
        },

      },

      desktop: {

      },

      companion: {

      },


    },
  },

    {
      actions: {

        spawnActors: assign<AppStateContext, AppEvent>({
          xrActor: () => spawn(CreateXRSessionStateMachine(), { name: 'xr', sync: true }),
          nwActor: () => spawn(CreateNetworkStateMachine  (), { name: 'nw', sync: true }),
        }),

        spawnGameService: assign<AppStateContext, AppEvent>({
          gameActor: (ctx) => {
            const manageCountdown = (ctx.role === Role.XR || ctx.role === Role.Desktop) || ctx.noMulti;
            const s = spawn(CreateGameStateMachine( manageCountdown ), { name: 'game', autoForward: true });
            if (!ctx.noMulti) {
              // hack to force game fsm to auto forward it's events to its sync children
              (s as any).forwardTo.add('syncService')
            }
            return s;
          }
        }),


        assignNoMulti: assign<AppStateContext, AppEvent>({
          noMulti: (_, evt) => (evt as AppInitEvent).noMulti === true
        }),

        assignRole: assign({
          role: (_, event) => (event as { data: Role }).data,
          // noMulti: (ctx, event) => ctx.noMulti || (event as { data: Role }).data === Role.Desktop,
          noMulti: (ctx, event) => {
            return (event as { data: Role }).data === Role.Desktop
          },
        }),

        startXR() {
          return AppService.state.send('REQUEST_SESSION')
        },

        disconnect() {
          return AppService.state.send('NETWORK_DISCONNECT')
        },

        exitSession() {
          return AppService.state.send('EXIT_SESSION')
        },

        networkConnect() {
          const roomid = createRoomId()
          AppService.state.send({ type: 'NETWORK_CONNECT', roomid, isMain: true })
        },

        networkConnectCompanion(_, evt) {
          const roomid = evt.type === 'COMPANION_JOIN_ROOM' ? evt.payload : null
          AppService.state.send({ type: 'NETWORK_CONNECT', roomid, isMain: false })
        },

        networkJoinRoom() {
          const roomid = createRoomId()
          AppService.state.send({ type: 'NETWORK_CLIENT_JOIN_ROOM', roomid })
        },

        networkReloadRoom() {
          const roomid = createRoomId()
          AppService.state.send({ type: 'NETWORK_DISCONNECT' })
          AppService.state.send({ type: 'NETWORK_CONNECT', roomid, isMain: true })
        },

        networkJoinRoomCompanion(_, evt) {
          const roomid = evt.type === 'COMPANION_JOIN_ROOM' ? evt.payload : null
          AppService.state.send({ type: 'NETWORK_CLIENT_JOIN_ROOM', roomid })
        },

        async actionDelay(ctx) {
          if (ctx.role === Role.XR) {
            await Delay(2000)
            AppService.state.send('GAME_ACTION_DONE')
          }
        },

      },

      services: {

        async loadFrontResources() {
          return await FrontResources.load()
        },

        loadActivity(ctx) {
          const id = (ctx.role === Role.XR) ? 'xr' : (ctx.role === Role.Desktop) ? 'desktop' : 'companion'
          return AppService.glapp.loadActivity(id)
        },

        async defineRole(): Promise<Role> {
          if (UrlParams.get('role') === 'desktop') return Role.Desktop;
          if (UrlParams.get('role') === 'companion') return Role.Companion;
          if (UrlParams.get('role') === 'xr') return Role.XR;

          if (browserDetect().mobile) return Role.Companion
          const xrAvailable = await AppService.glapp.xrview.isXRAvailable()
          return (xrAvailable) ? Role.XR : Role.Desktop
        }
      },


      guards: {
        noMulti(ctx) {
          return ctx.noMulti
        },
        multi(ctx) {
          return !ctx.noMulti
        },
        isXR: (ctx) => {
          return ctx.role === Role.XR
        },
        isDesktop: (ctx) => {
          return ctx.role === Role.Desktop
        },
        isCompanion: (ctx) => {
          return ctx.role === Role.Companion
        },
      }


    }

  )
}

function createRoomId() {
  let res = ''
  for (let i = 0; i < 4; i++) {
    res += ROOMID_CHARS[Math.floor(Math.random() * ROOMID_CHARS.length)]
  }
  return res
}


export type AppStateInterpreter = Interpreter<AppStateContext, any, AppEvent, AppStateType>


export function CreateAppStateInterpreter(): AppStateInterpreter {

  const res = interpret(CreateAppStateMachine())

  res.onTransition(state => {
    if (state.event.type === 'GAME_SET_COUNTDOWN' || state.event.type === 'GAME_ROTATE_PLAYER') return
    console.debug(`[App]: `, state.value);
    for (const c in state.children) {
      console.debug(`   [${c}]: `, state.children[c].getSnapshot()?.value);
    }
  })
///////////////
////////////

  return res;
}

function sound(id: AUDIO_ID) {
  return ()=>AudioManager.play(id)
}