import { AUDIO_ID } from "@/core/audio/AudioManager";
import { HotspotAudioId } from "@/services/states/GameContextDatas";

import type { AudioData } from "./Audios";



export const AudioEmitters: AudioData[] = [
  {
    id: AUDIO_ID.MUSIC_LEVEL_3,
    hotspot: HotspotAudioId.Peace,
    volume: 1,
    refDistance: -1,
    rolloffFactor: 2,
  },
  // {
  //   id: AUDIO_ID.INTRO_AMBIENT,
  //   hotspot: HotspotAudioId.Peace,
  //   volume: 1,
  //   refDistance: 0.5,
  //   rolloffFactor: 2,
  // },
  // {
  //   id: AUDIO_ID.FIRE_ALARM,
  //   hotspot: HotspotAudioId.Alarm1,
  //   volume: 0.2,
  //   refDistance: 0.5,
  //   rolloffFactor: 2,
  // },
  // {
  //   id: AUDIO_ID.FIRE_ALARM_2,
  //   hotspot: HotspotAudioId.Alarm2,
  //   volume: 0.2,
  //   refDistance: 0.5,
  //   rolloffFactor: 2,
  // },
  // {
  //   id: AUDIO_ID.FIRE,
  //   hotspot: HotspotAudioId.Fire,
  //   volume: 1.0,
  //   refDistance: 0.8,
  //   rolloffFactor: 1.8,
  // },
  {
    id: AUDIO_ID.FIRE_Position_Low,
    hotspot: HotspotAudioId.Fire,
    volume: 1.0,
    refDistance: 0.8,
    rolloffFactor: 1.8,
  },
  {
    id: AUDIO_ID.FIRE_Low,
    hotspot: HotspotAudioId.Fire,
    volume: 1.0,
    refDistance: -1,
    rolloffFactor: 1.8,
  }
]

type AudioConfigLevel = {
  state: string | string[],
  emitters: AUDIO_ID[]
}


const AudioConfig: AudioConfigLevel[] = [

  // {
  //   state: ['intro', 'tuto'],
  //   emitters: [
  //     AUDIO_ID.INTRO_AMBIENT,
  //   ]
  // },

  // {
  //   state: ['intro', 'tuto', 'game'],
  //   emitters: [
  //     AUDIO_ID.MUSIC_LEVEL_3,
  //   ]
  // },

  {
    state: 'game',
    emitters: [
      AUDIO_ID.FIRE_Position_Low,
      AUDIO_ID.FIRE_Low,
    ]
  },

]

export default AudioConfig;