<template>
  <transition name="fade">
    <div class="panel-dyk-wrapper">
      <div class="panel-dyk">
        <div class="inner">
          <div class="key">
            <span class="key-value">{{ t('end.map.dyk.key_value') }}</span>
            <span class="key-units">{{ t('end.map.dyk.key_units') }}</span>
          </div>
          <div class="explanation">
            <div class="explanation-inner" v-html="t('end.map.dyk.explanation')"/>
            <a class="explanation-source" :href="t('end.map.dyk.link_url')" rel="noreferrer noopener" target="_blank">{{ t('end.map.dyk.link_text') }}</a>
          </div>
        </div>
        <ui-btn-icon class="close" color="dark-red" @click="$emit('close')" name="crossmark" />
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<style lang="stylus" scoped>
.panel-dyk-wrapper
  position relative
  width 100%
  z-index 9

  margin-top: calc(-0.65rem - 0.125rem - 1px)

  +mobile()
    position: absolute;
    width: calc(100% - 3rem);

.panel-dyk
  position absolute
  right 0
  top 100%
  width 26.875rem

  padding 2rem
  padding-left 4rem

  color $beige

  +mobile()
    position relative
    right auto
    top auto
    width 100%
    padding 2rem

  &::before
    content ''
    position absolute
    inset 0
    background-color $dark-red
    clip-path polygon(0 0, 100% 0, 100% 0, 100% calc(100% - 32px), calc(100% - 32px) 100%, 0 100%, 0 100%, 0 0)

  .inner
    position relative

    display: flex
    align-items: flex-end
    gap: 3.125rem

    +mobile()
      gap: 1.5rem

  .key
    display flex
    flex-direction column
    align-items center
    @extend .font-bold
    letter-spacing 0.02em
    text-align center

    span
      display block

  .key-value
    font-size 168px
    line-height: 0.9
    height @font-size * @line-height
    padding-top (@font-size * @line-height) * (1 - @line-height)
    // border 1px solid green
    overflow hidden
  .key-units
    font-size 1rem

  .explanation
    @extend .text-body
    font-size 1rem
    line-height 133%

    .explanation-inner
      margin-bottom 1.25em

    .explanation-source
      color $beige
      font-size: 0.75rem
      font-style: italic

.btn.close
  position absolute
  top 0
  right 0

  +desktop()
    display none
</style>