import Delay from "@/core/Delay";
import AppService from "@/services/AppService"
import Controller from "../../common/controllers/Controller"
import XRActivity from "../XRActivity";
import { GameState } from "@/services/states/GameStateMachine";

export default class TutoController extends Controller {
  canGoDoorNext = false
  hasHandledEmojis = false

  constructor(activity: XRActivity) {
    super(activity)
  }

  async stateChange(state: GameState) {
    if (!state.matches('tuto')) return

    if (state.matches('tuto.xr.contact.emoji') && state.event.type === 'GAME_SEND_EMOJI' && !this.hasHandledEmojis) {
      this.hasHandledEmojis = true
      await Delay(4000)
      AppService.state.send('GAME_ACTION_DONE')
      return
    }

    if (state.matches('tuto.xr.move_around') && state.event.type === 'GAME_MOVE_PLAYER') {
      AppService.state.send('GAME_ACTION_DONE')
      return
    }

    if (state.matches('tuto.xr.crouch') && state.event.type === 'GAME_CROUCH_PLAYER') {
      AppService.state.send('GAME_ACTION_DONE')
      return
    }

    if (state.matches('tuto.xr.door') && !state.history.matches('tuto.xr.door')) {
      this.startDoorCountdown()
    }

    if (state.matches('tuto.xr.door') && state.event.type === 'GAME_DOOR_HOVER' && this.canGoDoorNext) {
      AppService.state.send('GAME_ACTION_DONE')
      return
    }

    if (state.matches('tuto.xr.restart')) {
      AppService.state.send('GAME_ACTION_DONE')
      return
    }
  }

  async startDoorCountdown() {
    await Delay(5000)
    this.canGoDoorNext = true

    if (this.activity.gameStateWatcher.currentState.context.doorHovered !== -1) {
      AppService.state.send('GAME_ACTION_DONE')
    }
  }

  reset() {
    this.canGoDoorNext = false
    this.hasHandledEmojis = false
    return
  }
}
