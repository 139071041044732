import gsap from "gsap";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import { GLContext } from "nanogl/types";
import Cameras from "./cameras/Cameras";
import Capabilities from "./core/Capabilities";
import { MainRenderContext } from "./core/Renderer";
import Viewport from "./core/Viewport";
import DebugDraw from "./dev/debugDraw/DebugDraw";
import GLView from "./GLView";
import Pointers from "./core/Pointers";
import Signal from "@/core/Signal";
import XRView, { XRViewFrameEvent } from "./xr/XRView";
import { Activity, ActivityId } from "./activities/Activity";
import { activityFactory } from "./activities/factory";
import { AbortSignal } from "@azure/abort-controller";
import { vec3 } from "gl-matrix";

export default class Renderer {


  /**
   * the HTMLElemment used to listen user inputs
   */
  ilayer    : HTMLElement

  /**
   * cameras manager
   */
  cameras   : Cameras

  pointers : Pointers

  onRender = new Signal<void>()

  activity: Activity = null


  /**
   * main backbuffer viewport
   */
  readonly viewport = new Viewport()

  readonly context: MainRenderContext;



  constructor( readonly glview : GLView, readonly xrview : XRView ) {

    glview.onFrame.on( this._onGLViewRender )
    xrview.onFrame.on( this._onXRViewRender )


    this.ilayer = glview.canvas

    DebugDraw.init( glview.gl )

    this.context  = new MainRenderContext( this.gl, this.viewport )
    this.pointers = new Pointers( this.ilayer )
    this.cameras  = new Cameras(this)

    Capabilities(this.gl).report()


    gsap.ticker.remove(gsap.updateRoot);

  }

  async loadActivity( id: ActivityId ){
    const activity = activityFactory( id, this )
    await activity.load( AbortSignal.none )
    this.activity?.unload()
    this.activity = activity
  }

  get gl(): GLContext{
    return this.glview.gl
  }

  get camera(): Camera<PerspectiveLens> {
    return this.cameras.camera
  }

  get width(): number {
    return this.glview.width
  }

  get height(): number {
    return this.glview.height
  }


  private _onGLViewRender = (time:DOMHighResTimeStamp)=>{
    gsap.updateRoot(performance.now()/1000);
    time;

    this.context.withCamera( this.camera )
    this.viewport.setSize(this.glview.width, this.glview.height)
    this.cameras.preRender()
    this.camera.updateViewProjectionMatrix(this.viewport.width, this.viewport.height);

    this.activity?.render()
    this.pointers.endFrame()
  }


  private _onXRViewRender = (e:XRViewFrameEvent)=>{
    gsap.updateRoot(performance.now()/1000);
    e;
    this.activity?.renderXR()
    this.pointers.endFrame()
  }


  dispose(){
    this.pointers.dispose()
  }


  unprojectToViewport( pos:vec3 ): [number, number] {
    const V = vec3.create()
    vec3.transformMat4(V, pos, this.camera._viewProj );
    return [ V[0], V[1] ]
  }

  unprojectToScreen( pos:vec3 ): [number, number] {
    const vpPos = this.unprojectToViewport( pos )

    return [
      (vpPos[0]*.5+.5)*this.glview.canvasWidth,
      (-vpPos[1]*.5+.5)*this.glview.canvasHeight
    ]
  }



}