<template>
  <div class="brands">
    <span class="logo-primary">
      <span class="logo-fema">
        <ui-icon name="logo-fema"/>
      </span>
      <span class="logo-meta">
        <ui-icon name="logo-meta"/>
      </span>
    </span>
    <span class="logo-secondary">
      <span class="logo-ad-concil">
        <ui-icon name="logo-ad-concil"/>
      </span>
      <span class="logo-ready">
        <ui-icon name="logo-ready"/>
      </span>
    </span>
  </div>
</template>

<style lang="stylus" scoped>
.brands
  display flex
  flex-direction column

  .logo-primary
    display flex
    align-items center
    gap 3rem
    margin-bottom 1rem
    +desktop()
      gap 4rem
  .logo-secondary
    display flex
    align-items center
    gap .875rem
    +desktop()
      gap 1.5rem

  .logo-meta,
  .logo-fema,
  .logo-ready,
  .logo-ad-concil
    display inline-block
    svg
      width 100%
      height 100%
    &--mobile
      display none
    
  .logo-fema
    position: relative
    &::after
      content: ''
      position: absolute
      top: 50%
      right: -1.5rem
      transform: translateY(-50%)
      height: 40px
      width: 1px
      background: $beige
      +desktop()
        height: 59px
        right: -2rem
      +oculus()
        height: 40px

  &--loading
    +mobile()
      width 146px
      flex-direction: row
      flex-wrap: wrap
      justify-content: space-between
      gap 0.5rem

    .logo-secondary
      +mobile()
        justify-content: space-between
    .logo-meta
      width: 4.75rem
      +desktop()
        width: 5.75rem
      
      +oculus()
          width: 4.75rem
    .logo-fema
      width: 4.75rem
      +desktop()
        width: 7.5625rem
      +oculus()
        width: 4.75rem
    .logo-ready
      width: 4.575rem
    .logo-ad-concil
      width: 1.75rem
      +desktop()
        width: 2.9rem
      +oculus()
        width: 1.75rem

  &--pause
    width 48%
    gap 1rem
    .logo-primary
      gap 2.5rem
      margin-bottom 0rem
    .logo-secondary
      gap 0.75rem
    .logo-meta
      width: 5.5625rem
    .logo-fema
      width: 4.75rem
      +desktop()
        width: 5.3125rem
        &::after
          background: $black
          height: 60px
          right: -1rem
    .logo-ready
      width: 2.875rem
      +desktop()
        width: 3.375rem
    .logo-ad-concil
      width: 1.75rem
      +desktop()
        width: 2.1875rem
</style>