import Controller from "../../common/controllers/Controller"
import XRActivity from "../XRActivity";
import AppService from "@/services/AppService";
import { GameState } from "@/services/states/GameStateMachine";
import { XRGamepadButton } from "@webgl/xr/XRGamepad";
import { useHotspots } from "@/services/stores/hotspots";

enum InterfaceNames {
  INTRO = 'intro',
  PAUSE = 'pause',
  FAIL = 'fail',
  SUCCESS = 'success',
  DISCONNECTED = 'disconnected',
  BONUS = 'bonus',
  MALUS = 'malus',
}


const { findHotspot } = useHotspots()

export default class InterfacesController extends Controller {
  activity: XRActivity
  interface: InterfaceNames = null

  constructor(activity: XRActivity) {
    super(activity)
  }

  setInterface(state: GameState) {
    if (state.context.isPaused) {
      this.interface = InterfaceNames.PAUSE
      return
    }

    if (state.matches('intro') && !state.matches('intro.xr.wait_companion')) {
      this.interface = InterfaceNames.INTRO
      return
    }

    if (state.matches('end.failed.final')) {
      this.interface = InterfaceNames.FAIL
      return
    }

    if (state.matches('end.initial') || state.matches('end.success')) {
      this.interface = InterfaceNames.SUCCESS
      return
    }

    if (state.event.type === 'GAME_HOTSPOT_ACTION_DONE' && !state.matches('end') ) {
      const id = state.event.payload
      const hotspot = findHotspot(id)

      if( hotspot.points !== 0 ){
        const bonus = hotspot.points > 0 ? this.activity.interfaces.bonus : this.activity.interfaces.malus
        bonus.show()
      }

    }

    this.interface = null
  }

  interfaceHide(prevInterface: InterfaceNames, name: InterfaceNames, buttons: XRGamepadButton[]) {
    if (prevInterface !== name || this.interface === name) return

    for (let i = 0; i < buttons.length; i++) {
      this.activity.gamepads.buttons.updateHighlight(buttons[i], false)
    }

    this.activity.interfaces[name].hide()
  }

  interfaceShow(prevInterface: InterfaceNames, name: InterfaceNames, buttons: XRGamepadButton[]) {
    if (this.interface !== name || prevInterface === name) return

    for (let i = 0; i < buttons.length; i++) {
      this.activity.gamepads.buttons.updateHighlight(buttons[i], true)
    }

    this.activity.interfaces[name].show()
  }

  showInterfaces(prevInterface: InterfaceNames) {
    this.interfaceHide(prevInterface, InterfaceNames.DISCONNECTED, ['A'])
    this.interfaceHide(prevInterface, InterfaceNames.INTRO, [])
    this.interfaceHide(prevInterface, InterfaceNames.PAUSE, ['A', 'Y', 'B'])
    this.interfaceHide(prevInterface, InterfaceNames.FAIL, ['A', 'B'])
    this.interfaceHide(prevInterface, InterfaceNames.SUCCESS, ['A'])

    this.interfaceShow(prevInterface, InterfaceNames.DISCONNECTED, ['A'])
    this.interfaceShow(prevInterface, InterfaceNames.INTRO, [])
    this.interfaceShow(prevInterface, InterfaceNames.PAUSE, ['A', 'Y', 'B'])
    this.interfaceShow(prevInterface, InterfaceNames.FAIL , ['A', 'B'])
    this.interfaceShow(prevInterface, InterfaceNames.SUCCESS, ['A'])
  }

  handleIntro(state: GameState) {
    if (!state.matches('intro')) return
    if (state.event.type !== 'GAME_INTRO_NEXT_STEP') return

    this.activity.interfaces.intro.nextStep()
  }

  stateChange(state: GameState) {
    const xr = AppService.state.children.get('xr').getSnapshot()
    const network = AppService.state.children.get('nw').getSnapshot()
    if (!xr.matches('active') || !AppService.state.state.matches('vr')) return

    const prevInterface = this.interface

    if( network.context.actorCount !== 2 ){
      this.interface = InterfaceNames.DISCONNECTED
    } else {
      this.setInterface(state)
    }

    this.showInterfaces(prevInterface)
    this.handleIntro(state)
  }

  reset() {
    this.interface = null
    this.activity.interfaces.reset()
  }
}
