
import GLArrayBuffer from "nanogl/arraybuffer";

import Program from 'nanogl/program'


import Camera from "nanogl-camera";
import { mat4 } from "gl-matrix";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import { GLContext } from "nanogl/types";
import Ray from "@webgl/math/Ray";

import VS from './shader.vert'
import FS from './shader.frag'


const OFFSET_MAT = mat4.create()
mat4.fromTranslation(OFFSET_MAT, [0, 0, -.03])


export default class XRPointerLineRenderer {

  geom : GLArrayBuffer
  prg: Program;
  cfg: LocalConfig;

  constructor( private gl: GLContext ) {

    this.prg = new Program( gl, VS(), FS());
    
    this.geom = new GLArrayBuffer( gl, new Float32Array([
      0,1,
      0,-1,
      1,1,
      1,-1,
    ]));

    this.geom.attrib( 'aPosition', 2, gl.FLOAT );

    this.cfg = GLState.get(gl).config()
      .depthMask(false)
      .enableCullface(false)
      .enableBlend()
      .enableDepthTest()
      .blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )

  }

  prepare(camera:Camera){
    this.prg.use()
    this.prg.uViewProjMatrix( camera._viewProj )
    this.prg.uCameraWorldPos( camera._wposition )

    this.geom.attribPointer( this.prg );
    this.cfg.apply()
  }


  draw( ray:Ray, opacity=1, length=2, thickness=.01 ){
    this.prg.uRayOrigin(ray.origin)
    this.prg.uRayDir(ray.direction)
    this.prg.uParams( [ thickness, length, opacity ] )
    this.geom.drawTriangleStrip()
  }

}