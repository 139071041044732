import { XRHandedness } from "webxr";
import { mat3, mat4, quat, vec2, vec3 } from "gl-matrix";

import Ray from "@/webgl/math/Ray";
import Time from "@webgl/Time";
import AppService from "@/services/AppService";
import type XRNavigation from "./XRNavigation";
import { ButtonEvent } from "../XRGamepad";

const V3 = vec3.create();
const VNZ = vec3.fromValues(0.0, 0.0, -1.0);
const VRNZ = vec3.fromValues(0.0, 0.0, -1.0);
const R = quat.create();
quat.rotateX(R, R, -Math.PI / 5);
vec3.transformQuat(VRNZ, VRNZ, R);

const M3 = mat3.create();
const M4 = mat4.create();
const Q_ID = quat.create();
const QUATA = quat.create();
const QUATB = quat.create();
const Q_ORIGIN = quat.create();
quat.rotateY(Q_ORIGIN, Q_ORIGIN, -Math.PI / 2)

export default class XRNavigationController {


  aiming = false;
  ctrlhand: XRHandedness = "right";
  rotation: quat;
  _targetRotation: quat;
  _tgtAngle: number;
  _bound = false;
  _enabledControllers: Record<XRHandedness, boolean> = {
    "left": true,
    "right": true,
    "none": false
  };
  _rotationEnabled = true
  rotationAngle = 0
  isRotating = false

  heading = 0

  constructor(
    public readonly navigation: XRNavigation
  ) {
    this.rotation = quat.create();
    this._targetRotation = quat.create();
    this._tgtAngle = 0;

  }

  bind() {
    if (this._bound)
      return;
    this._bound = true;
    this.navigation.xrview.inputs.onBtnDown.on(this.onBtnDown);
  }

  unbind() {
    if (!this._bound)
      return;
    this._bound = false;
    this.navigation.xrview.inputs.onBtnDown.off(this.onBtnDown);
  }

  disableController(hand: XRHandedness) {
    this._enabledControllers[hand] = false;
    if (this.ctrlhand == hand && this.aiming) {
      this.navigation.active = false;
      this.aiming = true;
    }
  }

  enableController(hand: XRHandedness) {
    this._enabledControllers[hand] = true;
  }

  enableRotation() {
    this._rotationEnabled = true
  }

  disableRotation() {
    this._rotationEnabled = false
  }

  onBtnDown = (evt: ButtonEvent) => {

    if (this.aiming)
      return;

    const state = AppService.state.children.get('game').getSnapshot()
    if (state.context.isPaused || (!state.matches('game') && !state.matches('tuto')))
      return

    if (this._enabledControllers.left) {
      switch (evt.name) {
        case "Left/Down":
        case "Left/Up":
        case "Left/Right":
        case "Left/Left":
          this.navigation.active = true;
          this.aiming = true;
          this.ctrlhand = "left";
          this.navigation.onNavigationStart.dispatch()
          break;
        default:
          break;
      }
    }

    if (this._enabledControllers.right) {
      switch (evt.name) {
        case "Right/Down":
        case "Right/Up":
        case "Right/Right":
        case "Right/Left":
          this.navigation.active = true;
          this.aiming = true;
          this.ctrlhand = "right";
          this.navigation.onNavigationStart.dispatch()
          break;
        default:
          break;
      }
    }

    if (this._rotationEnabled) {
      switch (evt.name) {
        case "Left/Right":
          this.isRotating = true
          break;
        case "Left/Left":
          this.isRotating = true
          break;
        default:
          break;
      }
    }

  }

  onJoystickRotate() {
    const movement = this.navigation.xrview.inputs.getAxes('left')[0]

    if (Math.abs(movement) < 0.02) {
      this.onJoystickRotateRelease()
      return
    }

    const angle = movement * 2 * Time.dt
    this.rotationAngle = angle
    this.navigation.onRotationRequest.dispatch(angle)
  }
  
  onJoystickRotateRelease() {
    this.rotationAngle = 0
    this.isRotating = false
  }

  onStickRelease() {
    if (this.navigation.hasTarget && this.aiming) {
      this.navigation.onNavigationRequest.dispatch({
        position: this.navigation.target,
        heading: this.heading,
        reason: 'controller'
      });
    }
    this.aiming = false;
    this.navigation.active = false;
  }

  prepareRay(ray: Ray) : boolean{
    const input = this.navigation.xrview.inputs.getInput(this.ctrlhand)
    if( !input ) return false
    const ctrlPose = input.pose
    if( !ctrlPose ) return false
    mat3.fromMat4(M3, ctrlPose.transform.matrix as mat4);
    quat.fromMat3(QUATA, M3);

    vec3.transformQuat(ray.direction, VRNZ, QUATA);
    vec3.transformQuat(this.navigation.forward, VNZ, QUATA);
    vec3.normalize(ray.direction, ray.direction);
    vec3.normalize(this.navigation.forward, this.navigation.forward);

    const pos = ctrlPose.transform.position
    ray.origin[0] = pos.x
    ray.origin[1] = pos.y
    ray.origin[2] = pos.z

    vec3.scaleAndAdd(ray.origin, ray.origin, ray.direction, 0.05);

    return true
  }

  update() {

    if (!this.navigation.hasTarget) {
      vec3.scaleAndAdd(
        this.navigation.target,
        this.navigation.ray.origin,
        this.navigation.ray.direction,
        1.0
      );
    }

    // this.xrmodule.xrroot.y += this.xrmodule.gamepads.inputs.getAxes("left")[1];

    if (this.aiming) {

      const len = vec2.squaredLength(this.navigation.xrview.inputs.getAxes(this.ctrlhand));

      if (len < 0.1) {
        this.onStickRelease();
        return;
      }

      V3.set(this.navigation.ray.direction);
      V3[1] = 0;
      vec3.normalize(V3, V3);
      quat.identity(QUATA)
      quat.rotateY(
        QUATA,
        QUATA,
        Math.atan2(-V3[0], -V3[2])
      )

      const angle = Math.atan2(
        -this.navigation.xrview.inputs.getAxes(this.ctrlhand)[0],
        -this.navigation.xrview.inputs.getAxes(this.ctrlhand)[1]
      );

      this.heading = - (Math.atan2(-V3[0], -V3[2]) + angle + Math.PI / 2)
      quat.rotateY(
        QUATB,
        Q_ORIGIN,
        -this.heading
      )

      this.navigation.renderer.targetNode.rotation.set(QUATB);

      // this.getMoveMatrix(M4, this.navigation.target)
      // DebugDraw.drawGuizmo(M4)


    }

    if (this.isRotating) this.onJoystickRotate()

  }


  setTargetRotation(rotation: quat){
    this._targetRotation.set(rotation);
  }

  reset() {
    quat.identity(this._targetRotation);
    quat.identity(this.rotation);
    this.moveTo(vec3.create());
  }


  getMoveMatrix( m:mat4, target: vec3 ){
    const headRotation = this.navigation.xrview.headset.getHorizontalRotation()
    quat.rotateY( QUATA, Q_ID, -headRotation);
    quat.multiply(this.rotation, this._targetRotation, QUATA);
    mat4.fromRotationTranslation(m, this.rotation, target);
  }

  moveTo(target: vec3, ) {
    this.getMoveMatrix(M4, target)
    this.navigation.xrview.setTransformedSpace( M4 );
  }

}