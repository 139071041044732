import Renderer from "@webgl/Renderer"
import XRActivity from "./xr/XRActivity"
import DesktopActivity from "./desktop/DesktopActivity"
import CompanionActivity from "./companion/CompanionActivity"
import { Activity, ActivityId } from "./Activity"


export function activityFactory( id : ActivityId, renderer: Renderer ) : Activity {
  switch( id ) {
    case 'xr'        : return new XRActivity        (renderer)
    case 'desktop' : return new DesktopActivity (renderer)
    case 'companion' : return new CompanionActivity ()
    default: throw new Error(`Unknown activity id: ${id}`)
  }
}