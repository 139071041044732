import Photon, { ConnectionProtocol } from "../photon/Photon-Javascript_SDK"


export enum PhotonClientLogLevel {
  DEBUG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4,
  OFF = 6
}


export type PhotonConfig = {
  readonly protocol: ConnectionProtocol 
  readonly appId: string 
  readonly appVersion: string 
  readonly logLevel: PhotonClientLogLevel
}


const envprotocol = (process.env.VUE_APP_PHOTON_PROTOCOL || "").toLowerCase()


export const DefaultPhotonConfig: PhotonConfig = {
  protocol:envprotocol === "wss" ? Photon.ConnectionProtocol.Wss : Photon.ConnectionProtocol.Ws,
  appId:process.env.VUE_APP_PHOTON_APP_ID,
  appVersion:process.env.VUE_APP_PHOTON_APP_VERSION,
  logLevel:PhotonClientLogLevel.ERROR
}