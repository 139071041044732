import { Howl } from "howler";

import XRActivity from "../xr/XRActivity";
import DesktopActivity from "../desktop/DesktopActivity";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { GameState } from "@/services/states/GameStateMachine";
import AudioConfig, { AudioEmitters } from "./AudioConfig";
import MatrixUtils from "@webgl/math/MatrixUtils";
import { vec3 } from "gl-matrix";
import { stateMatch } from "@/services/states/Utils";
import { HotspotAudioId } from "@/services/states/GameContextDatas";
import { HotspotAudio, useAudioHotspots } from "@/services/stores/hotspots";


export type AudioData = {
  id: AUDIO_ID,
  volume: number,
  hotspot: HotspotAudioId,
  refDistance: number,
  rolloffFactor: number,
}

class Audio {

  _isPlaying = false
  constructor( readonly data:AudioData, private howl: Howl, private _position:vec3 ){}



  play(){
    if( this._isPlaying ) return
    console.log('play', this.data.id);

    this._isPlaying = true

    this.howl.off('fade',this.onFadeOutComplete )

    const howl = this.howl
    const data = this.data

    const sprite = ( (howl as any)._sprite.main )? "main" : undefined;
    const id = howl.play(sprite);

    howl.once('play', () => {

      if( data.refDistance > 0 ){

        const [x, y, z] = this._position
        howl.pos(x, y, z, id);
        
        howl.pannerAttr({
          panningModel: 'HRTF',
          refDistance: data.refDistance,
          rolloffFactor: data.rolloffFactor,
        }, id)
      }
        
      howl.fade(0, data.volume, 800, id)

    }, id);
  }


  stop(){
    if( !this._isPlaying ) return
    console.log('stop', this.data.id);
    this._isPlaying = false

    this.howl.on('fade',this.onFadeOutComplete )
    this.howl.fade(this.data.volume, 0, 800)
  }

  onFadeOutComplete = ()=>{
    console.log('fade out complete', this.data.id);

    this.howl.off('fade',this.onFadeOutComplete )
    this.howl.stop()
  }
}




export default class Audios {
  _canTrigger: boolean
  _triggerAudios: Audio[] = []
  _ambientAudios: Audio[] = []
  _isAmbientPlaying: boolean
  findHotspotAudio: (id: HotspotAudioId) => HotspotAudio

  constructor(protected activity: XRActivity | DesktopActivity) {
    const hotspotsAudio = useAudioHotspots()
    this.findHotspotAudio = hotspotsAudio.findHotspot
  }

  start() {

    this._ambientAudios = AudioEmitters.map((data: AudioData) => {
      return this.initAudio(data)
    })
    this.activity.gameStateWatcher.onStateChange.on( this._stateChange )
    this._stateChange(this.activity.gameStateWatcher.currentState)
  }


  // get audio position
  getPosition(id: HotspotAudioId): vec3 {
    const res = vec3.create()
    const m = this.findHotspotAudio(id).matrix
    MatrixUtils.getTranslation(res, m)
    return res
  }



  // init audio object
  initAudio(data: AudioData): Audio {
    const audio = AudioManager.getAudio(data.id)
    return new Audio(data, audio, this.getPosition(data.hotspot))
  }


  // watch state to play audios at right time
  _stateChange = (state: GameState) => {


    const emitters = new Set<AUDIO_ID>()

    /**
     * collect all emitters active for the given state
     */
    for (const cfg of AudioConfig) {
      if( stateMatch( state, cfg.state) ){
        cfg.emitters.forEach(id=>emitters.add(id))
      }
    }

    this._ambientAudios.forEach((audio: Audio) => {
      if( !emitters.has(audio.data.id) ){
        audio.stop()
      } else {
        audio.play()
      }
    })

  }

}