import Signal from "@/core/Signal";
import AppService from "@/services/AppService";
import { GameState, GameStateInterpreter } from "@/services/states/GameStateMachine";

export default class GameStateWatcher {

  readonly onStateChange = new Signal<GameState>()

  private _gameService: GameStateInterpreter
  private _currentState: GameState;
  
  public get currentState(): GameState {
    return this._currentState;
  }

  

  constructor() {
    AppService.state.onTransition( this._onAppStateUpdate )
    this._onAppStateUpdate()
  }
  

  private _onAppStateUpdate = () =>{
    const gameService = AppService.state.children.get('game') as GameStateInterpreter
    if( gameService ){
      this._gameService = gameService
      this._gameService.onTransition(this._onGameStateUpdate)
      AppService.state.off( this._onAppStateUpdate )
      
    }  
  }


  private _onGameStateUpdate = (gamestate:GameState) =>{
    this._currentState = gamestate
    
    // console.log("GameStateController ", gamestate.value );
    this.onStateChange.dispatch( gamestate )
  }
  
  
  dispose() {
    AppService.state.off( this._onAppStateUpdate )
    this._gameService?.off( this._onGameStateUpdate )
  }
}