import Controller from "./Controller"
import XRActivity from "@webgl/activities/xr/XRActivity";
import DesktopActivity from "@webgl/activities/desktop/DesktopActivity";
import { GameState } from "@/services/states/GameStateMachine"

/**
 * Load Roomlevel according to game state
 */
export default class RoomLevel extends Controller {
  activity: XRActivity | DesktopActivity

  stateChange(state: GameState) {
    this.activity.roomLoader.setLevel(state.context.roomlevel);
  }

  reset() {
    return
  }
}