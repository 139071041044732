var fn = function( obj ){
var __t,__p='';
__p+='precision lowp float;\n\nuniform sampler2D tTex0;\nuniform sampler2D tTex1;\nuniform sampler2D tTex2;\nuniform sampler2D tTexBlank;\nuniform float uOpacity;\nuniform float uStep;\nuniform float uBlank;\n\nvarying vec2 vTexCoord;\n\nvoid main(void){\n  vec2 coords = vec2(1. - vTexCoord.x, 1. - vTexCoord.y);\n\n  vec4 tex0 = texture2D(tTex0, coords);\n  vec4 tex1 = texture2D(tTex1, coords);\n  vec4 tex2 = texture2D(tTex2, coords);\n  vec4 texBlank = texture2D(tTexBlank, coords);\n\n  vec4 texContent1 = mix(tex0, tex1, clamp(uStep, 0., 1.));\n  vec4 texContent = mix(texContent1, tex2, clamp(uStep - 1., 0., 1.));\n  vec4 tex = mix (texContent, texBlank, uBlank);\n\n  gl_FragColor = vec4(tex.rgb, tex.a * uOpacity);\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
