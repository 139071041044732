
enum RenderPass {
  COLOR = 'color',
  DEPTH = 'depth',
  BLOOM = 'bloom',
  REFLECT_DEPTH = 'reflect-depth',
  
  DEFAULT = COLOR
}

export default RenderPass