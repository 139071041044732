import { GltfScene } from "@webgl/engine/GltfScene";
import Picking from "@webgl/engine/Picking";
import { TextureResource } from "@webgl/resources/TextureResource";
import WebglAssets from "@webgl/resources/WebglAssets";
import Gltf from "nanogl-gltf/lib/Gltf";
import Bounds from "nanogl-pbr/Bounds";
import { GLContext } from "nanogl/types";
import { FogPass } from "@webgl/fog/FogPass";
import UnlitPass from "nanogl-pbr/UnlitPass";
import Input, { Sampler, Uniform } from "nanogl-pbr/Input";
import { RenderContext } from "@webgl/core/Renderer";
import FogProps from "@webgl/fog/FogProps";
import { mat4 } from "gl-matrix";
import FireMaterial, { FireMaterialId } from "./FireMaterial";
import RenderPass from "@webgl/core/RenderPass";
import gui from "@webgl/dev/gui";
import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Skybox, { SkyboxChunk } from "./Skybox";
import PickingSources from "@webgl/activities/common/PickingSources";

// {
//   string label = "xxx";

//   if ($F == 1 ) label = "Guestroom_Tuto";
//   if ($F == 2 ) label = "GuestRoom_Opened";
//   if ($F == 3 ) label = "Bedroom";
//   if ($F == 4 ) label = "Bedroom_GC_HC";
//   if ($F == 5 ) label = "Bedroom_GC_HO";
//   if ($F == 6 ) label = "Bedroom_GO_HO";
//   if ($F == 7 ) label = "CorridorAllClosed";
//   if ($F == 8 ) label = "Corridor_S";
//   if ($F == 9 ) label = "Corridor_L";
//   if ($F == 10) label = "Corridor_SL";
//   if ($F == 11) label = "Study_H";
//   if ($F == 12) label = "Study_HL";
//   if ($F == 13) label = "Study_L";
//   if ($F == 14) label = "Study";
//   if ($F == 15) label = "Living";
//   if ($F == 16) label = "Living_H";
//   if ($F == 17) label = "Living_S";

//   return label;
// }


export enum RoomLevelId {
  Guestroom_DC = 1,
  GuestRoom_DO,

  Bedroom_GO_WC_DC,
  Bedroom_GC_WC_DC,
  Bedroom_GC_WC_DO,
  Bedroom_GC_WO_DC,
  Bedroom_GC_WO_DO,
  Bedroom_GO_WO_DC,
  Bedroom_GO_WO_DO,
  Bedroom_GO_WC_DO,

  Corridor_BO_DC,
  Corridor_BC_DC,
  Corridor_BC_DO,
  Corridor_BO_DO,

  Study_CO_WC_DO,
  Study_CO_WC_DC,
  Study_CO_WO_DC,
  Study_CC_WO_DC,
  Study_CC_WC_DC,
  Study_CC_WO_DO,
  Study_CC_WC_DO,
  Study_CO_WO_DO,

  Living_SO_DC,
  Living_SC_DC,
  Living_SC_DO,
  Living_SO_DO,

  Landing,
  Outdoor,
}


interface IRoomLevelAssets {

  pickingSources: PickingSources
  navmesh: Picking;
  walls: Picking;

  get loaded(): boolean;

  load() :Promise<void>
  unload():void

  preRender():void;
  render(ctx:RenderContext):void;
  getWorldMatrix(): mat4
  
}


class RoomLevelAssets implements IRoomLevelAssets {

  pickingSources: PickingSources
  completeTex: TextureResource;
  scene: GltfScene
  navmesh: Picking;
  walls: Picking;
  ceilingHeight: number;
  private _loaded = false;
  public get loaded() {
    return this._loaded;
  }

  constructor(private level: RoomLevelId, private room: Room) {

    const req = `room/scene-${this.level}.glb`
    this.scene = new GltfScene(req, room.gl)

    this.pickingSources = new PickingSources(this.level)
    
    this.scene.overrides.overridePass("complete", (ctx, material)=>{
      material.addPass( room.completeUnlitPass, RenderPass.COLOR)
      material.addPass( room.completeBloomPass, RenderPass.BLOOM )
      return null
    })

    this.scene.overrides.overridePass("skybox", (ctx, material)=>{
      material.addPass( room.skyboxPass, RenderPass.COLOR)
      return null
    })

    this.fireOverride("LargeFire")
    this.fireOverride("SidewallFire")

    this.completeTex = WebglAssets.getTexture(`room/complete-${this.level}.jpg`, room.gl)

  }

  fireOverride( name:FireMaterialId ) {
    this.scene.overrides.overridePass(name, (ctx, material)=>{
      const fireMat = this.room.getFireMaterial(name)
      material.addPass( fireMat.pass, RenderPass.COLOR)
      material.addPass( fireMat.bloomPass, RenderPass.BLOOM )
      return null
    })
  }

  load() {
    return Promise.all([
      this.scene.load(),
      this.pickingSources.load(),
      this.completeTex.load(),
    ]).then(() => this.onLoaded())
  }

  onLoaded() {
    this._loaded = true
    this._extractNavMesh(this.scene.gltf)
    this._updateCeiling()
  }

  unload() {
    this._loaded = false
    this.scene.unload()
    this.completeTex.unload()
  }

  preRender() {
    if( !this._loaded ) return
    this.scene.gltf.root.updateWorldMatrix();
  }


  render(ctx: RenderContext) {
    if( !this._loaded ) return
    // this.room.completeUnlitPass.baseColor.attachSampler().set(this.completeTex.texture)
    this.room.completeSampler.set(this.completeTex.texture)
    this.scene.render(ctx)
  }

  private _extractNavMesh(gltf: Gltf) {
    this.navmesh = Picking.extractFromGltfNode( gltf, 'navmesh')
    this.walls   = Picking.extractFromGltfNode( gltf, 'walls'  )
  }

  private _updateCeiling() {
    const out = new Bounds()
    this.scene.computeStaticBounds(out);
    this.ceilingHeight = out.max[1];
  }

  // for fog
  getWorldMatrix(): mat4
  {
    return this.scene.gltf.root._wmatrix;
  }
}



class SkyboxLevel implements IRoomLevelAssets {
  
  skybox: Skybox;
  
  matId: mat4;

  navmesh: Picking = null
  
  walls: Picking = null

  pickingSources: PickingSources = null

  constructor( private skyboxTexRes: TextureResource ) {
    this.skybox = new Skybox(skyboxTexRes.glp.gl)
    this.skybox.envmap = skyboxTexRes 
    this.matId = mat4.create()
    this.pickingSources = new PickingSources(RoomLevelId.Outdoor)
  }

  public get loaded() {
    return this.skyboxTexRes.isLoaded;
  }

  load():Promise<void> {
    return this.skyboxTexRes.load().then()
  }

  unload(): void {
    this.skyboxTexRes.unload()
  }

  preRender(){
    0
  }

  render(ctx:RenderContext){
    this.skybox.render(ctx)
  }


  getWorldMatrix(): mat4{
    return this.matId
  }

}


class LightmapBloomThreshold extends Chunk {
  threshold: Input;
  multiplier: Input;
  constructor() {
    super(true, false);

    this.multiplier = new Input("LMMult", 1)
    this.threshold = new Input("LMThreshold", 1)

    this.multiplier.attachConstant(1.0)
    this.threshold.attachConstant(.934)

    this.addChild(this.threshold)
    this.addChild(this.multiplier)

  }

  _genCode(slots: ChunksSlots): void {
    let code = '';
    code += 'FragColor.rgb = mix(  vec3(0.0), FragColor.rgb*LMMult(), step( LMThreshold(), FragColor.r ) );'
    slots.add( 'postf_linear', code)
  }
}



export default class Room {


  private _currentLevelId: RoomLevelId = 1
  completeMultiplier: Uniform;
  envmap: TextureResource;

  get currentLevelId(): RoomLevelId{
    return this._currentLevelId
  }

  get currentLevel(): IRoomLevelAssets{
    return this.levels[this._currentLevelId]
  }
  
  get pickingSources(): PickingSources{
    return this.currentLevel.pickingSources
  }

  
  levels : Record<RoomLevelId, IRoomLevelAssets>
  
  firePasses: FireMaterial[] = []
  completeUnlitPass: FogPass | UnlitPass;
  completeBloomPass: UnlitPass;
  
  skyboxChunk: SkyboxChunk;
  skyboxPass: FogPass | UnlitPass;

  ceilingHeight = 3.5;

  completeSampler: Sampler;

  threshold : LightmapBloomThreshold



  constructor(readonly gl: GLContext, private fogProps?: FogProps) {

    this.completeUnlitPass = fogProps ? new FogPass(fogProps) : new UnlitPass();
    this.completeSampler = this.completeUnlitPass.baseColor.attachSampler()
    this.completeMultiplier = this.completeUnlitPass.baseColorFactor.attachUniform()
    this.completeMultiplier.set( 1, 1, 1 )
    this.completeUnlitPass.glconfig
      .enableDepthTest()
      .enableCullface()

    this.skyboxPass = fogProps ? new FogPass(fogProps, "sbfp", false, false, false) : new UnlitPass();
    this.skyboxPass.baseColor.attachConstant([0, 0, 0])
    this.skyboxPass.glconfig
      .enableDepthTest()
      .enableCullface()

    this.skyboxChunk = new SkyboxChunk()
    this.skyboxPass.inputs.add(this.skyboxChunk)


    this.envmap = WebglAssets.getTexture(`skybox_night.jpg`, gl)
    


    this.threshold = new LightmapBloomThreshold()
    this.completeBloomPass = new UnlitPass();
    this.completeBloomPass.baseColor.attach(this.completeSampler)
    this.completeBloomPass.inputs.add( this.threshold )

    // this.completeUnlitPass.baseColor.attachConstant([1, 1, 1])

/////////////////
////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
////////////////
////////////////////////
///////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
//////////////

    this.completeBloomPass.glconfig
      .enableDepthTest()
      .enableCullface()

    this.levels = {
      [RoomLevelId.Guestroom_DC    ]: new RoomLevelAssets(RoomLevelId.Guestroom_DC    , this),
      [RoomLevelId.GuestRoom_DO    ]: new RoomLevelAssets(RoomLevelId.GuestRoom_DO    , this),
      [RoomLevelId.Bedroom_GO_WC_DC]: new RoomLevelAssets(RoomLevelId.Bedroom_GO_WC_DC, this),
      [RoomLevelId.Bedroom_GC_WC_DC]: new RoomLevelAssets(RoomLevelId.Bedroom_GC_WC_DC, this),
      [RoomLevelId.Bedroom_GC_WC_DO]: new RoomLevelAssets(RoomLevelId.Bedroom_GC_WC_DO, this),
      [RoomLevelId.Bedroom_GC_WO_DC]: new RoomLevelAssets(RoomLevelId.Bedroom_GC_WO_DC, this),
      [RoomLevelId.Bedroom_GC_WO_DO]: new RoomLevelAssets(RoomLevelId.Bedroom_GC_WO_DO, this),
      [RoomLevelId.Bedroom_GO_WO_DC]: new RoomLevelAssets(RoomLevelId.Bedroom_GO_WO_DC, this),
      [RoomLevelId.Bedroom_GO_WO_DO]: new RoomLevelAssets(RoomLevelId.Bedroom_GO_WO_DO, this),
      [RoomLevelId.Bedroom_GO_WC_DO]: new RoomLevelAssets(RoomLevelId.Bedroom_GO_WC_DO, this),
      [RoomLevelId.Corridor_BO_DC  ]: new RoomLevelAssets(RoomLevelId.Corridor_BO_DC  , this),
      [RoomLevelId.Corridor_BC_DC  ]: new RoomLevelAssets(RoomLevelId.Corridor_BC_DC  , this),
      [RoomLevelId.Corridor_BC_DO  ]: new RoomLevelAssets(RoomLevelId.Corridor_BC_DO  , this),
      [RoomLevelId.Corridor_BO_DO  ]: new RoomLevelAssets(RoomLevelId.Corridor_BO_DO  , this),
      [RoomLevelId.Study_CO_WC_DO  ]: new RoomLevelAssets(RoomLevelId.Study_CO_WC_DO  , this),
      [RoomLevelId.Study_CO_WC_DC  ]: new RoomLevelAssets(RoomLevelId.Study_CO_WC_DC  , this),
      [RoomLevelId.Study_CO_WO_DC  ]: new RoomLevelAssets(RoomLevelId.Study_CO_WO_DC  , this),
      [RoomLevelId.Study_CC_WO_DC  ]: new RoomLevelAssets(RoomLevelId.Study_CC_WO_DC  , this),
      [RoomLevelId.Study_CC_WC_DC  ]: new RoomLevelAssets(RoomLevelId.Study_CC_WC_DC  , this),
      [RoomLevelId.Study_CC_WO_DO  ]: new RoomLevelAssets(RoomLevelId.Study_CC_WO_DO  , this),
      [RoomLevelId.Study_CC_WC_DO  ]: new RoomLevelAssets(RoomLevelId.Study_CC_WC_DO  , this),
      [RoomLevelId.Study_CO_WO_DO  ]: new RoomLevelAssets(RoomLevelId.Study_CO_WO_DO  , this),
      [RoomLevelId.Living_SO_DC    ]: new RoomLevelAssets(RoomLevelId.Living_SO_DC    , this),
      [RoomLevelId.Living_SC_DC    ]: new RoomLevelAssets(RoomLevelId.Living_SC_DC    , this),
      [RoomLevelId.Living_SC_DO    ]: new RoomLevelAssets(RoomLevelId.Living_SC_DO    , this),
      [RoomLevelId.Living_SO_DO    ]: new RoomLevelAssets(RoomLevelId.Living_SO_DO    , this),
      [RoomLevelId.Landing         ]: new RoomLevelAssets(RoomLevelId.Landing         , this),
      [RoomLevelId.Outdoor         ]: new SkyboxLevel( WebglAssets.getTexture(`skybox_end.jpg`, gl)),
    }

  }


  getLevel(level: RoomLevelId) {
    return this.levels[level]
  }


  setCurrentLevel(level: RoomLevelId) {
    this._currentLevelId = level
  }


  getFireMaterial(id: FireMaterialId): FireMaterial {

    const res = this.firePasses.find( (v) => v.id === id )
    if( res ) return res

    const m = new FireMaterial( this.gl, id, this.fogProps )
    this.firePasses.push(m)

    return m
  }


  async loadCommons(): Promise<void> {
    await this.envmap.load()
    this.skyboxChunk.envmap = this.envmap.texture
  }

  preRender(): void {
    this.currentLevel.preRender()
    this.firePasses.forEach( (v) => v.update() )
    const lmmult = this.fogProps.lmMultiplier
    this.completeMultiplier.set( lmmult, lmmult, lmmult )
  }

  render(ctx: RenderContext) {
    this.skyboxChunk.camera = ctx.camera
    this.currentLevel.render(ctx)
  }

  get worldMatrix(): mat4
  {
    return this.currentLevel.getWorldMatrix();
  }
}

