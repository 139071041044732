<template>
  <transition name="long-fade">
    <div class="ending-index" :class="classes">
      <div class="inner">
        <div class="countdown">{{ countdown }}</div>
        <div class="title">{{ t('end.success.title') }}</div>
        <div class="content">{{ t('end.success.content') }}</div>
        <div class="actions">
          <ui-btn @click="onClick" :disabled="isCompanion" :color="isCompanion ? 'dark-red' : 'light'">
            {{ isCompanion ? t('tuto.companion.action') : t('end.success.cta') }}
          </ui-btn>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
  import { computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { useAppState, useGameState } from '@/services/composition';
import { formatCountdown } from '@/services/states/Utils';
import { MAX_COUNTDOWN } from '@/services/states/GameContextDatas';
import { Role } from '@/services/states/AppStateMachine';

const { t } = useI18n()
const state = useGameState()
const { send, state: appState } = useAppState()

const countdown = computed(() => {
  return formatCountdown(MAX_COUNTDOWN - state.value.context.countdown)
})

const isCompanion = computed(() => {
  return appState.value.context.role === Role.Companion
})

const classes = computed(() => {
  const role = isCompanion.value ? 'ending-index--companion' : 'ending-index--desktop'
  return [role]
})

const onClick = () => {
  send('GAME_ACTION_DONE')
  // if (isCompanion.value) {
  //   send('EXIT_SESSION')
  // }
}
</script>

<style lang="stylus" scoped>
.ending-index
  full()
  display flex
  align-items center
  justify-content center
  padding 2rem

  color $beige

  &--companion
    color $dark-red
    background-color $beige

  .inner
    position relative
    max-width 44.375rem
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 2rem

    text-align center

  .countdown
    @extend .font-bold
    font-size 3rem
    line-height 90%
    letter-spacing 0.02em

    +mobile()
      font-size 2rem

  .title
    @extend .font-bold
    font-size (242 / 900) * 100vh
    line-height 90%
    letter-spacing 0.02em

    +mobile()
      font-size: 6rem

  .content
    @extend .font-bold
    font-size 1.375rem
    line-height 90%
    letter-spacing: 0.02em;

    +mobile()
      font-size: 1.375rem
</style>