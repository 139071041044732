import MatrixUtils from "@webgl/math/MatrixUtils";
import { mat4, quat, vec3 } from "gl-matrix";
import type { XRRigidTransform } from "webxr";

const POS = vec3.create();
const ROT = quat.create();
const SCALE = vec3.create();

const XRUtils = {

  mat4ToXRRigidTransform(m:mat4 ): XRRigidTransform {
    MatrixUtils.decompose(m, POS, ROT, SCALE );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new (window as any).XRRigidTransform(
      {x: POS[0], y: POS[1], z: POS[2]},
      {x: ROT[0], y: ROT[1], z: ROT[2], w: ROT[3]});
  },

  domPointToQuat(orientation:DOMPointReadOnly, out:quat): void {
    out[0] = orientation.x;
    out[1] = orientation.y;
    out[2] = orientation.z;
    out[3] = orientation.w;
  }, 

  domPointToVec3(orientation:DOMPointReadOnly, out:vec3): void {
    out[0] = orientation.x;
    out[1] = orientation.y;
    out[2] = orientation.z;
  }

}


export default XRUtils;