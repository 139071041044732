import type { Activity } from "../Activity"

export default class CompanionActivity implements Activity {
  constructor(){
    return
  }

  preRender():void {
    return
  }

  rttPass():void {
    return
  }

  render():void {
    return
  }

  renderXR(): void {
    // noop on companion activity
  }

  load() :Promise<void> {
    return Promise.all([]).then()
  }

  unload(): void {
    return
  }
}