var fn = function( obj ){
var __t,__p='';
__p+='attribute vec2 aPosition;\n\nuniform mat4 uWorldMatrix;\nuniform mat4 uViewProjMatrix;\nuniform vec3 uCameraWorldPos;\n\nuniform vec3 uRayOrigin;\nuniform vec3 uRayDir;\n\nuniform lowp vec3 uParams;\n\nvarying vec2 vUVs;\n\n#define A_ZPOS aPosition.x\n#define A_SIDE aPosition.y\n\n#define THICKNESS uParams.x*0.5\n#define LENGTH uParams.y\n\nvoid main(void){\n\n  // vec3 localPos = vec3(0.0, 0.0, -A_ZPOS*LENGTH);\n  // vec3 localDps = vec3(0.0, 0.0, -A_ZPOS*LENGTH+1.0);\n\n  vec3 worldPos = uRayOrigin + uRayDir * (A_ZPOS*LENGTH);\n  vec3 wTangent = uRayDir;\n\n  vec3 viewDir = normalize(uCameraWorldPos-worldPos);\n  vec3 bitanAxis = normalize( cross( viewDir, wTangent ) );\n\n  bitanAxis = normalize( bitanAxis );\n\n  worldPos += bitanAxis * (THICKNESS*A_SIDE);\n\n  gl_Position = uViewProjMatrix*vec4(worldPos, 1.0);\n\n  vUVs = vec2(A_ZPOS, A_SIDE);\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
