import RoomLevel from "@webgl/activities/common/controllers/RoomLevel";
import Controller from "@webgl/activities/common/controllers/Controller";
import FogController from "@webgl/activities/common/controllers/FogController";
import TutoController from "@webgl/activities/desktop/controllers/TutoController";
import PickingManager from "@webgl/activities/desktop/controllers/PickingManager";
import DesktopActivity from "@webgl/activities/desktop/DesktopActivity";
import CameraController from "@webgl/activities/desktop/controllers/CameraController";
import VignetteController from "@webgl/activities/common/controllers/VignetteController";
import ZoneEvents from "@webgl/activities/common/controllers/ZoneEvents";

export default class DesktopControllers {
  controllers: Controller[] = []
  readonly cameraCtrl: CameraController;
  readonly pickingManager: PickingManager;

  constructor( private activity: DesktopActivity ){
    this.cameraCtrl = new CameraController(activity)
    this.controllers.push(this.cameraCtrl)

    this.pickingManager = new PickingManager(activity)
    this.controllers.push(this.pickingManager)

    this.controllers.push(new RoomLevel(activity))
    this.controllers.push(new FogController(activity))
    this.controllers.push(new TutoController(activity))
    this.controllers.push(new VignetteController(activity))
    this.controllers.push(new ZoneEvents(activity))
  }

  start() {
    this.controllers.forEach(ctrl => ctrl.start())
  }

  reset() {
    this.controllers.forEach(ctrl => ctrl.reset())
  }
}