import { vec3 } from "gl-matrix";

import Ray from "@webgl/math/Ray";
import Time from "@webgl/Time";
import AppService from "@/services/AppService";
import Controller from "@webgl/activities/common/controllers/Controller";
import XRActivity from "../XRActivity";
import rayPointDistance from "@webgl/math/rayPointDistance";
import XRPointerLineRenderer from "@webgl/xr/pointers/XRPointerLineRenderer";
import { clamp } from "@webgl/math";
import { useDoors } from "@/services/stores/doors";
import { GameState } from "@/services/states/GameStateMachine";
import { HotspotId } from "@/services/states/GameContextDatas";
import { RenderContext } from "@webgl/core/Renderer";
import { Hotspot3D, use3DHotspots } from "@/services/stores/hotspots";

const HOTSPOT_PICK_RADIUS = .3

const { doors } = useDoors()

const isExit = (state: GameState) => {
  return state.matches('game.bedroom.exit_room') || state.matches('game.corridor.exit_room') || state.matches('game.studyroom.exit_room') || state.matches('game.living.exit_room')
}

export default class PickingManager extends Controller {
  ray: Ray
  rayDist = Infinity
  enabled = false
  activity: XRActivity
  lineOpacity = 0.1
  doorsEnabled = false
  lineRenderer: XRPointerLineRenderer
  find3DHotspot: (id: HotspotId) => Hotspot3D
  hotspotsEnabled = false

  get hotspotHovered() {
    return this.activity.gameStateWatcher.currentState.context.hotspotHovered
  }

  get doorHovered() {
    return this.activity.gameStateWatcher.currentState.context.doorHovered
  }

  get currentHotspots() {
    return this.activity.hotspots.currentHotspots
  }

  get currentDoors() {
    return doors;//.filter(door => door.states.some(state => this.activity.gameStateWatcher.currentState.matches(state)))
  }

  constructor(activity: XRActivity) {
    super(activity)

    const hotspots3D = use3DHotspots()
    this.find3DHotspot = hotspots3D.findHotspot

    this.lineRenderer = new XRPointerLineRenderer(this.activity.gl)
  }

  start() {
    super.start()
    this.enabled = true
  }

  stop() {
    super.stop()
    this.enabled = false
  }

  enableHotspots() {
    if (this.hotspotsEnabled) return
    this.hotspotsEnabled = true
  }

  disableHotspots() {
    if (!this.hotspotsEnabled) return
    this.hotspotsEnabled = false
    if (this.activity.gameStateWatcher.currentState.context.hotspotHovered !== -1) AppService.state.send({ type: 'GAME_HOTSPOT_HOVER', payload: -1 })
  }

  enableDoors() {
    if (this.doorsEnabled) return
    this.doorsEnabled = true
  }

  disableDoors() {
    if (!this.doorsEnabled) return
    this.doorsEnabled = false
    if (this.activity.gameStateWatcher.currentState.context.doorHovered !== -1) AppService.state.send({ type: 'GAME_DOOR_HOVER', payload: -1 })
  }

  raycastHotspots(ray: Ray) {
    let id = -1
    let distance = Infinity
    let rayDist = Infinity

    for (let i = 0; i < this.currentHotspots.length; i++) {
      const hotspot = this.currentHotspots[i]
      const pos = this.find3DHotspot(hotspot.id)

      if (pos !== undefined && pos.isVisible) {
        //if camera inside the hotspot disable hit
        if(vec3.distance(pos.position, ray.origin) < HOTSPOT_PICK_RADIUS) continue;

        const dist = rayPointDistance(ray, pos.position)

        if (dist < HOTSPOT_PICK_RADIUS && dist < distance) {
          id = hotspot.id
          distance = dist
          rayDist = vec3.distance(ray.origin, pos.position)
        }
      }
    }

    return [rayDist, id]
  }

  raycastDoors(ray: Ray) {
    let id = -1
    let rayDist = Infinity

    for (let i = 0; i < this.currentDoors.length; i++) {
      const door = this.currentDoors[i]
      const target = this.activity.room.pickingSources.getTarget(door.node)
      const dist = target.raycast(ray)

      if (dist < rayDist) {
        id = door.id
        rayDist = dist
      }
    }

    return [rayDist, id]
  }

  handleHotspots(ray: Ray) {
    const [rayDist, id] = this.raycastHotspots(ray)

    this.rayDist = rayDist

    return id
  }

  handleDoors(ray: Ray) {
    const [rayDist, id] = this.raycastDoors(ray)

    this.rayDist = rayDist

    return id
  }

  updateLineOpacity(hovering: boolean) {
    const factor =  hovering ? 1 : -1
    this.lineOpacity = clamp(this.lineOpacity + (Time.dt / .2) * factor, 0.1, 1)
  }

  preRender() {
    if (!this.enabled || this.activity.navigation.active) return

    const rightInput = this.activity.renderer.xrview.inputs.getInput('right')
    if (!rightInput) return

    this.ray = rightInput.ray
    this.rayDist = Infinity

    const prevHotspotHovered = this.hotspotHovered
    const prevDoorHovered = this.doorHovered

    let hotspotHovered = -1
    let doorHovered = -1


    if (this.hotspotsEnabled) {
      hotspotHovered = this.handleHotspots(this.ray)
    }

    if (this.doorsEnabled) {
      doorHovered = this.handleDoors(this.ray)

      if( this.hotspotsEnabled && hotspotHovered === -1){
        for (let i = 0; i < this.currentHotspots.length; i++) {
          const hotspot = this.currentHotspots[i]
          if( hotspot.linkedDoor === doorHovered){
            hotspotHovered = hotspot.id
          }
        }
      }
    }



    if (prevHotspotHovered !== hotspotHovered){
      AppService.state.send({ type: 'GAME_HOTSPOT_HOVER', payload: hotspotHovered })
    }

    if (prevDoorHovered !== doorHovered){
      AppService.state.send({ type: 'GAME_DOOR_HOVER', payload: doorHovered })
    }

    const isHovering = (doorHovered !== -1) || (hotspotHovered !== -1)
    this.updateLineOpacity(isHovering)
  }

  render(context: RenderContext) {
    if (!this.ray || !this.enabled || this.activity.navigation.active) return

    this.lineRenderer.prepare(context.camera)
    this.lineRenderer.draw(this.ray, this.lineOpacity, Math.min(5, this.rayDist))
  }

  stateChange(state: GameState) {
    if (state.context.isPaused) {
      this.disableHotspots()
      this.disableDoors()
      return
    }

    if (state.matches('intro')) {
      this.disableHotspots()
      this.disableDoors()
      return
    }

    if (state.matches('tuto.xr.door')) {
      this.disableHotspots()
      this.enableDoors()
      return
    }

    if (state.matches('tuto.xr.actions')) {
      this.enableHotspots()
      this.enableDoors()
      return
    }

    if (state.matches('tuto')) {
      this.disableHotspots()
      this.disableDoors()
      return
    }

    if (isExit(state)) {
      this.disableHotspots()
      this.disableDoors()
      return
    }

    if (state.matches('game')) {
      this.enableHotspots()
      this.enableDoors()
      return
    }

    if (state.matches('end')) {
      this.disableHotspots()
      this.disableDoors()
      return
    }
  }

  reset() {
    this.disableHotspots()
    this.disableDoors()
  }
}
