const frameToTime = (frame: number, fps = 30, output = 'second'): number => {
  let duration = 0
  switch (output) {
    case 'second' :
      duration = frame / fps
      break
  }

  return duration
}

// curry method to set default framerate
const setFPS = (fps: number) => (frame: number): number => frameToTime(frame, fps)

export {
  frameToTime as default,
  frameToTime,
  setFPS
}
