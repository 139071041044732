import { DoorId } from "../states/GameContextDatas"

export enum Temperature {
  HOT = 'hot',
  WARM = 'warm',
  SAFE = 'safe'
}

export type Door = {
  id: DoorId,
  node: string,
  states: string[],
  temperature: Temperature
}

export const DOORS: Door[] = [
  {
    id: DoorId.Guestroom_Bedroom,
    node: 'door_bedrooms',
    states: ['tuto.xr.door', 'tuto.xr.actions'],
    temperature: Temperature.SAFE
  },
  {
    id: DoorId.Bedroom_Corridor,
    node: 'door_bedroom_hall',
    states: ['game.bedroom.initial'],
    temperature: Temperature.SAFE
  },
  {
    id: DoorId.Corridor_Living,
    node: 'door_living_hall',
    states: ['game.corridor.initial'],
    temperature: Temperature.HOT
  },
  {
    id: DoorId.Corridor_Studyroom,
    node: 'door_study_hall',
    states: ['game.corridor.initial'],
    temperature: Temperature.SAFE
  },
  {
    id: DoorId.Studyroom_Living,
    node: 'door_study_living',
    states: ['game.studyroom.initial'],
    temperature: Temperature.SAFE
  },
  {
    id: DoorId.Living_Landing,
    node: 'door_exit',
    states: ['game.living.initial'],
    temperature: Temperature.SAFE
  },
  {
    id: DoorId.Elevator,
    node: 'door_elevator',
    states: ['game.landing.initial'],
    temperature: Temperature.SAFE
  },
  {
    id: DoorId.Stairs,
    node: 'door_stairs',
    states: ['game.landing.initial'],
    temperature: Temperature.SAFE
  },
]

const INDEX = Object.fromEntries(DOORS.map((d, i) => [d.id, i]))

const doors = DOORS

export function useDoors() {
  function findDoor(id: DoorId): Door {
    return doors[INDEX[id]] as Door
  }

  return {
    doors,
    findDoor
  }
}
