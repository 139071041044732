import gsap from 'gsap'
import Rect from "nanogl-primitives-2d/rect"
import Program from "nanogl/program"
import Texture2D from "nanogl/texture-2d"
import GltfLoader from "nanogl-gltf/lib/io/GltfLoader"
import GLState, { LocalConfig } from "nanogl-state/GLState"
import { GLContext } from "nanogl/types"
import { mat4, vec3 } from "gl-matrix"

import frag from './hotspot.frag'
import vert from './hotspot.vert'
import Time from '@webgl/Time'
import Renderer from "@webgl/Renderer"
import AppService from "@/services/AppService"
import RenderMask from "@webgl/core/RenderMask"
import XRActivity from "@webgl/activities/xr/XRActivity"
import MatrixUtils from "@webgl/math/MatrixUtils"
import WebglAssets from '@webgl/resources/WebglAssets'
import indicatorVert from './hotspotIndicator.vert'
import indicatorFrag from './hotspotIndicator.frag'
import DesktopActivity from "@webgl/activities/desktop/DesktopActivity"
import { LiveProgram } from "@webgl/core/LiveShader"
import { GltfModuleIO } from "@webgl/resources/GltfResource"
import { RenderContext } from "@webgl/core/Renderer"
import { TextureResource } from '@webgl/resources/TextureResource'
import { DoorId, getDoorStepPosition, getNextStepPosition, HotspotAudioId, HotspotId } from "@/services/states/GameContextDatas"
import { useHotspots, Hotspot, Hotspot3D, use3DHotspots, useScreenHotspots, HotspotAudio, useAudioHotspots } from "@/services/stores/hotspots"
import AudioManager, { AUDIO_ID } from '@/core/audio/AudioManager'
import Tracking from '@/core/Tracking'

const V = vec3.create()
const FORWARD = vec3.fromValues(0, 0, -1)
const DIRECTION = vec3.create()

/**
 * hotspots nodes
 */
export class Hotspots {
  hotspots: Hotspot[]
  hotspotsAudio: HotspotAudio[]
  findHotspot: (id: HotspotId) => Hotspot
  findHotspot3D: (id: HotspotId) => Hotspot3D
  updateHotspotMatrix: (id: HotspotId, matrix: mat4, position: vec3) => void
  updateHotspot3DVisible: (id: HotspotId, visible: boolean) => void
  updateHotspotAudioMatrix: (id: HotspotAudioId, matrix: mat4) => void

  get renderer(): Renderer {
    return this.activity.renderer
  }

  get state() {
    return this.activity.gameStateWatcher.currentState
  }

  get playerPosition() {
    return this.state.context.position
  }

  get playerRotation() {
    return this.state.context.rotation
  }

  get currentHotspots(): Hotspot[] {
    if (this.state.matches('tuto')
      && AppService.state.state.context.noMulti === false
      && this.state.context.advisedHotspots.length === 0) {
      return []
    }

    return this.hotspots.filter(hotspot => hotspot.states.some(state => this.state.matches(state)) && !this.state.context.hotspots.includes(hotspot.id))
  }

  constructor(readonly activity: XRActivity | DesktopActivity) {
    const hotspots = useHotspots()
    this.hotspots = hotspots.hotspots as Hotspot[]
    this.findHotspot = hotspots.findHotspot

    const hotspots3D = use3DHotspots()
    this.findHotspot3D = hotspots3D.findHotspot
    this.updateHotspotMatrix = hotspots3D.updateHotspotMatrix
    this.updateHotspot3DVisible = hotspots3D.updateHotspotVisible

    const hotspotsAudio = useAudioHotspots()
    this.hotspotsAudio = hotspotsAudio.hotspots as HotspotAudio[]
    this.updateHotspotAudioMatrix = hotspotsAudio.updateHotspotMatrix
  }

  async load(): Promise<void> {
    const gltf = await new GltfLoader(new GltfModuleIO(), "hotspots/scene.glb").load()
    gltf.nodes.forEach(n=>n.updateWorldMatrix())

/////////////////
/////////////////////////////////////////////////////////////
//////////////

    for (let i = 0; i < this.hotspots.length; i++) {
      const nodeId = this.hotspots[i].node
      const node = gltf.getNode(nodeId)
      if(!node) throw new Error(`Hotspot node ${nodeId} not found in gltf`)
      this.updateHotspotMatrix(this.hotspots[i].id, node._wmatrix, node._wposition)
    }

    for (let i = 0; i < this.hotspotsAudio.length; i++) {
      const nodeId = this.hotspotsAudio[i].node
      const node = gltf.getNode(nodeId)
      if(!node) throw new Error(`Hotspot node ${nodeId} not found in gltf`)
      this.updateHotspotAudioMatrix(this.hotspotsAudio[i].id, node._wmatrix)
    }
  }
}

// DESKTOP HOTSPOTS

export class HotspotsDesktop extends Hotspots {
  updateHotspotVisible: (id: HotspotId, visible: boolean) => void
  updateHotspotScreenPos: (id: HotspotId, x: number, y: number) => void

  get hotspotHovered() {
    return this.state.context.hotspotHovered
  }

  constructor(readonly activity: DesktopActivity) {
    super(activity)

    const hotspotsScreen = useScreenHotspots()
    this.updateHotspotVisible = hotspotsScreen.updateHotspotVisible
    this.updateHotspotScreenPos = hotspotsScreen.updateHotspotScreenPos
  }

  update(){
    const viewproj = this.renderer.camera._viewProj
    const w = this.renderer.glview.canvasWidth
    const h = this.renderer.glview.canvasHeight

    for (let i = 0; i < this.currentHotspots.length; i++) {
      const hotspot = this.currentHotspots[i]
      const hotspot3D = this.findHotspot3D(hotspot.id)

      const angle = Math.atan2(hotspot3D.position[2] - this.playerPosition.y, hotspot3D.position[0] - this.playerPosition.x);
      const a = Math.atan2(Math.sin(angle - this.playerRotation), Math.cos(angle - this.playerRotation));

      if (a < -0.65 || a > 0.65) {
        this.updateHotspotVisible(hotspot.id, false)
        continue
      }

      MatrixUtils.getTranslation(V, hotspot3D.matrix)
      vec3.transformMat4(V, V, viewproj)
      this.updateHotspotVisible(hotspot.id, true)
      this.updateHotspotScreenPos(hotspot.id,
        (V[0]*.5+.5)*w,
        (-V[1]*.5+.5)*h
      )
    }
  }


  handleClick() {

    const id = this.hotspotHovered

    if (id === -1) return
    const state = this.state
    const hotspot = this.findHotspot(id)
    const isDone = this.state.context.hotspots.includes(hotspot.id)
    const isExit = state.matches('tuto.xr.exit_room') || state.matches('game.bedroom.exit_room') || state.matches('game.corridor.exit_room') || state.matches('game.studyroom.exit_room') || state.matches('game.living.exit_room')

    if (isDone) return

    AudioManager.play(AUDIO_ID.UI_CLICK_Main)

    if (isExit) {
      const position = getNextStepPosition(this.state)
      AppService.state.send({ type: 'GAME_MOVE_PLAYER', payload: position})
      AppService.state.send({ type: 'GAME_ACTION_DONE' })
      Tracking.selectHotspotPlaceAction(hotspot.trackingData)
      return
    }
    
    if (hotspot.isDoor) {
      const position = getDoorStepPosition(this.state)
      AppService.state.send({ type: 'GAME_MOVE_PLAYER', payload: position})
      AppService.state.send({ type: 'GAME_ACTION_DONE' })
      Tracking.selectHotspotPlaceAction(hotspot.trackingData)
      return
    }

    AppService.state.send({ type: 'GAME_HOTSPOT_ACTION_DONE', payload: id })

  }





  // s

}

// XR HOTSPOTS

// Hotspot Renderer

const M4 = mat4.create()
const NORMALIZE_MAT = mat4.create()
const s = .3
mat4.rotateZ(NORMALIZE_MAT, NORMALIZE_MAT, Math.PI)
mat4.rotateY(NORMALIZE_MAT, NORMALIZE_MAT, Math.PI)
mat4.scale(NORMALIZE_MAT, NORMALIZE_MAT, vec3.fromValues(s, s, s))

class HotspotXRRenderer {
  hoverFactor = 0
  currentHover = false
  currentAdvised = false
  advisedInnerFactor = 0
  advisedOuterFactor = 0

  get isHover()  {
    return this.parent.hotspotHovered === this.hotspot.id
  }

  get isAdvised() {
    return this.state.context.advisedHotspots.includes(this.hotspot.id)
  }

  get state() {
    return this.parent.activity.gameStateWatcher.currentState
  }

  constructor(
    readonly parent: HotspotsXR, readonly hotspot: Hotspot,
    readonly hotspot3D: Hotspot3D, readonly icon: Texture2D) {
  }

  preRender() {
    if (this.isHover !== this.currentHover) {
      this.currentHover = this.isHover
      gsap.to(this, {
        hoverFactor: this.isHover ? 1 : 0,
        duration: 1,
        ease: 'Power1.easeInOut'
      })
    }

    if (this.isAdvised !== this.currentAdvised) {
      this.currentAdvised = this.isAdvised
      gsap.timeline()
        .to(this, {
          advisedInnerFactor: this.isAdvised ? 1 : 0,
          duration: 0.5,
          ease: 'Power1.easeIn'
        })
        .to(this, {
          advisedOuterFactor: this.isAdvised ? 1 : 0,
          duration: 0.5,
          ease: 'Power1.easeOut'
        })
    }

    this.updateVisible()
  }

  updateVisible() {
    const angle = Math.atan2(
      this.hotspot3D.position[2] - this.parent.playerPosition.y,
      this.hotspot3D.position[0] - this.parent.playerPosition.x
    )
    const a = Math.atan2(
      Math.sin(angle - this.parent.playerRotation),
      Math.cos(angle - this.parent.playerRotation)
    )

    this.parent.updateHotspot3DVisible(this.hotspot.id, a >= -1 && a <= 1)
  }

  render(ctx: RenderContext, prg: Program, quad: Rect) {
    mat4.mul(M4, this.hotspot3D.matrix, NORMALIZE_MAT)
    prg.uMVP(ctx.camera.getMVP(M4))
    prg.uHovered(this.hoverFactor)
    prg.uAdvised([this.advisedInnerFactor, this.advisedOuterFactor])
    prg.uOpacity(1.)
    prg.tTex(this.icon)

    quad.render()
  }
}

// Indicator Renderer

const INDICATOR_MAT = mat4.create()
const LEFT_INDICATOR_MAT = mat4.create()
const RIGHT_INDICATOR_MAT = mat4.create()
const indicatorScale = .72

mat4.translate(LEFT_INDICATOR_MAT, LEFT_INDICATOR_MAT, [-0.5, 0, -1])
mat4.rotateZ(LEFT_INDICATOR_MAT, LEFT_INDICATOR_MAT, Math.PI)
mat4.rotateY(LEFT_INDICATOR_MAT, LEFT_INDICATOR_MAT, Math.PI)

mat4.translate(RIGHT_INDICATOR_MAT, RIGHT_INDICATOR_MAT, [0.5, 0, -1])
mat4.rotateZ(RIGHT_INDICATOR_MAT, RIGHT_INDICATOR_MAT, Math.PI)
mat4.rotateY(RIGHT_INDICATOR_MAT, RIGHT_INDICATOR_MAT, Math.PI)

class IndicatorRenderer {
  time = 0
  loaded = false
  texture: TextureResource
  opacity = 0
  indicatorPrg: Program
  indicatorQuad = new Rect(this.gl)

  constructor(readonly activity: XRActivity, readonly gl: GLContext) {
    this.indicatorPrg = LiveProgram(gl, indicatorVert, indicatorFrag)
  }

  async load() {
    this.texture = WebglAssets.getTexture("xr/indicator.png", this.gl, {
      wrap: "clamp",
      smooth: true,
      alpha: true
    })

    await this.texture.load()

    this.onLoaded()
  }

  onLoaded() {
    this.loaded = true

    const scaleX = this.texture.texture.width / this.texture.texture.height
    mat4.scale(LEFT_INDICATOR_MAT, LEFT_INDICATOR_MAT, vec3.fromValues(indicatorScale * scaleX, indicatorScale, indicatorScale))
    mat4.scale(RIGHT_INDICATOR_MAT, RIGHT_INDICATOR_MAT, vec3.fromValues(indicatorScale * scaleX, indicatorScale, indicatorScale))
  }

  render(ctx: RenderContext, position: vec3) {
    if (!this.loaded) return

    this.time += Time.dt

    vec3.transformQuat(DIRECTION, FORWARD, ctx.camera.rotation)
    vec3.normalize(DIRECTION, DIRECTION)

    const angle1 = Math.atan2(position[2] - ctx.camera.position[2], position[0] - ctx.camera.position[0])
    const angle2 = Math.atan2(DIRECTION[2], DIRECTION[0])
    const angle = Math.atan2(Math.sin(angle1 - angle2), Math.cos(angle1 - angle2))

    this.opacity = angle >= -1 && angle <= 1
      ? Math.max(this.opacity - Time.dt * 1.5, 0.)
      : Math.min(this.opacity + Time.dt * 1.5, 1.)

    if (this.opacity === 0 || !this.activity.renderer.xrview.pose) return false

    mat4.mul(
      INDICATOR_MAT,
      (this.activity.renderer.xrview.pose.transform.matrix as mat4),
      angle < 0 ? LEFT_INDICATOR_MAT : RIGHT_INDICATOR_MAT
    )

    this.indicatorPrg.use()
    this.indicatorQuad.attribPointer(this.indicatorPrg)

    this.indicatorPrg.uMVP(ctx.camera.getMVP(INDICATOR_MAT))
    this.indicatorPrg.uReverse(angle < 0 ? 0. : 1.)
    this.indicatorPrg.uOpacity(this.opacity)
    this.indicatorPrg.uTime(this.time)
    this.indicatorPrg.tTex(this.texture.texture)

    this.indicatorQuad.render()

    return true
  }
}

// Hotspots

const SIZE = 512
const MARGIN = 2

/**
 * hotspots rendering for XR
 */
export class HotspotsXR extends Hotspots {
  cvs: HTMLCanvasElement
  prg: Program
  quad = new Rect(this.gl)
  ctx2d: CanvasRenderingContext2D
  loaded = false
  iconsImg: { name: string, img: HTMLImageElement }[] = []
  glconfig: LocalConfig
  indicator: IndicatorRenderer
  prevAdvisedHotspot: Hotspot = null

  private _icons = new Map<string, Texture2D>()
  private _hotspotsRenderers = new Map<HotspotId, HotspotXRRenderer>()

  get isExitState() {
    return this.state.matches('tuto.xr.exit_room') || this.state.matches('game.bedroom.exit_room') || this.state.matches('game.corridor.exit_room') || this.state.matches('game.studyroom.exit_room') || this.state.matches('game.living.exit_room')
  }

  get lastAdvisedHotspot() {
    const advised = this.state.context.advisedHotspots
    return advised[advised.length - 1]
  }

  get doorHovered(): DoorId | -1 {
    return this.state.context.doorHovered
  }

  get hotspotHovered() {
    return this.state.context.hotspotHovered
  }

  get currentAdvisedHotspot(): Hotspot {
    return this.currentHotspots.find(hotspot => hotspot.id === this.lastAdvisedHotspot)
  }

  constructor(readonly gl : GLContext, readonly activity: XRActivity) {
    super(activity)

    this.prg = LiveProgram(gl, vert, frag)

    this.glconfig = GLState.get(gl).config()
      .depthMask(false)
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    this.indicator = new IndicatorRenderer(this.activity, this.gl)

    this.cvs = document.createElement('canvas')
    this.cvs.width = SIZE
    this.cvs.height = SIZE
    this.ctx2d = this.cvs.getContext('2d')
    this.ctx2d.fillStyle='#fff'
  }

  loadIcon (s:string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = require(`@/assets/raw-icons/${s}.svg`)

      const img = new Image()
      img.src = url
      this.iconsImg.push({ name: s, img })

      img.onload = () => {
        this.ctx2d.clearRect(0,0, SIZE, SIZE)
        this.ctx2d.globalCompositeOperation = 'copy'
        const ratio = img.width / img.height
        const size = SIZE - MARGIN * 2
        if(ratio >= 1){
          const h = size / ratio
          this.ctx2d.drawImage(img, MARGIN, MARGIN + (size - h) / 2, size, h);
        }else{
          const w = size * ratio
          this.ctx2d.drawImage(img, MARGIN + (size - w) / 2, MARGIN, w, size);
        }

        this.ctx2d.globalCompositeOperation = 'source-in'
        this.ctx2d.fillRect(0,0, SIZE, SIZE)

        const tex = new Texture2D(this.gl, this.gl.LUMINANCE)
        tex.clamp()
        tex.fromImage(this.cvs)

        this._icons.set(s, tex)

        resolve()
      }

      img.onerror = reject
    })
  }

  loadIcons(): Promise<void>[] {
    return this.hotspots.flatMap(hotspot => {
      return this._icons.has(hotspot.icon) ? [] : this.loadIcon(hotspot.icon)
    })
  }

  loadXR(): Promise<void> {
    return Promise.all([
      this.load(),
      this.indicator.load(),
      ...this.loadIcons(),
    ]).then(() => this.onLoaded())
  }

  onLoaded() {
    for (let i = 0; i < this.hotspots.length; i++) {
      const hotspot = this.hotspots[i]
      this._hotspotsRenderers.set(hotspot.id, new HotspotXRRenderer(
        this,
        hotspot,
        this.findHotspot3D(hotspot.id),
        this._icons.get(hotspot.icon)
      ))
    }

    this.loaded = true
  }

  handleClick() {

    const id = this.hotspotHovered

    if (id === -1) return


    const hotspot = this.findHotspot(id)
    if (hotspot.isDoor) {
      const position = getDoorStepPosition(this.state)
      AppService.state.send({ type: 'GAME_MOVE_PLAYER', payload: position})
      console.log('hotspot.handleClick isDoor')
      AppService.state.send({ type: 'GAME_ACTION_DONE' })
      return
    }

    AppService.state.send({ type: 'GAME_HOTSPOT_ACTION_DONE', payload: id })

  }

  preRender() {
    for(let i = 0; i < this.currentHotspots.length; i++){
      const hotspot = this.currentHotspots[i]
      const hotspotRenderer = this._hotspotsRenderers.get(hotspot.id)
      hotspotRenderer.preRender()
    }
  }

  render(ctx: RenderContext){
    if (!this.loaded) return
    if ((ctx.mask & RenderMask.BLENDED) === 0) return
    if (this.currentHotspots.length === 0) return

    this.prg.use()
    this.glconfig.apply()

    this.quad.attribPointer(this.prg)

    for(let i = 0; i < this.currentHotspots.length; i++){
      const hotspot = this.currentHotspots[i]
      const hotspotRenderer = this._hotspotsRenderers.get(hotspot.id)
      hotspotRenderer.render(ctx, this.prg, this.quad)
    }

    if (this.currentAdvisedHotspot) {
      const hotspotRenderer = this._hotspotsRenderers.get(this.currentAdvisedHotspot.id)
      const visible = this.indicator.render(ctx, hotspotRenderer.hotspot3D.position)

      if (this.prevAdvisedHotspot !== this.currentAdvisedHotspot && visible) {
        AudioManager.play(AUDIO_ID.UI_POP_IN)
      }
    }

    this.prevAdvisedHotspot = this.currentAdvisedHotspot
  }
}