import gsap from 'gsap'
import Node from "nanogl-node"
import Rect from "nanogl-primitives-2d/rect"
import Program from "nanogl/program"
import GLState, { LocalConfig } from "nanogl-state/GLState"
import { mat4 } from "gl-matrix"
import { GLContext } from "nanogl/types"

import vert from "./intro.vert"
import frag from "./intro.frag"
import WebglAssets from "@webgl/resources/WebglAssets"
import { LiveProgram } from "@webgl/core/LiveShader"
import { RenderContext } from "@webgl/core/Renderer"
import { TextureResource } from "@webgl/resources/TextureResource"
import { DefaultGamePlayerPosition } from "@/services/states/GameContextDatas"

const defaultPos = DefaultGamePlayerPosition()

const STEPS_NUMBER = 3

export default class Intro {
  prg: Program
  node: Node
  step = 0
  quad = new Rect(this.gl)
  loaded = false
  matrix = mat4.create()
  texRes: TextureResource[] = []
  showing = false
  opacity = 0
  glconfig: LocalConfig
  blankFactor = 0

  constructor(private gl: GLContext) {
    this.prg = LiveProgram(gl, vert, frag)
    this.glconfig = GLState.get(gl).config()
      .depthMask(false)
      .enableDepthTest(false)
      .enableBlend(true)
      .blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

    for (let i = 0; i < STEPS_NUMBER; i++) {
      const tex = WebglAssets.getTexture(`xr/interfaces/intro_${i}.png`, this.gl, {
        wrap: "clamp",
        smooth: true,
        alpha: true
      })
      this.texRes.push(tex)
    }

    const tex = WebglAssets.getTexture(`xr/interfaces/intro_blank.png`, this.gl, {
      wrap: "clamp",
      smooth: true,
      alpha: true
    })
    this.texRes.push(tex)
  }

  setupNode() {
    this.node = new Node()

    this.node.position.set([defaultPos.x, 1.25, defaultPos.y + 1.5])

    const width = 0.8
    const ratio = this.texRes[0].texture.height / this.texRes[0].texture.width * width
    this.node.scale.set([width, ratio, 1])

    this.node.updateWorldMatrix()

    mat4.copy(this.matrix, this.node._wmatrix)
  }

  async load() {
    await Promise.all(this.texRes.map(tex => tex.load()))
    this.onLoaded()
  }

  onLoaded() {
    this.setupNode()
    this.loaded = true
  }

  show() {
    this.showing = true

    gsap.to(this, {
      opacity: 1,
      duration: 0.5,
      ease: "power2.out"
    })
  }

  hide() {
    gsap.killTweensOf(this, 'blankFactor')

    gsap.to(this, {
      opacity: 0,
      duration: 1,
      ease: "power2.out",
      onComplete: () => {
        this.showing = false
      }
    })
  }

  hideNow() {
    gsap.killTweensOf(this, 'blankFactor')
    this.opacity = 0
    this.showing = false
    this.step = 0
  }

  nextStep() {
    if (this.step >= STEPS_NUMBER - 1) return

    gsap.timeline()
      .to(this, {
        blankFactor: 1,
        duration: 0.5,
        ease: "power1.in",
        onComplete: () => {
          this.step++
        }
      })
      .to(this, {
        blankFactor: 0,
        duration: 0.5,
        ease: "power1.out",
      })
  }

  render(context: RenderContext){
    if (!this.loaded || !this.showing) return

    this.prg.use()
    this.glconfig.apply()
    this.quad.attribPointer(this.prg)

    this.prg.uMVP(context.camera.getMVP(this.matrix))

    this.prg.uStep(this.step)
    this.prg.uBlank(this.blankFactor)
    this.prg.uOpacity(this.opacity)

    this.prg.tTex0(this.texRes[0].texture)
    this.prg.tTex1(this.texRes[1].texture)
    this.prg.tTex2(this.texRes[2].texture)
    this.prg.tTexBlank(this.texRes[3].texture)

    this.quad.render()
  }
}