var fn = function( obj ){
var __t,__p='';
__p+='precision highp float;\n\nvarying vec2 vPos;\nvarying vec4 vNDC;\nvarying vec3 vWorldPos;\nvarying float vAlpha;\nuniform vec3 uColor;\nuniform vec3 uGlowColor;\n\n#define additive 0\n\n'+
( require( "../fog/fog-inc.frag" )() )+
'\n\nvoid main() {\n    float len = length(vPos);\n    float a = 1.0 - smoothstep(0.25, 0.5, len);\n    float a2 = smoothstep(0.0, 0.4, len);\n    vec3 color = mix(uColor, uGlowColor, a2);\n    color *= color;\n\n    // push the higher values around alpha to create some reddish glow\n//    color.x = pow(uColor.x, 1.0/a);\n//    color.y = pow(uColor.y, 1.0/a);\n//    color.z = pow(uColor.z, 1.0/a);\n    gl_FragColor = vec4(color, a * vAlpha);\n\n    gl_FragColor = applyFog(gl_FragColor, vWorldPos, vNDC, vec3(0.0), vec3(0.0));\n    gl_FragColor.rgb = sqrt(gl_FragColor.rgb);\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
