import { GLContext } from "nanogl/types";
import Fbo from "nanogl/fbo";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import { RenderContext } from "@webgl/core/Renderer";
import RenderPass from "@webgl/core/RenderPass";
import { Renderable } from "@webgl/fog/FogVFX";

const FBO_SIZE = 1024;

export default class VolLightDepth
{
    private fbo: Fbo;
    private width: number;
    private height: number;
    private renderables: Renderable[] = [];
    private occluders: Renderable[] = [];
    private sourceCfg: LocalConfig;
    private occluderCfg: LocalConfig;

    constructor(private gl: GLContext)
    {
        this.fbo = new Fbo(gl);
        this.fbo.bind();
        this.fbo.attachColor(gl.RGB);
        this.fbo.attachDepth(true, false, false);
        this.fbo.resize(FBO_SIZE, FBO_SIZE)
        this.fbo.getColorTexture().wrap(gl.CLAMP_TO_EDGE);

        this.sourceCfg = GLState.get(gl).config()
            .enableCullface(true)
            .enableDepthTest(true)
            .depthMask(true)
            .depthFunc(gl.GREATER)
            .cullFace(gl.FRONT);

        this.occluderCfg = GLState.get(gl).config()
            .enableCullface(true)
            .enableDepthTest(true)
            .depthMask(true)
            .depthFunc(gl.LESS)
            .cullFace(gl.BACK);
    }

    get texture()
    {
        return this.fbo.getColorTexture();
    }

    addSource(obj: Renderable)
    {
        this.renderables.push(obj);
    }

    addOccluder(obj: Renderable)
    {
        this.occluders.push(obj);
    }

    preRender()
    {
        // empty
        this.width = FBO_SIZE;
        this.height = Math.floor(FBO_SIZE / window.innerWidth * window.innerHeight);
        this.fbo.resize(this.width, this.height);
    }

    bind()
    {
        const gl = this.gl
        this.fbo.bind()
        GLState.get(gl).apply()// ensure depthMask true
        this.fbo.defaultViewport()
        gl.clearColor(0, 0, 0, 1);
        gl.clearDepth(0);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
        gl.clearDepth(1);
    }

    render(ctx: RenderContext, eye?: string)
    {
        const depthCtx = {
            ...ctx,
            pass: RenderPass.DEPTH
        };

        const gl = this.gl
        const fbo = this.fbo
        const w = fbo.width >> 1;

        if (eye === "left")
            gl.viewport(0, 0, w, fbo.height);
        else if (eye === "right")
            gl.viewport(w, 0, w, fbo.height);


        const camFwdX = ctx.camera._wmatrix[8];
        const camFwdY = ctx.camera._wmatrix[9];
        const camFwdZ = ctx.camera._wmatrix[10];
        depthCtx.glConfig = this.sourceCfg;

        this.renderables.forEach(obj => {
            const m = obj.worldMatrix;
            const dot = camFwdX * m[8] + camFwdY * m[9] + camFwdZ * m[10];
            this.sourceCfg.cullFace(dot < 0.0? gl.BACK : gl.FRONT);

            obj.render(depthCtx);
        });

        depthCtx.glConfig = this.occluderCfg;
        this.occluders.forEach(obj => obj.render(depthCtx));
    }
}
