import Controller from "./Controller"
import XRActivity from "@webgl/activities/xr/XRActivity";
import DesktopActivity from "@webgl/activities/desktop/DesktopActivity";
import { GameState } from "@/services/states/GameStateMachine";
import { useHotspots } from "@/services/stores/hotspots";

const { findHotspot } = useHotspots()

export default class VignetteController extends Controller {
  constructor(activity: XRActivity | DesktopActivity) {
    super(activity)
  }

  async stateChange(state: GameState) {
    if (state.event.type === 'GAME_HOTSPOT_ACTION_DONE') {
      const hotspot = findHotspot(state.event.payload)
      if (hotspot.points >= 0) {
        this.activity.vignette.hide()
        return
      }

      this.activity.vignette.showTemp()
    }
  }

  reset() {
    this.activity.vignette.reset()
  }
}
