import { reactive } from "vue"
import { mat4, vec3 } from "gl-matrix"

import { DoorId, HotspotAudioId, HotspotId } from "../states/GameContextDatas"

export type Hotspot = {
  id: HotspotId,
  trackingData: string,
  node: string,
  states: string[],
  name: string,
  icon: string,
  points: number,
  isDoor?: boolean
  isEnd?: boolean
  mustCrouch?: boolean

  /**
   * click on this door will trigger the hotspot action
   */
  linkedDoor?: DoorId

}

export type Hotspot3D = {
  id: HotspotId,
  matrix: mat4,
  position: vec3,
  isVisible?: boolean
}

export type Hotspot2D = {
  id: HotspotId,
  position: number[],
  isVisible?: boolean
}

export type HotspotAudio = {
  id: HotspotAudioId,
  node: string,
  matrix: mat4,
}

export const HOTSPOTS: Hotspot[] = [
  {
    id: HotspotId.Step0_Door,
    node: 'tag_DoorTuto',
    trackingData: '[guestroom,open_door]',
    states: ['tuto.xr.door', 'tuto.xr.actions'],
    name: 'open_door',
    icon: 'open-door',
    points: 0,
    isDoor: true,
    linkedDoor: DoorId.Guestroom_Bedroom
  },
  // {
  //   id: HotspotId.Step0_Continue,
  //   node: 'tag_DoorTuto',
  //   states: ['tuto.xr.exit_room'],
  //   name: 'start_escape',
  //   icon: 'move-away',
  //   points: 0,
  // },
  {
    id: HotspotId.Step1_CloseDoor,
    node: 'tag_DoorTuto',
    trackingData: '[bedroom,close_door]',
    states: ['game.bedroom.initial'],
    name: 'close_door',
    icon: 'close-door',
    points: 10,
    isEnd: true,
    linkedDoor: DoorId.Guestroom_Bedroom
  },
  {
    id: HotspotId.Step1_Window,
    node: 'tag_1_Window',
    trackingData: '[bedroom,open_window]',
    states: ['game.bedroom.initial'],
    name: 'open_window',
    icon: 'open-window',
    points: -10,
    isEnd: true
  },
  {
    id: HotspotId.Step1_Door,
    node: 'tag_1_LeaveRoomHall',
    trackingData: '[bedroom,open_door]',
    states: ['game.bedroom.initial'],
    name: 'open_door',
    icon: 'open-door',
    points: 0,
    isDoor: true,
    linkedDoor: DoorId.Bedroom_Corridor
  },
  // {
  //   id: HotspotId.Step1_Continue,
  //   node: 'tag_1_LeaveRoomHall',
  //   states: ['game.bedroom.exit_room'],
  //   name: 'exit_room',
  //   icon: 'move-away',
  //   points: 0,
  // },
  {
    id: HotspotId.Step2_CloseDoor,
    node: 'tag_2_CloseDoor',
    trackingData: '[corridor,close_door]',
    states: ['game.corridor.initial'],
    name: 'close_door',
    icon: 'close-door',
    points: 10,
    isEnd: true,
    mustCrouch: true,
    linkedDoor: DoorId.Bedroom_Corridor
  },
  {
    id: HotspotId.Step2_OpenDoor,
    node: 'tag_2_LeaveRoomLiving',
    trackingData: '[corridor,open_door]',
    states: ['game.corridor.initial'],
    name: 'open_door',
    icon: 'open-door',
    points: -10,
    isEnd: true,
    mustCrouch: true,
    linkedDoor: DoorId.Corridor_Living
  },
  // {
  //   id: HotspotId.Step2_Crouch,
  //   node: 'tag_2_Crouch',
  //   states: ['game.corridor.initial'],
  //   name: 'crouch',
  //   icon: 'crouch',
  //   points: 10,
  // },
  {
    id: HotspotId.Step2_Door,
    node: 'tag_2_LeaveRoomStudy',
    trackingData: '[corridor,open_door]',
    states: ['game.corridor.initial'],
    name: 'open_door',
    icon: 'open-door',
    points: 0,
    isDoor: true,
    mustCrouch: true,
    linkedDoor: DoorId.Corridor_Studyroom
  },
  // {
  //   id: HotspotId.Step2_Continue,
  //   node: 'tag_2_LeaveRoomStudy',
  //   states: ['game.corridor.exit_room'],
  //   name: 'exit_room',
  //   icon: 'move-away',
  //   points: 0,
  // },
  {
    id: HotspotId.Step3_CloseDoor,
    node: 'tag_3_CloseDoor',
    trackingData: '[study,close_door]',
    states: ['game.studyroom.initial'],
    name: 'close_door',
    icon: 'close-door',
    points: 10,
    isEnd: true,
    linkedDoor: DoorId.Corridor_Studyroom
  },
  {
    id: HotspotId.Step3_Window,
    node: 'tag_3_Window',
    trackingData: '[study,open_window]',
    states: ['game.studyroom.initial'],
    name: 'open_window',
    icon: 'open-window',
    points: -10,
    isEnd: true,
  },
  {
    id: HotspotId.Step3_Bag,
    node: 'tag_3_Bag',
    trackingData: '[study,open_bag]',
    states: ['game.studyroom.initial'],
    name: 'bag',
    icon: 'packing-stuff',
    points: -10,
    isEnd: true,
  },
  {
    id: HotspotId.Step3_Door,
    node: 'tag_3_LeaveRoomLiving',
    trackingData: '[study,open_door]',
    states: ['game.studyroom.initial'],
    name: 'open_door',
    icon: 'open-door',
    points: 0,
    isDoor: true,
    linkedDoor: DoorId.Studyroom_Living
  },
  // {
  //   id: HotspotId.Step3_Continue,
  //   node: 'tag_3_LeaveRoomLiving',
  //   states: ['game.studyroom.exit_room'],
  //   name: 'exit_room',
  //   icon: 'move-away',
  //   points: 0,
  // },
  {
    id: HotspotId.Step4_CloseDoor,
    node: 'tag_4_CloseDoor',
    trackingData: '[living,close_door]',
    states: ['game.living.initial'],
    name: 'close_door',
    icon: 'close-door',
    points: 10,
    isEnd: true,
    linkedDoor: DoorId.Studyroom_Living
  },
  {
    id: HotspotId.Step4_Call911,
    node: 'tag_4_Call911',
    trackingData: '[living,call_911]',
    states: ['game.living.initial'],
    name: 'call_911',
    icon: 'call-911',
    points: -10,
    isEnd: true
  },
  {
    id: HotspotId.Step4_WetTowel,
    node: 'tag_4_WetTowel',
    trackingData: '[living,wet_towel]',
    states: ['game.living.initial'],
    name: 'wet_towel',
    icon: 'wet-towel',
    points: 10,
    isEnd: true
  },
  {
    id: HotspotId.Step4_Door,
    node: 'tag_4_LeaveRoomLanding',
    trackingData: '[living,open_door]',
    states: ['game.living.initial'],
    name: 'open_door',
    icon: 'open-door',
    points: 0,
    isDoor: true,
    linkedDoor: DoorId.Living_Landing
  },
  // {
  //   id: HotspotId.Step4_Continue,
  //   node: 'tag_4_LeaveRoomLanding',
  //   states: ['game.living.exit_room'],
  //   name: 'exit_room',
  //   icon: 'move-away',
  //   points: 0,
  // },
  {
    id: HotspotId.Step5_Lift,
    node: 'tag_5_Lift',
    trackingData: '[landing,lift]',
    states: ['game.landing.initial'],
    name: 'lift',
    icon: 'elevator',
    points: -10,
    isEnd: true,
    linkedDoor: DoorId.Elevator

  },
  {
    id: HotspotId.Step5_Stairs,
    node: 'tag_5_Stairs',
    trackingData: '[landing,stairs]',
    states: ['game.landing.initial'],
    name: 'stairs',
    icon: 'take-stairs',
    points: 0,
    isEnd: true,
    linkedDoor: DoorId.Stairs
  },
]

const INDEX = Object.fromEntries(HOTSPOTS.map((h, i) => [h.id, i]))

const HOTSPOTS_3D_POSITIONS = HOTSPOTS.map(hotspot => ({
  id: hotspot.id,
  matrix: mat4.create(),
  position: vec3.create()
}))

const HOTSPOTS_SCREEN_POSITIONS = HOTSPOTS.map(hotspot => ({
  id: hotspot.id,
  position: [0, 0],
  isVisible: false
}))

const HOTSPOTS_MAP_POSITIONS = [
  {
    id: HotspotId.Step0_Door,
    position: [0.5, 0.85]
  },
  // {
  //   id: HotspotId.Step0_Continue,
  //   position: [0.4, 0.9]
  // },
  {
    id: HotspotId.Step1_CloseDoor,
    position: [0.5, 0.85]
  },
  {
    id: HotspotId.Step1_Window,
    position: [0.8, 1]
  },
  {
    id: HotspotId.Step1_Door,
    position: [0.6, 0.725]
  },
  // {
  //   id: HotspotId.Step1_Continue,
  //   position: [0.5, 0.8]
  // },
  {
    id: HotspotId.Step2_CloseDoor,
    position: [0.6, 0.725]
  },
  {
    id: HotspotId.Step2_OpenDoor,
    position: [0.5, 0.6]
  },
  // {
  //   id: HotspotId.Step2_Crouch,
  //   position: [0.5, 0.4]
  // },
  {
    id: HotspotId.Step2_Door,
    position: [0.65, 0.35]
  },
  // {
  //   id: HotspotId.Step2_Continue,
  //   position: [0.6, 0.35]
  // },
  {
    id: HotspotId.Step3_CloseDoor,
    position: [0.675, 0.35]
  },
  {
    id: HotspotId.Step3_Window,
    position: [1, 0.325]
  },
  {
    id: HotspotId.Step3_Bag,
    position: [0.9, 0.425]
  },
  {
    id: HotspotId.Step3_Door,
    position: [0.85, 0.225]
  },
  // {
  //   id: HotspotId.Step3_Continue,
  //   position: [0.9, 0.2]
  // },
  {
    id: HotspotId.Step4_CloseDoor,
    position: [0.85, 0.225]
  },
  {
    id: HotspotId.Step4_Call911,
    position: [0.225, 0.2]
  },
  {
    id: HotspotId.Step4_WetTowel,
    position: [0.825, 0]
  },
  {
    id: HotspotId.Step4_Door,
    position: [0.35, 0]
  },
  // {
  //   id: HotspotId.Step4_Continue,
  //   position: [0.3, 0]
  // },
  {
    id: HotspotId.Step5_Lift,
    position: [0.175, -0.175]
  },
  {
    id: HotspotId.Step5_Stairs,
    position: [0.775, -0.175]
  }
]

const HOTSPOTS_MINIMAP_POSITIONS = [
  {
    id: HotspotId.Step0_Door,
    position: [0.475, 0.86]
  },
  {
    id: HotspotId.Step1_CloseDoor,
    position: [0.475, 0.86]
  },
  {
    id: HotspotId.Step1_Window,
    position: [0.75, 1]
  },
  {
    id: HotspotId.Step1_Door,
    position: [0.6, 0.725]
  },
  {
    id: HotspotId.Step2_CloseDoor,
    position: [0.6, 0.725]
  },
  {
    id: HotspotId.Step2_OpenDoor,
    position: [0.475, 0.625]
  },
  {
    id: HotspotId.Step2_Door,
    position: [0.725, 0.325]
  },
  {
    id: HotspotId.Step3_CloseDoor,
    position: [0.725, 0.325]
  },
  {
    id: HotspotId.Step3_Window,
    position: [1, 0.25]
  },
  {
    id: HotspotId.Step3_Bag,
    position: [0.9, 0.4]
  },
  {
    id: HotspotId.Step3_Door,
    position: [0.85, 0.2]
  },
  {
    id: HotspotId.Step4_CloseDoor,
    position: [0.85, 0.2]
  },
  {
    id: HotspotId.Step4_Call911,
    position: [0.2, 0.175]
  },
  {
    id: HotspotId.Step4_WetTowel,
    position: [0.825, 0]
  },
  {
    id: HotspotId.Step4_Door,
    position: [0.4, 0]
  },
  {
    id: HotspotId.Step5_Lift,
    position: [0.2, -0.35]
  },
  {
    id: HotspotId.Step5_Stairs,
    position: [0.65, -0.35]
  }
]

export const HOTSPOTS_AUDIO = [
  {
    id: HotspotAudioId.Fire,
    node: 'audio_Fire_Guestroom',
    matrix: mat4.create()
  },
  {
    id: HotspotAudioId.Alarm1,
    node: 'audio_Alarm_1',
    matrix: mat4.create()
  },
  {
    id: HotspotAudioId.Alarm2,
    node: 'audio_Alarm_2',
    matrix: mat4.create()
  },
  {
    id: HotspotAudioId.Cat,
    node: 'audio_Cat',
    matrix: mat4.create()
  },
  {
    id: HotspotAudioId.Peace,
    node: 'audio_Peace',
    matrix: mat4.create()
  }
]

const INDEX_AUDIO = Object.fromEntries(HOTSPOTS_AUDIO.map((h, i) => [h.id, i]))

const hotspots = HOTSPOTS
const hotspots3DPositions = reactive<Hotspot3D[]>(HOTSPOTS_3D_POSITIONS)
const hotspotsScreenPositions = reactive<Hotspot2D[]>(HOTSPOTS_SCREEN_POSITIONS)
const hotspotsMapPositions = HOTSPOTS_MAP_POSITIONS
const hotspotsMiniMapPositions = HOTSPOTS_MINIMAP_POSITIONS
const hotspotsAudio = reactive<HotspotAudio[]>(HOTSPOTS_AUDIO)

export function useHotspots() {
  function findHotspot(id: HotspotId): Hotspot {
    return hotspots[INDEX[id]] as Hotspot
  }

  return {
    hotspots,
    findHotspot
  }
}

export function use3DHotspots() {
  function findHotspot(id: HotspotId): Hotspot3D {
    return hotspots3DPositions[INDEX[id]] as Hotspot3D
  }
  // update all hotspots matrix
  function updateHotspotMatrix(id: HotspotId, matrix: mat4, position: vec3) {
    const h = findHotspot(id)
    h.matrix = matrix
    h.position = position
  }

  // update hotspot visible
  function updateHotspotVisible(id: HotspotId, visible: boolean) {
    findHotspot(id).isVisible = visible
  }

  return {
    hotspots: hotspots3DPositions,
    findHotspot,
    updateHotspotMatrix,
    updateHotspotVisible
  }
}

export function useScreenHotspots() {
  function findHotspot(id: HotspotId): Hotspot2D {
    return hotspotsScreenPositions[INDEX[id]] as Hotspot2D
  }

  // update all hotspots projected coords
  function updateHotspotScreenPos(id: HotspotId, x: number, y:number) {
    findHotspot(id).position = [x, y]
  }

  // update hotspot visible
  function updateHotspotVisible(id: HotspotId, visible: boolean) {
    findHotspot(id).isVisible = visible
  }

  return {
    hotspots: hotspotsScreenPositions,
    findHotspot,
    updateHotspotVisible,
    updateHotspotScreenPos
  }
}

export function useMapHotspots() {
  function findHotspot(id: HotspotId): Hotspot2D {
    return hotspotsMapPositions[INDEX[id]] as Hotspot2D
  }

  return {
    hotspots: hotspotsMapPositions,
    findHotspot
  }
}

export function useMiniMapHotspots() {
  function findHotspot(id: HotspotId): Hotspot2D {
    return hotspotsMiniMapPositions[INDEX[id]] as Hotspot2D
  }

  return {
    hotspots: hotspotsMiniMapPositions,
    findHotspot
  }
}

export function useAudioHotspots() {
  function findHotspot(id: HotspotAudioId): HotspotAudio {
    return hotspotsAudio[INDEX_AUDIO[id]] as HotspotAudio
  }

  // update all hotspots matrix
  function updateHotspotMatrix(id: HotspotAudioId, matrix: mat4) {
    const h = findHotspot(id)
    h.matrix = matrix
  }

  return {
    hotspots: hotspotsAudio,
    findHotspot,
    updateHotspotMatrix
  }
}