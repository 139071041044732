
import vShader from "./volLight.vert"
import fShader from "./volLight.frag"
import LiveShader from "@webgl/core/LiveShader";
import UnlitPass from "nanogl-pbr/UnlitPass";
import Camera from "nanogl-camera";
import Program from "nanogl/program";
import Node from "nanogl-node";
import FogProps from "@webgl/fog/FogProps";
import VolLightDepth from "@webgl/fog/VolLightDepth";
import { mat4 } from "gl-matrix";

const m = mat4.create();

/**
 * create Hot-reloadable shaders
 */
const FragCode = LiveShader(fShader)


export class VolLightPass extends UnlitPass {
    isBackward = false;

    constructor( private fogProps: FogProps, private volLightDepth: VolLightDepth, name?: string ){
        super(name)

        this._updateCode();

        // update pass shader code when glsl update
        // could add vertex shader also, but here we use one from nanogl-pbr
        // noop in non DEBUG mode
        FragCode.onHmr(()=>this._updateCode());
    }

    /**
     * in release mode, this is called once, in the ctor
     * in DEBUG, it can be called each time the fragment shader is edited
     * in this case, the pass need to be invalidated to force recompilation
     */
    private _updateCode( ){
        this._shaderSource.vert = vShader()
        this._shaderSource.frag = FragCode()


/////////////////////
////////////////////////////////////
/////////////////////////////////////////////////////////
//////////////////
    }

    prepare(prg: Program, node: Node, camera: Camera)
    {
        super.prepare(prg, node, camera);
        this.fogProps.applyToProgram(prg, camera);
        prg.tDepth(this.volLightDepth.texture);
        mat4.invert(m, camera._viewProj);
        prg.uUnprojectMatrix(m);
        prg.uIsBackward(this.isBackward ? 1.0 : 0.0);
    }
}
