import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import { mat4, vec3 } from "gl-matrix";
import { XREye } from "webxr";
import { RenderContext } from "@webgl/core/Renderer";
import XRView from "./XRView";
import Viewport from "@webgl/core/Viewport";
import RenderPass from "@webgl/core/RenderPass";
import MatrixUtils from "@webgl/math/MatrixUtils";

const _elDIR = vec3.create()
export default class XRHeadset {

  camera = new Camera(new PerspectiveLens());
  
  // forward = vec3.create();
  // wposition: vec3 = vec3.create();
  // wrotation: quat = quat.create();
  
  private _viewport = new Viewport()

  constructor(
    public readonly xrview: XRView
  ) {}



  
  /**
   * 
   * @returns return local hoizontal head rotation in referenceSpace
   */
  getHorizontalRotation(): number {
    const pose = this.xrview.refPose;
    if( !pose ) return 0;
    const m = pose.transform.matrix as mat4;
    MatrixUtils.getZaxis( _elDIR, m )
    return Math.atan2( _elDIR[0], _elDIR[2] )
  }
  
  getEyeContext( eye : XREye ) : RenderContext {
    const pose = this.xrview.pose;
    const layer = this.xrview.session.renderState.baseLayer;
    
    const view = pose.views.find( v=>v.eye === eye )
    if( !view ) return null;

    this.camera.setMatrix(view.transform.matrix as mat4);
    this.camera.lens._proj.set( view.projectionMatrix as mat4 );
    this.camera.lens._valid = true
    this.camera.updateWorldMatrix()
    this.camera.updateViewProjectionMatrix(1, 1);

    const xrvp = layer.getViewport(view);
    this._viewport.x = xrvp.x
    this._viewport.y = xrvp.y
    this._viewport.width = xrvp.width
    this._viewport.height = xrvp.height

    return {
      camera: this.camera,
      viewport: this._viewport,
      gl: this.xrview.gl,
      mask: 0x1FFFFFFF,
      pass: RenderPass.COLOR,
    }
  }

}