import Deferred from "@/core/Deferred";
import Signal from "@/core/Signal";
import AppService from "@/services/AppService";

import { mat4 } from "gl-matrix";
import { GLContext } from "nanogl/types";
import type {
  XRBoundedReferenceSpace,
  XRReferenceSpace,
  XRSession,
  Navigator,
  XRFrame,
  XRViewerPose,
  XRReferenceSpaceType,
  XRWebGLLayer
} from "webxr";
import XRHeadset from "./XRHeadset";
import XRInputs from "./XRInputs";
import XRUtils from "./XRUtils";


const XRSystem = (navigator as unknown as Navigator).xr

export type XRViewFrameEvent = {
  time: DOMHighResTimeStamp
  frame: XRFrame
}

export default class XRView {

  session: XRSession = null

  readonly onFrame = new Signal<XRViewFrameEvent>()
  readonly onSessionStart = new Signal<void>()

  readonly headset : XRHeadset

  referenceSpace: XRReferenceSpace | XRBoundedReferenceSpace
  transformedSpace: XRReferenceSpace | XRBoundedReferenceSpace

  /**
   * pose in transformed space (world space)
   */
  pose: XRViewerPose

  /**
   * pose in reference/origin space (headspace)
   */
  refPose: XRViewerPose

  inputs: XRInputs;


  get gllayer(): XRWebGLLayer {
    return this.session.renderState.baseLayer
  }


  constructor( readonly gl : GLContext ){
    this.headset = new XRHeadset(this)
    this.inputs = new XRInputs();
  }

  isXRAvailable() : Promise<boolean> {
    if( !XRSystem ) return Promise.resolve(false)
    return XRSystem.isSessionSupported('immersive-vr')
  }

  startSession(){
    return this._requestSession()
  }

  exitSession(){
    if( this.session === null ) return
    return this.session.end()
  }

  setTransformedSpace( m : mat4): void {
    if( !this.referenceSpace ) {
      console.warn("Try to navigate in XR, but no reference space available")
      return
    }
    mat4.invert(m,m)
    const rt = XRUtils.mat4ToXRRigidTransform(m)
    this.transformedSpace = this.referenceSpace.getOffsetReferenceSpace(rt)
  }


  private async _requestSession() {

    if( this.session !== null ) throw new Error("session already started")

    this.session = await XRSystem.requestSession('immersive-vr', {
      requiredFeatures: ['local-floor'],
      optionalFeatures: [
        'low-fixed-foveation-level',
      ]
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await (this.gl as any).makeXRCompatible();

    // session.updateTargetFrameRate(72);

    this.session.addEventListener('end', this._handleSessionEnded);


    this.session.updateRenderState({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      baseLayer: new XRWebGLLayer(this.session, this.gl, {
        antialias: true,
        depth: true,
        stencil: false,
        alpha: false,
        framebufferScaleFactor: 1
      }),
      depthNear: 0.1,
      depthFar: 50.0
    });

    this.inputs.init(this.session);

    this.referenceSpace = await this._createReferenceSpace()
    this.setTransformedSpace(mat4.create())
    await this._requestInitialPose()

    this._requestFrame()

    this.onSessionStart.dispatch()

  }


  private _requestInitialPose() : Promise<void>{

    const deferred = new Deferred<void>()
    this.session.requestAnimationFrame((time: DOMHighResTimeStamp, frame: XRFrame) => {
      this.pose = frame.getViewerPose(this.referenceSpace);
      deferred.resolve()
    });
    return deferred.promise

  }

  private async _createReferenceSpace() {
    let refSpace : XRReferenceSpace | XRBoundedReferenceSpace

    refSpace = await this._requestReferenceSpace('bounded-floor')
    if( refSpace === null )
      refSpace = await this._requestReferenceSpace('local-floor')
    if( refSpace === null )
    refSpace = await this._requestReferenceSpace('local')
    if( refSpace === null )
      throw new Error("no reference space available")

    return refSpace

  }

  private async _requestReferenceSpace(type: XRReferenceSpaceType): Promise<XRReferenceSpace | XRBoundedReferenceSpace | null> {
    try{
      return await this.session.requestReferenceSpace(type)
    } catch(e) {
      return null
    }
  }

  private _releaseSession(){
    if( this.session === null ) return
    this.session.removeEventListener('end', this._handleSessionEnded);
    this.session = null
    AppService.state.send('END_SESSION')
  }

  private _requestFrame() {
    if (this.session === null) return
    this.session.requestAnimationFrame(this.frame);
  }

  private frame = (time: DOMHighResTimeStamp, frame: XRFrame) => {
    if (this.session === null) return
    this.pose = frame.getViewerPose(this.transformedSpace);
    this.refPose = frame.getViewerPose(this.referenceSpace);
    this.inputs.update(frame, this.transformedSpace);
    this.onFrame.dispatch({time, frame})


    this._requestFrame();
  }


  private _handleSessionEnded = ()=>{
    this._releaseSession()
  }

}