import Node from "nanogl-node"
import { GLContext } from "nanogl/types"
import { mat4, vec3 } from "gl-matrix"

import Fire from "@webgl/entities/Fire";
import Room from "@webgl/entities/Room"
import Embers from "@webgl/embers/Embers";
import Audios from "../common/Audios"
import Gamepads from "@webgl/xr/entities/gamepad/Gamepads"
import FogProps from "@webgl/fog/FogProps";
import Renderer from "@webgl/Renderer"
import Lighting from "@webgl/engine/Lighting"
import Vignette from "@webgl/entities/Vignette"
import DebugDraw from "@webgl/dev/debugDraw/DebugDraw"
import AppService from "@/services/AppService"
import Interfaces from "@webgl/entities/Interfaces";
import RenderMask from "@webgl/core/RenderMask"
import AudioManager from "@/core/audio/AudioManager"
import XRControllers from "./controllers/XRControllers"
import GameStateWatcher from "@/services/states/GameStateWatcher"
import RoomLoadingController from "../common/controllers/RoomLoadingController"
import BlackFade, { FadeControl } from "@webgl/entities/BlackFade"
import XRNavigation, { NavigationData } from "@webgl/xr/navigation/XRNavigation"
import { FogVFX } from "@webgl/fog/FogVFX";
import type { Activity } from "../Activity"
import { GameState } from "@/services/states/GameStateMachine";
import { HotspotsXR } from "@webgl/entities/Hotspots"
import { RenderContext } from "@webgl/core/Renderer"
import Zones from "@webgl/entities/Zones";

/**
 * Main class rendering and managing webgl rendering part of XR experience
 */
export default class XRActivity implements Activity {
  readonly gl: GLContext

  fire            : Fire
  fade            : BlackFade
  room            : Room
  root            : Node
  audio           : Audios
  embers          : Embers
  fogVFX          : FogVFX
  fogProps        : FogProps
  gamepads        : Gamepads
  hotspots        : HotspotsXR
  lighting        : Lighting
  vignette        : Vignette
  interfaces      : Interfaces
  roomLoader      : RoomLoadingController
  navigation      : XRNavigation
  controllers     : XRControllers
  fadeMoveControl : FadeControl
  gameStateWatcher: GameStateWatcher
  zones           : Zones                ;

  constructor( readonly renderer:Renderer ){
    this.gl = renderer.gl

    this.root = new Node()

    this.lighting = new Lighting(this.gl)
    this.root.add(this.lighting.root)

    this.gamepads = new Gamepads(this.renderer)
    this.gamepads.setLightSetup(this.lighting.lightSetup)

    this.fade = new BlackFade(this.gl)
    this.fadeMoveControl = this.fade.createControl()
    this.vignette = new Vignette(this.gl)
    this.fogProps = new FogProps()
    this.controllers = new XRControllers(this)
    this.interfaces = new Interfaces(this, this.gl)

    this.room = new Room(this.gl, this.fogProps)
    this.audio = new Audios(this)
    this.navigation = new XRNavigation(renderer.xrview)
    this.hotspots = new HotspotsXR(this.gl, this)
    this.zones            = new Zones()

    this.roomLoader = new RoomLoadingController(this.room, this.fade.createControl())
    this.gameStateWatcher = new GameStateWatcher()

    this.fire   = new Fire(this.fogProps, this.gl, this.root)
    this.embers = new Embers(this.fogProps, this.gl)
    this.fogVFX = new FogVFX(this, true)

    this.fogVFX.addFire(this.fire)
    this.fogVFX.addOccluder(this.room)


    this.roomLoader.onLevelChanged.on(this.onRoomLevelReady)

    this.navigation.enable()
    this.navigation.xrctrl.enableRotation()
    this.navigation.xrctrl.enableController("right")
    this.navigation.xrctrl.disableController("left")
    this.navigation.onNavigationRequest.on(this.handleNavigationRequest)
    // this.navigation.onRotationRequest.on(this.handleRotationRequest)

    this.renderer.xrview.onSessionStart.on(this.xrSessionStart)

    this.gameStateWatcher.onStateChange.on(this.stateChange)

/////////////////
////////////////////////
///////////////////////////////////////////////////
//////////////
  }

///////////////
//////////////////////////
/////////////////
////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//////
///
////////////

  load() :Promise<void> {
    const addLoad = () => { AppService.state.send('LOAD_GL_ELEMENT') }

    const toLoad = [
      this.lighting.load().then(addLoad),
      this.gamepads.load().then(addLoad),
      this.navigation.load().then(addLoad),
      this.fire.load().then(addLoad),
      this.embers.load().then(addLoad),
      this.hotspots.loadXR().then(addLoad),
      this.interfaces.load().then(addLoad),
      this.zones.load().then(addLoad),
      this.room.loadCommons().then(addLoad),
      this.controllers.illuminatorScreen.load().then(addLoad),
      ...this.fogVFX.load().map(p => p.then(addLoad))
    ]

    AppService.state.send({ type: 'LOAD_GL_TOTAL', total: toLoad.length })

    return Promise.all(toLoad).then( ()=>this.onLoaded())
  }

  onLoaded(){
    this.controllers.illuminatorScreen.createHotspotIcons()
    this.controllers.start()
    this.audio.start()
    this.interfaces.init()
  }

  unload(): void {
    this.lighting.dispose()
    this.controllers.stop()
  }

  /**
   * update loop, called once per frame before render
   */
  preRender():void {

    this.handleRotationRequest()
    this.fogProps.update(this.gamepads.list.get("right").root, this.room)
    this.gamepads.preRender()
    this.navigation.preRender()
    this.room.preRender()
    this.root.updateWorldMatrix()
    this.fire.preRender()
    this.embers.preRender()
    this.fogVFX.preRender()
    this.hotspots.preRender()
    this.controllers.pickingManager.preRender()
    this.controllers.playerCtrl.preRender()
  }

  /**
   * called between update and render to make some drawcall on some texture before binding the main FBO
   */
  rttPass():void {
    this.lighting.lightSetup.prepare(this.gl)
  }

  clearColor(){
    const c = this.fogProps.fogColorA
    this.gl.clearColor(c[0], c[1], c[2], 1)
  }

  /**
   * Non stereo rendering for Quest virtual browser
   */
   render():void {
    const gl = this.gl

    this.preRender()
    this.rttPass()

    const context = this.renderer.context
    const ctxObj = context.toObject()

    this.fogVFX.render(ctxObj)

    gl.bindFramebuffer(gl.FRAMEBUFFER, null)
    this.clearColor()
    gl.clear(this.gl.COLOR_BUFFER_BIT)

    this.renderScene(ctxObj)

    DebugDraw.render(context)
    DebugDraw.clear()
  }

  /**
   * Stereo rendering to xr gllayer FBO
   */
  renderXR(): void {
    AudioManager.updateListener(this.renderer.xrview.refPose.transform.matrix as mat4)

    const gl = this.gl
    const xrview = this.renderer.xrview

    this.preRender()
    this.rttPass()

    this.fogVFX.render(xrview)

    gl.bindFramebuffer(gl.FRAMEBUFFER, xrview.gllayer.framebuffer)
    this.clearColor()
    gl.clear(this.gl.COLOR_BUFFER_BIT)

    this.fogVFX.setRenderEye("left")
    const ctxLeft: RenderContext = xrview.headset.getEyeContext("left")

    if(ctxLeft){
      this.renderScene(ctxLeft, 'left')
      DebugDraw.render(ctxLeft)
    }

    this.fogVFX.setRenderEye("right")
    const ctxRight = xrview.headset.getEyeContext('right')
    if(ctxRight){
      this.renderScene(ctxRight, 'right')
      DebugDraw.render(ctxRight)
    }

    DebugDraw.clear()
  }

  /**
   * Execute drawcalls loop for the entire Scene (opaque and blended) for a given camera/viewport combination
   */
  private renderScene( context: RenderContext, eyeID?: string ):void {
    context.viewport.setupGl(this.gl)

    this.drawScene(context)
    this.embers.render(context, eyeID)
    this.fogVFX.lightBeam.render(context)
    this.gamepads.render(context)
    this.renderFullScreen(context)

    DebugDraw.drawGuizmo(this.controllers.playerCtrl.playerTranslation)
  }

  private drawScene(context: RenderContext)
  {
    this.renderSceneWithMask(context, RenderMask.OPAQUE)
    // this.skybox.render(context)
    this.renderSceneWithMask(context, RenderMask.BLENDED)
  }

  private renderSceneWithMask(context: RenderContext, mask: RenderMask):void {
    context.mask = mask
    this.room.render(context)
    this.navigation.render(context)
    this.hotspots.render(context)
    this.interfaces.render(context)
    this.controllers.pickingManager.render(context)
  }

  private renderFullScreen(context: RenderContext):void {
    context.mask = RenderMask.BLENDED
    this.vignette.render()
    this.fade.render()
  }

  /**
   * force reference pose update when session start since we can't do it while session isnt ready
   */
  xrSessionStart = ()=>{
    this.controllers.playerCtrl.movePlayer()

/////////////////
//////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////
//////////////
  }

  /**
  * called when room has loaded a new level
  */
  onRoomLevelReady = () => {
    this.navigation.navmesh = this.room.currentLevel.navmesh
    this.navigation.walls   = this.room.currentLevel.walls
  }

  handleNavigationRequest = (r:NavigationData)=>{
    if( r.reason === 'controller' ){
      AppService.state.send({ type: 'GAME_MOVE_PLAYER', payload: {
        x: r.position[0],
        y: r.position[2],
        heading: r.heading
      }})
    }
  }

  handleRotationRequest = () => {
    const angle = this.navigation.xrctrl.rotationAngle
    if( angle !== 0 ){
      AppService.state.send({
        type: 'GAME_MOVE_HEADING_PLAYER',
        payload: this.gameStateWatcher.currentState.context.rotation + angle
      })
    }
  }

  navigateTo( request: NavigationData ){
    const tgt = vec3.create()
    tgt.set( request.position )
    const heading = request.heading

    this.fadeMoveControl.fadeIn().then(()=>{
      this.navigation.moveTo(tgt, heading)
      this.fadeMoveControl.fadeOut()
    })
  }

  navigateToNoFade(request: NavigationData) {
    const tgt = vec3.create()
    tgt.set(request.position)
    const heading = request.heading
    this.navigation.moveTo(tgt, heading)
  }

  crouch(isCrouched: boolean, target: vec3, heading: number) {
    this.fadeMoveControl.fadeIn().then(()=>{
      this.navigation.crouch(isCrouched, target, heading)
      this.fadeMoveControl.fadeOut()
    })
  }

  stateChange = (state: GameState) => {
    if (state.event.type !== 'GAME_RESET' &&
      state.event.type !== 'GAME_BACK_HOME' && state.event.type !== 'GAME_RESTART') return
    this.reset()
  }

  reset() {
    this.controllers.reset()
    this.gamepads.buttons.reset()
    this.fire.reset()
    this.embers.reset()
  }
}
