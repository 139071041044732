var fn = function( obj ){
var __t,__p='';
__p+='precision highp float;\n\nvarying highp vec2 vTexCoord;\nuniform sampler2D tLatLon;\nuniform mat4 uUnproject;\n\n#define PI 3.1415926\n#define TAU 6.283184\n\nfloat atan2(in float y, in float x)\n{\n  return atan(y,x);\n}\n\nvoid main( void ){\n\n  vec3 wDir = normalize( (uUnproject * vec4(vTexCoord, 1.0, 1.0)).xyz );\n  vec2 tc = vec2(  - atan(wDir.x, wDir.z)/TAU + .5, - asin(wDir.y)/PI + .5 );\n\n  gl_FragColor = texture2D( tLatLon, tc );\n\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
