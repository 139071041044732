import Ray from "@webgl/math/Ray";
import AppService from "@/services/AppService";
import Controller from "@webgl/activities/common/controllers/Controller";
import DesktopActivity from "../DesktopActivity";
import { DoorId } from "@/services/states/GameContextDatas";
import { useDoors } from "@/services/stores/doors";
import { GameState } from "@/services/states/GameStateMachine";
import Viewport from "@/store/modules/Viewport";

const { doors, findDoor } = useDoors()

const ray = new Ray()

const isExit = (state: GameState) => {
  return state.matches('game.bedroom.exit_room') || state.matches('game.corridor.exit_room') || state.matches('game.studyroom.exit_room') || state.matches('game.living.exit_room')
}

export default class PickingManager extends Controller {
  enabled = false
  activity: DesktopActivity
  doorsEnabled = false

  get doorHovered() {
    return this.activity.gameStateWatcher.currentState.context.doorHovered
  }

  get isCrouched() {
    return this.activity.gameStateWatcher.currentState.context.isCrouched
  }

  get currentDoors() {
    return doors
    // return doors.filter(door => door.states.some(state => this.activity.gameStateWatcher.currentState.matches(state)))
  }

  get currentHotspots() {
    return this.activity.hotspots.currentHotspots
  }

  get primaryPointer() {
    return this.activity.renderer.pointers.primary
  }

  constructor(activity: DesktopActivity) {
    super(activity)
  }

  start() {
    super.start()
    this.enabled = true
  }

  stop() {
    super.stop()
    this.enabled = false
  }

  enableDoors() {
    if (this.doorsEnabled) return
    this.doorsEnabled = true
  }

  disableDoors() {
    if (!this.doorsEnabled) return
    this.doorsEnabled = false
    if (this.activity.gameStateWatcher.currentState.context.doorHovered !== -1) AppService.state.send({ type: 'GAME_DOOR_HOVER', payload: -1 })
  }

  raycastDoors(ray: Ray): DoorId | -1 {
    let id = -1
    let rayDist = Infinity

    for (let i = 0; i < this.currentDoors.length; i++) {
      const door = this.currentDoors[i]
      const target = this.activity.room.pickingSources.getTarget(door.node)
      const dist = target.raycast(ray)

      if (dist < rayDist) {
        id = door.id
        rayDist = dist
      }
    }

    return id
  }

  preRender() {
    if (!this.enabled || this.activity.controllers.cameraCtrl.isMoving
      || this.activity.controllers.cameraCtrl.isCrouching
      || this.activity.controllers.cameraCtrl.lookat.isActive) return

    const input = this.primaryPointer.coord.viewport
    if (!input) return

    ray.unproject(input, this.activity.renderer.camera)

    if (this.doorsEnabled) {
      const prevDoorHovered = this.doorHovered
      const doorHovered = this.raycastDoors(ray)

      if (prevDoorHovered !== doorHovered){

        for (let i = 0; i < this.currentHotspots.length; i++) {
          const hotspot = this.currentHotspots[i]
          if( hotspot.linkedDoor === doorHovered && !Viewport.isMobile){
            if( !hotspot.mustCrouch || this.isCrouched){
              AppService.state.send({ type: 'GAME_HOTSPOT_HOVER', payload: hotspot.id })
            }

          }
        }

        if( doorHovered !== -1){
          const door = findDoor(doorHovered)
          if( door.states.some(state => this.activity.gameStateWatcher.currentState.matches(state))){
            AppService.state.send({ type: 'GAME_DOOR_HOVER', payload: doorHovered })
          }
        } else {
          AppService.state.send({ type: 'GAME_DOOR_HOVER', payload: -1 })
                            }

        if( doorHovered === -1 ){
          AppService.state.send({ type: 'GAME_HOTSPOT_HOVER', payload: -1 })
        }

      }
    }
  }
  stateChange(state: GameState) {
    if (state.context.isPaused) {
      this.disableDoors()
      return
    }

    if (state.matches('intro')) {
      this.disableDoors()
      return
    }

    if (state.matches('tuto.xr.door') || state.matches('tuto.xr.actions')) {
      this.enableDoors()
      return
    }

    if (state.matches('tuto')) {
      this.disableDoors()
      return
    }

    if (isExit(state)) {
      this.disableDoors()
      return
    }

    if (state.matches('game')) {
      this.enableDoors()
      return
    }

    if (state.matches('end')) {
      this.disableDoors()
      return
    }
  }

  reset() {
    this.disableDoors()
  }
}
