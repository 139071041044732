import { ZoneId } from "@webgl/entities/Zones";
import { DoorId, HotspotId, GamePlayerPosition } from "./GameContextDatas";

export type BaseGameEvent<TType = string> = {
  readonly type: TType,
  readonly payload?: undefined,
  readonly _network?: boolean,
}

export type BaseGameEventWithPayload<TType = string, TPayload = unknown> = {
  readonly type: TType,
  readonly payload: TPayload
  readonly _network?: boolean,
}


export type GameZoneChangePayload = {
  from: ZoneId,
  to: ZoneId,
}


export type GameSimpleEvent =
  BaseGameEvent<'GAME_SEND_EMOJI'> |
  BaseGameEvent<'GAME_RESET'> |
  BaseGameEvent<'GAME_TOGGLE_PAUSE'> |
  BaseGameEvent<'GAME_NEXT_STEP'> |
  BaseGameEvent<'GAME_ACTION_DONE'> |
  BaseGameEvent<'GAME_INTRO_ENDED'> |
  BaseGameEvent<'GAME_COMPANION_TUTO_NEXT'> |
  BaseGameEvent<'NETWORK_ROOM_UNKNOWN'> |
  BaseGameEvent<'GAME_BACK_HOME'> |
  BaseGameEvent<'GAME_RESTART'> |
  BaseGameEvent<'GAME_INTRO_NEXT_STEP'> |
  BaseGameEvent<'GAME_COMPANION_INTRO_NEXT_STEP'>


export type GamePlayerPositionEvent =
  BaseGameEventWithPayload<'GAME_MOVE_PLAYER', GamePlayerPosition>

export type GamePlayerHeadingEvent =
  BaseGameEventWithPayload<'GAME_MOVE_HEADING_PLAYER', number>

export type GamePlayerRotateEvent =
  BaseGameEventWithPayload<'GAME_ROTATE_PLAYER', number>

export type GamePlayerCrouchEvent =
  BaseGameEventWithPayload<'GAME_CROUCH_PLAYER', boolean>

export type GameChooseMultiEvent =
  BaseGameEventWithPayload<'GAME_CHOOSE_MULTI', boolean>

export type GameHotspotEvent =
  BaseGameEventWithPayload<'GAME_HOTSPOT_ACTION_DONE', HotspotId>

export type GameAdviseHotspotEvent =
  BaseGameEventWithPayload<'GAME_HOTSPOT_ADVISE', HotspotId>

export type GameSetCountdownEvent =
  BaseGameEventWithPayload<'GAME_SET_COUNTDOWN', number>

export type GameHoverDoorEvent =
  BaseGameEventWithPayload<'GAME_DOOR_HOVER', DoorId>

export type GameHoverHotspotEvent =
  BaseGameEventWithPayload<'GAME_HOTSPOT_HOVER', HotspotId>

export type GameSelectHotspotEvent =
  BaseGameEventWithPayload<'GAME_HOTSPOT_SELECT', HotspotId>

export type GameZoneChangeEvent =
  BaseGameEventWithPayload<'GAME_ZONE_CHANGE', GameZoneChangePayload>

export type GameFadeEvent =
  BaseGameEventWithPayload<'GAME_FADE', boolean>

export type NetworkRoomJoinedEvent =
  BaseGameEventWithPayload<'NETWORK_ROOM_JOINED', boolean>

export type NetworkFullRoomEvent =
  BaseGameEventWithPayload<'NETWORK_FULLROOM', boolean>



export type GameEvent =
  GameSimpleEvent |
  GamePlayerPositionEvent |
  GamePlayerHeadingEvent |
  GamePlayerRotateEvent |
  GamePlayerCrouchEvent |
  GameHotspotEvent |
  GameAdviseHotspotEvent |
  GameSetCountdownEvent |
  GameHoverDoorEvent |
  GameHoverHotspotEvent |
  GameSelectHotspotEvent |
  GameChooseMultiEvent |
  GameZoneChangeEvent |
  GameFadeEvent |
  NetworkRoomJoinedEvent |
  NetworkFullRoomEvent


export type GameEventType = GameEvent['type']

export enum GameEventCode {
  _WILDCARD,

  GAME_NEXT_STEP,
  GAME_RESET,
  GAME_TOGGLE_PAUSE,
  GAME_SEND_EMOJI,
  GAME_INTRO_ENDED,
  GAME_CHOOSE_MULTI,
  GAME_MOVE_PLAYER,
  GAME_MOVE_HEADING_PLAYER,
  GAME_ROTATE_PLAYER,
  GAME_CROUCH_PLAYER,
  GAME_CLOSE_DOOR,
  GAME_ACTION_DONE,
  GAME_HOTSPOT_ACTION_DONE,
  GAME_HOTSPOT_ADVISE,
  GAME_SET_COUNTDOWN,
  GAME_HOTSPOT_HOVER,
  GAME_HOTSPOT_SELECT,
  GAME_DOOR_HOVER,
  GAME_COMPANION_TUTO_NEXT,
  GAME_ZONE_CHANGE,
  GAME_INTRO_NEXT_STEP,
  GAME_COMPANION_INTRO_NEXT_STEP,
  GAME_FADE,

  COUNT,

  NETWORK_ROOM_JOINED,
  NETWORK_FULLROOM,
  NETWORK_ROOM_UNKNOWN,
  GAME_BACK_HOME,
  GAME_RESTART
}

/**
 * Xstate has string Event types, photon require number Event codes
 * we need bidirectionnal conversion
 */
export function getGameEventCode(type: GameEventType): GameEventCode {
  return GameEventCode[type]
}

export function getGameEventType(code: GameEventCode): GameEventType {
  return GameEventCode[code] as GameEventType
}

export enum EventCachePolicy {
  NO_CACHE,
  ONCE,
  ADD,
}

export function getGameEventCachePolicy(code: GameEventCode): EventCachePolicy {
  switch (code) {
    case GameEventCode.GAME_ACTION_DONE:
    case GameEventCode.GAME_HOTSPOT_ACTION_DONE:
    case GameEventCode.GAME_COMPANION_TUTO_NEXT:
    case GameEventCode.GAME_ZONE_CHANGE:
      return EventCachePolicy.ADD
  }

  return EventCachePolicy.ONCE
}
