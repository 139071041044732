import { GltfScene } from "@webgl/engine/GltfScene";
import { GLContext } from "nanogl/types";
import Node from "nanogl-node";
import { VolLightPass } from "@webgl/fog/VolLightPass";
import { Uniform } from "nanogl-pbr/Input";
import VolLightDepth from "@webgl/fog/VolLightDepth";
import { quat, vec3 } from "gl-matrix";
import FogProps from "@webgl/fog/FogProps";
import { DEG2RAD } from "@webgl/math";
import { RenderContext } from "@webgl/core/Renderer";

export default class LightCone extends GltfScene
{
    completeUnlitPass: VolLightPass;
    private rootNode: Node = new Node();
    private uBrightness: Uniform;

    constructor(private fogProps: FogProps, gl:GLContext, parent:Node, volLightDepth: VolLightDepth)
    {
        const p = new Node();
        p._parent = parent;
        super('fog/LightCone.gltf', gl, undefined, p);
        this.rootNode = p;

        this.completeUnlitPass = new VolLightPass(fogProps, volLightDepth)
        this.completeUnlitPass.glconfig
            .enableDepthTest(false)
            .enableCullface()
            .enableBlend()
            .blendFunc(gl.SRC_ALPHA, gl.ONE);
        this.completeUnlitPass.baseColor.attachConstant([1.0, 1.0, 1.0])

        this.overrides.overridePass( "", this.completeUnlitPass )
    }

    preRender()
    {
        super.preRender();

        const h = Math.sin(this.fogProps.spotAngle * .5 * DEG2RAD ) * this.fogProps.spotRadius;

        vec3.set(this.rootNode.scale, h, h, this.fogProps.spotRadius);
        vec3.copy(this.rootNode.position, this.fogProps.spotPos);
        quat.copy(this.rootNode.rotation, this.fogProps.spotQuat);

        this.rootNode.invalidate();
        this.rootNode.updateMatrix();
        this.rootNode.updateWorldMatrix();
    }

    render(context: RenderContext): void
    {
        const mtx = this.worldMatrix;

        const { camera, gl } = context;
        camera.updateWorldMatrix();
        const dot = camera._wmatrix[8] * mtx[8] + camera._wmatrix[9] * mtx[9] + camera._wmatrix[10] * mtx[10];

        this.completeUnlitPass.glconfig.cullFace(dot < 0.0? gl.FRONT : gl.BACK);
        this.completeUnlitPass.isBackward = dot < 0.0;


        super.render(context);
    }

    get worldMatrix()
    {
        this.gltf.root.updateWorldMatrix();
        return this.gltf.root._wmatrix;
    }
}
