import AppService from "@/services/AppService"
import Controller from "../../common/controllers/Controller"
import DesktopActivity from "../DesktopActivity";
import { GameState } from "@/services/states/GameStateMachine";

export default class TutoController extends Controller {
  constructor(activity: DesktopActivity) {
    super(activity)
  }

  stateChange(state: GameState) {
    if (!state.matches('tuto')) return

    if (state.matches('tuto.xr.move_around') && state.event.type === 'GAME_MOVE_PLAYER') {
      AppService.state.send('GAME_ACTION_DONE')
      return
    }

    if (state.matches('tuto.xr.crouch') && state.event.type === 'GAME_CROUCH_PLAYER') {
      AppService.state.send('GAME_ACTION_DONE')
      return
    }

    if (state.matches('tuto.xr.door') && state.event.type === 'GAME_DOOR_HOVER') {
      AppService.state.send('GAME_ACTION_DONE')
      return
    }
  }

  reset() {
    return
  }
}
