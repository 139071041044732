import XRNavigationRenderer from "./XRNavigationRenderer";
import Ray from "@/webgl/math/Ray";
import { mat4, quat, vec3, vec4 } from "gl-matrix";
import XRNavigationController from "./XRNavigationController";
import XRView from "../XRView";
import GltfResource from "@webgl/resources/GltfResource";
import { TextureResource } from "@webgl/resources/TextureResource";
import { RenderContext } from "@webgl/core/Renderer";
import Picking from "@webgl/engine/Picking";
import WebglAssets from "@webgl/resources/WebglAssets";
import Signal from "@/core/Signal";

const V4 = vec4.create();

const M4 = mat4.create();
const Q_ID = quat.create();
const Q_ORIGIN = quat.create();
quat.rotateY(Q_ORIGIN, Q_ORIGIN, -Math.PI / 2)

const QUATA = quat.create();
const QUATB = quat.create();

export type NavigationData = {
  position: vec3,
  heading: number,
  reason?: string
}

export default class XRNavigation {

  ray: Ray;

  target: vec3;
  normal: vec3;
  forward: vec3;
  /**
   * offset between the actual navmesh and the rendered stuffs
   */
  offset=0.01

  hasTarget: boolean;
  renderer: XRNavigationRenderer;

  xrctrl: XRNavigationController;

  navmesh?: Picking
  walls?: Picking

  active = false;

  onNavigationRequest: Signal<NavigationData>;
  onRotationRequest: Signal<number>;
  onNavigationStart: Signal<void>;
  // onNavigation: Signal<NavigationData>;

  private _loadPromise: Promise<void>;

  aimTarget: GltfResource;
  rayLine: TextureResource;

  constructor(
    public xrview: XRView
  ) {

    this.ray = new Ray();

    this.target = vec3.create();
    this.normal = vec3.create();
    this.forward = vec3.create();

    this.renderer = new XRNavigationRenderer(this);
    this.xrctrl = new XRNavigationController(this);
    this.onNavigationRequest = new Signal();
    this.onRotationRequest = new Signal();
    this.onNavigationStart = new Signal();
    // this.onNavigation        = new Signal();


    this.aimTarget = new GltfResource(
      "xr/aim_target/aim_target.gltf",
      xrview,
    );

    this.rayLine = WebglAssets.getTexture("xr/ray_line.png", xrview.gl, {
      wrap: "clamp",
    } );

  }

  async load(): Promise<void> {
    if (!this._loadPromise) {
      this._loadPromise = this._doLoad();
    }
    return this._loadPromise
  }

  private async _doLoad() {
    await Promise.all([
      this.aimTarget.load(),
      this.rayLine.load(),
    ])
    this.init();
  }

  init() {
    this.renderer.init();
    // this.scene.root.add(this.renderer.targetNode);
  }

  release(){
    this.xrctrl.unbind();
    // this.scene.root.remove(this.renderer.targetNode);
  }

  get enabled(){
    return this.xrctrl._bound
  }

  enable(){
    this.xrctrl.bind();
  }

  disable(){
    this.xrctrl.unbind();
  }

  preRender() {
    this.renderer.targetNode.updateWorldMatrix()

    if( !this.xrctrl.prepareRay(this.ray) ) {
      // no controllers found
      return
    }

    this.hasTarget = false

    if( this.walls ){
      const valid = this.walls.raycast(this.ray, V4, this.normal);
      this.target[0] = V4[0];
      this.target[1] = 0;
      this.target[2] = V4[2];

      if (valid) {
        this.hasTarget = true
        vec3.scaleAndAdd(this.target, this.target, this.normal, 0.01 )
        this.target[1] += this.offset
      }

    }

    if( this.navmesh && !this.hasTarget){
      const valid = this.navmesh.raycast(this.ray, V4, this.normal);
      this.target[0] = V4[0];
      this.target[1] = V4[1];
      this.target[2] = V4[2];
      // this.normal[0] = 0
      // this.normal[1] = 1
      // this.normal[2] = 0

      if (valid) {
        vec3.scaleAndAdd(this.target, this.target, this.normal, this.offset )
      }
      this.hasTarget = valid !== 0;
    }

    this.xrctrl.update();
    this.renderer.preRender();

  }


  // navigateTo( request: NavigationData ){
  //   this.onNavigationRequest.dispatch(request);

  //   const tgt = vec3.create()
  //   tgt.set( request.position )
  //   const heading = request.heading

  //   this.renderer.fade.fadeIn().then(()=>{
  //     this.moveTo(tgt, heading)
  //     this.onNavigation.dispatch(request);
  //   })
  // }


  private getMoveMatrix( m:mat4, target: vec3, heading:number ){
    const headRotation = this.xrview.headset.getHorizontalRotation()
    quat.rotateY(QUATA, Q_ID, -headRotation);
    quat.rotateY(QUATB, Q_ORIGIN, -heading)
    quat.multiply(QUATB, QUATB, QUATA);
    mat4.fromRotationTranslation(m, QUATB, target);
  }

  moveTo(target: vec3, heading: number) {
    this.getMoveMatrix(M4, target, heading)
    this.xrview.setTransformedSpace(M4);
  }

  crouch(isCrouched: boolean, target: vec3, heading: number) {
    // TODO : improve crouch -> make crouch adapted to player height
    this.getMoveMatrix(M4, target, heading)
    this.xrview.setTransformedSpace(M4);
  }

  render(ctx: RenderContext) {
    if (this.active)
      this.renderer.render(ctx);
  }

}