import Camera from "nanogl-camera";
import Node from "nanogl-node";
import GLArrayBuffer from "nanogl/arraybuffer";
import GLIndexBuffer from "nanogl/indexbuffer";
import { GLContext } from "nanogl/types";
import { vec3 } from "gl-matrix";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";
import { clamp01 } from "@/webgl/math";
import UnlitPass from "nanogl-pbr/UnlitPass";
import GLState, { LocalConfig } from "nanogl-state/GLState";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";
import XRNavigation from "./XRNavigation";
import { GltfBaseMaterial } from "nanogl-gltf/lib/elements/Material";
import { LiveProgram } from "@webgl/core/LiveShader";


import lineVert from "./unlit-texture-line.vert"
import lineFrag from "./unlit-texture-line.frag"


import { Bezier3 } from "@webgl/math/bezier";
import { RenderContext } from "@webgl/core/Renderer";
// import DebugDraw from "@webgl/dev/debugDraw/DebugDraw";
import RenderMask from "@webgl/core/RenderMask";

// SAMPLE POINT
const UP = vec3.fromValues(0, 1, 0);
const V3A = vec3.create();
const V3B = vec3.create();
const V3C = vec3.create();

const P0 = vec3.create();
const P1 = vec3.create();
const P2 = vec3.create();
const P3 = vec3.create();


class RayLine {

  _segcount = 30;

  _vbuffer: GLArrayBuffer;
  _ibuffer: GLIndexBuffer;

  _vdata: Float32Array;

  start: vec3;
  end: vec3;
  texture: Texture2D;
  prg: Program;

  constructor(gl: GLContext) {

    this.start = vec3.create();
    this.end = vec3.create();

    this._vbuffer = new GLArrayBuffer(gl, null, gl.DYNAMIC_DRAW);
    this._ibuffer = new GLIndexBuffer(gl, gl.UNSIGNED_SHORT);

    this._vbuffer
      .attrib("aPosition", 3, gl.FLOAT)
      .attrib("aNextPosition", 3, gl.FLOAT)
      .attrib("aPreviousPosition", 3, gl.FLOAT)
      .attrib("aTexCoord", 2, gl.FLOAT);

    this._vdata = new Float32Array(this._segcount * 2 * 11);

    this.prg = LiveProgram(gl, lineVert, lineFrag)

  }

  updateBezier(p0: vec3, p1: vec3, p2: vec3, p3: vec3, warning: boolean) {

    for (let i = this._segcount - 1; i >= 0; i--) {
      const stride = i * 2 * 11;

      const p = (i / (this._segcount));

      Bezier3(p, p0, p1, p2, p3, V3A);
      Bezier3(clamp01(p + (1 / this._segcount)), p0, p1, p2, p3, V3B);
      Bezier3(clamp01(p - (1 / this._segcount)), p0, p1, p2, p3, V3C);


      // DebugDraw.drawPoint(V3A)
      this._vdata.set(V3A, stride + 0);
      this._vdata.set(V3B, stride + 3);
      this._vdata.set(V3C, stride + 6);
      this._vdata.set([0, p * 0.5 + (warning ? 0.5 : 0.0)], stride + 9);

      this._vdata.set(V3A, stride + 11);
      this._vdata.set(V3B, stride + 14);
      this._vdata.set(V3C, stride + 17);
      this._vdata.set([1, p * 0.5 + (warning ? 0.5 : 0.0)], stride + 20);

    }

    this._vbuffer.data(this._vdata);

  }

  render(camera: Camera) {
    const prg = this.prg;

    prg.use();
    prg.tTexture(this.texture);

    prg.uModelViewMatrix(camera._view);
    prg.uProjectionMatrix(camera.lens.getProjection());

    prg.uResolution([window.innerWidth * 0.5, window.innerHeight]);
    prg.uLineWidth(0.015);

    this._vbuffer.bind();
    this._vbuffer.attribPointer(prg);
    this._vbuffer.drawTriangleStrip();

  }

}


export default class XRNavigationRenderer {

  targetNode: Node;
  cfg: LocalConfig;
  rayLine: RayLine;
  aimTarget: MeshRenderer;
  // fade: BlackFade;

  constructor(
    public readonly navigation: XRNavigation
  ) {

    const gl = navigation.xrview.gl

    // this.targetNode.rotateX(-Math.PI / 2);
    // this.scene.root.add(this.targetNode);

    this.cfg = GLState.get(gl).config()
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(gl.ONE, gl.ONE);

    this.rayLine = new RayLine(gl);
    // this.fade = new BlackFade(gl);

  }



  init() {

    const gl = this.navigation.xrview.gl
    this.rayLine.texture = this.navigation.rayLine.texture

    const gltf = this.navigation.aimTarget.gltf

    this.aimTarget = gltf.renderables[0] as MeshRenderer;
    this.targetNode = this.aimTarget.node
    const glow = gltf.getMaterial("Glow") as GltfBaseMaterial<UnlitPass>;
    const uwhite = gltf.getMaterial("UnlitWhite") as GltfBaseMaterial<UnlitPass>;
    glow.materialPass.baseColorFactor.attachConstant(0.1, "r");
    uwhite.materialPass.baseColorFactor.attachConstant(0.65, "r");

    glow.materialPass.mask = RenderMask.BLENDED
    uwhite.materialPass.mask = RenderMask.BLENDED

    glow.materialPass.glconfig
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(gl.ONE, gl.ONE);

    uwhite.materialPass.glconfig
      .enableDepthTest(true)
      .depthMask(false)
      .enableCullface(false)
      .enableBlend(true)
      .blendFunc(gl.ONE, gl.ONE);

  }

  preRender() {

    this.targetNode.position.set(this.navigation.target);
    this.targetNode.invalidate();

    const dist = (vec3.sqrDist(this.navigation.ray.origin, this.navigation.target) / 20);
    const curve = 0.3 + dist * 1.5;

    P0.set(this.navigation.ray.origin);
    vec3.scaleAndAdd(P1, this.navigation.ray.origin, this.navigation.forward, curve);
    // vec3.scaleAndAdd(P2, this.navigation.target, this.navigation.normal, curve);
    vec3.scaleAndAdd(P2, this.navigation.target, UP, curve);
    P3.set(this.navigation.target);

    this.rayLine.updateBezier(P0, P1, P2, P3, !this.navigation.hasTarget);


  }

  render(ctx: RenderContext) {
    if (ctx.mask & RenderMask.BLENDED) {

      this.cfg.apply();
      this.rayLine.render(ctx.camera);

      if (this.navigation.hasTarget) {
        this.aimTarget.render(
          ctx.gl,
          ctx.camera,
          ctx.mask,
          ctx.pass
        )
      }
    }
  }
  
  // renderFade(ctx: RenderContext) {
  //   if (ctx.mask & RenderMask.BLENDED) {
  //     this.fade.render()
  //   }
  // }

}