var fn = function( obj ){
var __t,__p='';
__p+='precision lowp float;\n\nuniform sampler2D tTex;\nuniform float uOpacity;\n\nvarying vec2 vTexCoord;\n\nvoid main(void){\n  vec2 coords = vec2(vTexCoord.x, 1. - vTexCoord.y);\n  vec4 tex = texture2D(tTex, coords);\n  gl_FragColor = vec4(tex.rgb, tex.a * uOpacity);\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
