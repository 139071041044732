var fn = function( obj ){
var __t,__p='';
__p+='precision highp float;\n\nattribute vec2 aPosition;\nattribute vec3 aUV; // instanced (uv, size)\n\n// use previous and current for motion blur\nuniform sampler2D tPrev;\nuniform sampler2D tCurr;\n\n//uniform mat4 uReproj;\nuniform mat4 uModelViewMatrix;\nuniform mat4 uProjectionMatrix;\nuniform mat4 uPrevViewMatrix;\nuniform float uSize;\n\nvarying vec2 vPos;\nvarying vec3 vWorldPos;\nvarying vec4 vNDC;\nvarying float vAlpha;\n\nvoid main()\n{\n//    vec4 prev = texture2D(tPrev, aUV.xy);\n    vec4 curr = texture2D(tCurr, aUV.xy);\n\n    vec4 currPos = uModelViewMatrix * vec4(curr.xyz, 1.0);\n//    vec4 prevPos = uPrevViewMatrix * vec4(prev.xyz, 1.0);\n\n//    vec4 currScreenPos = uProjectionMatrix * currPos;\n//    vec4 prevScreenPos = uProjectionMatrix * prevPos;\n\n    float size = uSize * aUV.z;\n\n    /*vec3 dir = currScreenPos.xyz / currScreenPos.w - prevScreenPos.xyz / prevScreenPos.w;\n\n    float dist = length(dir) * currScreenPos.w;\n    // prevent overly long particles (behind the screen, too close to face) from smearing too much\n    if (dist > 1.0) dist = 0.0;\n\n    float angle = atan(dir.y, dir.x);\n    float ca = cos(angle);\n    float sa = sin(angle);\n    mat2 mat = mat2(ca, sa, -sa, ca);\n    vec2 pos = aPosition * vec2(dist + size, size);\n    currPos.xy += mat * pos;*/\n\n    vec2 pos = aPosition * size;\n    currPos.xy += pos;\n    gl_Position = uProjectionMatrix * currPos;\n\n    vPos = aPosition;\n    // fade out near beginning & end of life\n    vAlpha = smoothstep(0.0, 0.1, curr.w) * smoothstep(1.0, 0.9, curr.w);\n\n    // make degenerate if not visible\n    gl_Position *= vAlpha;\n\n//    vAlpha *= (dist + size) / size;\n\n    vNDC = gl_Position;\n    vWorldPos = curr.xyz;\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
