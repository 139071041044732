import { GltfScene } from "@webgl/engine/GltfScene";
import { GLContext } from "nanogl/types";
import Node from "nanogl-node";
import { FogPass } from "@webgl/fog/FogPass";
import { Uniform } from "nanogl-pbr/Input";
import FogProps from "@webgl/fog/FogProps";
import { RoomLevelId } from "@webgl/entities/Room";
import { RenderContext } from "@webgl/core/Renderer";
import MeshRenderer from "nanogl-gltf/lib/renderer/MeshRenderer";
import { GamePlayerPosition } from "@/services/states/GameContextDatas";
import { vec3 } from "gl-matrix";

const v = vec3.create();

export default class Fire extends GltfScene
{
    completeUnlitPass: FogPass;
    vertices: number[];
    private uBrightness: Uniform;
    private brightness = 20;
    private activeRenderable: MeshRenderer;

    constructor(fogProps: FogProps, gl:GLContext, parent:Node)
    {
        super('fire.glb', gl, undefined, parent)

        this.completeUnlitPass = new FogPass(fogProps)
        this.completeUnlitPass.glconfig
            .enableDepthTest()
            .enableCullface()
        this.completeUnlitPass.baseColor.attachConstant([1.0, 0.8, 0.5])
        this.uBrightness = this.completeUnlitPass.baseColorFactor.attachUniform("uBrightness")

        this.overrides.overridePass( "", this.completeUnlitPass )
    }

    preRender()
    {
        super.preRender();
        const range = 5;
        const r = 10 - range * Math.random();
        this.brightness = this.brightness + (r - this.brightness) * .25;
        const b = this.brightness*3;
        this.uBrightness.set(b, b, b);
    }

    get worldMatrix()
    {
        return this.gltf.root._wmatrix;
    }

    render(context:RenderContext)
    {
        this.activeRenderable?.render(context.gl, context.camera, context.mask, context.pass, context.glConfig );
    }

    setLevel(roomlevel: RoomLevelId): void
    {
        const gltf = this.gltf

        for (const renderable of gltf.renderables) {
            if (renderable.mesh.name === "step_" + roomlevel) {
                this.activeRenderable = renderable;
            }
        }

        if (this.activeRenderable) {
            this.activeRenderable.computeBounds();
            this.updateVerts();
        }
        else
            this.vertices = null;
    }

    getDistance(pos: GamePlayerPosition): number
    {
        if (!this.activeRenderable)
            return 100000;

        let minDist = Number.POSITIVE_INFINITY;
        const len = this.vertices.length;
        for (let i = 0; i < len; i += 2) {
            const dx = pos.x - this.vertices[i];
            const dy = pos.y - this.vertices[i + 1];
            const sqrDist = dx * dx + dy * dy;
            if (sqrDist < minDist)
                minDist = sqrDist
        }

        return Math.sqrt(minDist);
    }

    private updateVerts(): void
    {
        this.vertices = [];
        const mesh = this.activeRenderable.mesh;
        const mtx = this.activeRenderable.node._wmatrix;

        mesh.primitives.forEach(prim => {
            const acc = prim.attributes.getSemantic("POSITION").accessor;
            for (let i = 0; i < acc.count; ++i) {
                acc.getValue(v, i);
                vec3.transformMat4(v, v, mtx);
                this.vertices.push(v[0], v[2]);
            }
        });
    }

    reset() {
        this.activeRenderable = undefined
    }
}
