
import vShader from "./fog.vert"
import fShader from "./fog.frag"
import LiveShader from "@webgl/core/LiveShader";
import UnlitPass from "nanogl-pbr/UnlitPass";
import Camera from "nanogl-camera";
import Program from "nanogl/program";
import Node from "nanogl-node";
import FogProps from "@webgl/fog/FogProps";
import Flag from "nanogl-pbr/Flag";


/**
 * create Hot-reloadable shaders
 */
const FragCode = LiveShader(fShader)


export class FogPass extends UnlitPass {
    additive: Flag;
    spotlight: Flag;
    gamma: Flag;

    constructor( private fogProps: FogProps, name?: string, additive = false, spotlight = true, gamma = true ) {
        super(name)

        this._updateCode()

        this.inputs.add( this.additive = new Flag( 'additive', additive ) )
        this.inputs.add( this.spotlight = new Flag( 'spotlight', spotlight ) )
        this.inputs.add( this.gamma = new Flag( 'gamma', gamma ) )

        // update pass shader code when glsl update
        // could add vertex shader also, but here we use one from nanogl-pbr
        // noop in non DEBUG mode
        FragCode.onHmr(()=>this._updateCode())
    }

    /**
     * in release mode, this is called once, in the ctor
     * in DEBUG, it can be called each time the fragment shader is edited
     * in this case, the pass need to be invalidated to force recompilation
     */
    private _updateCode( ){
        this._shaderSource.vert = vShader()
        this._shaderSource.frag = FragCode()
        
        


/////////////////////
////////////////////////////////////
/////////////////////////////////////////////////////////
//////////////////
    }

    prepare(prg: Program, node: Node, camera: Camera)
    {
        super.prepare(prg, node, camera);
        this.fogProps.applyToProgram(prg, camera);
    }
}
