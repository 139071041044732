import AppService from "@/services/AppService";
import { reactive } from "vue";

type Viewport = {
  windowWidth: number
  windowHeight: number
  isMobile: boolean
  isDesktop: boolean
  isLandscape: boolean
  isTouch: boolean
  webpSupport: boolean
}

const Viewport = reactive<Viewport>({
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  isMobile: false,
  isDesktop: false,
  isLandscape: false,
  isTouch: "ontouchstart" in window,
  webpSupport: false
}) as Viewport
export default Viewport


Viewport.isMobile = isMobile();
Viewport.isDesktop = isDesktop();
Viewport.isLandscape = isLandscape();
Viewport.isTouch = isTouch();

function isMobile(){
  return Viewport.windowWidth < 768;
}

function isDesktop(){
  return Viewport.windowWidth >= 1024;
}

function isLandscape(){
  return Viewport.windowWidth > Viewport.windowHeight
}

function isTouch(){
  return "ontouchstart" in window
}

export function isWebpSupport() {
  Viewport.webpSupport = true;
}

function onResize(){
  Viewport.windowWidth = window.innerWidth
  Viewport.windowHeight = window.innerHeight
  Viewport.isMobile = isMobile()
  Viewport.isDesktop = isDesktop()
  Viewport.isLandscape = isLandscape()

  calculateVH()
}

const calculateVH = () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
}


export function disposeViewport(): void {
  window.removeEventListener('resize', onResize);
}

window.addEventListener('resize', onResize);
calculateVH()