
import LightSetup from "nanogl-pbr/lighting/LightSetup";
import { GltfScene } from "@webgl/engine/GltfScene";
import { XRHandedness } from "webxr";
import GamepadEntity from "./GamepadEntity";
import Node from "nanogl-node";
import { RenderContext } from "@webgl/core/Renderer";
import { StandardMetalness } from "nanogl-pbr/StandardPass";
import WebglAssets from "@webgl/resources/WebglAssets";
import { TextureResource } from "@webgl/resources/TextureResource";
import { Sampler } from "nanogl-pbr/Input";
import TexCoord from "nanogl-pbr/TexCoord";
import { materialIsStandard } from "@webgl/engine/GltfUtils";
import Texture2D from "nanogl/texture-2d";
import { mat3 } from "gl-matrix";
import GamepadButtons from "./GamepadButtons";
import Renderer from "@webgl/Renderer";

export default class Gamepads {
  node: Node;
  list: Map<XRHandedness, GamepadEntity>;
  gltfRes: GltfScene;
  colorRes: TextureResource;
  occlusRes: TextureResource;

  plasticPass: StandardMetalness;
  glassPass  : StandardMetalness;
  lightPass  : StandardMetalness;
  screenPassL : StandardMetalness;
  screenPassR : StandardMetalness;
  screenTextureL: Texture2D;
  screenTextureR: Texture2D;

  private _lightSetup: LightSetup;

  buttons: GamepadButtons

  get xrview() {
    return this.renderer.xrview
  }

  constructor(
    public readonly renderer: Renderer,
  ) {

    this.list = new Map<XRHandedness, GamepadEntity>();
    this.node = new Node();

    this.plasticPass = new StandardMetalness()
    this.glassPass   = new StandardMetalness()
    this.lightPass   = new StandardMetalness()
    this.screenPassL  = new StandardMetalness()
    this.screenPassR  = new StandardMetalness()

    this.gltfRes = new GltfScene( "illuminators_out/illuminators.gltf", this.xrview.gl, undefined, this.node )
    this.gltfRes.overrides.overridePass("plastic"   , this.plasticPass)
    this.gltfRes.overrides.overridePass("flashlight", this.lightPass  )
    this.gltfRes.overrides.overridePass("glass"     , this.glassPass  )
    this.gltfRes.overrides.overridePass("screenL"    , this.screenPassL )
    this.gltfRes.overrides.overridePass("screenR"    , this.screenPassR )

    this.occlusRes = WebglAssets.getTexture("illuminators_out/occlus.jpg", this.xrview.gl,{
    })
    this.colorRes = WebglAssets.getTexture("illuminators_out/plastic_BaseColor.jpg", this.xrview.gl,{
    })

    this.screenTextureL = new Texture2D(this.xrview.gl)
    this.screenTextureL.repeat()
    this.screenTextureL.setFilter(true)

    this.screenTextureR = new Texture2D(this.xrview.gl)
    this.screenTextureR.repeat()
    this.screenTextureR.setFilter(true)

    this.buttons = new GamepadButtons(this, this.renderer.context)
  }

  load() {

    return Promise.all([
      this.gltfRes.load(),
      this.colorRes.load(),
      this.occlusRes.load(),
      this.buttons.load()
    ]).then(()=>this.onLoaded())
  }



  onLoaded(){

    const gltf = this.gltfRes.gltf;
    this.list.set(
      "left",
      new GamepadEntity(
        "left",
        gltf.getNode( "left_quest2_controller_world" ),
        gltf.getNode( "left_quest2_mesh"),
      )
    );

    this.list.set(
      "right",
      new GamepadEntity(
        "right",
        gltf.getNode( "right_quest2_controller_world"),
        gltf.getNode( "right_quest2_mesh"),
      )
    );

    const gl = this.xrview.gl;

    const sampler = new Sampler('ttex', TexCoord.create())
    sampler.set(this.colorRes.texture)
    this.plasticPass.surface.baseColor.attach(sampler, 'rgb')
    this.plasticPass.surface.roughness.attachConstant(0.5)
    this.plasticPass.surface.metalness.attachConstant(0)
    const os = this.plasticPass.occlusion.attachSampler()
    os.set(this.occlusRes.texture)

    this.plasticPass.glconfig
      .enableCullface()
      .depthMask(true)
      .enableDepthTest( )

    this.glassPass.surface.baseColor.attachConstant([0, 0, 0])
    this.glassPass.surface.roughness.attachConstant(0.1)
    this.glassPass.surface.metalness.attachConstant(0)
    this.glassPass.mask = 0
    this.glassPass.glconfig
      .enableCullface()
      .depthMask(false)
      .enableDepthTest( )
      .enableBlend()
      .blendColor(.9,.9,.9,1)
      .blendFunc( gl.ONE, gl.CONSTANT_COLOR )

    this.lightPass.surface.baseColor.attachConstant([0, 0, 0])
    this.lightPass.emissive.attachConstant([1, 1, 1])
    this.lightPass.glconfig
      .enableCullface()
      .depthMask(false)
      .enableDepthTest( )

      
    const m = mat3.create()
    m[0] = -1
    const screenTc = TexCoord.createTransformed('aTexCoord0', m)

    // this.screenPass.surface.baseColor.attachSampler('texscreen', screenTc).set(this.screenTexture)
    this.screenPassL.surface.baseColor.attachConstant([0, 0, 0])
    this.screenPassL.emissive.attachSampler('texscreen', screenTc).set(this.screenTextureL)
    this.screenPassL.emissiveFactor.attachConstant(0.9)
    this.screenPassL.glconfig
      .enableCullface()
      .depthMask(true)
      .enableDepthTest( )

    // this.screenPass.surface.baseColor.attachSampler('texscreen', screenTc).set(this.screenTexture)
    this.screenPassR.surface.baseColor.attachConstant([0, 0, 0])
    this.screenPassR.emissive.attachSampler('texscreen', screenTc).set(this.screenTextureR)
    this.screenPassR.emissiveFactor.attachConstant(0.9)
    this.screenPassR.glconfig
      .enableCullface()
      .depthMask(true)
      .enableDepthTest( )



    this.setupMaterials()

    this.buttons.onLoaded()
  }

  setLightSetup( setup:LightSetup){
    this._lightSetup = setup
    this.setupMaterials()
  }


  setupMaterials(){
    this.plasticPass.setLightSetup(this._lightSetup)
    this.glassPass  .setLightSetup(this._lightSetup)
    this.screenPassL .setLightSetup(this._lightSetup)
    this.screenPassR .setLightSetup(this._lightSetup)
    this.screenPassL.gammaMode.set( "GAMMA_NONE")
    this.screenPassR.gammaMode.set( "GAMMA_NONE")

    const gltf = this.gltfRes.gltf;
    if( !gltf ) return
    gltf.depthPass.depthFormat.set(this._lightSetup.depthFormat.value());

    for (const material of gltf.materials) {
      if( materialIsStandard(material)){
        material.materialPass.setLightSetup(this._lightSetup)
      }
    }
  }


  preRender() {
    const inputs = this.xrview.inputs;
    for (const input of inputs.list) {
      this.list.get(input.source.handedness)
        .update(input);
    }
    this.node.updateWorldMatrix()
    this.gltfRes.preRender()

  }

  render(context: RenderContext) {
    this.gltfRes.render(context)
    this.buttons.render(context)
  }

}