export type pagePanelId = "privacy" | "cookies" | "terms";

export type pagePanelProps = {
  slug: pagePanelId;
  content: string[];
};

export const PAGE_PANELS: pagePanelProps[] = [
  {
    slug: "privacy",
    content: [
      "title",
      "subintroduction",
      "subtitle1",
      "description1_a",
      "description1_b",
      "description1_c",
      "description1_d",
      "description1_e",
      "description1_f",
      "description1_g",
      "description1_h",
      "description1_i",
      "description1_j",
      "description1_k",
      "description1_l",
      "description1_m",
      "description1_n",
      "description1_o",
      "description1_p",
      "description1_q",
      "description1_r",
      "subtitle2",
      "description2_a",
      "description2_b",
      "subtitle3",
      "description3",
      "subtitle4",
      "description4",
      "subtitle5",
      "description5",
      "subtitle6",
      "description6",
    ],
  },
  {
    slug: "cookies",
    content: [
      "title",
      "introduction",
      "subtitle1",
      "description1_a",
      "description1_b",
      "description1_c",
      "description1_d",
      "description1_e",
      "description1_f",
      "description1_g",
      "description1_h",
      "subtitle2",
      "description2",
      "subtitle3",
      "description3",
      "subtitle4",
      "description4_a",
      "description4_b",
      "description4_c",
      "description4_d",
      "description4_e",
      "description4_f",
    ],
  },
  {
    slug: "terms",
    content: ["title"],
  },
];

const INDEX = Object.fromEntries(PAGE_PANELS.map((d, i) => [d.slug, i]));

const pagePanels = PAGE_PANELS;

export function usePagePanels() {
  function findPage(id: pagePanelId): pagePanelProps {
    return pagePanels[INDEX[id]] as pagePanelProps;
  }

  return {
    pagePanels,
    findPage,
  };
}
