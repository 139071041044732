/**
 * This is used by FogPass so we only need to update the props once and can be reused by different materials
 */
import { TextureResource } from "@webgl/resources/TextureResource";
import Texture2D from "nanogl/texture-2d";
import Program from "nanogl/program";
import Camera from "nanogl-camera";
import { mat4, quat, vec3 } from "gl-matrix";
import { DEG2RAD } from "@webgl/math";
import Node from "nanogl-node";
import Room from "@webgl/entities/Room";
import gui from "@webgl/dev/gui";
import { HexToNewVec3 } from "@webgl/core/Color";

const proj = mat4.create();
const mat = mat4.create();

export type FogPreset = Partial<{
    fogColorA: [number, number, number]
    fogColorB: [number, number, number]
    heightFalloff: number
    density: number
    additive: number
    ceilingHeight: number
    vignette: number
    waveMin: number
    waveMax: number
    waveScale: number
    scattering: number
    lmMultiplier: number
}>




// export const FogStage1Preset: FogPreset = {
//     "fogColorA": [0.1125, 0.05605, 0.02906],
//     "fogColorB": [0.07500, 0.04382, 0.02266],
//     "heightFalloff": 4.565217391304348,
//     "density": 3.9130434782608696,
//     "ceilingHeight": 2,
//     "additive": 0.10869565217391304,
//     "vignette": 0.5,
//     "waveMin": 0.34782608695652173,
//     "waveMax": 1,
//     "waveScale": 0.20108695652173914,
//     "scattering": 1

// }

export const FogStage1Preset: FogPreset = {
    "fogColorA": [
        0.48750001192092896,
        0.18281249701976776,
        0.3233903646469116
    ],
    "fogColorB": [
        0.5249999761581421,
        0.2395312488079071,
        0.359675794839859
    ],
    "heightFalloff": 2.119565217391304,
    "density": 1.684782608695652,
    "ceilingHeight": 1,
    "additive": 0.10869565217391304,
    "vignette": 0.5,
    "waveMin": 0.11898238384042097,
    "waveMax": 0.173328839843807,
    "waveScale": 0.114,
    "scattering": 1.6304347826086956,
    "lmMultiplier": 1.6684782608695652
  }

export const FogLivingPreset: FogPreset = {
    "fogColorA": [
      0.48750001192092896,
      0.18281249701976776,
      0.3233903646469116
    ],
    "fogColorB": [
      0.5249999761581421,
      0.2395312488079071,
      0.359675794839859
    ],
    "heightFalloff": 1.7934782608695652,
    "density": 0.9782608695652174,
    "ceilingHeight": 1,
    "additive": 0.10869565217391304,
    "vignette": 0.5,
    "waveMin": 0.16304347826086957,
    "waveMax": 0.2391304347826087,
    "waveScale": 0.114,
    "scattering": 0.42391304347826086,
    "lmMultiplier": 1.9673913043478262
  }



// export const CorridorPreset: FogPreset = {
//     "fogColorA": [0.1125, 0.05605, 0.02906],
//     "fogColorB": [0.07500, 0.04382, 0.02266],
//     "heightFalloff": 6.195652173913044,
//     "density": 2.2282608695652173,
//     "ceilingHeight": 1.2,
//     "vignette": 0.5,
//     "waveMin": 0.16304347826086957,
//     "waveMax": 1,
//     "waveScale": 0.2
// }

export const CorridorPreset: FogPreset = {
        "fogColorA": [  0.4375,  0.1476,  0.2813],
        "fogColorB": [ 0.44999, 0.18562, 0.29691],
        "heightFalloff": 6.195652173913044,
        "density": 2.2282608695652173,
        "ceilingHeight": 1.2,
        "additive": 0.10869565217391304,
        "vignette": 0.5,
        "waveMin": 0.16304347826086957,
        "waveMax": 1,
        "waveScale": 0.114,
        "scattering": 1,
        "lmMultiplier": 1
}


export const FogIntroPreset: FogPreset = {
    "density": 0.0,
    "vignette": 0.0,
    "lmMultiplier": 1.8
}


export const FogNonePreset: FogPreset = {
    "density": 0.0,
    "vignette": 0.0,
    "lmMultiplier": 1.8
}


export default class FogProps {
    scatterTex: Texture2D;
    screenTexScale = [1.0, 1.0]
    screenTexOffset = [0.0, 0.0]
    density = 3.0;
    ceilingHeight = 3.0;
    heightFalloff = 1.0;
    fogColorA = HexToNewVec3(0x483e41)
    fogColorB = HexToNewVec3(0x483e41)
    waveTex: TextureResource;
    spotTex: TextureResource;
    waveScale = 1
    waveTimeFactor = -100;
    waveMin = .6
    waveMax = 1.2
    waveSpeed = .1
    vignette = 0.5
    spotPos = vec3.create()
    spotQuat = quat.create()
    spotRadius = 5
    spotBrightness = 7
    spotAngle = 70
    spotInscattering = 0.003
    spotInvalid = true
    spotShadowMap: Texture2D;
    additive = .3
    scattering = 2.5
    scatteringPower = 1
    spotShadowMapSize = 512
    lmMultiplier = 1
    private spotMatrix: mat4 = mat4.create()

    constructor() {
        console.log(this.fogColorA);

        this.applyPreset(FogStage1Preset)
    }

    applyToProgram(prg: Program, camera: Camera) {
        prg.uCameraPosition(camera._wposition);

        // For debug, if camera is way above the room, disable fog
        if (camera._wposition[1] > 4) {
            prg.uFogDensity(0);
        } else {
            prg.uFogDensity(this.density);
        }

        prg.uCeilingHeight(this.ceilingHeight);
        prg.uHeightFalloff(this.heightFalloff);
        prg.uFogColorA?.(this.fogColorA[0] * this.fogColorA[0], this.fogColorA[1] * this.fogColorA[1], this.fogColorA[2] * this.fogColorA[2]);
        prg.uFogColorB?.(this.fogColorB[0] * this.fogColorB[0], this.fogColorB[1] * this.fogColorB[1], this.fogColorB[2] * this.fogColorB[2]);
        prg.uFogAdditive(this.additive);
        prg.uWaveScale(this.waveScale);
        prg.uWaveScale(this.waveScale);
        prg.uWaveTimeFactor(this.waveTimeFactor);
        // prg.uWaveOffset2(...this.waveOffset2);
        prg.uWaveAmount(this.waveMin, this.waveMax);
        prg.uVignette(this.vignette);
        prg.tWave(this.waveTex.texture)

        if (prg.tScattering) {
            prg.uScreenTexOffset(...this.screenTexOffset);
            prg.uScreenTexScale(...this.screenTexScale);
            prg.tScattering(this.scatterTex);
            prg.uScatteringAmount(this.scattering);
            prg.uScatteringPower(this.scatteringPower);
        }

        if (this.spotInvalid) {
            mat4.perspective(proj, this.spotAngle * DEG2RAD, 1.0, 0.01, this.spotRadius);
            mat4.fromRotationTranslation(mat, this.spotQuat, this.spotPos);
            mat4.invert(mat, mat);
            mat4.mul(this.spotMatrix, proj, mat);
            this.spotInvalid = false;
        }

        if (prg.uSpotRadius) prg.uSpotRadius(this.spotRadius);
        if (prg.uSpotMatrix) prg.uSpotMatrix(this.spotMatrix);
        if (prg.tSpotTex) prg.tSpotTex(this.spotTex.texture)
        if (prg.uSpotBrightness) prg.uSpotBrightness(this.spotBrightness);
        if (prg.uSpotPos) prg.uSpotPos(this.spotPos);
        if (prg.tSpotShadow) prg.tSpotShadow(this.spotShadowMap);
        if (prg.uSpotInscattering) prg.uSpotInscattering(this.spotInscattering);
        if (prg.uShadowTexSize) prg.uShadowTexSize(1.0 / this.spotShadowMap.width, 1.0 / this.spotShadowMap.height);
    }

    update(spotTarget: Node, room: Room) {
        room;
        if (spotTarget) {
            vec3.copy(this.spotPos, spotTarget.position);
            // quat.rotateY(this.spotQuat, spotTarget.rotation, Math.PI);
            quat.copy(this.spotQuat, spotTarget.rotation);
            this.spotInvalid = true;
        }
        // this.ceilingHeight = room.ceilingHeight;
    }

    applyPreset(preset: FogPreset) {
        if (preset.fogColorA     !== undefined) this.fogColorA     .set(preset.fogColorA     );
        if (preset.fogColorB     !== undefined) this.fogColorB     .set(preset.fogColorB     );
        if (preset.heightFalloff !== undefined) this.heightFalloff =    preset.heightFalloff  ;
        if (preset.density       !== undefined) this.density       =    preset.density        ;
        if (preset.ceilingHeight !== undefined) this.ceilingHeight =    preset.ceilingHeight  ;
        if (preset.additive      !== undefined) this.additive      =    preset.additive       ;
        if (preset.vignette      !== undefined) this.vignette      =    preset.vignette       ;
        if (preset.waveMin       !== undefined) this.waveMin       =    preset.waveMin        ;
        if (preset.waveMax       !== undefined) this.waveMax       =    preset.waveMax        ;
        if (preset.waveScale     !== undefined) this.waveScale     =    preset.waveScale      ;
        if (preset.scattering    !== undefined) this.scattering    =    preset.scattering     ;
        if( preset.lmMultiplier  !== undefined) this.lmMultiplier  =    preset.lmMultiplier   ;
    
    }

/////////////////
////////////
////////////////////////////////////

/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////////////
//////////////////////////////////////
////////////////////////////////////////////
///////////////////////////////////////
///////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////
/////////////////////////////////////////////

/////////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////////
////////////////////////
//////////
/////


//////////////
}