import Controller from "../../common/controllers/Controller"
import XRActivity from "../XRActivity";
import { GameState } from "@/services/states/GameStateMachine";

export default class NavigationController extends Controller {
  constructor(private xractivity: XRActivity) {
    super(xractivity)
  }

  stateChange(state: GameState) {
    const disable = (['intro']).some( s=> state.matches(s))
    if( disable ){
      this.xractivity.navigation.disable()
    }
    else {
      this.xractivity.navigation.enable()
    }
  }

  reset() {
    return
  }
}
