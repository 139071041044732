<template>
  <div class="guide">
    <div class="guide-heading">
      <slot name="heading" />
    </div>

    <div class="guide-actions">
      <slot name="actions"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="stylus" scoped>
.guide
  display flex
  flex-direction: column
  justify-content: flex-end
  align-items: center
  gap 2rem
  width calc(100% - (0.5rem * 2))
  height 12rem
  padding: 3rem 0.5rem

  position absolute
  bottom 0.5rem
  left 0.5rem
  right 0.5rem

  color $black

  text-align center

  pointer-events: none

  .guide-heading,
  .guide-actions
    position relative

  .guide-heading
    ::v-deep(.title)
      font-style: normal;
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 90%;
      /* or 26px */

      text-align: center;
      letter-spacing: 0.02em;
  .guide-actions
    flex none
</style>