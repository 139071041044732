/* eslint-disable @typescript-eslint/no-explicit-any */
import { actions, ActorRef, assign, createMachine, Interpreter, spawn, State, StateMachine } from "xstate";
import Delay from "@/core/Delay";
import AppService from "../AppService";
import { GameEvent, GameHotspotEvent, GameZoneChangeEvent } from "./GameEvents";
import { DebugGamePlayerPosition, DefaultGamePlayerPosition, DoorId, FailGamePlayerPosition, HotspotId, MAX_COUNTDOWN, WinGamePlayerPosition } from "./GameContextDatas";
import AudioManager, { AUDIO_ID } from "@/core/audio/AudioManager";
import { RoomLevelId } from "@webgl/entities/Room";
import { useHotspots } from "../stores/hotspots";
import { FogStage1Preset, FogNonePreset, FogPreset, CorridorPreset, FogLivingPreset } from "@webgl/fog/FogProps";
import { Role } from "./AppStateMachine";

import i18n from '@/core/i18n'
import { ZoneId } from "@webgl/entities/Zones";
import Tracking from "@/core/Tracking";
const { t } = i18n.global

function CreateContext() {
  console.log("CreateContext");
  
  return {
    /**
     * xr player position in the room
     */
    position: DefaultGamePlayerPosition(),

    /**
     * player rotation
     */
    rotation: 0,

    /**
     * is player crouched
     */
    isCrouched: false,

    /**
     * room level to load
     */
    roomlevel: 1 as RoomLevelId,

    /**
     * hotspot hovered by player
     */
    hotspotHovered: -1 as HotspotId | -1,

    /**
     * hotspot selected by companion
     */
    hotspotSelected: -1 as HotspotId | -1,

    /**
     * door hovered by player
     */
    doorHovered: -1 as DoorId | -1,

    /**
     * actions done by the player
     */
    hotspots: [] as HotspotId[],

    /**
     * actions advised by companion
     */
    advisedHotspots: [] as HotspotId[],

    /**
     * emojis sent by xr player
     */
    emojis: [] as Date[],

    /**
     * is black fade active
     */
    isFadeActive: false,

    /**
     * remaining time in seconds before gameover
     */
    countdown: 0,


    syncService: null as ActorRef<any, any>,

    /**
     * should this machine increment the countdown, or use network event instead
     */
    _manageCountdown: true,

    /**
     * is on pause ?
     */
    isPaused: false,

    fogPreset: {} as FogPreset,

    metadata: {} as { room?: string; title?: string },
  }
}

export type GameStateContext = ReturnType<typeof CreateContext>;



export type GameStateType = {
  value: string
  context: GameStateContext
}


export type GameState = State<GameStateContext, GameEvent, any, any>

export type GameStateMachine = StateMachine<GameStateContext, any, GameEvent, GameStateType>

// HOTSPOTS

function OnHotspot(state: string) {
  return {
    GAME_HOTSPOT_ACTION_DONE: {
      actions: [

        assign((ctx: GameStateContext, e: GameHotspotEvent) => ({
          hotspotHovered: -1,
          hotspots: addHotspot(ctx, e.payload),
          roomlevel: getHotspotLevel(ctx, state, e.payload)
        })),
        async (_: GameStateContext, e: GameHotspotEvent) => {
          const hotspot = findHotspot(e.payload)
          Tracking.selectHotspotPlaceAction(hotspot.trackingData)

          if (hotspot.points > 0) {
            sound(AUDIO_ID.UI_ACTION_Success)()
          } else if (hotspot.points < 0) {
            sound(AUDIO_ID.UI_ACTION_fail)()
          }

          playActionSound(e.payload)()
        },
        // (ctx: GameStateContext, e: GameHotspotEvent) => updateCountdown(ctx, e.payload),
        assign((ctx: GameStateContext, e: GameHotspotEvent) => {
          const hotspot = findHotspot(e.payload)
          console.log("aaa", ctx.countdown, ctx.countdown + hotspot.points);
          return ({
            countdown:  ctx.countdown + hotspot.points,
          })
        }),
      ]
    },
    GAME_ACTION_DONE: 'exit_room'
  }
}

// TRAVEL ZONES

function OnTravelZoneTransition( from: ZoneId, to: ZoneId, target: string) {
  return {
    target,
    cond:(_:GameStateContext,e:GameZoneChangeEvent) => (e.payload.from === from, e.payload.to === to)
  }
}

function OnTravelZone( from: ZoneId, to: ZoneId, target: string) {
  return {
    // GAME_ACTION_DONE: target,
    GAME_ZONE_CHANGE: OnTravelZoneTransition(from, to, target)
  }
}



/*
  GAME_START_INTRO
    => target intro

  GAME_PLAYER_MOVE, GAME_PLAYER_MOVE, GAME_PLAYER_MOVE,

  GAME_PLAYER_COMPLETE_INTRO
  GAME_COMPANION_COMPLETE_INTRO
    => target tuto

  GAME_PLACE_TAG (id, locationId)
  GAME_REMOVE_TAG (id, locationId)
  GAME_PLACE_TAG (id, locationId)

  GAME_PLAYER_LEFT_TUTO_ROOM
  => target game

  GAME_PLAYER_MOVE, GAME_PLAYER_MOVE, GAME_PLAYER_MOVE,

  GAME_PLACE_TAG (id, locationId)

  GAME_OPEN_DOOR (id)
  GAME_CLOSE_DOOR (id)

  GAME_PLAYER_MOVE

  GAME_FAILED_TIMEOUT
  => target end


  GAME_RESET

  GAME_START_INTRO
    => target intro
*/

function sound(id: AUDIO_ID) {
  return ()=>{AudioManager.play(id)}
}

function ambiantLevel(level: 1|2|3) {
  return ()=>AudioManager.setAmbientLevel(level)
}

function soundOpenDoor() {
  return ()=>AudioManager.play( AudioManager.getRandomOpenDoor())
}

function soundCloseDoor() {
  return ()=>AudioManager.play( AudioManager.getRandomCloseDoor())
}

function soundOpenWindow() {
  return ()=>AudioManager.play( AudioManager.getRandomOpenWindow())
}






export function CreateGameStateMachine(manageCountdown = true): GameStateMachine {


  const context = CreateContext();
  context._manageCountdown = manageCountdown;


  return createMachine<GameStateContext, GameEvent, GameStateType>({

    id: "game",

    initial: 'initial',

    context,

    entry: [
      'spawnSyncService',
      'startAmbiantMusic'
    ],
    on: {
      GAME_BACK_HOME: {
        
        actions: [
          'backHome',
          'clearRoomCache',
          'resetContextForBackhome',
        ],
        target: 'intro'
      },
      GAME_RESTART: {
        actions: [
          'restart',
          'clearRoomCache',
          'resetContext',
          Tracking.startAgain,
        ],
        target: ['tuto.xr.exit_room', 'tuto.companion.completed']
      },

      GAME_MOVE_PLAYER: {
        actions: assign({ position: (_, e) => e.payload })
      },

      GAME_FADE: {
        actions: assign({ isFadeActive: (_, e) => e.payload })
      },

      GAME_MOVE_HEADING_PLAYER: {
        actions: assign({ position: (ctx, e) => ({
          ...ctx.position,
          heading: e.payload
        })})
      },

      GAME_ROTATE_PLAYER: {
        actions: assign({ rotation: (_, e) => e.payload })
      },

      GAME_CROUCH_PLAYER: {
        actions: assign({ isCrouched: (_, e) => e.payload })
      },

      GAME_SEND_EMOJI: {
        actions: assign({ emojis: ctx => ([...ctx.emojis, new Date()]) })
      },

      GAME_HOTSPOT_ADVISE: { actions: [
        'adviseHotspot',
        (ctx, e) => Tracking.highlightHotpost( findHotspot(e.payload).trackingData )
      ] },

      GAME_DOOR_HOVER: {
        actions: assign({ doorHovered: (_, e) => e.payload })
      },

      GAME_HOTSPOT_HOVER: {
        actions: assign({ hotspotHovered: (_, e) => e.payload })
      },

      GAME_HOTSPOT_SELECT: {
        actions: assign({ hotspotSelected: (_, e) => e.payload })
      },

      GAME_RESET: {
        actions: ['clearRoomCache', 'resetContext'],
        target: 'intro'
      },

      GAME_TOGGLE_PAUSE: {
        actions: 'togglePause',
      },

    },

    states: {

      initial: {
        always: process.env.VUE_APP_GAME_INITIAL_STATE || 'intro',
      },

      intro: {
        entry: [
          'setPlayerIntroPosition',
          level(RoomLevelId.Guestroom_DC),
          fogPreset(FogNonePreset),
          ambiantLevel(1),
        ],

        exit: Tracking.introNext,
        
        type: 'parallel',
        onDone: 'tuto',
        on: {
          GAME_INTRO_ENDED: 'tuto'
        },
        states: {
          xr: {
            initial: 'step1',
            states: {
              step1: {
                on: {
                  GAME_INTRO_NEXT_STEP: 'step2'
                }
              },
              step2: {
                on: {
                  GAME_INTRO_NEXT_STEP: 'step3'
                }
              },
              step3: {
                on: {
                  GAME_INTRO_NEXT_STEP: 'wait_companion'
                }
              },
              wait_companion: {
                type: 'final'
              }
            }
          },
          companion: {
            initial: 'step1',
            states: {
              step1: {
                on: {
                  GAME_COMPANION_INTRO_NEXT_STEP: 'step2'
                },
                always: {
                  target: 'wait_xr',
                  cond: 'noMulti'
                }
              },
              step2: {
                on: {
                  GAME_COMPANION_INTRO_NEXT_STEP: 'step3'
                }
              },
              step3: {
                on: {
                  GAME_COMPANION_INTRO_NEXT_STEP: 'wait_xr'
                }
              },
              wait_xr: {
                type: 'final'
              }
            }
          }
        },
      },

      tuto: {

        entry: [
          Tracking.pageViewExperience,
          level(RoomLevelId.Guestroom_DC),
          fogPreset(FogNonePreset),
          metadata({
            room: t('journey.room.guest_room'),
            title: t('journey.title.tutorial'),
          }),
          ambiantLevel(1),
        ],

        onDone: {
          actions:'initCountdown',
          target: 'game',
        },

        type: 'parallel',

        states: {

          xr: {

            initial: 'contact',

            states: {
              contact: {
                initial: 'wait',
                onDone: 'crouch',
                always: {
                  target: 'crouch',
                  cond: 'noMulti'
                },
                states: {
                  wait: {
                    on: {
                      GAME_ACTION_DONE: 'hello'
                    }
                  },
                  hello: {
                    entry: [
                      Tracking.sayHello,
                      sound(AUDIO_ID.UI_SAY_HELLO)
                    ],
                    after:{
                      4000: 'emoji'
                    }
                  },
                  emoji: {
                    on: {
                      GAME_ACTION_DONE: 'completed',
                    }
                  },
                  completed: {
                    type: 'final'
                  }
                }
              },

              move_around: {
                exit: Tracking.tutoMove,
                on: { GAME_ACTION_DONE: 'crouch' }
              },

              crouch: {
                exit: Tracking.tutoCrouch,
                on: { GAME_ACTION_DONE: 'door' },
              },

              door: {
                exit: Tracking.tutoDoor,
                on: { GAME_ACTION_DONE: 'actions' }
              },

              actions: {
                on: { GAME_ACTION_DONE: 'exit_room' }
              },

              exit_room: {
                entry: [
                  level(RoomLevelId.GuestRoom_DO),
                  soundOpenDoor(),
                  metadata({
                    room: t('journey.room.guest_room'),
                    title: t('journey.title.tutorial_completed'),
                  })
                ],
                on: OnTravelZone( 'guestroom', 'bedroom', 'completed' )
                // on: { GAME_ACTION_DONE: 'completed' },
              },

              completed: {
                entry: Tracking.tutoExit,
                type: 'final',
              }

            }
          },

          companion: {

            initial: 'contact',

            states: {
              contact: {
                initial: 'initial',
                onDone: 'plan',
                always: {
                  target: 'plan',
                  cond: 'noMulti'
                },
                states: {
                  initial: {
                    on: {
                      GAME_ACTION_DONE: 'sent'
                    }
                  },
                  sent: {
                    on: {
                      GAME_ACTION_DONE: 'completed'
                    }
                  },
                  completed: {
                    type: 'final'
                  }
                }
              },

              plan: {
                type: 'final',
                on: { GAME_ACTION_DONE: 'teammate' }
              },

              teammate: {
                type: 'final',
                on: { GAME_ACTION_DONE: 'wait_teammate' }
              },

              wait_teammate: {
                type: 'final',
                on: { GAME_ACTION_DONE: 'action' }
              },

              action: {
                type: 'final',
                on: {
                  GAME_HOTSPOT_ADVISE: {
                    target: 'wait_hotspot',
                    actions: ['adviseHotspot', Tracking.tutoGuideAdvice ]
                  }
                },
                always: {
                  target: 'wait_hotspot',
                  cond: 'noMulti'
                }
              },

              wait_hotspot: {
                type: 'final',
                on: { GAME_ACTION_DONE: 'completed' }
              },

              completed: {
                type: 'final'
              },

            }

          }


        }
      },



      game: {

        invoke: {
          src: 'countdownTick',
        },

        initial: 'bedroom',

        onDone: 'end.initial',

        on:{
          GAME_SET_COUNTDOWN: {
            actions: assign({ countdown: (_, e) => e.payload }),
          },
        },

        always:{
          target: 'end.failed', cond: 'outOfTime'
        },

        entry: [
          // 'initCountdown',
          // 'setPlayerDebugPosition',
          fogPreset(FogStage1Preset),
          metadata({
            room: t('journey.room.bedroom')
          })
        ],


        states: {

          bedroom: {

            initial: 'initial',
            onDone: 'corridor',

            entry: [
              fogPreset(FogStage1Preset),
              ambiantLevel(1),
            ],

            states: {

              /**
               * initial state, entered in the bedroom after complete tuto
               */
              initial:{
                entry: level(RoomLevelId.Bedroom_GO_WC_DC),
                on: OnHotspot('game.bedroom.initial'),
              },

              exit_room: {
                entry: [
                  soundOpenDoor(),
                  assign((ctx: GameStateContext) => ({
                    roomlevel: getHotspotLevel(ctx, 'game.bedroom.exit_room')
                  })),
                ],
                // on: { GAME_ACTION_DONE: 'completed' },
                on: OnTravelZone( 'bedroom', 'corridor', 'completed' )
              },

              completed: {
                type: 'final',
              }
            }
          },

          corridor: {

            initial: 'initial',
            onDone: 'studyroom',

            entry: [
              fogPreset(CorridorPreset),
              metadata({
                room: t('journey.room.corridor')
              }),
              ambiantLevel(2),
            ],

            invoke: {
              src: 'coughtIfNotCrouched',
            },

            states: {

              /**
               * initial state, entered in the corridor after complete bedroom
               */
              initial:{
                entry: level(RoomLevelId.Corridor_BO_DC),
                on: OnHotspot('game.corridor.initial')
              },

              exit_room: {
                entry: [
                  soundOpenDoor(),
                  assign((ctx: GameStateContext) => ({
                    roomlevel: getHotspotLevel(ctx, 'game.corridor.exit_room')
                  })),
                ],
                // on: { GAME_ACTION_DONE: 'completed' },
                on: OnTravelZone( 'corridor', 'study', 'completed' )
              },

              completed: {
                type: 'final',
              }
            }
          },

          studyroom: {

            initial: 'initial',
            onDone: 'living',

            entry: [
              fogPreset(FogStage1Preset),
              metadata({
                room: t('journey.room.studyroom')
              }),
              ambiantLevel(2),
            ],

            states: {

              /**
               * initial state, entered in the study after complete corridor
               */
              initial:{
                entry: level(RoomLevelId.Study_CO_WC_DC),
                on: OnHotspot('game.studyroom.initial')
              },

              exit_room: {
                entry: [
                  soundOpenDoor(),
                  assign((ctx: GameStateContext) => ({
                    roomlevel: getHotspotLevel(ctx, 'game.studyroom.exit_room')
                  })),
                ],
                // on: { GAME_ACTION_DONE: 'completed' }
                on: OnTravelZone( 'study', 'living', 'completed' )
              },

              completed: {
                type: 'final',
              }

            },
          },

          living: {

            initial: 'initial',
            onDone: 'landing',

            entry: [
              fogPreset(FogLivingPreset),
              metadata({
                room: t('journey.room.living')
              }),
              ambiantLevel(3),
            ],

            states: {

              /**
               * initial state, entered in the living after complete study
               */
              initial:{
                entry: level(RoomLevelId.Living_SO_DC),
                on: OnHotspot('game.living.initial')
              },

              exit_room: {
                entry: [
                  soundOpenDoor(),
                  assign((ctx: GameStateContext) => ({
                    roomlevel: getHotspotLevel(ctx, 'game.living.exit_room')
                  })),
                ],
                // on: { GAME_ACTION_DONE: 'completed' },
                on: OnTravelZone( 'living', 'landing', 'completed' )
              },

              completed: {
                type: 'final',
              }
            },
          },

          landing: {
            initial: 'initial',
            onDone: 'exit',

            entry: [
              fogPreset(FogStage1Preset),
              ambiantLevel(3),
            ],

            states: {

              /**
               * initial state, entered in the landing after complete living
               */
              initial:{
                entry: level(RoomLevelId.Landing),
                on: {
                  GAME_HOTSPOT_ACTION_DONE: [
                    {
                      target: 'completed',
                      cond:(ctx, e) => e.payload === HotspotId.Step5_Stairs,
                      actions: [
                        'addHotspot',
                        (_: GameStateContext, e: GameHotspotEvent) => playActionSound(e.payload)()
                      ],
                    },
                    {
                      actions: [
                        'addHotspot',
                        (_: GameStateContext, e: GameHotspotEvent) => playActionSound(e.payload)()
                      ],
                    }
                  ]
                }
              },

              wait: {
                // after: {
                //   2000: { target: 'completed' }
                // }
                entry: [
                  'actionDelay',
                ],
                on: {
                  GAME_ACTION_DONE: 'completed'
                }
              },

              completed: {
                type: 'final',
              }
            },
          },

          exit: {
            type:'final'
          }

        }

      },


      end: {

        initial: 'initial',

        entry:[
          ambiantLevel(1),
          Tracking.pageViewEndscreen,
        ],

        states : {

          initial: {
            entry: [
              level(RoomLevelId.Outdoor),
              () =>  sound(AUDIO_ID.UI_GAME_SUCCESS)()
            ],
            on: {
              GAME_ACTION_DONE: 'success',
            }
          },

          success: {
          },

          failed: {
            entry: [
              () =>  sound(AUDIO_ID.UI_GAME_OVER)(),
              assign((ctx: GameStateContext) => ({
                doorHovered: -1
              })),
              (c)=>Tracking.timesUp(c.metadata.room)
            ],
            initial: 'initial',
            states: {
              initial: {
                entry: 'actionDelay',
                on: {
                  GAME_ACTION_DONE: 'final',
                },
              },
              final: {
                type: 'final'
              }
            }
          }

        }

      },

    }

  }, {
    actions: {

      spawnSyncService: assign<GameStateContext, GameEvent>({
        syncService: () => spawn(AppService.network.getGameSyncService(), { name: "syncService" })
      }),

      setPlayerIntroPosition: assign<GameStateContext, GameEvent>({position: DefaultGamePlayerPosition()}),
      setPlayerFailPosition : assign<GameStateContext, GameEvent>({position: FailGamePlayerPosition   ()}),
      setPlayerWinPosition  : assign<GameStateContext, GameEvent>({position: WinGamePlayerPosition    ()}),
      setPlayerDebugPosition: assign<GameStateContext, GameEvent>({position: DebugGamePlayerPosition  ()}),

      resetContext: assign<GameStateContext, GameEvent>(()=>CreateContext()),
      resetContextForBackhome : assign<GameStateContext, GameEvent>((c)=>{
        c.isPaused ? Tracking.pauseBackHome() : Tracking.endBackHome()
        return CreateContext()
      }),

      clearRoomCache() {
        AppService.network.clearEventCache()
      },

      addHotspot: assign((ctx, evt) => {
        return {
          hotspots: [...ctx.hotspots, evt.payload as HotspotId]
        }
      }),

      adviseHotspot: assign((ctx, evt) => {
        return {
          advisedHotspots: [...ctx.advisedHotspots, evt.payload as HotspotId]
        }
      }),

      initCountdown: assign<GameStateContext, GameEvent>({
        countdown: MAX_COUNTDOWN
      }),

      togglePause: assign((ctx) => {
        return {
          isPaused: !ctx.isPaused
        }
      }),

      async actionDelay(ctx) {
        if (ctx._manageCountdown) {
          await Delay(2000)
          AppService.state.send('GAME_ACTION_DONE')
        }
      },

      backHome(c) {
        AppService.state.send('BACK_HOME')
      },

      restart() {
        if (AppService.state.state.context.role === Role.XR) {
          AppService.state.send('REQUEST_SESSION')
        }
      },

      startAmbiantMusic(){
        AudioManager.startAmbient()
      }

    },


    services: {

      countdownTick: (ctx) => () => {

        // console.log('countdown tick start', ctx._manageCountdown)

        if (ctx._manageCountdown) {

          let ref = performance.now()

          const id = setInterval(() => {
            const now = performance.now()
            const dt = (now - ref) / 1000


            const gameState = AppService.state.state.context.gameActor.getSnapshot()
            const newCountdown = Math.max( 0, gameState.context.countdown - dt )

            if (!gameState.context.isPaused) {
              AppService.state.send({ type: 'GAME_SET_COUNTDOWN', payload:newCountdown })
            }
            ref = now

          }, 1000);

          return () => clearInterval(id);

        }
        return () => { 0 }
      },


      coughtIfNotCrouched: () => () => {
        AudioManager.play(AUDIO_ID.IA_COUGHT)
        let isCrouched = false
        const id = setInterval(() => {

          const gameState = AppService.state.state.context.gameActor.getSnapshot()
          const ctxIsCrouched = gameState.context.isCrouched
          if( isCrouched !== ctxIsCrouched ) {
            isCrouched = ctxIsCrouched
            if (!isCrouched) {
              AudioManager.fadeIn(AUDIO_ID.IA_COUGHT, 1, .3)
            } else {
              AudioManager.fadeOut(AUDIO_ID.IA_COUGHT, .3)
            }
          }
        }, 200);

        return () => {
          AudioManager.fadeOutStop(AUDIO_ID.IA_COUGHT, .3)
          clearInterval(id);
        }

      }

    },


    guards: {

      outOfTime: (ctx) => {
        return ctx.countdown <= 0
      },

      noMulti: () => {
        return AppService.state.state.context.noMulti
      },

      notDesktop: () => {
        return AppService.state.state.context.role !== Role.Desktop
      }

    }
  })
}

function metadata(metadata: { room?:string, title?: string }) {
  return assign<GameStateContext, GameEvent>({
    metadata
  })
}

function level(roomlevel: RoomLevelId) {
  return assign<GameStateContext, GameEvent>({
    roomlevel
  })
}

function getHotspotLevel(ctx: GameStateContext, state: string, hotspot?: HotspotId): RoomLevelId {
  const currentHotspots =  [...ctx.hotspots, ...(hotspot ? [hotspot] : [])] as HotspotId[]

  if (state === 'game.bedroom.initial' || state === 'game.bedroom.exit_room') {
    const guestroom = currentHotspots.includes(HotspotId.Step1_CloseDoor) ? 'GC' : 'GO' // guestroom closed / guestroom opened
    const window = currentHotspots.includes(HotspotId.Step1_Window) ? 'WO' : 'WC' // window opened / window closed
    const door = state === 'game.bedroom.exit_room' ? 'DO' : 'DC' // door opened / door closed

    return RoomLevelId[`Bedroom_${guestroom}_${window}_${door}` as keyof typeof RoomLevelId]
  }

  if (state === 'game.corridor.initial' || state === 'game.corridor.exit_room') {
    const bedroom = currentHotspots.includes(HotspotId.Step2_CloseDoor) ? 'BC' : 'BO' // bedroom closed / bedroom opened
    const door = state === 'game.corridor.exit_room' ? 'DO' : 'DC' // door opened / door closed

    return RoomLevelId[`Corridor_${bedroom}_${door}` as keyof typeof RoomLevelId]
  }

  if (state === 'game.studyroom.initial' || state === 'game.studyroom.exit_room') {
    const corridor = currentHotspots.includes(HotspotId.Step3_CloseDoor) ? 'CC' : 'CO' // corridor closed / corridor opened
    const window = currentHotspots.includes(HotspotId.Step3_Window) ? 'WO' : 'WC' // window opened / window closed
    const door = state === 'game.studyroom.exit_room' ? 'DO' : 'DC' // door opened / door closed

    return RoomLevelId[`Study_${corridor}_${window}_${door}` as keyof typeof RoomLevelId]
  }

  if (state === 'game.living.initial' || state === 'game.living.exit_room') {
    const study = currentHotspots.includes(HotspotId.Step4_CloseDoor) ? 'SC' : 'SO' // study closed / study openeduseful ?
    const door = state === 'game.living.exit_room' ? 'DO' : 'DC' // door opened / door closed

    return RoomLevelId[`Living_${study}_${door}` as keyof typeof RoomLevelId]
  }

  return ctx.roomlevel
}

function addHotspot(ctx: GameStateContext, hotspot: HotspotId): HotspotId[] {
  return [...ctx.hotspots, hotspot]
}

const { findHotspot } = useHotspots()



function playActionSound(hotspot: HotspotId) {
  if (hotspot === HotspotId.Step1_CloseDoor || hotspot === HotspotId.Step2_CloseDoor || hotspot === HotspotId.Step3_CloseDoor || hotspot === HotspotId.Step4_CloseDoor) {
    return soundCloseDoor()
  }

  if (hotspot === HotspotId.Step2_OpenDoor) {
    // PLAY TRY TO OPEN DOOR
    return () => { return }
  }

  if (hotspot === HotspotId.Step1_Window || hotspot === HotspotId.Step3_Window) {
    return soundOpenWindow()
  }

  if (hotspot === HotspotId.Step3_Bag) {
    return sound(AUDIO_ID.IA_BAG)
  }

  if (hotspot === HotspotId.Step4_Call911) {
    // PLAY CALL 911
    return sound(AUDIO_ID.IA_CALL_911)
  }

  if (hotspot === HotspotId.Step4_WetTowel) {
    return sound(AUDIO_ID.IA_TOWEL)
  }

  if (hotspot === HotspotId.Step5_Lift) {
    return sound(AUDIO_ID.IA_ELEVATOR)
  }

  if (hotspot === HotspotId.Step5_Stairs) {
    // PLAY STAIRS
    return () => { return }
  }

  return () => { return }
}


function fogPreset(fogPreset: FogPreset) {
  return assign<GameStateContext, GameEvent>({
    fogPreset
  })
}

export type GameStateInterpreter = Interpreter<GameStateContext, any, GameEvent, GameStateType>
