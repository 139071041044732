import { Howl, Howler, HowlOptions } from 'howler';
import { mat3, mat4, quat, vec3 } from "gl-matrix";
import { clamp01 } from '@webgl/math';
import gsap from 'gsap';

export enum AUDIO_ID {
  MUSIC_LEVEL_1   = "Music_level_1",
  MUSIC_LEVEL_2   = "Music_level_2",
  MUSIC_LEVEL_3   = "Music_level_3",

  // IA_OPEN_DOOR    = "Open_Door"    ,
  // IA_CLOSE_DOOR   = "Close_Door"   ,

  DOOR_Open_01  = "DOOR_Open-01",
  DOOR_Open_02  = "DOOR_Open-02",
  DOOR_Open_03  = "DOOR_Open-03",
  DOOR_Close_01 = "DOOR_Close-01",
  DOOR_Close_02 = "DOOR_Close-02",
  DOOR_Close_03 = "DOOR_Close-03",

  WINDOW_Open_01 = "WINDOW_Open-01",
  WINDOW_Open_02 = "WINDOW_Open-02",
  WINDOW_Open_03 = "WINDOW_Open-03",
  
  ELEVATOR_OPEN = "ELEVATOR_Open",

  IA_TOWEL        = "Towel"        ,
  IA_BAG          = "BAG_grab"     ,
  IA_ELEVATOR     = "ELEVATOR_Open"     ,
  IA_NOTIFICATION = "Notification" ,
  IA_CALL_911     = "PHONE_911" ,
  IA_COUGHT       = "SMOKE" ,


  FIRE_Position_Mid  = "FIRE_Position-Mid" , 
  FIRE_Position_Low  = "FIRE_Position-Low" , 
  FIRE_Position_High = "FIRE_Position-High", 
  FIRE_Mid           = "FIRE_Mid"          , 
  FIRE_Low           = "FIRE_Low"          , 
  FIRE_High          = "FIRE_High"         ,

  UI_ACTION_fail      = "UI_ACTION_fail",
  UI_ACTION_Success   = "UI_ACTION_Success",
  UI_CLICK_Main       = "UI_CLICK_Main",
  UI_CLICK_Secondary  = "UI_CLICK_Secondary",
  UI_GAME_OVER        = "UI_GAME-OVER",
  UI_GAME_SUCCESS     = "UI_GAME-SUCCESS",
  UI_HOTSPOT          = "UI_HOTSPOT",
  UI_HOVER            = "UI_HOVER",
  UI_POP_IN           = "UI_POP-IN",
  UI_SAY_HELLO        = "UI_SAY-HELLO",
  UI_SYNC_Ko          = "UI_SYNC_Ko",
  UI_SYNC_Ok          = "UI_SYNC_Ok",
}

const VZERO = vec3.fromValues(0.0, 0.0, 0.0);
const VUP = vec3.fromValues(0.0, 1.0, 0.0);
const VZ = vec3.fromValues(0.0, 0.0, -1.0);
const V3A = vec3.create();
const V3B = vec3.create();
const V3C = vec3.create();
const QUAT = quat.create();
const MAT3 = mat3.create();

type Bounds = {bounds:[number, number]}



export class AudioLib {
  _audio: Map<AUDIO_ID, Howl> = new Map<AUDIO_ID, Howl>()
  ambients: number[];
  ambientLevel = 1


  getRandomOpenDoor(){
    const r = Math.random();
    if(r < 0.33) return AUDIO_ID.DOOR_Open_01;
    if(r < 0.66) return AUDIO_ID.DOOR_Open_02;
    return AUDIO_ID.DOOR_Open_03;
  }

  getRandomCloseDoor(){
    const r = Math.random();
    if(r < 0.33) return AUDIO_ID.DOOR_Close_01;
    if(r < 0.66) return AUDIO_ID.DOOR_Close_02;
    return AUDIO_ID.DOOR_Close_03;
  }

  getRandomOpenWindow(){
    const r = Math.random();
    if(r < 0.33) return AUDIO_ID.WINDOW_Open_01;
    if(r < 0.66) return AUDIO_ID.WINDOW_Open_02;
    return AUDIO_ID.WINDOW_Open_03;
  }

  constructor() {
    Howler.autoSuspend = false;
    Howler.html5PoolSize = 0;
    Howler.usingWebAudio = true;

    // AMBIENT
    this.addAudio(AUDIO_ID.MUSIC_LEVEL_1, { loop: true, volume: 0, bounds:[2666, 32000] });
    this.addAudio(AUDIO_ID.MUSIC_LEVEL_2, { loop: true, volume: 0, bounds:[2666, 32000] });
    this.addAudio(AUDIO_ID.MUSIC_LEVEL_3, { loop: true, volume: 0, bounds:[2666, 32000] });
    // this.addAudio(AUDIO_ID.MUSIC_LEVEL_3, { loop: true, volume: 0, bounds:[1333, 33333] });

    this.addAudio(AUDIO_ID.FIRE_Position_Mid  , { loop: true, volume: 0, bounds:[1000,11000]});
    this.addAudio(AUDIO_ID.FIRE_Position_Low  , { loop: true, volume: 0, bounds:[1000,11000]});
    this.addAudio(AUDIO_ID.FIRE_Position_High , { loop: true, volume: 0, bounds:[1000,11000]});
    this.addAudio(AUDIO_ID.FIRE_Mid           , { loop: true, volume: 0, bounds:[1000,16000]});
    this.addAudio(AUDIO_ID.FIRE_Low           , { loop: true, volume: 0, bounds:[1000,16000]});
    this.addAudio(AUDIO_ID.FIRE_High          , { loop: true, volume: 0, bounds:[1000,16000]});

    // INTERACTIONS
    // this.addAudio(AUDIO_ID.IA_OPEN_DOOR);
    // this.addAudio(AUDIO_ID.IA_CLOSE_DOOR);
    this.addAudio(AUDIO_ID.DOOR_Open_01);
    this.addAudio(AUDIO_ID.DOOR_Open_02);
    this.addAudio(AUDIO_ID.DOOR_Open_03);
   
    this.addAudio(AUDIO_ID.DOOR_Close_01);
    this.addAudio(AUDIO_ID.DOOR_Close_02);
    this.addAudio(AUDIO_ID.DOOR_Close_03);
   
    this.addAudio(AUDIO_ID.WINDOW_Open_01);
    this.addAudio(AUDIO_ID.WINDOW_Open_02);
    this.addAudio(AUDIO_ID.WINDOW_Open_03);


    this.addAudio(AUDIO_ID.IA_TOWEL);
    this.addAudio(AUDIO_ID.IA_BAG);
    this.addAudio(AUDIO_ID.IA_ELEVATOR);
    this.addAudio(AUDIO_ID.IA_NOTIFICATION);
    this.addAudio(AUDIO_ID.IA_CALL_911);
    this.addAudio(AUDIO_ID.IA_COUGHT, {loop:true});

    this.addAudio(AUDIO_ID.UI_ACTION_fail     , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_ACTION_Success  , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_CLICK_Main      , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_CLICK_Secondary , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_GAME_OVER       , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_GAME_SUCCESS    , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_HOTSPOT         , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_HOVER           , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_POP_IN          , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_SAY_HELLO       , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_SYNC_Ko         , { volume: 0.5 });
    this.addAudio(AUDIO_ID.UI_SYNC_Ok         , { volume: 0.5 });

    this.addAudio(AUDIO_ID.IA_COUGHT, {loop:true});

    if (process.env.VUE_APP_SOUND_OFF === 'true') {
      Howler.volume(0);
    }

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        Howler.mute(false)
      } else {
        Howler.mute(true)
      }
    });
  }

  updateListener(listenerMatrix: mat4) {
    vec3.transformMat4(V3A, VZERO, listenerMatrix);
    mat3.fromMat4(MAT3, listenerMatrix);
    quat.fromMat3(QUAT, MAT3);
    vec3.transformMat3(V3B, VZ, MAT3);
    vec3.transformMat3(V3C, VUP, MAT3);
    Howler.pos(V3A[0], V3A[1], V3A[2]);
    Howler.orientation(V3B[0], V3B[1], V3B[2], V3C[0], V3C[1], V3C[2]);
  }

  setMute(flag: boolean) {
    Howler.volume(flag ? 0 : 1);
  }

  getAudio(id: AUDIO_ID) {
    return this._audio.get(id);
  }

  play(id: AUDIO_ID) {
    const howl = this._audio.get(id);
    if (!howl || howl.playing())
      return;
    howl.seek(0);
    
    const sprite = ( (howl as any)._sprite.main )? "main" : undefined;
    return howl.play(sprite)
  }

  stop(id: AUDIO_ID) {
    this._audio.get(id).stop();
  }

  fadeOut(id: AUDIO_ID, duration = 1000): Promise<number> {
    const howl = this._audio.get(id);
    howl.off("fade");
    howl.fade(howl.volume(), 0, duration);
    return new Promise((resolve) => {
      howl.once("fade", resolve);
    })
  }


  startAmbient() {
    this.ambients = [
      this.play(AUDIO_ID.MUSIC_LEVEL_1),
      this.play(AUDIO_ID.MUSIC_LEVEL_2),
      this.play(AUDIO_ID.MUSIC_LEVEL_3),
    ]
    this.updateAmbientLevels()
  }

  setAmbientLevel(level: 1|2|3 ) {
    console.log('Ambient level', level);
    
    gsap.killTweensOf(this,'ambientLevel');
    gsap.to(this, {ambientLevel: level, duration: 1, onUpdate: ()=>{this.updateAmbientLevels()}});
  }

  updateAmbientLevels() {
    let v:number;

    v = clamp01(1-Math.abs( this.ambientLevel - 1 ));
    this.getAudio(AUDIO_ID.MUSIC_LEVEL_1).volume(v);

    v = clamp01(1-Math.abs( this.ambientLevel - 2 ));
    this.getAudio(AUDIO_ID.MUSIC_LEVEL_2).volume(v);

    v = clamp01(1-Math.abs( this.ambientLevel - 3 ));
    this.getAudio(AUDIO_ID.MUSIC_LEVEL_3).volume(v);
    
  }


  fadeIn(id: AUDIO_ID, volume = 1.0, duration= 1000): Promise<number> {
    const howl = this._audio.get(id);

    if (!howl.playing())
      this.play(id);

    howl.off("fade");
    howl.fade(howl.volume(), volume, duration);

    return new Promise((resolve) => {
      howl.once("fade", resolve);
    })

  }

  fadeOutStop(id: AUDIO_ID, duration = 1000) {
    this.fadeOut(id, duration).then(() => this.stop(id));
  }

  addAudio(id: AUDIO_ID, opts: Partial<HowlOptions & Bounds>  = {}) {
    const _opts = {
      preload: true,
      autoplay: false
    };

    if( opts.bounds ){
      opts.sprite = {main:[opts.bounds[0], opts.bounds[1] - opts.bounds[0]]};
    }
    Object.assign(_opts, opts);

    const fileName = id;

    const howl = new Howl({
      src: [require(`@/assets/audios/${fileName}.mp3`)],
      ..._opts
    });

    this._audio.set(id, howl);

    return howl;
  }

}

const _instance = new AudioLib();

export default _instance