var fn = function( obj ){
var __t,__p='';
__p+='precision lowp float;\n\nuniform vec3 uParams;\n\nvarying vec2 vUVs;\n\n#define OPACITY uParams.z\n\n\nvoid main( void ){\n  float fade = 1.0 - vUVs.x*vUVs.x;\n  // float y = vUVs.y*2.5;\n  // fade *= pow( exp(-y*y), .1);\n  gl_FragColor = vec4(1.0, 1.0, 1.0, fade*OPACITY);\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
