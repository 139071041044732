import { GTMTrackingData } from "@/@types/gtm"
import AppService from "@/services/AppService"
import { Role } from "@/services/states/AppStateMachine"
import Viewport from "@/store/modules/Viewport"

type TrackingEvent =
  'select_content'

type ContentType  =
  'important-information' |
  'start-the-experience' |
  'about' |
  'refresh' |
  'sync-ok' |
  'intro-next' |
  'tuto-move' |
  'tuto-crouch' |
  'tuto-door' |
  'tuto-exit' |
  'tuto-guide-advice' |
  'say-hello' |
  'select-hotspot' |
  'highlight-hotpost' |
  'pause-back-home' |
  'start-again' |
  'end-back-home' |
  'learn-more' |
  'do-you-know' |
  'end-select-hotpost' |

  'times-up' |
  'learn-more' |
  'map-switch-action' |
  'terms' |
  'privacy' |
  'cookies' 



function role(){
  let r = Role[AppService.state.getSnapshot().context.role]
  if( r === 'Desktop' && Viewport.isMobile) r = 'Mobile'
  return r;
}

export default class Tracking {

  static track(data: GTMTrackingData): void {
    window.dataLayer.push(data)
  }

  
  private static _event(event: TrackingEvent, type: ContentType, item_id = '', data: GTMTrackingData = {}): void {
    data.event        = event
    data.content_type = type
    data.item_id      = item_id
    data.device      = role()
    console.log('Tracking event', data );
    window.dataLayer?.push(data)
  }
  
  
  private static _pageview( page_location: string, page_title :string): void {
    const event        = 'page_view'
    const data = {
      event, 
      page_location,
      page_title
    }
    // data.device      = Role[AppService.state.getSnapshot().context.role]
    console.log('Tracking pageview', data );
    window.dataLayer?.push(data)
  }
  
  
  public static selectContent         ( contenttype:ContentType, item_id = '' ){
    Tracking._event('select_content', contenttype, item_id)
  }


  public static importantInformation        (){ Tracking.selectContent('important-information') }
  public static startTheExperience          (){ Tracking.selectContent('start-the-experience' ) }
  public static about                       (){ Tracking.selectContent('about'                ) }
  public static refresh                     (){ Tracking.selectContent('refresh'              ) }
  public static syncOk                      (){ Tracking.selectContent('sync-ok'              ) }
  public static introNext                   (){ Tracking.selectContent('intro-next'           ) }
  public static tutoMove                    (){ Tracking.selectContent('tuto-move'            ) }
  public static tutoCrouch                  (){ Tracking.selectContent('tuto-crouch'          ) }
  public static tutoDoor                    (){ Tracking.selectContent('tuto-door'            ) }
  public static tutoExit                    (){ Tracking.selectContent('tuto-exit'            ) }
  public static tutoGuideAdvice             (){ Tracking.selectContent('tuto-guide-advice'    ) }
  public static sayHello                    (){ Tracking.selectContent('say-hello'            ) }
  public static pauseBackHome               (){ Tracking.selectContent('pause-back-home'      ) }
  public static startAgain                  (){ Tracking.selectContent('start-again'          ) }
  public static endBackHome                 (){ Tracking.selectContent('end-back-home'        ) }
  public static doYouKnow                   (){ Tracking.selectContent('do-you-know'          ) }
  public static endSelectHotpostPlaceAction (){ Tracking.selectContent('end-select-hotpost'   ) }

  public static learnMore                   (){ Tracking.selectContent('learn-more'           ) }
  public static mapSwitchAction             (){ Tracking.selectContent('map-switch-action'    ) }
  public static terms                       (){ Tracking.selectContent('terms'                ) }
  public static privacy                     (){ Tracking.selectContent('privacy'              ) }
  public static cookies                     (){ Tracking.selectContent('cookies'              ) }
  
  
  public static timesUp                     (room:string){ Tracking.selectContent('times-up', room             ) }
  public static selectHotspotPlaceAction    (item_id:string){ Tracking.selectContent('select-hotspot', item_id    )}
  public static highlightHotpost            (item_id:string){ Tracking.selectContent('highlight-hotpost', item_id )}
  
  

  public static pageViewHomepage   (){ Tracking._pageview('/'          , 'homepage'   )}
  public static pageViewExperience (){ Tracking._pageview('/experience', 'experience' )}
  public static pageViewEndscreen  (){ Tracking._pageview('/end'       , 'endscreen'  )}
  
  public static pageViewSync       (){ 
    if( AppService.state.getSnapshot().context.role === Role.Desktop ) return
    Tracking._pageview('/sync'      , 'sync'       )
  }

///////////////
//////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
///
////////////
}
