import Signal from "@/core/Signal";
import Picking from "@webgl/engine/Picking";
import Ray from "@webgl/math/Ray";
import { GltfModuleIO } from "@webgl/resources/GltfResource";
import { vec3 } from "gl-matrix";
import GltfLoader from "nanogl-gltf/lib/io/GltfLoader";








const ZoneIdsList = [
  "guestroom",
  "bedroom"  ,
  "study"    ,
  "living"   ,
  "wc"       ,
  "corridor" ,
  "landing"  ,
] as const

export type ZoneId = typeof ZoneIdsList[number]

const ray = new Ray()
ray.direction.set([0, -1, 0])




class Zone {
  
  constructor( readonly id : ZoneId, readonly picking : Picking ){
  }


}

/**
 * segment room scene into zones
 * trigger events when player move around and chage zone
 */
export default class Zones {

  zones: Zone[] = [];

  onZoneChange = new Signal<ZoneId>()

  private _currentZone: ZoneId = null;

  public get currentZone(): ZoneId {
    return this._currentZone;
  }

  reset() {
    this._currentZone = 'guestroom';
  }

  async load(): Promise<void> {

    const loader = new GltfLoader(new GltfModuleIO(), "zones.glb", {
      // abortSignal: this.abortSignal
    });

    const gltf = await loader.load();
    const nodes = gltf.nodes.filter(n => n.mesh)
    // nodes.forEach(n => console.log(n.name));

    for (const id of ZoneIdsList) {
      const node = nodes.find(n => n.name === "zone_"+id)
      if (!node) throw new Error(`Missing Zone ${id} in gltf file`)
      this.zones.push(new Zone(id, Picking.createFromGltfNode(node)))
    }
      
  }

  /**
   * called by PlayerPosition controller
   */
  setPlayerPosition(p: vec3) {
    ray.origin.set([p[0], 10, p[2]])

    for (let i = 0; i < this.zones.length; i++) {
      const zone = this.zones[i];
      if( zone.picking.raycast(ray) !== 0 ){
        if( this._currentZone !== zone.id ){
          this._currentZone = zone.id
          this.onZoneChange.dispatch(zone.id)
        }
        return
      }
      
    }
  }

}