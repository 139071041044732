var fn = function( obj ){
var __t,__p='';
__p+='attribute vec2 aPosition;\nattribute vec2 aTexCoord;\n\nuniform mat4 uMVP;\n\nvarying vec2 vTexCoord;\n\nvoid main(void){\n  gl_Position = uMVP * vec4(aPosition, 0.0, 1.0);\n  vTexCoord = aTexCoord;\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
