
import Ray from '@webgl/math/Ray';
import { mat3, mat4, vec3, vec4 } from 'gl-matrix'
import Node from "nanogl-node";
import GltfNode from "nanogl-gltf/lib/elements/Node";
import Gltf from 'nanogl-gltf/lib/Gltf';

const edge1 = vec3.create();
const edge2 = vec3.create();
const normal = vec3.create();
const diff = vec3.create();
const VEC3 = vec3.create();
const M4 = mat4.create();
const M3 = mat3.create();

const ORIG = vec3.create();
const DIR = vec3.create();

const DEFAULT_POS = vec4.create()
const DEFAULT_NRM = vec3.create()


class Picking {

  id: string;
  node: Node;
  indices: ArrayLike<number>;
  vertices: ArrayLike<number>;
  doubleSided: boolean;

  static extractFromGltfNode(gltf: Gltf, nodename:string): Picking {

    const node = gltf.getNode(nodename)
    console.assert(node != null, `Node ${nodename} not found`)

    const i = gltf.renderables.findIndex(r => r.node.name === nodename)
    gltf.renderables.splice(i, 1)

    return Picking.createFromGltfNode(node)
  }


  static createFromGltfNode(node:GltfNode): Picking {

    const mesh = node.mesh
    console.assert( mesh!=null, 'NavMesh mesh not found' )
    console.assert( node!=null, 'NavMesh node not found' )
  
    const pAccessor = mesh.primitives[0].attributes.getSemantic( 'POSITION' ).accessor
    const positions = pAccessor.createElementHolderArray( pAccessor.count )
    pAccessor.getValues( positions, 0, pAccessor.count )
  
    const iAccessor = mesh.primitives[0].indices
    const indices = iAccessor.createElementHolderArray( iAccessor.count )
    iAccessor.getValues( indices, 0, iAccessor.count )
  
    return new Picking(node, indices, positions)
  }



  constructor(node: Node, indices: ArrayLike<number>, vertices: ArrayLike<number>, doubleSided=false) {

    this.id = '';

    this.node = node;
    this.indices = indices;
    this.vertices = vertices;
    this.doubleSided = doubleSided

  }

  raycast(ray: Ray, pos=DEFAULT_POS , nrm=DEFAULT_NRM): number {

    mat4.invert(M4, this.node._wmatrix);
    mat3.fromMat4(M3, M4);
    vec3.transformMat4(ORIG, ray.origin, M4);
    vec3.transformMat3(DIR, ray.direction, M3);

    const origin = ORIG; //ray.pos;
    const rayDir = DIR;  //ray.dir;
    const faces = this.indices;
    const verts = this.vertices;

    let v0, v1, v2;
    let sign;


    for (let i = 0; i < faces.length; i += 3) {

      v0 = faces[i + 0] * 3;
      v1 = faces[i + 1] * 3;
      v2 = faces[i + 2] * 3;

      edge1[0] = verts[v1 + 0] - verts[v0 + 0];
      edge1[1] = verts[v1 + 1] - verts[v0 + 1];
      edge1[2] = verts[v1 + 2] - verts[v0 + 2];

      edge2[0] = verts[v2 + 0] - verts[v0 + 0];
      edge2[1] = verts[v2 + 1] - verts[v0 + 1];
      edge2[2] = verts[v2 + 2] - verts[v0 + 2];

      vec3.cross(normal, edge1, edge2);

      let DdN = vec3.dot(rayDir, normal);

      if (DdN > 0.0) {
        if (!this.doubleSided) continue;
        sign = 1.0;
      } else if (DdN < 0.0) {
        sign = -1.0;
        DdN = -DdN;
      } else {
        continue;
      }

      diff[0] = origin[0] - verts[v0 + 0];
      diff[1] = origin[1] - verts[v0 + 1];
      diff[2] = origin[2] - verts[v0 + 2];

      vec3.cross(VEC3, diff, edge2);
      const DdQxE2 = sign * vec3.dot(rayDir, VEC3);
      if (DdQxE2 < 0.0) {
        continue;
      }

      vec3.cross(VEC3, edge1, diff);
      const DdE1xQ = sign * vec3.dot(rayDir, VEC3);
      if (DdE1xQ < 0.0) {
        continue;
      }

      if (DdQxE2 + DdE1xQ > DdN) {
        continue;
      }


      const QdN = - sign * vec3.dot(diff, normal);
      if (QdN < 0) {
        continue;
      }

      // i pos
      vec3.scaleAndAdd(VEC3, origin, rayDir, QdN / DdN);
      vec3.transformMat4(VEC3, VEC3, this.node._wmatrix);

      pos[0] = VEC3[0]
      pos[1] = VEC3[1]
      pos[2] = VEC3[2]
      pos[3] = QdN / DdN // dist

      // i normal
      // vec3.transformMat3(normal, normal, M3);
      vec3.normalize(normal, normal);

      nrm[0] = normal[0];
      nrm[1] = normal[1];
      nrm[2] = normal[2];

      return sign;

    }

    return 0;

  }


}

export default Picking;