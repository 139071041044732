import RoomLevel from "@webgl/activities/common/controllers/RoomLevel";
import Controller from "@webgl/activities/common/controllers/Controller";
import XRActivity from "../XRActivity";
import FogController from "@webgl/activities/common/controllers/FogController";
import PickingManager from "@webgl/activities/xr/controllers/PickingManager";
import PlayerPosition from "@webgl/activities/xr/controllers/PlayerPosition";
import TutoController from "@webgl/activities/xr/controllers/TutoController";
import InputController from "@webgl/activities/xr/controllers/InputController";
import IlluminatorScreen from "@webgl/activities/xr/controllers/IlluminatorScreen";
import VignetteController from "@webgl/activities/common/controllers/VignetteController";
import VibrationsController from "@webgl/activities/xr/controllers/VibrationsController";
import InterfacesController from "@webgl/activities/xr/controllers/InterfacesController";
import NavigationController from "./NavigationController";
import ZoneEvents from "@webgl/activities/common/controllers/ZoneEvents";

export default class XRControllers {
  controllers: Controller[] = []
  readonly playerCtrl: PlayerPosition;
  readonly pickingManager: PickingManager;
  readonly illuminatorScreen: IlluminatorScreen;

  constructor( private activity: XRActivity ){
    this.playerCtrl = new PlayerPosition(activity)
    this.controllers.push(this.playerCtrl)

    this.pickingManager = new PickingManager(activity)
    this.controllers.push(this.pickingManager)

    this.illuminatorScreen = new IlluminatorScreen(activity)
    this.controllers.push(this.illuminatorScreen)

    this.controllers.push(new RoomLevel           (activity))
    this.controllers.push(new FogController       (activity))
    this.controllers.push(new TutoController      (activity))
    this.controllers.push(new InputController     (activity))
    this.controllers.push(new VignetteController  (activity))
    this.controllers.push(new VibrationsController(activity))
    this.controllers.push(new InterfacesController(activity))
    this.controllers.push(new NavigationController(activity))
    this.controllers.push(new ZoneEvents          (activity))
  }

  start(){
    this.controllers.forEach(ctrl => ctrl.start())
  }

  stop() {
    this.controllers.forEach(ctrl => ctrl.stop())
  }

  reset() {
    this.controllers.forEach(ctrl => ctrl.reset())
  }
}