var fn = function( obj ){
var __t,__p='';
__p+='// this is a blur shader, but named scatter because it\'s used for the fog light scattering and may\n// be adapted into something more specific than a blur shader\n\nuniform float uKernel[KERNEL_SIZE];\nuniform vec2 uStepSize;\nuniform vec2 uStepOffset;\nuniform float uThreshold;\nuniform sampler2D tInput;\n\nvarying vec2 vTexCoord0;\n\nvec4 sampleTex(vec2 uv)\n{\n    return max(texture2D(tInput, uv) - uThreshold, 0.0);\n}\n\nvoid main() {\n    float totalW = uKernel[0];\n    gl_FragColor = sampleTex(vTexCoord0);\n\n    for (int i = 0; i < KERNEL_SIZE; ++i) {\n        float w = uKernel[i];\n        vec2 offs = (float(i)-float(KERNEL_SIZE)*0.5) * uStepSize + uStepOffset;\n        gl_FragColor += (sampleTex(vTexCoord0 + offs)) * w;\n        totalW += w;\n    }\n\n    gl_FragColor /= totalW;\n}\n';
return __p;
};
fn.toString=fn;
module.exports = fn;
/////////////

/////////////////////
///////////////////////
//////////////////////////////
 
////////////////////////////
///////////////////////////////////////
/////////////
///
 

/////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////
///
//
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////

//////////
//
////////////////////////////////
////////////////////////////////////////////
///////
////
//////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
////////
/////
///

/////////
fn.onHmr = function(){}
//////////
